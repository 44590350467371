/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelIntroInstructions } from '../../commonComponents/GuidancePanelIntroInstructions';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function AccessibleScalableAIIntroPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Accessible & Scalable AI"
        description={
          <>
            Accessible artificial intelligence (AI) enables natural interactions that reflect the reality of human
            conversation. Customers can interrupt, overshare, misspell, misspeak, be overly vague, or be overly specific
            without confusing their virtual assistant or running into a dead end.
            <br />
            <br />
            Scalable AI delivers fast time-to-value and a low cost of ownership. Use out-of-the-box language models and
            pre-built conversational flows customized to your industry and your customers' languages to launch quickly.
            Then, scale rapidly with AI that's purposefully embedded into the product in a way that's easy for
            non-technical business users to customize and control.
          </>
        }
      />
      <GuidancePanelIntroInstructions />
    </div>
  );
}

const AccessibleScalableAIIntroPanelConfig: SectionPanelConfig = {
  title: 'Accessible & Scalable AI',
  SectionPanelComponent: AccessibleScalableAIIntroPanel,
  panel: LendyrPanel.HOME,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.ACCESSIBLE_SCALABLE_AI_INTRO,
};

export { AccessibleScalableAIIntroPanel, AccessibleScalableAIIntroPanelConfig };
