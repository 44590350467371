/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const DO_PANEL_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Build a custom extension',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-build-custom-extension',
    type: 'Docs',
  },
  {
    title: 'Use a custom extension',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-call-extension',
    type: 'Docs',
  },
  {
    title: 'Create Extensions',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/extensions',
    type: 'Tutorial',
  },
];

function DoPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Do"
        description="For high-value transactional use cases; urgent or time-sensitive tasks; or high-volume requests that consume your contact center, help your customers through actions, not words."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Log in to Lendyr by clicking on the "Login" button, then "Enter".
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Enter the following in the assistant window:
          <GuidancePanelTextSnippet text="I want to get a new debit card" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Select "Open a new account", and then pick a type of account.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Schedule an appointment to pick up the new debit card.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile codeNecessary data={DO_PANEL_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const DoPanelConfig: SectionPanelConfig = {
  title: 'Do',
  SectionPanelComponent: DoPanel,
  panel: LendyrPanel.DO,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.DO,
};

export { DoPanel, DoPanelConfig };
