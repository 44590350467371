/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2019, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import Cookies from 'js-cookie';
import { v4 as originalUUID } from 'uuid';

import { IS_SESSION_STORAGE, isSecureHost } from '../utils/browserUtils';

/**
 * This module is responsible for managing the storage of anonymousUserId. We use an anonymousUserId if the userId is
 * not set in order to not repeat bill unique users that are anonymous. The id is stored (preferentially) as a 1st
 * party cookie that expires in 45 days. The expiration date is, so we don't leave data sitting in the user's browser
 * for a long period of time per IBM policy. The period of 45 days is chosen so that the cookie does not expire
 * within a given month of so which would result in the user being detected as a new user and thus causing the
 * client to be billed for an additional user. The number should be between 32 days (the minimum to cover a whole month)
 * and 365 days (the maximum allowed by IBM policy).
 */

// The length of cookie the anonymous userID cookie should live in days. This may be fractions of days if needed.
const COOKIE_DURATION = 45; // Days

// The prefix attached to all anonymous user ids.
const ANONYMOUS_USER_ID_PREFIX = 'anonymous_IBMuid-';

// The key in browser storage that contains the session id for a particular user.
const ANONYMOUS_USER_ID_KEY = 'IBM_WAC_ANONYMOUS_USER_ID';

/**
 * Returns the anonymousUserId as recorded in local storage. If there is no defined anonymousUserId, this
 * will return undefined.
 */
function getAnonymousUserID(): string {
  // Return the value of the cookie, looking in session storage first as that's our fallback if cookies aren't
  // available.
  let userID;
  if (IS_SESSION_STORAGE()) {
    userID = window.sessionStorage.getItem(ANONYMOUS_USER_ID_KEY) || Cookies.get(ANONYMOUS_USER_ID_KEY);
  } else {
    userID = Cookies.get(ANONYMOUS_USER_ID_KEY);
  }

  // If there is an ID, we need to do a "set" again which will update the expiration date on the cookie.
  if (userID) {
    setAnonymousUserId(userID);
  }

  return userID || null;
}

/**
 * Sets the anonymousUserId in local storage.
 */
function setAnonymousUserId(anonymousUserId: string) {
  Cookies.set(ANONYMOUS_USER_ID_KEY, anonymousUserId, {
    expires: COOKIE_DURATION,
    sameSite: 'lax',
    secure: isSecureHost(),
  });
  if (!Cookies.get(ANONYMOUS_USER_ID_KEY) && IS_SESSION_STORAGE()) {
    // If the cookie failed to get set, it means we're in a higher security level and we need to attempt to use
    // session storage instead. If tracking is disabled, browsers may disable localStorage and cookies, but still allow
    // session storage. Falling back to session storage at least allows us to not bill each page view as an
    // individual user! https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Privacy/Storage_access_policy
    window.sessionStorage.setItem(ANONYMOUS_USER_ID_KEY, anonymousUserId);
  }
}

/**
 * Generated a new anonymousUserId (MUST start with anonymous_ to be handled correctly downstream).
 */
function generateAndSetAnonymousUserId() {
  const anonymousUserId = `${ANONYMOUS_USER_ID_PREFIX}${originalUUID()}`;
  setAnonymousUserId(anonymousUserId);
  return anonymousUserId;
}

export { getAnonymousUserID, setAnonymousUserId, generateAndSetAnonymousUserId };
