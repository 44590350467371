/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { FAQ_PAGE_CUSTOM_ELEMENT_ID } from '../../../pages/FAQPage';
import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel, RestartType } from '../../demoTypes';
import { demoActions } from '../../store/demoActions';
import { trackDemoFeatureFinished } from '../../utils/demoTracking';
import { POSITION_WEB_CHAT_CONFIG_OPTIONS } from '../../utils/demoWebChatConfigOptions';
import { DemoPanelTransitionConfig } from '../demoTransitionTypes';

const POSITION_TRANSITION_CONFIG: DemoPanelTransitionConfig = {
  doTransitionWithWebChat: (store, instance) => {
    instance.updateHomeScreenConfig({ is_on: false });
    trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.POSITION);

    return () => {
      const { demoSiteParamsAndHash, isWebChatLoaded } = store.getState();
      // If the user is leaving this panel, reload web chat since it no longer needs to be rendered in a custom
      // element, If web chat isn't loaded, this means the user isn't on the FAQ page where web chat can be loaded.
      if (isWebChatLoaded && demoSiteParamsAndHash.panel !== LendyrPanel.POSITION) {
        store.dispatch(demoActions.doRestartType(RestartType.RELOAD_WEB_CHAT));
      }

      instance.updateHomeScreenConfig({ is_on: true });
    };
  },

  getWebChatConfigOptions: () => ({
    ...POSITION_WEB_CHAT_CONFIG_OPTIONS,
    element: document.getElementById(FAQ_PAGE_CUSTOM_ELEMENT_ID),
  }),

  pagesWithWebChat: [LendyrPage.FAQ],
};

export { POSITION_TRANSITION_CONFIG };
