/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Store } from 'redux';

import { lendyrActions } from '../../lendyr/store/lendyrActions';
import { LendyrAppState } from '../../lendyr/store/LendyrAppState';
import { CustomMenuType } from '../../web-chat-dependencies/types/CustomMenuOptions';
import {
  BusEventSend,
  BusEventType,
  BusEventWindowPreClose,
  MainWindowCloseReason,
} from '../../web-chat-dependencies/types/eventBusTypes';
import { WatsonAssistantChatInstance } from '../../web-chat-dependencies/types/WatsonAssistantChatInstance';
import { ResolvablePromise, resolvablePromise } from '../../web-chat-dependencies/utils/resolvablePromise';
import { PostChatSurveyState } from './customPanelConstants';

/**
 * This function handles setting the web chat event listeners to trigger the post-chat survey in web chat after
 * sending a message and minimizing web chat, or clicking "Rate chat" button in the custom menu options.
 *
 * Note: The post-chat survey should be available at any time during the essentials tour, which is why this isn't
 * handled in a transition.
 */
function postChatSurveySetup(store: Store<LendyrAppState>, instance: WatsonAssistantChatInstance) {
  const { postChatSurveyState } = store.getState().persistedToBrowserSessionStorage.siteData;
  const panelInstance = instance.customPanels.getPanel();
  // Track if the user has sent a message in order to determine if the send listener needs to be set again on restart.
  let resetSendHandler = postChatSurveyState !== PostChatSurveyState.HIDE;

  let windowPreCloseResolvablePromise: ResolvablePromise;
  let windowPreCloseEvent: BusEventWindowPreClose;

  function resolveWindowPreClosePromise() {
    if (windowPreCloseEvent && windowPreCloseResolvablePromise) {
      windowPreCloseResolvablePromise.doResolve();
      windowPreCloseResolvablePromise = null;
      windowPreCloseEvent = null;
    }
  }

  /**
   * When the user goes to close the widget, determine if the post chat survey needs to be opened before closing
   * the widget, or do nothing at all.
   */
  function windowPreCloseHandler(event: BusEventWindowPreClose) {
    const { postChatSurveyState } = store.getState().persistedToBrowserSessionStorage.siteData;
    // If the user hasn't completed the survey, we need to display the survey before closing the widget. We should
    // prevent the post-chat survey from triggering when a journey tour is being opened.
    if (event.reason !== MainWindowCloseReason.OPEN_TOUR && postChatSurveyState === PostChatSurveyState.SHOW) {
      const promise = resolvablePromise();

      windowPreCloseEvent = event;
      windowPreCloseResolvablePromise = promise;
      panelInstance.open({ hidePanelHeader: true });

      return promise;
    }

    return null;
  }

  /**
   * Prevent the widget from closing on restart so the user can send a message to trigger the post-chat survey again.
   */
  function restartConversationHandler() {
    if (windowPreCloseEvent && windowPreCloseResolvablePromise) {
      windowPreCloseEvent.cancelClose = true;
      resolveWindowPreClosePromise();
    }

    if (resetSendHandler) {
      resetSendHandler = false;
      instance.once({ type: BusEventType.SEND, handler: sendEventHandler });
    }
  }

  /**
   * When the user sends a message to the assistant, handle creating a session storage value to remember if the
   * survey should prevent the widget from closing.
   */
  function sendEventHandler(event: BusEventSend) {
    const { postChatSurveyState } = store.getState().persistedToBrowserSessionStorage.siteData;

    if (postChatSurveyState === PostChatSurveyState.HIDE && event.data.input.text) {
      resetSendHandler = true;
      store.dispatch(
        lendyrActions.updateSessionStorageStateSiteData({
          postChatSurveyState: PostChatSurveyState.SHOW,
        }),
      );
    }
  }

  instance.once({ type: BusEventType.SEND, handler: sendEventHandler });
  instance.on([
    { type: BusEventType.WINDOW_PRE_CLOSE, handler: windowPreCloseHandler },
    { type: BusEventType.RESTART_CONVERSATION, handler: restartConversationHandler },
    { type: BusEventType.CUSTOM_PANEL_PRE_CLOSE, handler: resolveWindowPreClosePromise },
  ]);

  // Add a menu option that opens the post-chat survey so the user can provide feedback at any time.
  instance.updateCustomMenuOptions(CustomMenuType.BOT, [
    {
      text: 'Rate chat',
      handler: () => panelInstance.open({ hidePanelHeader: true }),
    },
  ]);
}

export { postChatSurveySetup };
