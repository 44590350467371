/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

/**
 * This component serves to mock the carbon text input to prevent users from actually using it.
 */
function MockTextInput({ labelText, value, helperText }: { labelText: string; value: string; helperText?: string }) {
  return (
    <div className="bx--form-item bx--text-input-wrapper bx--text-input-wrapper--readonly bx--text-input-wrapper--light">
      <div className="bx--label">{labelText}</div>
      <div className="bx--text-input__field-outer-wrapper">
        <div className="bx--text-input__field-wrapper">
          <div className="LoginModal__TextInput bx--text-input bx--text-input--light">{value}</div>
        </div>
        {helperText && <div className="bx--form__helper-text">{helperText}</div>}
      </div>
    </div>
  );
}

export { MockTextInput };
