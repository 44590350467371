/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelIntroInstructions } from '../../commonComponents/GuidancePanelIntroInstructions';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function TellDoShowIntroPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Tell, Do, & Show"
        description={
          <>
            Use Watson Assistant to help your customers in the way that serves them best:
            <br />
            <br />
            <strong>Tell</strong> the customer the right answer, or
            <br />
            <strong>Do</strong> the action for them, or
            <br />
            <strong>Show</strong> them how to resolve their issue.
            <br />
            <br />
            Choose between each resolution method according to the urgency, complexity, and popularity of each topic you
            equip your assistant to handle.
          </>
        }
      />
      <GuidancePanelIntroInstructions />
    </div>
  );
}

const TellDoShowIntroPanelConfig: SectionPanelConfig = {
  title: 'Tell Do Show Intro',
  SectionPanelComponent: TellDoShowIntroPanel,
  panel: LendyrPanel.HOME,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.TELL_DO_SHOW_INTRO,
};

export { TellDoShowIntroPanel, TellDoShowIntroPanelConfig };
