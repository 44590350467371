/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { AnyAction } from 'redux';
import { DeepPartial } from 'ts-essentials';

import { demoActions } from '../../framework/store/demoActions';
import { PersistedToBrowserSessionStorage } from '../../framework/store/demoStoreTypes';
import { LendyrSessionStorageState } from '../types/lendyrStoreTypes';

/**
 * This file contains lendyr specific actions for updating the lendyr app state in the redux store.
 */

interface LendyrActionsType {
  updateSessionStorageStateSiteData: (
    partial: DeepPartial<PersistedToBrowserSessionStorage<LendyrSessionStorageState>['siteData']>,
  ) => AnyAction;
}

const lendyrActions: LendyrActionsType = {
  updateSessionStorageStateSiteData: demoActions.updateSessionStorageStateSiteData,
};

export { lendyrActions };
