/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { BusEventType, TypeAndHandler } from '../../../web-chat-dependencies/types/eventBusTypes';
import { DemoFeaturePanelsTrackingNames } from '../../demoTypes';
import { trackDemoFeatureFinished } from '../../utils/demoTracking';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const SIZE_TRANSITION_CONFIG: DemoTransitionConfig = {
  doTransitionWithWebChat: (store, instance) => {
    const windowOpenTypeAndHandler: TypeAndHandler = {
      type: BusEventType.WINDOW_OPEN,
      handler: () => {
        trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.SIZE);
      },
    };

    // If the web chat is opened then track that the feature has been completed.
    instance.on(windowOpenTypeAndHandler);
    instance.updateCSSVariables({
      'BASE-width': '350px',
      'BASE-height': '80%',
      'BASE-max-height': '80%',
    });

    return () => {
      instance.off(windowOpenTypeAndHandler);
      instance.updateCSSVariables({
        'BASE-width': 'min(360px, var(--WatsonAssistantChat-BASE-max-width))',
        'BASE-height': 'calc(100vh - 64px)',
        'BASE-max-height': '640px',
      });
    };
  },

  restartOptions: {
    reloadWebChat: true,
  },

  disableMobileView: true,
};

export { SIZE_TRANSITION_CONFIG };
