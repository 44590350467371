/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './BYOSCard.css';

import React from 'react';

import { SearchResult } from './SearchResult';

interface BYOSCardProps {
  searchResult: SearchResult;
}

/**
 * A Bring your own search card component for displaying search content cards.
 */
function BYOSCard({ searchResult }: BYOSCardProps) {
  const title = searchResult?.title;
  const text = searchResult?.text?.[0];

  return (
    <div className="BYOSCard">
      <div className="ibm-web-chat--card">
        <p className="BYOSCard__Title">{title}</p>
        {text}
      </div>
    </div>
  );
}

export { BYOSCard };
