/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelIntroInstructions } from '../../commonComponents/GuidancePanelIntroInstructions';
import { GuidancePanelList, GuidancePanelListItem } from '../../commonComponents/GuidancePanelList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function PhoneIntroPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Phone"
        description={
          <>
            This feature tour will introduce you to the essential features of the Watson Assistant phone channel. You'll
            learn how you can deliver exceptional, scalable experiences for your customers over the phone with virtual
            assistants that:
            <GuidancePanelList>
              <GuidancePanelListItem>
                Answer questions and accomplish tasks for callers without forcing them to listen to long lists of
                options or navigate a phone tree
              </GuidancePanelListItem>
              <GuidancePanelListItem>
                Provide a pleasant experience with natural, easy-to-understand voices that leave callers delighted
              </GuidancePanelListItem>
              <GuidancePanelListItem>
                Help callers accomplish tasks, including through utilization of other channels such as SMS
              </GuidancePanelListItem>
              <GuidancePanelListItem>
                Hand off seamlessly to human agents (when necessary) with the context of the previous conversation
              </GuidancePanelListItem>
            </GuidancePanelList>
          </>
        }
      />
      <GuidancePanelIntroInstructions />
    </div>
  );
}

const PhoneIntroPanelConfig: SectionPanelConfig = {
  title: 'Phone Intro',
  SectionPanelComponent: PhoneIntroPanel,
  page: LendyrPage.PHONE_TOUR,
  panel: LendyrPanel.HOME,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.PHONE_INTRO,
};

export { PhoneIntroPanel, PhoneIntroPanelConfig };
