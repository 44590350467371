/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * The different places a custom menu may be configured.
 */
enum CustomMenuType {
  /**
   * The overflow menu on the bot view.
   */
  BOT = 'bot',

  /**
   * The overflow menu on the human agent view.
   */
  AGENT = 'agent',
}

/**
 * A single menu option.
 */
interface CustomMenuOption {
  /**
   * The text to display for the menu option.
   */
  text: string;

  /**
   * The callback handler to call when the option is selected.
   */
  handler: () => void;
}

export { CustomMenuType, CustomMenuOption };
