/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import {
  generateAndSetAnonymousUserId,
  getAnonymousUserID,
} from '../../web-chat-dependencies/services/anonymousUserIDStorage';
import { ServiceManager } from '../../web-chat-dependencies/services/ServiceManager';
import TrackService, { TrackEventProps } from '../../web-chat-dependencies/services/TrackService';
import { PublicConfig } from '../../web-chat-dependencies/types/PublicConfig';
import { IS_MOBILE } from '../../web-chat-dependencies/utils/browserUtils';
import { getAnalyticsPath } from '../../web-chat-dependencies/utils/config';
import { DEMO_ASSISTANT } from './demoConstants';
import { getDemoSiteParamsAndHash } from './demoUtils';
import { isMobileViewDisabledForCurrentTransition } from './miscUtils';

const eligibleHostnames = new Set<string>([
  'www.ibm.com',
  'wwwstage.ibm.com',
  'web-chat.global.assistant.watson.cloud.ibm.com',
  'web-chat.assistant.test.watson.cloud.ibm.com',
  'web-chat.assistant.dev.watson.cloud.ibm.com',
  'assistant-demo.assistant.dev.watson.cloud.ibm.com',
  'assistant-demo.assistant.test.watson.cloud.ibm.com',
  'assistant-demo.global.assistant.watson.cloud.ibm.com',
  'assistant-demo.global.assistant.dev.watson.appdomain.cloud',
  'assistant-demo.global.assistant.test.watson.appdomain.cloud',
  'assistant-demo.global.assistant.watson.appdomain.cloud',
  'localhost',
]);

/**
 * Used to determine if a user is on an appropriate url for demo site tracking. We're doing this because there are
 * events that can be fired as a part of normal web chat logic that we only want to send when the user is actually on
 * the demo site. If the user is on the right hostname and their pathname ends with demo.html then they are eligible for
 * demo tracking.
 */
const userIsEligibleForDemoSiteTracking =
  eligibleHostnames.has(window.location.hostname) && window.location.pathname.endsWith('demo.html');

const publicConfigForTracking: PublicConfig = {
  region: 'us-south',
} as PublicConfig;

const serviceManager = new ServiceManager();
serviceManager.trackService = new TrackService(serviceManager, true, true, getAnalyticsPath(publicConfigForTracking));

const userID = getAnonymousUserID() || generateAndSetAnonymousUserId();
// Note: this will share the anonymous user ID with the web chat instance that is used on this page.
const trackUserID = serviceManager.trackService.getTrackUserID(DEMO_ASSISTANT.serviceInstanceID, userID);

/**
 * Track page views in demo site. This function should only be run when the page loads instead of listening to
 * changes on the page such as clicking an anchor link.
 *
 * @param page the page the user viewed.
 */
function trackDemoPageView(page: string) {
  const event: TrackEventProps = {
    eventName: 'Demo Site Interaction',
    type: 'Page view',
    name: page,
  };

  if (userIsEligibleForDemoSiteTracking) {
    serviceManager.trackService.demoTrack(event, trackUserID);
  }
}

/**
 * Track when a user finishes a feature. Will only be tracked once per feature, per render of the demo site.
 *
 * @param feature the feature the user finished.
 */
function trackDemoFeatureFinished(feature: string) {
  const event: TrackEventProps = {
    eventName: 'Demo Site Interaction',
    type: 'Feature finished',
    name: feature,
  };

  trackEventViewType(event);

  if (userIsEligibleForDemoSiteTracking) {
    serviceManager.trackService.demoTrack(event, trackUserID);
  }
}

/**
 * Track when a user opens a panel in the demo site nav. Will only be tracked once per panel, per render of the demo site.
 *
 * @param sectionPanel the panel the user opened.
 */
function trackDemoSectionPanelViewed(sectionPanel: string) {
  const event: TrackEventProps = {
    eventName: 'Demo Site Interaction',
    type: 'Panel viewed',
    name: sectionPanel,
  };

  trackEventViewType(event);

  if (userIsEligibleForDemoSiteTracking) {
    serviceManager.trackService.demoTrack(event, trackUserID);
  }
}

/**
 * Track when a user performs an action in a section panel. This could be anything.
 *
 * @param action an action performed in the panel.
 */
function trackDemoSectionPanelAction(action: string) {
  const event: TrackEventProps = {
    eventName: 'Demo Site Interaction',
    type: 'Panel viewed',
    name: action,
  };

  if (userIsEligibleForDemoSiteTracking) {
    serviceManager.trackService.demoTrack(event, trackUserID);
  }
}

/**
 * Track when a user clicks on a setup link within a panel.
 *
 * @param sectionPanel the panel the user found the link in.
 */
function trackDemoSetupLinkClicked(sectionPanel: string) {
  const event: TrackEventProps = {
    eventName: 'Demo Site Interaction',
    type: 'Setup link clicked',
    name: sectionPanel,
  };

  if (userIsEligibleForDemoSiteTracking) {
    serviceManager.trackService.demoTrack(event, trackUserID);
  }
}

/**
 * This handles setting a property to the provided event object the tells the medium in which the user is
 * interacting with the demo site.
 */
function trackEventViewType(event: TrackEventProps) {
  if (IS_MOBILE) {
    event.viewType = 'mobile';
    return;
  }

  const demoSiteParamsAndHash = getDemoSiteParamsAndHash(window.location);

  if (!demoSiteParamsAndHash.mobileView || isMobileViewDisabledForCurrentTransition(demoSiteParamsAndHash)) {
    event.viewType = 'desktop';
    return;
  }

  event.viewType = 'emulated mobile';
}

export {
  trackDemoPageView,
  trackDemoSectionPanelAction,
  trackDemoFeatureFinished,
  trackDemoSectionPanelViewed,
  trackDemoSetupLinkClicked,
};
