/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelIntroInstructions } from '../../commonComponents/GuidancePanelIntroInstructions';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function UnifyPersonalizeIntroPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Unify & Personalized"
        description={
          <>
            Deliver consistent, personalized experiences without having to migrate your existing tech stack.
            <br />
            <br />
            Provide an exceptional support experience across all of your channels that's unique to each customer
            according to their profile. Seamlessly integrate AI-powered experiences with the systems and processes that
            run your business and make up your tech stack today with no migration required.
          </>
        }
      />
      <GuidancePanelIntroInstructions />
    </div>
  );
}

const UnifyPersonalizeIntroPanelConfig: SectionPanelConfig = {
  title: 'Unify & Personalized intro',
  SectionPanelComponent: UnifyPersonalizeIntroPanel,
  panel: LendyrPanel.HOME,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.UNIFY_PERSONALIZED_INTRO,
};

export { UnifyPersonalizeIntroPanel, UnifyPersonalizeIntroPanelConfig };
