/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function SearchInSuggestionsPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Search in Suggestions"
        description={
          <>
            If your user asks a question and the answer provided by the assistant doesn't quite hit the mark, your user
            can use the "?" icon to view additional suggestions.
            <br />
            <br />
            If you've configured the Search integration, Suggestions will surface relevant content in addition to common
            topics, intelligently suggested alternate topics, and external support like a human agent.
          </>
        }
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following: <GuidancePanelTextSnippet text="What digital wallets can I use?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          The assistant gives you a relevant answer, but it's not the right one. Click on the Suggestions "?" icon in
          the corner of the assistant.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          View the "Related content" section. Click the bookmark icon of the appropriate answer to learn more.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
    </div>
  );
}

const SearchInSuggestionsPanelConfig: SectionPanelConfig = {
  title: 'Search in Suggestions',
  SectionPanelComponent: SearchInSuggestionsPanel,
  panel: 'search-in-suggestions',
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.SEARCH_IN_SUGGESTIONS,
};

export { SearchInSuggestionsPanel, SearchInSuggestionsPanelConfig };
