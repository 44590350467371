/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import { CustomPanelType } from '../../framework/demoTypes';
import { DemoAppState } from '../../framework/store/DemoAppState';
import { HasChildren } from '../../web-chat-dependencies/types/HasChildren';
import { WatsonAssistantChatInstance } from '../../web-chat-dependencies/types/WatsonAssistantChatInstance';
import { PostChatSurvey } from './PostChatSurvey';
import { PreChatForm } from './PreChatForm';

interface CustomPanelsPortalProps extends HasChildren {
  /**
   * The host element to render the custom panel content in.
   */
  hostElement: HTMLElement;
}

interface CustomPanelPortalsContainerProps {
  instance: WatsonAssistantChatInstance;
}

/**
 * A portal container for handling what needs to be rendered in the custom panel for the demo site.
 */
function CustomPanelPortalsContainer({ instance }: CustomPanelPortalsContainerProps) {
  const customPanelType = useSelector((state: DemoAppState) => state.customPanelType);
  const panelInstance = instance.customPanels.getPanel();
  const CustomPanelContainer = getCustomPanelContainer(customPanelType);

  return (
    <CustomPanelsPortal hostElement={panelInstance.hostElement}>
      {CustomPanelContainer && <CustomPanelContainer instance={instance} />}
    </CustomPanelsPortal>
  );
}

/**
 * Returns a CustomPanelContainer component given the custom panel type value provided.
 */
function getCustomPanelContainer(customPanelType: CustomPanelType) {
  switch (customPanelType) {
    case CustomPanelType.PRE_CHAT_FORM:
      return PreChatForm;
    case CustomPanelType.POST_CHAT_SURVEY:
      return PostChatSurvey;
    default:
      return null;
  }
}

/**
 * Renders a portal to that will allow custom content to be rendered into the custom panel.
 */
function CustomPanelsPortal({ children, hostElement }: CustomPanelsPortalProps) {
  return ReactDOM.createPortal(children, hostElement);
}

export { CustomPanelPortalsContainer };
