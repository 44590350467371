/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const LIVE_AGENT_ESCALATION_NO_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Integrate Zendesk',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-deploy-zendesk',
    type: 'Docs',
  },
  {
    title: 'Integrate Salesforce',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-deploy-salesforce',
    type: 'Docs',
  },
];

const LIVE_AGENT_ESCALATION_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Integrate Genesys',
    link: 'https://web-chat.global.assistant.watson.cloud.ibm.com/docs.html?to=service-desks-genesys',
    type: 'Docs',
  },
  {
    title: 'Integrate NICE',
    link: 'https://github.com/watson-developer-cloud/assistant-web-chat-service-desk-starter/tree/main/src/incontact/webChat',
    type: 'Tutorial',
  },
  {
    title: 'Integrate Oracle',
    link: 'https://github.com/watson-developer-cloud/assistant-web-chat-service-desk-starter/tree/main/src/oracle/webChat',
    type: 'Tutorial',
  },
  {
    title: 'Integrate Twilio',
    link: 'https://github.com/watson-developer-cloud/assistant-web-chat-service-desk-starter/tree/main/src/flex/webChat',
    type: 'Tutorial',
  },
  {
    title: 'Integrate Kustomer',
    link: 'https://github.com/watson-developer-cloud/assistant-web-chat-service-desk-starter/tree/main/src/kustomer/webChat',
    type: 'Tutorial',
  },
  {
    title: 'Build a custom integration',
    link: 'https://github.com/watson-developer-cloud/assistant-web-chat-service-desk-starter',
    type: 'Tutorial',
  },
];

function LiveAgentEscalationPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Live Agent Escalation"
        description={
          <>
            Escalating your users to a human agent can be useful when all other options have been exhausted. Watson
            Assistant also allows the agent to view the full conversation history, giving them the context necessary to
            help the user.
            <br />
            <br />
            Watson Assistant supports live agent integrations with Zendesk, Salesforce, Genesys, NICE inContact, Twilio,
            Kustomer, and Oracle among others.
          </>
        }
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following:
          <GuidancePanelTextSnippet text="Increase credit limit" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Click to connect to an agent.</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Talk to the agent.</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Click on the "Disconnect" button in the agent banner and end the chat with the agent.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <div className="GuidancePanelDivider" />
      <LiveAgentEscalationPanelImplementation />
    </div>
  );
}

function LiveAgentEscalationPanelImplementation() {
  return (
    <GuidancePanelImplementationSection>
      <GuidancePanelImplementationTile data={LIVE_AGENT_ESCALATION_NO_CODE_LINKS} />
      <GuidancePanelImplementationTile codeNecessary data={LIVE_AGENT_ESCALATION_CODE_LINKS} />
    </GuidancePanelImplementationSection>
  );
}

const LiveAgentEscalationPanelConfig: SectionPanelConfig = {
  title: 'Live Agent Escalation',
  SectionPanelComponent: LiveAgentEscalationPanel,
  panel: LendyrPanel.LIVE_AGENT,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.LIVE_AGENT,
};

export { LiveAgentEscalationPanel, LiveAgentEscalationPanelImplementation, LiveAgentEscalationPanelConfig };
