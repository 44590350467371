/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { SuggestionsPanel } from '../EssentialsAndIndexSection/SuggestionsPanel';

function AccessibleSuggestionsPanel() {
  return <SuggestionsPanel />;
}

const AccessibleSuggestionsPanelConfig: SectionPanelConfig = {
  title: 'Suggestions',
  SectionPanelComponent: AccessibleSuggestionsPanel,
  panel: LendyrPanel.ACCESSIBLE_SUGGESTIONS,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.ACCESSIBLE_SUGGESTIONS,
};

export { AccessibleSuggestionsPanel, AccessibleSuggestionsPanelConfig };
