/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './DemoPageHeader.css';

import { Menu32 } from '@carbon/icons-react';
import { Button, OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cadeProfileIcon from '../assets/cade-profile-icon.jpg';
import goldmanHeaderIconTertiary from '../assets/Goldman_Sachs_Blue_Box.svg';
import lendyrHeaderIconSecondary from '../assets/lendyr-header-icon-secondary.svg';
import lendyrHeaderIconTertiary from '../assets/lendyr-header-icon-tertiary.svg';
import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPageType } from '../framework/demoTypes';
import { useWebChatInstance } from '../framework/hooks/useWebChatInstance';
import { demoActions } from '../framework/store/demoActions';
import { DemoAppState } from '../framework/store/DemoAppState';
import { TOUR_STEP_ID_DISPUTE_CHARGE_2 } from '../framework/utils/demoConstants';
import { trackDemoFeatureFinished } from '../framework/utils/demoTracking';
import { DemoOverflowMenuItem } from './DemoOverflowMenuItem';
import { DemoPageModal } from './DemoPageModal';
import { LendyrButton } from './LendyrButton';
import { LoginModal } from './LoginModal';
import { PageLink } from './PageLink';

interface DemoPageHeader {
  /**
   * Determines if the menu should render in desktop mode.
   */
  isDesktop: boolean;

  /**
   * The type of demo page to render.
   */
  type?: LendyrPageType;
}

/**
 * The header component which acts as a top navigation for the demo site lendyr page.
 */
function DemoPageHeader({ isDesktop, type }: DemoPageHeader) {
  const instance = useWebChatInstance();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: DemoAppState) => state.isAuthenticated);
  const [showLinksMenu, setShowLinksMenu] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  /**
   * This effect ensures the login modal is hidden if it's open once the user is authenticated by any means.
   */
  useEffect(() => {
    if (isAuthenticated && showLoginModal) {
      setShowLoginModal(false);
    }
  }, [isAuthenticated, showLoginModal]);

  return (
    <header className="DemoPageHeader">
      <Button
        className="DemoPageHeader__MenuButton"
        onClick={() => setShowLinksMenu(!showLinksMenu)}
        kind="ghost"
        aria-label="Open links menu"
      >
        <Menu32 />
      </Button>
      <div className="DemoPageHeader__Brand">
        <PageLink className="DemoPageHeader__BrandName" page={LendyrPage.HOME}>
          <img className="DemoPageHeader__BrandIcon" src={headerIcon(type)} alt="Lendyr icon" />
          Ayco
        </PageLink>
        {type === LendyrPageType.PRIMARY && <div className="DemoPageHeader__Welcome">Welcome back, Cade</div>}
      </div>
      <div
        className={cx('DemoPageHeader__HeaderMenu', {
          'DemoPageHeader__HeaderMenu--open': !isDesktop && showLinksMenu,
        })}
      >
        <LendyrButton
          className="DemoPageHeader__ChatWithUsButton"
          onClick={() => {
            if (instance) {
              instance.openWindow();
              trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.OPEN_FROM_BUTTONS);
            }
          }}
        >
          Chat with us
        </LendyrButton>
        <ul className="DemoPageHeader__LinksMenu">
          <li>
            <PageLink className="DemoPageHeader__Link" page={LendyrPage.HOME}>
              Products
            </PageLink>
          </li>
          <li>
            <PageLink className="DemoPageHeader__Link" page={LendyrPage.FAQ}>
              FAQs
            </PageLink>
          </li>
        </ul>
      </div>
      {!isAuthenticated && (
        <LendyrButton className="DemoPageHeader__LoginButton" onClick={() => setShowLoginModal(true)}>
          Login
        </LendyrButton>
      )}
      {isAuthenticated && (
        <OverflowMenu
          className="DemoPageHeader__ProfileButton"
          menuOptionsClass="DemoPageHeader__ProfileOptions"
          size="sm"
          flipped
          menuOffsetFlip={{ top: 10, left: -64 }}
          renderIcon={() => {
            return <img className="DemoPageHeader__ProfileIcon" src={cadeProfileIcon} alt="profile" />;
          }}
        >
          <DemoOverflowMenuItem
            page={LendyrPage.TRANSACTIONS}
            itemText="Investments"
            onClick={() => {
              // If the user is in a tour, clicking the transactions button should take the user to the second step
              // since the first step instructs them to click this button which they would complete.
              if (instance.getState().isTourActive) {
                instance.tours.goToStep(TOUR_STEP_ID_DISPUTE_CHARGE_2);
              }
            }}
          />
          <OverflowMenuItem itemText="Settings" />
        </OverflowMenu>
      )}
      {showLoginModal && (
        <DemoPageModal>
          <LoginModal
            onLogin={() => {
              dispatch(demoActions.setStateProperty('isAuthenticated', true));
            }}
            onClose={() => setShowLoginModal(false)}
          />
        </DemoPageModal>
      )}
    </header>
  );
}

/**
 * Depending on the type of the demo page being rendered return a different header icon.
 *
 * @param type The type of demo page to render, {@link LendyrPageType}.
 * @returns an svg.
 */
function headerIcon(type: string) {
  switch (type) {
    case LendyrPageType.SECONDARY:
      return goldmanHeaderIconTertiary;
    case LendyrPageType.TERTIARY:
      return goldmanHeaderIconTertiary;
    default:
      return goldmanHeaderIconTertiary;
  }
}

export { DemoPageHeader };
