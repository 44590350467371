/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './CustomLauncher.css';

import React from 'react';
import { useSelector } from 'react-redux';

import { DemoFeaturePanelsTrackingNames } from '../framework/demoTypes';
import { DemoAppState } from '../framework/store/DemoAppState';
import { trackDemoFeatureFinished } from '../framework/utils/demoTracking';
import { WatsonAssistantChatInstance } from '../web-chat-dependencies/types/WatsonAssistantChatInstance';

interface CustomLauncherProps {
  instance: WatsonAssistantChatInstance;
}

/**
 * This component is for the "custom launcher" demo.
 */
function CustomLauncher({ instance }: CustomLauncherProps) {
  // The default value for showing the custom launcher is dependent on if the web chat is already open or not which we
  // can get from the web chat instance state. Normally we could rely on the window open and close events below but
  // unfortunately those don't help us on first load because this container isn't mounted before those event fire for
  // the first time.
  const showCustomLauncher = useSelector((state: DemoAppState) => state.showCustomLauncher);

  return (
    showCustomLauncher && (
      <button
        className="CustomLauncher"
        type="button"
        onClick={() => {
          instance.openWindow();
          trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.CUSTOM_LAUNCHER);
        }}
      >
        <CustomLauncherIcon />
        <div className="CustomLauncher__TextContainer">
          <div className="CustomLauncher__Text">
            Hi! I'm a virtual assistant.
            <br />
            How can I help you?
          </div>
        </div>
      </button>
    )
  );
}

function CustomLauncherIcon() {
  return (
    <svg
      className="CustomLauncher__Icon"
      focusable="false"
      preserveAspectRatio="xMidYMid meet"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      aria-hidden="true"
      fill="currentColor"
    >
      <path d="M17.74,30,16,29l4-7h6a2,2,0,0,0,2-2V8a2,2,0,0,0-2-2H6A2,2,0,0,0,4,8V20a2,2,0,0,0,2,2h9v2H6a4,4,0,0,1-4-4V8A4,4,0,0,1,6,4H26a4,4,0,0,1,4,4V20a4,4,0,0,1-4,4H21.16Z" />
      <path d="M8 10H24V12H8zM8 16H18V18H8z" />
    </svg>
  );
}

export { CustomLauncher };
