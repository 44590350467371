/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './BYOSCarousel.css';

import { Tile } from 'carbon-components-react';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { CarouselContainer } from '../CarouselContainer';
import { SearchResult } from './SearchResult';

const PERSONAL = 'Personal';
const BUSINESS = 'Business';
const CHECKING_ACCOUNTS_PERSONAL = ['Premier', 'Advanced', 'Elite'];
const CHECKING_ACCOUNTS_BUSINESS = ['Business'];
const CHECKING_ACCOUNTS = [...CHECKING_ACCOUNTS_PERSONAL, ...CHECKING_ACCOUNTS_BUSINESS];

/**
 * A Bring your own search carousel component for displaying swipe-able search content that can be configured with custom
 * content that the user can swipe between.
 */
function BYOSCarousel({ carousalData }: any) {
  const accountType = carousalData?.account_type;

  // Filter the results to only show those for the given account type.
  const checkingAccountData = carousalData?.search_results
    ?.filter((result: SearchResult) => {
      if (result.title) {
        return doesResultMatchAccount(result, accountType);
      }
      return false;
    })
    .map(({ title, text }: SearchResult) => {
      return { title, description: text };
    });

  return (
    <CarouselContainer>
      {checkingAccountData?.map(({ title, description }: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={index}>
          <Tile className="BYOSCarousel__Card">
            <div className="BYOSCarousel__CardText">
              <div className="BYOSCarousel__CardTitle">{title}</div>
              <div className="BYOSCarousel__CardDescription">{description}</div>
            </div>
          </Tile>
        </SwiperSlide>
      ))}
    </CarouselContainer>
  );
}

function doesResultMatchAccount(result: SearchResult, accountType: string) {
  switch (accountType) {
    case PERSONAL:
      return CHECKING_ACCOUNTS_PERSONAL.some(checkingAccountType => result.title.includes(checkingAccountType));
    case BUSINESS:
      return CHECKING_ACCOUNTS_BUSINESS.some(businessAccount => result.title.includes(businessAccount));
    default:
      return CHECKING_ACCOUNTS.some(checkingAccount => result.title.includes(checkingAccount));
  }
}

const BYOSCarouselExport = React.memo(BYOSCarousel);

export { BYOSCarouselExport as BYOSCarousel };
