/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { ToolingSVGPreviewOverlay } from '../../commonComponents/ToolingSVGPreviewOverlay';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { IterateQuicklyDefaultSVGComponent } from './svgComponents/IterateQuicklyDefaultSVGComponent';

function IterateQuicklySummaryPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Summary"
        description={
          <>
            Unlock your team’s potential to improve and iterate quickly on your support experience.
            <br />
            <br />
            Watson Assistant pairs an easy-to-use build experience and pre-designed repeatable conversational patterns
            to accelerate the build without the need for IT or development resources.
          </>
        }
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Use the buttons below to navigate through the tour.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Follow the steps to familiarize yourself with specific features.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          For more information, check out the linked documentation and guided tutorials.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <ToolingSVGPreviewOverlay>
        <IterateQuicklyDefaultSVGComponent />
      </ToolingSVGPreviewOverlay>
    </div>
  );
}

const IterateQuicklySummaryPanelConfig: SectionPanelConfig = {
  title: 'Iterate Quickly Summary',
  SectionPanelComponent: IterateQuicklySummaryPanel,
  panel: LendyrPanel.ITERATE_QUICKLY_SUMMARY,
  page: LendyrPage.ITERATE_QUICKLY_TOUR,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.ITERATE_QUICKLY_SUMMARY,
};

export { IterateQuicklySummaryPanel, IterateQuicklySummaryPanelConfig };
