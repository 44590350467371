/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { LendyrPanel } from '../../demoTypes';
import { navigateTo } from '../../utils/demoUtils';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const PHONE_TRANSITION_CONFIG: DemoTransitionConfig = {
  onTransition: store => {
    const { panel } = store.getState().demoSiteParamsAndHash;
    // If the user has loaded the page in the phone tour on a specific panel, navigate them to the start of the tour
    // since it relies on them having started from the beginning.
    if (panel) {
      navigateTo({ panel: LendyrPanel.HOME });
    }
  },

  disableMobileView: true,
};

export { PHONE_TRANSITION_CONFIG };
