/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2020, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { GenericItem } from './wa-types';

/**
 * The following extensions are part of an experimental contract between the service and the channels.
 * It's mainly employed with the Web chat which is the highest fidelity channel provided by the product.
 */

/**
 * The following declarations are an extension to the publicly available Watson Assistant API.
 * Typescript allows declaration merging for such use cases.
 */
declare module './wa-types' {
  interface MessageRequest {
    /**
     * The ID of the thread this request belongs to.
     * In channels that support a multi-turn dialog, messages may be organized in separate threads of conversation which are all related by the same ID.
     * When a client submits a request that is part of a previously started thread, it must provide that thread ID here so the service knows
     * what thread the request belongs to. If the message doesn't belong to any particular thread it can be the ID of the
     * main thread which is "main" (THREAD_ID_MAIN).
     */
    thread_id?: string;

    /**
     * The unique identifier for this request object. This value may be assigned by the client when a request is made but will be assigned by the service if one is not provided.
     */
    id: string;

    /**
     * The history information to store as part of this request. This includes extra information that was provided to
     * the user that was used in making the request.
     */
    history?: MessageHistory;
  }

  /**
   * A rich user representation
   */
  interface User {
    /**
     * The type of the user helps to differentiate with whom is the author of a message.
     */
    type: UserTypes;

    /**
     * An identification that is up to external system to denote. There are no limitations enforced by the service.
     */
    id: string;

    /**
     * Optional information that is available for a user or a human agent.
     */
    personal?: UserPersonalInfo;
  }

  interface MessageResponse {
    /**
     * The ID of the thread this response belongs to. In channels that support a multi-turn dialog, messages may be
     * organized in separate threads of conversation which are all related by the same ID. When a new thread in a
     * conversation with a bot begins, the service will assign a new ID to the thread. For responses that are
     * continuing an existing thread, the service will rely on the client to provide the thread ID in its request to
     * inform the service which thread the messages belong to. This ID will also be present on the last message in a
     * thread when a response indicates that a thread has ended.
     *
     * This value is currently determined by looking at the dialog state of the response and any node that is not the
     * root node indicates that the message is in a thread (except for the final message of the thread which is part
     * of the thread even though it may be a root node).
     *
     * If a message is not in a particular thread, it will be assigned the ID of the main thread which is "main"
     * (THREAD_ID_MAIN). For messages with a human agent, the thread ID will be determined by the client.
     */
    thread_id?: string;

    /**
     * A unique identifier for this response object. This value is assigned by the service when the request is generated.
     */
    id: string;

    /**
     * The id of the request that this is the response of. It is expected that bot responses will match a specific request, however
     * human agent responses may not be attached to a specific user request.
     */
    request_id?: string;

    /**
     * An indicator of the thread status associated with this response.
     */
    thread_status?: ThreadStatus;

    /**
     * The id of the session this message is in response to. This may be a new session and can be different from the
     * session id that was sent as part of a previous request if that session has timed out. This property is added by
     * the integration service.
     */
    session_id?: string;

    /**
     * Details about the assistant.
     */
    assistant?: {
      metadata?: {
        plan_name?: string;
      };
    };
  }

  interface ConnectToAgentItem {
    /**
     * When a conversation is escalated to an agent additional information is needed to fullfill the request. This
     * additional information typically is added by the channel integration and cannot be deduced from the dialog
     * itself.
     */
    transfer_info?: TransferInfo;
  }
}

/**
 * Indicates the different states a thread can be in for a given message.
 */
export enum ThreadStatus {
  /**
   * Indicates that the response is starting a new thread.
   */
  STARTED = 1,

  /**
   * Indicates that the request and response are in the same thread and the thread is continuing.
   */
  CONTINUING = 2,

  /**
   * Indicates that the request and response are in the same thread but the response has indicated that thread has
   * ended.
   * This is currently determined by examining the dialog state. If the
   * dialog stack first item is the root then it means this response is a leaf in the dialog tree.
   */
  ENDED = 3,
}

/**
 * The thread id of the main thread for every channel. It is used in both MessageRequest and Message Response.
 * This ID is specifically for communicating with the bot and is distinct from the id of a sub-thread or the threads with any human agent.
 */
export const THREAD_ID_MAIN = 'main';

/**
 * Defines the latest client version keyword
 */
export const CLIENT_VERSION_LATEST = 'latest';

/**
 * Defines fields that make up the session history key
 */
export type SessionHistoryKey = {
  /**
   * Region
   */
  region: string;

  /**
   * Version of WebChat
   */
  version: string;

  /**
   * A code used by the service desk to securely load the session history, for example in the agent application.
   */
  auth_code: string;

  /**
   * Session identifier for a channel conversation
   */
  session_id: string;

  /**
   * The integration id of the service desk integration.
   */
  integration_id: string;

  /**
   * The service instance id to which the service desk integration belongs to.
   */
  service_instance_id: string;

  /**
   * The subscription_id (resource group id) to which the service instance ID is mapped to.
   */
  subscription_id: string;
};

/**
 * Additional information needed during the escalation process.
 */
export type TransferInfo = {
  /**
   * A code used by the service desk to securely load the session history, for example in the agent application.
   */
  auth_code?: string;

  /**
   * A key used by the service desk to securely load the session history, for example in the agent application.
   * It contains multiple values delimited by double colons ('::').
   * It follows the pattern:
   * ${ region }::${ version }::${ authCode }::${ sessionID }::${ integrationID }::${ serviceInstanceID }::${ subscriptionID }
   * Can be deserialized using the SessionHistoryKey.from() function
   */
  session_history_key?: string;

  /**
   * Each service desk may require different information to start the connection. It can be account details or security information.
   * This is a bucket of all the service desk specific properties.
   */
  additional_data?: {
    [k: string]: string;
  };

  /**
   * Some service desk do the routing to the specific agent or group via metadata in the context.
   * Other service desk rely on the service to decide which Agent/Team/Skill should this conversation be escalated to.
   * In those use cases, the service will set the assignee.
   */
  assignee?: any;

  /**
   * Presented to the agent as the first message of a conversation. Its value is determined by a combination of `message_to_human_agent` and `topic`.
   * If `message_to_human_agent` does not exist, the service will replace it with a default message explaining the conversation has been escalated
   * from a virtual assistant.
   */
  summary_message_to_agent?: GenericItem[];

  /**
   * Deprecated. A message to present to the user when an escalation occurred.
   * Initially, the intention was to populate the Web chat Connect to Agent response card with this text.
   */
  message_to_user?: string;

  /**
   * An Escalation can occur due to different reasons. It is expected that the channel will show a different message based on the reason.
   */
  reason?: {
    /**
     * The reason for the escalation.
     */
    type: ConnectToAgentReason;

    /**
     * Deprecated. Intended to be shown in the card when there is a special reason.
     */
    message?: string;
  };

  /**
   * Contains information to route the escalated conversation to the appropriate service desk department or queue.
   */
  target?: {
    [service_desk: string]: {
      [routing_info: string]: string;
    };
  };
};

/**
 * The reason for the escalation.
 */
export enum ConnectToAgentReason {
  /**
   * An intentional escalation designed in the dialog.
   */
  ASSISTANT_REQUESTED = 'assistant_requested',

  /**
   * An escalation which occurred due to the user requesting an agent.
   */
  USER_REQUESTED = 'user_requested',

  /**
   * An escalation which occurred due to an error in the service.
   */
  GENERAL_ERROR = 'general_error',

  /**
   * An escalation which occurred due to a failure to transfer a conversation to Watson. This may happen only in backdoor integrations (Intercom),
   * in which an agent has approved a suggestion but the virtual agent failed to continue with the conversation.
   */
  TRANSFER_FAILURE = 'transfer_failure',
}
// ************ END OF EXPERIMENTAL ******************* //

// ************* User Profile ***************** //

/**
 * The set of possible conversation companions.
 */
export enum UserTypes {
  HUMAN_AGENT = 'human_agent',
  USER = 'user',

  /**
   * The bot which replies to the user.
   */
  WATSON = 'watson',

  /**
   * Used to denote that an event was processed/logged by the service
   */
  SERVICE = 'service',
}

/**
 * Gender used in user info provided to Watson by client, typically within a JWT.
 */
export enum UserGender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

/**
 * Personal information about a user. We expect to evolve this type as we learn more about our clients and service desks systems.
 */
export interface UserPersonalInfo {
  first_name?: string;
  last_name?: string;

  /**
   * Occasionally, the display name will be different from the first and last names.
   */
  display_name?: string;
  age_info?: {
    age: number;

    /**
     * Four digit year.
     */
    birth_year?: number;

    /**
     * 1 for January; possible values: [1, 12].
     */
    birth_month?: number;

    /**
     * Possible values: [1, 31].
     */
    birth_day_of_month?: number;
  };

  /**
   * Contacts might be mandatory in some cases. For instance to be able to make a secure connection with Zendesk, one must provide email.
   * Service desk which creates tickets for a chat, will in many cases want to associate the ticket with the user email.
   */
  contacts?: {
    email?: string;
    phone?: string;
  }[];
  gender?: UserGender;

  /**
   * Typically scraped from the browser info.
   */
  locale?: string;
  company?: string;
}

/**
 * This interface contains information about the history of a given message. This information will eventually be
 * saved in the history store.
 */
export interface MessageHistory {
  /**
   * The time at which this message occurred.
   */
  timestamp?: number;

  /**
   * For session history the data passed to instance.updateHistoryUserDefined() is returned to the user here (on page change/refresh).
   */
  user_defined?: unknown;

  /**
   * The user-friendly label that was associated with this message. This is used on messages that were sent by the
   * user to the assistant to request a response. This is the user displayed text that was entered or selected by
   * the user when that request was made.
   */
  label?: string;

  /**
   * If this message is related to another message, this is the ID of that other message. This is used when a user
   * choices an option and it includes the ID of the message response that presented the options to the user so we
   * can associate the user's request with that earlier response and display the appropriate selected state.
   */
  relatedMessageID?: string;
}
