/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { IterateQuicklyOverlay } from './IterateQuicklyOverlay';
import { PublishWithEaseSVGComponent } from './svgComponents/PublishWithEaseSVGComponent';
import { PublishWithEaseTooltipSVGComponent } from './svgComponents/PublishWithEaseTooltipSVGComponent';

const PUBLISH_WITH_EASE: GuidancePanelImplementationData[] = [
  {
    title: 'Publishing your content',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-publish',
    type: 'Docs',
  },
];

function PublishWithEasePanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Publish with Ease"
        description="Go live with your new actions with a click of a button. Track your live and previously published versions, and maintain draft, live, and test environments to facilitate development and testing."
      />
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={PUBLISH_WITH_EASE} />
      </GuidancePanelImplementationSection>
      <IterateQuicklyOverlay>
        <PublishWithEaseSVGComponent />
        <PublishWithEaseTooltipSVGComponent />
      </IterateQuicklyOverlay>
    </div>
  );
}

const PublishWithEasePanelConfig: SectionPanelConfig = {
  title: 'Publish with Ease',
  SectionPanelComponent: PublishWithEasePanel,
  page: LendyrPage.ITERATE_QUICKLY_TOUR,
  panel: LendyrPanel.PUBLISH_WITH_EASE,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.PUBLISH_WITH_EASE,
};

export { PublishWithEasePanel, PublishWithEasePanelConfig };
