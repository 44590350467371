/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

enum EnvironmentType {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

// A record of hostnames that determine the environment type we're on.
const DEMO_SITE_HOSTNAMES: Record<string, EnvironmentType> = {
  localhost: EnvironmentType.DEVELOPMENT,
  'cde61010-us-east.lb.appdomain.cloud': EnvironmentType.DEVELOPMENT,
  'watson-assistant.thinkforward.work': EnvironmentType.DEVELOPMENT,
  'ayco.thinkforward.cloud': EnvironmentType.DEVELOPMENT,
  'wwwstage.ibm.com': EnvironmentType.STAGING,
  'www.ibm.com': EnvironmentType.PRODUCTION,
  'assistant-demo.assistant.dev.watson.cloud.ibm.com': EnvironmentType.DEVELOPMENT,
  'assistant-demo.assistant.test.watson.cloud.ibm.com': EnvironmentType.STAGING,
  'assistant-demo.global.assistant.watson.cloud.ibm.com': EnvironmentType.PRODUCTION,
  'assistant-demo.global.assistant.dev.watson.appdomain.cloud': EnvironmentType.DEVELOPMENT,
  'assistant-demo.global.assistant.test.watson.appdomain.cloud': EnvironmentType.STAGING,
  'assistant-demo.global.assistant.watson.appdomain.cloud': EnvironmentType.PRODUCTION,
};

function getEnvironmentVariables() {
  const environment = DEMO_SITE_HOSTNAMES[window.location.hostname];
  switch (environment) {
    case EnvironmentType.PRODUCTION:
      return {
        DEMO_WEB_CHAT_HOST: 'https://web-chat.global.assistant.watson.appdomain.cloud',
        DEMO_AMPLITUDE_API_KEY: '0ffc1c083838567bd38e74ff215e6365',
        DEMO_WALKME_ENV: '/',
        DEMO_ASSISTANT_INTEGRATION_ID: 'ded7e06f-3f3f-4bd2-aae7-45605a6b67ec',
        DEMO_HA_ASSISTANT_INTEGRATION_ID: '9824cce8-1e21-4e5d-9167-8bddda815884',
        DEMO_PHONE_TOUR_NUMBER: '+1 (732) 481-4918',
      };
    case EnvironmentType.STAGING:
      return {
        DEMO_WEB_CHAT_HOST: 'https://web-chat.global.assistant.test.watson.appdomain.cloud',
        DEMO_AMPLITUDE_API_KEY: 'fd6e3368992729a6ca343d00a4a2de43',
        DEMO_WALKME_ENV: '/test/',
        DEMO_ASSISTANT_INTEGRATION_ID: 'c9454772-2d77-4e0d-8e38-a8e52001b02d',
        DEMO_HA_ASSISTANT_INTEGRATION_ID: 'a1ced495-0828-4435-8614-2082cb69550b',
        DEMO_PHONE_TOUR_NUMBER: '+1 (503) 837-3287',
      };
    case EnvironmentType.DEVELOPMENT:
      return {
        DEMO_WEB_CHAT_HOST: 'https://web-chat.global.assistant.test.watson.appdomain.cloud',
        DEMO_AMPLITUDE_API_KEY: 'fd6e3368992729a6ca343d00a4a2de43',
        DEMO_WALKME_ENV: '/test/',
        DEMO_ASSISTANT_INTEGRATION_ID: 'c9454772-2d77-4e0d-8e38-a8e52001b02d',
        DEMO_HA_ASSISTANT_INTEGRATION_ID: 'a1ced495-0828-4435-8614-2082cb69550b',
        DEMO_PHONE_TOUR_NUMBER: '+1 (503) 837-3287',
      };
    default:
      throw Error(`Unrecognized hostname: ${window.location.hostname}`);
  }
}

const ENV_VARIABLES = getEnvironmentVariables();

export { ENV_VARIABLES };
