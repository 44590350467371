/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import cx from 'classnames';
import React, { useEffect, useRef } from 'react';

import { useDemoLocation } from '../../../framework/hooks/selectorHooks';
import { HasChildren } from '../../../web-chat-dependencies/types/HasChildren';
import { HasClassName } from '../../../web-chat-dependencies/types/HasClassName';

/**
 * This guidance panel content container component provides ref functions for manipulating the guidance panel root
 * element.
 */
function GuidancePanelContentContainer({ className, children }: HasChildren & HasClassName) {
  const { section, panel } = useDemoLocation();
  const guidancePanelElementRef = useRef<HTMLDivElement>();

  useEffect(() => {
    guidancePanelElementRef.current.querySelector('.GuidancePanel__MainContent').scrollTop = 0;
  }, [section, panel]);

  return (
    <div className={cx('GuidancePanel', className)} ref={guidancePanelElementRef}>
      {children}
    </div>
  );
}

export { GuidancePanelContentContainer };
