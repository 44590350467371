/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * Describes the possible post-chat survey states.
 */
enum PostChatSurveyState {
  /**
   * The user hasn't sent a message and so the post-chat survey should remain hidden.
   */
  HIDE = 'hide',

  /**
   * The user has sent a message and so the post-chat survey can surface when the user tries to minimize web chat.
   */
  SHOW = 'show',

  /**
   * The user has completed the survey and so it shouldn't surface when the user tries to minimize web chat.
   */
  COMPLETED = 'completed',
}

export { PostChatSurveyState };
