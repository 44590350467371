/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import merge from 'lodash/merge';

import { DemoServiceDesk } from '../../agent/demoServiceDesk';
import { ServiceDeskFactoryParameters } from '../../web-chat-dependencies/services/haa/ServiceDesk';
import { PagePublicConfig } from '../../web-chat-dependencies/types/PublicConfig';
import { DEMO_ASSISTANT, DEMO_HA_ASSISTANT } from './demoConstants';
import { getQueryStringParams } from './demoUtils';

const DEFAULT_WEB_CHAT_CONFIG_OPTIONS = getDefaultWatsonAssistantChatOptions();

const POSITION_WEB_CHAT_CONFIG_OPTIONS = {
  ...DEFAULT_WEB_CHAT_CONFIG_OPTIONS,
  showLauncher: false,
  openChatByDefault: true,
  hideCloseButton: true,
  disableCustomElementMobileEnhancements: true,
};

const CUSTOM_LAUNCHER_WEB_CHAT_CONFIG_OPTIONS = {
  ...DEFAULT_WEB_CHAT_CONFIG_OPTIONS,
  showLauncher: false,
};

/**
 * This function generates watson assistant chat options for web chat that are valid for the entire site.
 */
function getDefaultWatsonAssistantChatOptions() {
  const { ...integrationValues } = getQueryStringParams();
  const defaultOptions: PagePublicConfig = {
    ...integrationValues,
    showLauncher: true,
    pageLinkConfig: {
      linkIDs: {
        a40: {
          text: 'Credit card application tour',
        },
        b50: {
          text: 'I have a bill due that i need to pay',
        },
      },
    },
    __ibm__: {},
    serviceDeskFactory: (parameters: ServiceDeskFactoryParameters) => {
      return Promise.resolve(
        new DemoServiceDesk(parameters.callback, DEMO_HA_ASSISTANT.integrationID, DEMO_HA_ASSISTANT.region, null),
      );
    },
  };

  if (!defaultOptions.integrationID) {
    merge(defaultOptions, DEMO_ASSISTANT);
  }

  if (defaultOptions.clientVersion) {
    defaultOptions.__ibm__.useExactVersion = true;
  } else {
    // Lock web chat to v7 since v8 will switch web chat support from Carbon 10 to 11 which will break some demos.
    // Until Lendyr supports Carbon 11, web chat should stay on v7.
    defaultOptions.clientVersion = '7';
  }

  return defaultOptions;
}

export { DEFAULT_WEB_CHAT_CONFIG_OPTIONS, POSITION_WEB_CHAT_CONFIG_OPTIONS, CUSTOM_LAUNCHER_WEB_CHAT_CONFIG_OPTIONS };
