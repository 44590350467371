/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Home32, Money32, Percentage32, ScalesTipped32 } from '@carbon/icons-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import lendyrSideImageHome from '../assets/lendyr-side-image-home.jpg';
import { DemoPage } from '../components/DemoPage';
import { LendyrButton } from '../components/LendyrButton';
import { MortgageCalculator } from '../components/MortgageCalculator';
import { LendyrPageType } from '../framework/demoTypes';
import { useWebChatInstance } from '../framework/hooks/useWebChatInstance';
import { DemoAppState } from '../framework/store/DemoAppState';
import { CardLinkComponentProps } from '../framework/types/CardLinkComponentProps';
import { THREAD_ID_MAIN } from '../submodules/wa-fd-types-public/wa-extensions-types';

const CARD_LINKS: CardLinkComponentProps[] = [
  { Icon: Home32, title: 'Mortgage Loans' },
  { Icon: Money32, title: 'Jumbo Loans' },
  { Icon: ScalesTipped32, title: 'Home Equity' },
  { Icon: Percentage32, title: 'Refinancing' },
];

// Mortgage calculator user defined type variable constants to handle page interaction.
enum MortgageCalculatorSteps {
  FIRST_STEP = 'mc_first_step',
  LOAN_TERM = 'mc_loan_term_step',
  LAST_STEP = 'mc_last_step',
}

function HomeLoansPage() {
  const instance = useWebChatInstance();
  // This object holds values provided by the user to update the mortgage calculator fields as the user is progressing
  // through their conversation in web chat.
  const mortgageValues = useSelector((state: DemoAppState) => state.pageState.homeLoansPage.mortgageCalculatorFields);
  // Track the current step the user is on in order to enable sending mortgage calculator values on the page to web
  // chat when the user is in the middle of the mortgage calculator action.
  const currentActionStep = useSelector(
    (state: DemoAppState) => state.pageState.homeLoansPage.currentMortgageCalculatorStep,
  );
  const [loanAmountHelperText, setLoanAmountHelperText] = useState<string>(null);

  // This effect will reset the helper text for the loan amount field once we're in the loan term step action. This
  // tells us the user is past the loan amount step.
  useEffect(() => {
    if (loanAmountHelperText && currentActionStep === MortgageCalculatorSteps.LOAN_TERM) {
      setLoanAmountHelperText(null);
    }
  }, [loanAmountHelperText, currentActionStep]);

  return (
    <DemoPage
      title="Home Loans"
      description="Accessibility. Flexibility. Stability."
      type={LendyrPageType.SECONDARY}
      cardLinks={CARD_LINKS}
      MainContent={
        <div className="DemoPage__Body">
          <div className="DemoPage__SectionTextWrapper">
            <div className="DemoPage__SectionWrapper">
              <div className="DemoPageCommon__Title">Mortgages</div>
              <div className="DemoPageCommon__Description">
                Our robust capital, low rates, and loan options allow you to confidently make an offer on a new home.
              </div>
              <LendyrButton tertiary>View details</LendyrButton>
            </div>
            <div className="DemoPage__SectionWrapper">
              <div className="DemoPageCommon__Title">Home Improvement</div>
              <div className="DemoPageCommon__Description">
                If it's time to make your house more of a home, our home improvement loans are here to help.
              </div>
              <LendyrButton tertiary>View details</LendyrButton>
            </div>
            <div className="DemoPage__SectionWrapper">
              <div className="DemoPageCommon__Title">Home Equity</div>
              <div className="DemoPageCommon__Description">
                Get your house to work for you with our home equity loan options.
              </div>
              <LendyrButton tertiary>View details</LendyrButton>
            </div>
          </div>
          <img className="DemoPage__CreditCardImage" src={lendyrSideImageHome} alt="home" />
        </div>
      }
    >
      <MortgageCalculator
        values={mortgageValues}
        loanAmountHelperText={loanAmountHelperText}
        onLoanAmountFocus={() => {
          // If the user is being asked to provide their loan amount in web chat, set the helper text to inform the
          // user that hitting "enter" will send their input to web chat.
          if (currentActionStep === MortgageCalculatorSteps.FIRST_STEP) {
            setLoanAmountHelperText('Hit "enter" to send loan amount to web chat.');
          }
        }}
        onLoanAmountEnter={loanAmount => {
          // If the user is being asked to provide their loan amount in web chat, hitting enter should send the
          // input to web chat.
          if (currentActionStep === MortgageCalculatorSteps.FIRST_STEP) {
            instance.send(String(loanAmount));
          }
        }}
        onLoanTermChange={loanTerm => {
          // If the user is being asked to provide their loan term in web chat, selecting an item should send the input
          // to web chat.
          if (currentActionStep === MortgageCalculatorSteps.LOAN_TERM) {
            instance.send({
              id: null as string,
              thread_id: THREAD_ID_MAIN,
              input: { text: `${loanTerm} years` },
            });
          }
        }}
      />
    </DemoPage>
  );
}

export { HomeLoansPage, MortgageCalculatorSteps };
