/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { IterateQuicklyOverlay } from './IterateQuicklyOverlay';
import { RecognizeTrendsSVGComponent } from './svgComponents/RecognizeTrendsSVGComponent';

const RECOGNIZE_TRENDS_LINK: GuidancePanelImplementationData[] = [
  {
    title: 'Recognize trends at a glance',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-analytics-overview',
    type: 'Docs',
  },
];

function RecognizeTrendsPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Recognize Trends"
        description={
          <>
            Examine and understand the numbers behind your assistant's performance. Track volume with metrics like
            number of unique users, conversations, or requests. Evaluate performance through the key success metrics for
            virtual assistants, like action completion and recognition.
            <br />
            <br />
            Action completion measures whether your customers have their questions fully answered and outcomes
            completely achieved through the virtual assistant.
          </>
        }
      />
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile codeNecessary data={RECOGNIZE_TRENDS_LINK} />
      </GuidancePanelImplementationSection>
      <IterateQuicklyOverlay>
        <RecognizeTrendsSVGComponent />
      </IterateQuicklyOverlay>
    </div>
  );
}

const RecognizeTrendsPanelConfig: SectionPanelConfig = {
  title: 'Recognize Trends',
  SectionPanelComponent: RecognizeTrendsPanel,
  page: LendyrPage.ITERATE_QUICKLY_TOUR,
  panel: LendyrPanel.RECOGNIZE_TRENDS,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.RECOGNIZE_TRENDS,
};

export { RecognizeTrendsPanel, RecognizeTrendsPanelConfig };
