/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './ThemingPanelPageOverlay.css';

import React from 'react';

import customThemingHomeScreen01 from '../assets/custom-theming-home-screen-example-1.png';
import customThemingHomeScreen02 from '../assets/custom-theming-home-screen-example-2.png';
import customThemingHomeScreen03 from '../assets/custom-theming-home-screen-example-3.png';
import customThemingWebChat01 from '../assets/custom-theming-web-chat-example-1.png';
import customThemingWebChat02 from '../assets/custom-theming-web-chat-example-2.png';
import customThemingWebChat03 from '../assets/custom-theming-web-chat-example-3.png';
import { DemoPageOverlayPortal } from '../framework/DemoPageOverlayPortal';
import { ThemingPanelThemeType } from '../framework/utils/themingPanelTypes';

interface ThemingPanelPageOverlayProps {
  /**
   * The theming type that determines the theming images to display.
   */
  customThemingType: ThemingPanelThemeType;
}

/**
 * This component is a page overlay for showcasing various web chat theming examples for the theming panel.
 */
function ThemingPanelPageOverlay({ customThemingType }: ThemingPanelPageOverlayProps) {
  return (
    <DemoPageOverlayPortal preventPageScroll>
      <div className="ThemingPanelPageOverlay">
        {customThemingType === ThemingPanelThemeType.HOME_SCREEN && (
          <div className="ThemingPanelPageOverlay__ImagesContainer">
            <img
              className="ThemingPanelPageOverlay__Image"
              src={customThemingHomeScreen01}
              alt="customize home screen avatar"
            />
            <img
              className="ThemingPanelPageOverlay__Image"
              src={customThemingHomeScreen02}
              alt="customize web chat font"
            />
            <img
              className="ThemingPanelPageOverlay__Image"
              src={customThemingHomeScreen03}
              alt="customize home screen background color"
            />
          </div>
        )}
        {customThemingType === ThemingPanelThemeType.WEB_CHAT && (
          <div className="ThemingPanelPageOverlay__ImagesContainer">
            <img
              className="ThemingPanelPageOverlay__Image"
              src={customThemingWebChat01}
              alt="customize home screen avatar"
            />
            <img
              className="ThemingPanelPageOverlay__Image"
              src={customThemingWebChat02}
              alt="customize home screen avatar"
            />
            <img
              className="ThemingPanelPageOverlay__Image"
              src={customThemingWebChat03}
              alt="customize home screen avatar"
            />
          </div>
        )}
      </div>
    </DemoPageOverlayPortal>
  );
}

export { ThemingPanelPageOverlay };
