/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './MediaPlayer.css';

import { ErrorFilled32 } from '@carbon/icons-react';
import { Loading } from 'carbon-components-react';
import cx from 'classnames';
import React, { Suspense, useState } from 'react';

import { HasClassName } from '../web-chat-dependencies/types/HasClassName';

const ReactPlayerComponent = React.lazy(() => import(/* webpackChunkName: 'media-player' */ 'react-player'));

interface MediaPlayerProps extends HasClassName {
  /**
   * The url source of the video to display.
   */
  source: string;
}

function MediaPlayer({ source }: MediaPlayerProps) {
  const [isReady, setIsReady] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <div className="MediaPlayer__Wrapper">
      {!isReady && (
        <div className="MediaPlayer__LoadingContainer">
          <Loading />
        </div>
      )}
      {isError && (
        <div className="MediaPlayer__ErrorContainer">
          <div className="MediaPlayer__ErrorText">
            <ErrorFilled32 className="MediaPlayer__ErrorIcon" /> There was an error loading the video.
          </div>
        </div>
      )}
      <div className={cx({ Hidden: !isReady })}>
        <Suspense fallback={<></>}>
          <ReactPlayerComponent
            className="MediaPlayer"
            url={source}
            width="100%"
            height="100%"
            onReady={() => setIsReady(true)}
            onError={() => setIsError(true)}
            controls
          />
        </Suspense>
      </div>
    </div>
  );
}

export { MediaPlayer };
