/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2019, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * This type is an extension of the Promise type that adds additional functions that can be used to resolve the promise.
 */
type ResolvablePromise<TResolveType = any> = Promise<TResolveType> & {
  /**
   * Resolves this promise by calling its resolve function.
   */
  doResolve: (resolveValue?: TResolveType) => void;

  /**
   * Rejects this promise by calling its reject function.
   */
  doReject: (reason?: any) => void;

  /**
   * Indicates if this promise has been resolved.
   */
  isResolved: boolean;

  /**
   * Indicates if this promise has been rejected.
   */
  isRejected: boolean;

  /**
   * Indicates if this promise has been either resolved or rejected.
   */
  isComplete: boolean;
};

/**
 * This function will return a new promise that keeps a reference to its own resolve and reject functions so that they
 * can be called on demand by the code using the promise.
 */
function resolvablePromise<TResolveType = void>(): ResolvablePromise<TResolveType> {
  let resolveFunction: (value?: TResolveType | PromiseLike<TResolveType>) => void;
  let rejectFunction: (reason?: any) => void;

  const promise = new Promise<TResolveType>((resolve, reject) => {
    resolveFunction = resolve;
    rejectFunction = reject;
  }) as ResolvablePromise<TResolveType>;

  promise.doResolve = (resolveValue?: TResolveType) => {
    promise.isResolved = true;
    promise.isComplete = true;
    resolveFunction(resolveValue);
  };

  promise.doReject = (rejectValue?: any) => {
    promise.isRejected = true;
    promise.isComplete = true;
    rejectFunction(rejectValue);
  };

  promise.isResolved = false;
  promise.isRejected = false;
  promise.isComplete = false;

  return promise;
}

export { ResolvablePromise, resolvablePromise };
