/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Tag } from 'carbon-components-react';
import React from 'react';
import { useDispatch } from 'react-redux';

import lendyrTopazCard from '../../assets/gs-fin-execmgmt.png';
import lendyrPreferredCard from '../../assets/gs-fin-mgmt-3.png';
import lendyrEverydayCard from '../../assets/gs-fin-wellness.png';
import { HashAnchorContainer } from '../../components/HashAnchorContainer';
import { LendyrButton } from '../../components/LendyrButton';
import { useWebChatInstance } from '../../framework/hooks/useWebChatInstance';
import { demoActions } from '../../framework/store/demoActions';
import { TOUR_STEP_ID_CREDIT_CARD_3 } from '../../framework/utils/demoConstants';

enum LendyrCard {
  EVERYDAY = 'everyday-card',
  PREFERRED = 'preferred-card',
  TOPAZ = 'topaz-card',
}

/**
 * Describes information about a Lendyr credit card, as well as its benefits to display in the credit cards page.
 */
interface LendyrCardOffer {
  type: LendyrCard;

  /**
   * The title of the card offer.
   */
  title: string;

  /**
   * A description of the lendyr card.
   */
  description: string;

  /**
   * A list of lendyr card benefits.
   */
  cardHighlights: string[];

  /**
   * The source of the image to display in the offer.
   */
  imageSrc: string;

  /**
   * The alt-text of the image source.
   */
  imageAlt: string;
}

const CREDIT_CARD_OFFERS: LendyrCardOffer[] = [
  {
    type: LendyrCard.EVERYDAY,
    title: 'Lendyr Everyday Card',
    description: 'Make your daily debit card work for you with the benefits you actually need.',
    cardHighlights: [
      '$300 bonus plus 5% gas station cash back offer',
      'Earn 2% cash back on all other purchases',
      'No annual fee',
    ],
    imageSrc: lendyrEverydayCard,
    imageAlt: 'everyday card',
  },
  {
    type: LendyrCard.PREFERRED,
    title: 'Lendyr Preferred Card',
    description:
      'Grow your business with flexible programs and guidance from our industry-leading team every step of the way.',
    cardHighlights: [
      '$300 bonus plus 5% gas station cash back offer',
      'Earn 5% cash back on all other purchases',
      'No annual fee',
    ],
    imageSrc: lendyrPreferredCard,
    imageAlt: 'everyday card',
  },
  {
    type: LendyrCard.TOPAZ,
    title: 'Lendyr Topaz Card',
    description:
      'Need more benefits than you get with our Everyday Card? Apply for our premium Topaz Card, and start earning more.',
    cardHighlights: ['Earn 120,000 bonus points', 'Earn additional reward points on every purchase', '$90 annual fee'],
    imageSrc: lendyrTopazCard,
    imageAlt: 'topaz card',
  },
];

/**
 * This component is for rendering Lendyr card offers for the credit cards page.
 */
function CreditCardOffers() {
  const instance = useWebChatInstance();
  const dispatch = useDispatch();

  return (
    <div className="CreditCards__Content">
      {CREDIT_CARD_OFFERS.map(cardOffer => {
        const { type, title, description, cardHighlights, imageSrc, imageAlt } = cardOffer;
        return (
          <HashAnchorContainer key={type} id={type} className="CreditCards__Section">
            <img src={imageSrc} alt={imageAlt} />
            <div className="CreditCards__TextWrapper">
              <Tag type="teal">Cash Back</Tag>
              <div className="DemoPageCommon__Title">{title}</div>
              <div className="DemoPageCommon__Description">{description}</div>
              <ul className="CreditCards__CardHighlightsContainer">
                {cardHighlights.map(highlight => (
                  <li key={highlight} className="CreditCards__CardHighlight">
                    {highlight}
                  </li>
                ))}
              </ul>
              <div className="CreditCards__ButtonsContainer">
                <LendyrButton
                  hash={type}
                  onClick={() => {
                    if (instance?.getState().isTourActive) {
                      instance.tours.goToStep(TOUR_STEP_ID_CREDIT_CARD_3);
                    }
                    dispatch(demoActions.setCreditCardPageStateProperty('selectedCardOffer', cardOffer));
                  }}
                >
                  Apply now
                </LendyrButton>
                <LendyrButton tertiary>View details</LendyrButton>
              </div>
            </div>
          </HashAnchorContainer>
        );
      })}
    </div>
  );
}

export { CreditCardOffers, LendyrCardOffer, LendyrCard, CREDIT_CARD_OFFERS };
