/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidanceMobileViewWarningInlineNotification } from '../../commonComponents/GuidanceMobileViewWarningInlineNotification';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { FormFillingImplementationSection } from '../EssentialsAndIndexSection/FormFillingPanel';

function ShowFirstPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Show (1/2)"
        description="Deeply integrate your assistant into your digital customer experience to show and teach your customers how to complete tasks for themselves with confidence."
      />
      <GuidanceMobileViewWarningInlineNotification />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following:
          <GuidancePanelTextSnippet text="What are some credit card options?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Swipe or click through the options and click "Apply" on one of the cards. The assistant will add your
          information to the application automatically.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Enter the following:
          <GuidancePanelTextSnippet text="Why do you need my annual income?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Enter the following:
          <GuidancePanelTextSnippet text="80,000" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Click "Submit application"</GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <div className="GuidancePanelDivider" />
      <FormFillingImplementationSection />
    </div>
  );
}

const ShowFirstPanelConfig: SectionPanelConfig = {
  title: 'Show (1/2)',
  SectionPanelComponent: ShowFirstPanel,
  panel: LendyrPanel.SHOW_1,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.SHOW_1,
};

export { ShowFirstPanel, ShowFirstPanelConfig };
