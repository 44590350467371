/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { MediaPlayerOverlay } from '../../../MediaPlayerOverlay';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const CONVERSATIONAL_SEARCH_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Set up generative conversational search',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-search-extension-neuralseek',
    type: 'Docs',
  },
  {
    title: 'Bring your own search and help content',
    link: 'https://medium.com/ibm-watson/bring-your-own-search-to-ibm-watson-assistant-587e77410c98',
    type: 'Article',
  },
];

function ConversationalSearchPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Conversational Search"
        description={
          <>
            Watson Assistant delivers enterprise-ready generative conversational search, with{' '}
            <strong>conversational, contextual, and trustworthy</strong> responses grounded in your existing help
            content, with built-in safeguards that put curatorial control in the hands of your authors and experts.
            <br />
            <br />
            In this demo, Watson Assistant identifies help content relevant to the customer’s questions and transforms
            it into accurate conversational answers.
          </>
        }
      />
      {/* TODO: Uncomment when needed web chat demo is ready. */}
      {/* <GuidancePanelTryItOutList> */}
      {/*  <GuidancePanelTryItOutListItem> */}
      {/*    Ask any question written in your own words about Lendyr and Zelle, or enter the following: */}
      {/*    <GuidancePanelTextSnippet text="Do you support Zelle?" /> */}
      {/*  </GuidancePanelTryItOutListItem> */}
      {/*  <GuidancePanelTryItOutListItem> */}
      {/*    Follow along with the conversational flow as it walks you through more examples of generative conversational */}
      {/*    search. */}
      {/*  </GuidancePanelTryItOutListItem> */}
      {/* </GuidancePanelTryItOutList> */}
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={CONVERSATIONAL_SEARCH_LINKS} />
      </GuidancePanelImplementationSection>
      <MediaPlayerOverlay source="https://vimeo.com/825618158?share=copy" />
    </div>
  );
}

const ConversationalSearchPanelConfig: SectionPanelConfig = {
  title: 'Conversational Search',
  SectionPanelComponent: ConversationalSearchPanel,
  panel: LendyrPanel.CONVERSATION_SEARCH,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.CONVERSATION_SEARCH,
};

export { ConversationalSearchPanel, ConversationalSearchPanelConfig };
