/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React, { useCallback, useEffect, useState } from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../framework/demoTypes';
import { trackDemoFeatureFinished } from '../framework/utils/demoTracking';
import { getCurrentPanelName } from '../framework/utils/demoUtils';
import { THREAD_ID_MAIN } from '../submodules/wa-fd-types-public/wa-extensions-types';
import { MessageType } from '../submodules/wa-fd-types-public/wa-types';
import { BusEventPreReceive, BusEventType } from '../web-chat-dependencies/types/eventBusTypes';
import { WatsonAssistantChatInstance } from '../web-chat-dependencies/types/WatsonAssistantChatInstance';
import { isIFrameItem, isUserDefinedItem } from '../web-chat-dependencies/utils/messageUtils';
import { LoadingLocationPortal } from './LoadingLocationPortal';

interface GoogleMapsLocationContainerProps {
  instance: WatsonAssistantChatInstance;
}

/**
 * This component is for the "find the nearest location" demo where the users location is retrieved from the browser and
 * then a Google Maps iFrame is shown to direct them to the nearest location.
 */
function GoogleMapsLocationContainer(props: GoogleMapsLocationContainerProps) {
  const { instance } = props;
  const [showLoadingCount, setShowLoadingCount] = useState(0);
  const [userLatitude, setUserLatitude] = useState(0);
  const [userLongitude, setUserLongitude] = useState(0);

  // A useEffect that looks for a change in loading count and hides the input field if we should be in a loading state.
  useEffect(() => {
    instance.updateAssistantInputFieldVisibility(showLoadingCount === 0);
  }, [instance, showLoadingCount]);

  // Create a message request with a given geolocationPosition and send it to the assistant.
  const takeLocation = useCallback(
    (data: GeolocationPosition) => {
      setUserLatitude(data.coords.latitude);
      setUserLongitude(data.coords.longitude);
      const sendObject = {
        input: {
          message_type: MessageType.TEXT,
          text: `Ok I've shared my location.`,
        },
        id: null as string,
        thread_id: THREAD_ID_MAIN,
      };
      instance.send(sendObject);

      // Remove the loading indicator and bring back the input field.
      setShowLoadingCount(showLoadingCount => showLoadingCount - 1);
    },
    [instance],
  );

  // If there was an error retrieving the users location then send that to the assistant to end the action.
  const getLocationError = useCallback(
    (error: GeolocationPositionError) => {
      const sendObject = {
        input: {
          message_type: MessageType.TEXT,
          text: `There was an error sharing my location.`,
        },
        id: null as string,
        thread_id: THREAD_ID_MAIN,
      };
      // See https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError.
      if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
        sendObject.input.text = `I don't want to share my location at this time.`;
      } else if (error.code === GeolocationPositionError.POSITION_UNAVAILABLE) {
        sendObject.input.text = `The browser encountered an error sharing my location.`;
      }
      instance.send(sendObject);

      // Remove the loading indicator and bring back the input field.
      setShowLoadingCount(showLoadingCount => showLoadingCount - 1);
    },
    [instance],
  );

  useEffect(() => {
    // A preReceive handler for the "find the nearest location" demo that takes the users location and then provides an iframe
    // with directions to the nearest ibm location.
    const googleMapsLocationPreReceiveHandler = (event: BusEventPreReceive) => {
      const generic = event.data.output?.generic;
      generic?.forEach(item => {
        if (isUserDefinedItem(item) && item.user_defined?.user_defined_type === 'shareLocation') {
          // If we've received a custom response to share the users location we need to kick off a loading state in
          // web chat and use the browsers built in geolocation functionality to get the users' location.
          setShowLoadingCount(showLoadingCount => showLoadingCount + 1);

          // See https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition for more info.
          navigator.geolocation.getCurrentPosition(takeLocation, getLocationError);
        }
        if (isIFrameItem(item) && item.title === 'IBM Location') {
          // When we receive an iframe response for the closes IBM location we need to edit the source url to add the
          // users current latitude and longitude so that the directions will be from the users current location to
          // the location closest to them.
          // See https://developers.google.com/maps/documentation/embed/embedding-map#directions_mode for more info.
          item.source = `https://www.google.com/maps/embed/v1/directions?key=AIzaSyBQ22ruiQc8z8siUpOzcb4fDtvakV9aZnE&origin=${userLatitude},${userLongitude}&destination=IBM`;

          const currentPanelName = getCurrentPanelName();
          // Send an event for finishing the iframe feature depending on the panel being viewed.
          // Check if the panel being viewed is the "tell" panel since it demos the same thing.
          if (currentPanelName === LendyrPanel.TELL) {
            trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.TELL);
          } else {
            trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.IFRAME);
          }
        }
      });
    };

    instance.on({
      type: BusEventType.PRE_RECEIVE,
      handler: googleMapsLocationPreReceiveHandler,
    });

    return () => {
      instance.off({
        type: BusEventType.PRE_RECEIVE,
        handler: googleMapsLocationPreReceiveHandler,
      });
    };
  }, [getLocationError, takeLocation, instance, userLatitude, userLongitude, showLoadingCount]);

  return showLoadingCount > 0 && <LoadingLocationPortal instance={instance} />;
}

export { GoogleMapsLocationContainer };
