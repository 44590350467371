/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

/**
 * This context holds the width value of the entire page site which is different from the {@link DemoPage} which can
 * be affected by the {@link DemoGuidanceComponent} expanded state.
 */
const PageWidthContext = React.createContext<number>(window.innerWidth);

export { PageWidthContext };
