/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2020, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import packageJSON from '../../../package.json';

const VERSION: string = packageJSON.version?.split('-')[0];

// Prefix used to distinguish console logs omitted from our code
const WA_CONSOLE_PREFIX = '[Watson Assistant Demo]';

const MEANINGFUL_CONVERSATION_ATTRIBUTE = 'data-meaningful-conversation';

// This is a header respected by the IBM stack for tracking requests as they move from service to service. By default,
// Cerberus will add a transaction id x-request-id to the response which could be used for tracking. However,
// if we set one here we can reference it when debugging and downstream services can search in their logs for this
// value.
const API_DEBUGGING_HEADER_NAME = 'X-Global-Transaction-ID';

enum WriteableElementName {
  /**
   * An element that appears in the main message body directly above the welcome node.
   */
  WELCOME_NODE_BEFORE_ELEMENT = 'welcomeNodeBeforeElement',

  /**
   * An element that appears in the header on a new line. Only visible while talking to the bot.
   */
  HEADER_BOTTOM_ELEMENT = 'headerBottomElement',

  /**
   * An element that appears after the messages area and before the input area.
   */
  BEFORE_INPUT_ELEMENT = 'beforeInputElement',

  /**
   * An element that appears on the home screen after the conversation starters.
   */
  HOME_SCREEN_AFTER_STARTERS_ELEMENT = 'homeScreenAfterStartersElement',
}

export {
  WA_CONSOLE_PREFIX,
  VERSION,
  MEANINGFUL_CONVERSATION_ATTRIBUTE,
  API_DEBUGGING_HEADER_NAME,
  WriteableElementName,
};
