/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './PageLink.css';

import { Link, LinkProps } from 'carbon-components-react';
import cx from 'classnames';
import React from 'react';

import { DemoSiteParamsAndHash } from '../framework/demoTypes';
import { useExtractDemoLocationProps } from '../framework/hooks/useExtractDemoLocationProps';
import { useNavigateOnClick } from '../framework/hooks/useNavigateOnClick';

type PageLinkProps = LinkProps & DemoSiteParamsAndHash;

/**
 * This link component is used to navigate to different pages in the demo site page.
 */
function PageLink(props: PageLinkProps) {
  const { paramsAndHash, className, children, onClick, ...regularProps } = useExtractDemoLocationProps(props);
  const navigateOnClick = useNavigateOnClick(onClick, paramsAndHash);
  return (
    <Link {...regularProps} className={cx('PageLink', className)} onClick={navigateOnClick}>
      {children}
    </Link>
  );
}

export { PageLink };
