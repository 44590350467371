/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './EmailPage.css';

import cx from 'classnames';
import React from 'react';

import lendyrEmailBannerBackground from '../assets/lendyr-email-banner-background.svg';
import { LendyrLogo } from '../components/LendyrLogo';
import { PageLink } from '../components/PageLink';
import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../framework/demoTypes';
import { useDemoLocation } from '../framework/hooks/selectorHooks';
import { usePageWidth } from '../framework/hooks/usePageWidth';
import { BREAKPOINT_960, DEEP_LINKING_EMAIL_TEXT } from '../framework/utils/demoConstants';
import { trackDemoFeatureFinished } from '../framework/utils/demoTracking';

const EMAIL_BANNER_STYLE = { backgroundImage: `url(${lendyrEmailBannerBackground})` };

function EmailPage() {
  const pageWidth = usePageWidth();
  const { panel } = useDemoLocation();

  return (
    <div
      className={cx('EmailPage', {
        [`EmailPage--min-${BREAKPOINT_960}px`]: pageWidth > BREAKPOINT_960,
      })}
    >
      <div className="EmailPage__Content">
        <div className="EmailPage__Title">Your E-Mail</div>
        <div className="EmailPage__MainContent">
          <div className="EmailPage__Email">
            <div className="EmailPage__EmailHeader">
              <div className="EmailPage__BrandEmailContainer">
                <div className="EmailPage__EmailHeaderLogoContainer">
                  <LendyrLogo className="EmailPage__EmailHeaderLogo" />
                </div>
                <span className="EmailPage__BrandName">Ayco</span>
                <span className="EmailPage__BrandEmail">|&nbsp;&nbsp;&nbsp;ayco@ayco.com</span>
              </div>
              <span className="EmailPage__ToUserEmail">To: cade@example.com</span>
            </div>
            <div className="EmailPage__EmailBody">
              <div className="EmailPage__EmailBanner" style={EMAIL_BANNER_STYLE}>
                <LendyrLogo />
                <span>Ayco</span>
              </div>
              <div className="EmailPage__EmailTextContainer">
                <div className="EmailPage__EmailText">
                  Hi Cade,
                  <br />
                  <br />
                  Just a friendly reminder from our last conversation on your portfolio changes,{' '}
                  <PageLink
                    className="EmailPage__DeepLink"
                    onClick={() => {
                      if (panel === LendyrPanel.UNIFY_CROSS_CHANNEL_HELP) {
                        trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.UNIFY_CROSS_CHANNEL_HELP);
                      } else {
                        trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.DEEP_LINKING);
                      }
                    }}
                    page={LendyrPage.HOME}
                    pageLinkID="b50"
                  >
                    {DEEP_LINKING_EMAIL_TEXT}.
                  </PageLink>
                  <br />
                  <br />
                  Best regards,
                  <br />
                  Ayco Wealth Manager
                </div>
              </div>
            </div>
          </div>
          <div className="EmailPage__EmailNavigation">
            <div className="EmailPage__UserEmail">cade@example.com</div>
            <div className="EmailPage__EmailNavigationItem">Inbox</div>
            <div className="EmailPage__EmailNavigationItem">Drafts</div>
            <div className="EmailPage__EmailNavigationItem">Archive</div>
            <div className="EmailPage__EmailNavigationItem">Sent</div>
            <div className="EmailPage__EmailNavigationItem">Junk</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { EmailPage };
