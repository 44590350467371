/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelList, GuidancePanelListItem } from '../../commonComponents/GuidancePanelList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function PhoneSummaryPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Summary"
        description={
          <>
            Use the Watson Assistant phone channel to deliver exceptional, scalable experiences through 3 primary
            themes:
            <GuidancePanelList>
              <GuidancePanelListItem>
                Superior language processing that answers questions and accomplishes tasks without forcing callers to
                listen to long lists of options
              </GuidancePanelListItem>
              <GuidancePanelListItem>
                Comprehensive topic coverage increasing containment in comparison to a traditional IVR
              </GuidancePanelListItem>
              <GuidancePanelListItem>
                Connections to your existing contact center with seamless hand-off to human agents with the context of
                the previous conversation
              </GuidancePanelListItem>
            </GuidancePanelList>
          </>
        }
      />
    </div>
  );
}

const PhoneSummaryPanelConfig: SectionPanelConfig = {
  title: 'Phone Summary',
  SectionPanelComponent: PhoneSummaryPanel,
  page: LendyrPage.PHONE_TOUR,
  panel: LendyrPanel.PHONE_SUMMARY,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.PHONE_SUMMARY,
};

export { PhoneSummaryPanel, PhoneSummaryPanelConfig };
