/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Button, ComboBox, NumberInput } from 'carbon-components-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MockTextInput } from '../../components/demoGuidanceComponent/commonComponents/MockTextInput';
import { HashAnchorContainer } from '../../components/HashAnchorContainer';
import { demoActions } from '../../framework/store/demoActions';
import { DemoAppState } from '../../framework/store/DemoAppState';
import { LendyrCardOffer } from './CreditCardOffers';

const US_STATES = [
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

interface CreditCardApplicationProps {
  /**
   * A lendyr credit card offer object used to display data about the credit card the user is applying for.
   */
  cardOffer: LendyrCardOffer;
}

/**
 * This component is rendered in the credit cards page when the clicks to apply for a lendyr card in the credit
 * cards page or carousel in the web chat.
 */
function CreditCardApplication({ cardOffer }: CreditCardApplicationProps) {
  const dispatch = useDispatch();
  const annualIncome = useSelector((state: DemoAppState) => state.pageState.creditCardsPage.annualIncome);
  const [isComplete, setIsComplete] = useState(false);
  const { type, title, description, cardHighlights } = cardOffer;

  if (isComplete) {
    return <div className="CreditCards__ApplicationCompletedText DemoPage__CenterContent">Application submitted!</div>;
  }

  return (
    <HashAnchorContainer id={type} className="CreditCards__Application DemoPage__CenterContent">
      <div className="CreditCards__CardOfferSection CreditCards__TextWrapper">
        <div className="DemoPageCommon__Title">{title}</div>
        <div className="DemoPageCommon__Description">{description}</div>
        <ul className="CreditCards__CardHighlightsContainer">
          {cardHighlights.map(highlight => (
            <li key={highlight} className="CreditCards__CardHighlight">
              {highlight}
            </li>
          ))}
        </ul>
      </div>
      <div className="CreditCards__Application">
        <div className="CreditCards__InputGroup">
          <MockTextInput labelText="First name" value="Cade" />
          <MockTextInput labelText="Last name" value="McGregor" />
        </div>
        <MockTextInput labelText="Residential address" value="1234 IBM Avenue" />
        <div className="CreditCards__InputGroup">
          <MockTextInput labelText="City" value="City" />
          <ComboBox
            id="application-state"
            titleText="State"
            items={US_STATES}
            // This prop is required, but we don't want to make use of it.
            placeholder="You shouldn't be able to see this"
            selectedItem="New York"
            // This prop is not optional, but we won't make use of it.
            onChange={() => {}}
            light
          />
        </div>
        <MockTextInput labelText="Zip code" value="12345" />
        <MockTextInput labelText="Phone number" value="123-456-7890" />
        <div className="CreditCards__InputGroup">
          <MockTextInput labelText="Credit score" value="750" />
          <NumberInput
            id="application-annual-income"
            label="Annual income"
            type="number"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              // imaginaryTarget is not a standard event property, it's provided by the Carbon component NumberInput,
              // but it's also missing from their type definitions.
              dispatch(
                demoActions.setCreditCardPageStateProperty('annualIncome', (event as any).imaginaryTarget?.value),
              );
            }}
            value={annualIncome}
          />
        </div>
        <Button
          className="LendyrButton LendyrButton--primary"
          onClick={() => {
            setIsComplete(Boolean(annualIncome));
          }}
          disabled={!annualIncome}
        >
          Submit application
        </Button>
      </div>
    </HashAnchorContainer>
  );
}

export { CreditCardApplication };
