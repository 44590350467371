/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelIntroInstructions } from '../../commonComponents/GuidancePanelIntroInstructions';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function GenerativeAIIntroPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Generative AI"
        description={
          <>
            Generative AI is a transformative technology that is powering better conversational experiences at lower
            cost.
            <br />
            <br />
            Generative AI refers to foundation models that can take raw data — say, all of Wikipedia or the collected
            works of Rembrandt — and "learn" to generate statistically probable outputs when prompted.
            <br />
            <br />
            In this tour, you will learn how Watson Assistant is using large language models, a category of foundation
            models that specialize in text, to enhance its virtual assistants and improve customer and employee
            experiences.
          </>
        }
      />
      <GuidancePanelIntroInstructions />
    </div>
  );
}

const GenerativeAIIntroPanelConfig: SectionPanelConfig = {
  title: 'Generative AI intro',
  SectionPanelComponent: GenerativeAIIntroPanel,
  panel: LendyrPanel.HOME,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.GENERATIVE_AI_INTRO,
};

export { GenerativeAIIntroPanel, GenerativeAIIntroPanelConfig };
