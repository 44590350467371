/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function GenerativeAISummaryPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Tour Summary"
        description={
          <>
            Watson Assistant offers enterprise-ready generative features powered by customized, reliable IBM watsonx
            LLMs.
            <br />
            <br />
            Book a demo with an expert to see a custom demo of generative AI for your business, as Watson Assistant
            integrates generative AI into intelligent virtual assistants for customer and employee care.
          </>
        }
      />
    </div>
  );
}

const GenerativeAISummaryPanelConfig: SectionPanelConfig = {
  title: 'Generative AI summary',
  SectionPanelComponent: GenerativeAISummaryPanel,
  panel: LendyrPanel.GENERATIVE_AI_SUMMARY,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.GENERATIVE_AI_SUMMARY,
};

export { GenerativeAISummaryPanel, GenerativeAISummaryPanelConfig };
