/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { trackDemoSectionPanelAction } from '../../../../framework/utils/demoTracking';
import { useOnMount } from '../../../../web-chat-dependencies/hooks/useOnMount';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelSelectList, GuidanceSelectListItem } from '../../commonComponents/GuidancePanelSelectList';
import { SectionPanelComponentProps, SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { IterateQuicklyOverlay } from './IterateQuicklyOverlay';
import { ChooseATemplateSVGComponent } from './svgComponents/ChooseATemplateSVGComponent';
import { ChooseATemplateTooltipSVGComponent } from './svgComponents/ChooseATemplateTooltipSVGComponent';
import { StartingANewActionSVGComponent } from './svgComponents/StartingANewActionSVGComponent';
import { StartingANewActionTooltipSVGComponent } from './svgComponents/StartingANewActionTooltipSVGComponent';

const CREATE_ACTIONS_LINK: GuidancePanelImplementationData[] = [
  {
    title: 'Build actions',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-build-actions-overview',
    type: 'Docs',
  },
  {
    title: 'Build actions with templates',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-actions-templates',
    type: 'Docs',
  },
];

const CREATE_ACTIONS = 'Create Actions';
const CREATE_ACTIONS_ITEM_1_TEXT = 'Starting a new action';
const CREATE_ACTIONS_ITEM_2_TEXT = 'Choose a template';

function CreateActionsPanel({ selectedSubViewIndex, onSubViewChange }: SectionPanelComponentProps) {
  // Upon visiting this panel, track the first preview in the list as being viewed.
  useOnMount(() => {
    trackDemoSectionPanelAction(`${CREATE_ACTIONS} - ${CREATE_ACTIONS_ITEM_1_TEXT}`);
  });

  return (
    <div>
      <GuidancePanelHeading
        title={CREATE_ACTIONS}
        description={`Fill in the gaps by building new "actions," or conversational flows. Start from scratch, or use a library of templates that cover common use cases across industries and domains.`}
      />
      <GuidancePanelSelectList
        selectedIndex={selectedSubViewIndex}
        onChange={index => {
          if (selectedSubViewIndex !== index) {
            if (index === 0) {
              trackDemoSectionPanelAction(`${CREATE_ACTIONS} - ${CREATE_ACTIONS_ITEM_1_TEXT}`);
            } else {
              trackDemoSectionPanelAction(`${CREATE_ACTIONS} - ${CREATE_ACTIONS_ITEM_2_TEXT}`);
            }

            onSubViewChange(index);
          }
        }}
      >
        <GuidanceSelectListItem text={CREATE_ACTIONS_ITEM_1_TEXT} />
        <GuidanceSelectListItem text={CREATE_ACTIONS_ITEM_2_TEXT} />
      </GuidancePanelSelectList>
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={CREATE_ACTIONS_LINK} />
      </GuidancePanelImplementationSection>
      <IterateQuicklyOverlay>
        {selectedSubViewIndex === 0 && (
          <>
            <StartingANewActionSVGComponent />
            <StartingANewActionTooltipSVGComponent />
          </>
        )}
        {selectedSubViewIndex === 1 && (
          <>
            <ChooseATemplateSVGComponent />
            <ChooseATemplateTooltipSVGComponent />
          </>
        )}
      </IterateQuicklyOverlay>
    </div>
  );
}

const CreateActionsPanelConfig: SectionPanelConfig = {
  title: 'Create Actions',
  SectionPanelComponent: CreateActionsPanel,
  panel: LendyrPanel.CREATE_ACTIONS,
  page: LendyrPage.ITERATE_QUICKLY_TOUR,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.CREATE_ACTIONS,
  totalSubViews: 2,
};

export { CreateActionsPanel, CreateActionsPanelConfig };
