/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Store } from 'redux';

import { LendyrAppState } from '../../../lendyr/store/LendyrAppState';
import { BusEventType, BusEventWindowOpen } from '../../../web-chat-dependencies/types/eventBusTypes';
import { ResolvablePromise, resolvablePromise } from '../../../web-chat-dependencies/utils/resolvablePromise';
import { CustomPanelType } from '../../demoTypes';
import { demoActions } from '../../store/demoActions';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const PRE_CHAT_FORM_TRANSITION_CONFIG: DemoTransitionConfig = {
  doTransitionWithWebChat: (store: Store<LendyrAppState>, instance) => {
    const panelInstance = instance.customPanels.getPanel();

    let windowOpenResolvablePromise: ResolvablePromise;
    let windowOpenEvent: BusEventWindowOpen;

    function resolveWindowOpenPromise() {
      if (windowOpenEvent && windowOpenResolvablePromise) {
        const { preChatFormValues } = store.getState().persistedToBrowserSessionStorage.siteData;

        windowOpenEvent.cancelOpen = !preChatFormValues;
        windowOpenResolvablePromise.doResolve();

        windowOpenResolvablePromise = null;
        windowOpenEvent = null;
      }
    }

    /**
     * If there are no pre-chat form values in store, pause the window open event with a resolvable promise and open
     * the pre-chat form.
     */
    function windowOpenHandler(event: BusEventWindowOpen) {
      const { preChatFormValues } = store.getState().persistedToBrowserSessionStorage.siteData;

      if (!preChatFormValues) {
        const promise = resolvablePromise();

        windowOpenEvent = event;
        windowOpenResolvablePromise = promise;
        panelInstance.open({ hidePanelHeader: true });

        return promise;
      }

      // If the pre-chat form was complete, the home screen greeting message should also be updated to reflect that.
      instance.updateHomeScreenConfig({ greeting: getUserHomeScreenGreetingMessage(preChatFormValues.firstName) });

      return null;
    }

    /**
     * Update the home screen config with a custom greeting message for the user if they completed the form before
     * closing the panel.
     */
    function customPanelPreCloseHandler() {
      const { preChatFormValues } = store.getState().persistedToBrowserSessionStorage.siteData;
      if (preChatFormValues) {
        const { firstName } = preChatFormValues;
        instance.updateHomeScreenConfig({
          greeting: getUserHomeScreenGreetingMessage(firstName),
        });
      }

      resolveWindowOpenPromise();
    }

    const preChatFormHandlers = [
      { type: BusEventType.WINDOW_OPEN, handler: windowOpenHandler },
      { type: BusEventType.RESTART_CONVERSATION, handler: resolveWindowOpenPromise },
      { type: BusEventType.CUSTOM_PANEL_PRE_CLOSE, handler: customPanelPreCloseHandler },
    ];

    instance.on(preChatFormHandlers);
    store.dispatch(demoActions.setStateProperty('customPanelType', CustomPanelType.PRE_CHAT_FORM));

    return () => {
      instance.off(preChatFormHandlers);
    };
  },

  doAfterWebChatRender: (store: Store<LendyrAppState>, instance) => {
    const { persistedToBrowserSessionStorage } = store.getState();
    const { isWebChatOpen } = instance.getState();
    const panelInstance = instance.customPanels.getPanel();

    // If the pre-chat form should be rendered by default and web chat is opened by default, we should open the
    // custom panel immediately.
    if (isWebChatOpen && !persistedToBrowserSessionStorage.siteData.preChatFormValues) {
      panelInstance.open({ hidePanelHeader: true });
    }
  },

  restartOptions: {
    closeWebChat: true,
    onClickRestart: store => {
      store.dispatch(demoActions.setStateProperty('customPanelType', CustomPanelType.PRE_CHAT_FORM));
    },
  },
};

/**
 * Returns a custom home screen greeting message using the given first name provided by the user who submitted the
 * pre-chat form.
 */
function getUserHomeScreenGreetingMessage(firstName: string) {
  return `Hello, ${firstName}! How can I help you`;
}

export { PRE_CHAT_FORM_TRANSITION_CONFIG };
