/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { ClickableTile, ClickableTileProps } from 'carbon-components-react';
import React from 'react';

import { DemoSiteParamsAndHash } from '../framework/demoTypes';
import { useExtractDemoLocationProps } from '../framework/hooks/useExtractDemoLocationProps';
import { useNavigateOnClick } from '../framework/hooks/useNavigateOnClick';

type DemoClickableTileProps = Omit<ClickableTileProps, 'disabled'> & DemoSiteParamsAndHash;

/**
 * This component returns a carbon clickable tile that acts as a navigation link and prevents a page refresh. This is
 * useful when we need a carbon button to navigate a user to a new page or panel.
 */
function DemoClickableTile(props: DemoClickableTileProps) {
  const { paramsAndHash, onClick, ...regularProps } = useExtractDemoLocationProps(props);
  const navigateOnClick = useNavigateOnClick(onClick, paramsAndHash);
  return <ClickableTile {...regularProps} onClick={navigateOnClick} />;
}

export { DemoClickableTile, DemoClickableTileProps };
