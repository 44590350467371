/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const JOURNEYS_CODE_LINK: GuidancePanelImplementationData[] = [
  {
    title: 'Guide customers with journeys',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-journeys',
    type: 'Docs',
  },
  {
    title: 'Best practices guide with journeys',
    link: 'https://medium.com/ibm-watson/best-practices-for-watson-assistant-journeys-993d16fb0ad0',
    type: 'Article',
  },
];

function JourneysPanel() {
  return (
    <JourneysPanelContent
      title="Journeys"
      description="The best assistant is a teacher that shows your users how to self-serve. Rather than recreate (and maintain) existing workflows, use Watson Assistant to create journeys for your customers to discover and adopt new features, or find and complete tasks for themselves. Use video, text, and imagery to hand-hold your users with interactive, step-by-step help through your website."
    />
  );
}

function JourneysPanelContent({ title, description }: { title: string; description: string }) {
  return (
    <div>
      <GuidancePanelHeading title={title} description={description} />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following: <GuidancePanelTextSnippet text="Can you show me some investment options?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Click "Show me"</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Follow the journey to select new investment option.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Before completing the journey, return to the assistant by clicking the chat icon.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Enter the following:
          <GuidancePanelTextSnippet text="Do I need to do anything before submitting a investment request?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Resume and complete the guided journey.</GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={JOURNEYS_CODE_LINK} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const JourneysPanelConfig: SectionPanelConfig = {
  title: 'Journeys',
  SectionPanelComponent: JourneysPanel,
  panel: LendyrPanel.JOURNEYS,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.JOURNEYS,
};

export { JourneysPanel, JourneysPanelContent, JourneysPanelConfig };
