/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { ConversationalSearchPanel } from '../EssentialsAndIndexSection/ConversationalSearchPanel';

/**
 * This file is for rendering {@link ConversationalSearchPanel} in the "Generative AI" tour and for tracking purposes.
 */

const GenerativeConversationalSearchPanelConfig: SectionPanelConfig = {
  title: 'Generative AI: Conversational Search',
  SectionPanelComponent: ConversationalSearchPanel,
  page: LendyrPage.GENERATIVE_AI_TOUR,
  panel: LendyrPanel.GENERATIVE_CONVERSATIONAL_SEARCH,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.GENERATIVE_CONVERSATIONAL_SEARCH,
};

export { GenerativeConversationalSearchPanelConfig };
