/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './DemoGuidanceComponent.css';

import { HeaderPanel } from 'carbon-components-react';
import cx from 'classnames';
import React from 'react';

import { LendyrSection } from '../../framework/demoTypes';
import { useDemoLocation } from '../../framework/hooks/selectorHooks';
import { AccessibleScalableAISectionComponent } from './lendyrSections/AccessibleScalableAISection/AccessibleScalableAISectionComponent';
import { BuildExperienceSectionComponent } from './lendyrSections/BuildExperience/BuildExperienceSectionComponent';
import { DriveCustomerOutcomesSectionComponent } from './lendyrSections/DriveCustomerOutcomes/DriveCustomerOutcomesSectionComponent';
import { EssentialsSectionComponent } from './lendyrSections/EssentialsAndIndexSection/EssentialsSectionComponent';
import { FeatureListSectionComponent } from './lendyrSections/EssentialsAndIndexSection/FeatureListSectionComponent';
import { GenerativeAISectionComponent } from './lendyrSections/GenerativeAI/GenerativeAISectionComponent';
import { HomeSectionComponent } from './lendyrSections/HomeSectionComponent';
import { IterateQuicklySectionComponent } from './lendyrSections/IterateQuickly/IterateQuicklySectionComponent';
import { PhoneSectionComponent } from './lendyrSections/PhoneSection/PhoneSectionComponent';
import { TellDoShowSectionComponent } from './lendyrSections/TellDoShowSection/TellDoShowSectionComponent';
import { UnifyPersonalizedSectionComponent } from './lendyrSections/UnifyPersonalizedSection/UnifyPersonalizedSectionComponent';

interface DemoGuidanceComponentProps {
  /**
   * Determines if the side nav is in the expanded state.
   */
  expanded: boolean;

  /**
   * The callback function to fire when the overlay menu is closed.
   */
  onOverlayMenuClose: () => void;
}

/**
 * The feature guidance container introduces controls how the user decides to experience web chat features on the demo
 * site, either through a guided section or going directly to a feature demo.
 */
function DemoGuidanceComponent({ expanded, onOverlayMenuClose }: DemoGuidanceComponentProps) {
  const { section } = useDemoLocation();
  const GuidanceSectionComponent = getGuidanceSectionComponent(section);
  const renderHomeGuidancePanel = GuidanceSectionComponent === null;
  return (
    <>
      <div className={cx('bx--side-nav__overlay', { 'bx--side-nav__overlay-active': expanded })} />
      <HeaderPanel className="FeatureGuidancePanel carbon-dark-theme" expanded={expanded} aria-label="">
        {renderHomeGuidancePanel && (
          <HomeSectionComponent isGuidancePanelExpanded={expanded} onOverlayMenuClose={onOverlayMenuClose} />
        )}
        {!renderHomeGuidancePanel && <GuidanceSectionComponent />}
      </HeaderPanel>
    </>
  );
}

/**
 * Returns the guidance section type that should be displayed based on the first panel value.
 */
function getGuidanceSectionComponent(section: string) {
  switch (section) {
    case LendyrSection.ESSENTIALS:
      return EssentialsSectionComponent;
    case LendyrSection.INDEX:
      return FeatureListSectionComponent;
    case LendyrSection.TELL_DO_SHOW:
      return TellDoShowSectionComponent;
    case LendyrSection.ACCESSIBLE_SCALABLE_AI:
      return AccessibleScalableAISectionComponent;
    case LendyrSection.UNIFY_PERSONALIZED:
      return UnifyPersonalizedSectionComponent;
    case LendyrSection.PHONE:
      return PhoneSectionComponent;
    case LendyrSection.ITERATE_QUICKLY:
      return IterateQuicklySectionComponent;
    case LendyrSection.DRIVE_CUSTOMER_OUTCOMES:
      return DriveCustomerOutcomesSectionComponent;
    case LendyrSection.GENERATIVE_AI:
      return GenerativeAISectionComponent;
    case LendyrSection.BUILD_EXPERIENCE:
      return BuildExperienceSectionComponent;
    default:
      return null;
  }
}

const DemoGuidanceComponentExport = React.memo(DemoGuidanceComponent);

export { DemoGuidanceComponentExport as DemoGuidanceComponent };
