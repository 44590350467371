/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelIntroInstructions } from '../../commonComponents/GuidancePanelIntroInstructions';
import { ToolingSVGPreviewOverlay } from '../../commonComponents/ToolingSVGPreviewOverlay';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { IterateQuicklyDefaultSVGComponent } from './svgComponents/IterateQuicklyDefaultSVGComponent';
import { IterateQuicklyIntroTooltipSVGComponent } from './svgComponents/IterateQuicklyIntroTooltipSVGComponent';

function IterateQuicklyIntroPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Iterate Quickly"
        description={
          <>
            Unlock your team’s potential to improve and iterate quickly on your support experience.
            <br />
            <br />
            Watson Assistant pairs an easy-to-use build experience and pre-designed repeatable conversational patterns
            to accelerate the build without the need for IT or development resources.
          </>
        }
      />
      <GuidancePanelIntroInstructions />
      <ToolingSVGPreviewOverlay>
        <IterateQuicklyDefaultSVGComponent />
        <IterateQuicklyIntroTooltipSVGComponent />
      </ToolingSVGPreviewOverlay>
    </div>
  );
}

const IterateQuicklyIntroPanelConfig: SectionPanelConfig = {
  title: 'Iterate Quickly Intro',
  SectionPanelComponent: IterateQuicklyIntroPanel,
  panel: LendyrPanel.HOME,
  page: LendyrPage.ITERATE_QUICKLY_TOUR,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.ITERATE_QUICKLY_INTRO,
};

export { IterateQuicklyIntroPanel, IterateQuicklyIntroPanelConfig };
