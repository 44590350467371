/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Education32, PedestrianFamily32, Scales32 } from '@carbon/icons-react';
import React from 'react';

import lendyrSideImageStudent from '../assets/lendyr-side-image-student.jpg';
import { DemoPage } from '../components/DemoPage';
import { LendyrButton } from '../components/LendyrButton';
import { LendyrPageType } from '../framework/demoTypes';
import { CardLinkComponentProps } from '../framework/types/CardLinkComponentProps';

const CARD_LINKS: CardLinkComponentProps[] = [
  { Icon: Education32, title: 'Financial Wellness' },
  { Icon: Education32, title: 'Financial Management' },
  { Icon: PedestrianFamily32, title: 'Executive Financial Management' },
  { Icon: Scales32, title: 'SurvivorSupport®' },
];

function StudentLoansPage() {
  return (
    <DemoPage
      title="Employee Programs"
      description="Get to know your Ayco benefit"
      type={LendyrPageType.SECONDARY}
      cardLinks={CARD_LINKS}
      MainContent={
        <div className="DemoPage__Body">
          <div className="DemoPage__SectionTextWrapper">
            <div className="DemoPage__SectionWrapper">
              <div className="DemoPageCommon__Title">Financial Wellness</div>
              <div className="DemoPageCommon__Description">
                Get started with your financial wellness benefit offered by your employer.
              </div>
              <LendyrButton
                onClick={() => window.open('https://www.ayco.com/employees/wellness.html', '_blank')}
                tertiary
              >
                View detail
              </LendyrButton>
            </div>
            <div className="DemoPage__SectionWrapper">
              <div className="DemoPageCommon__Title">Financial Management</div>
              <div className="DemoPageCommon__Description">
                A financial benefit that meets the needs of increasing financial complexity
              </div>
              <LendyrButton
                onClick={() => window.open('https://www.ayco.com/employees/management.html', '_blank')}
                tertiary
              >
                View details
              </LendyrButton>
            </div>
            <div className="DemoPage__SectionWrapper">
              <div className="DemoPageCommon__Title">Executive Financial Management</div>
              <div className="DemoPageCommon__Description">
                A financial corporate benefit for executives and senior leaders
              </div>
              <LendyrButton
                onClick={() => window.open('https://www.ayco.com/employees/executive.html', '_blank')}
                tertiary
              >
                View details
              </LendyrButton>
            </div>
            <div className="DemoPage__SectionWrapper">
              <div className="DemoPageCommon__Title">SurvivorSupport®</div>
              <div className="DemoPageCommon__Description">
                Specialized guidance to help you or your family navigate financial decisions after the loss of a loved
                one.
              </div>
              <LendyrButton
                onClick={() => window.open('https://www.ayco.com/employees/survivor-support.html', '_blank')}
                tertiary
              >
                View details
              </LendyrButton>
            </div>
          </div>
          <img className="DemoPage__CreditCardImage" src={lendyrSideImageStudent} alt="student" />
        </div>
      }
    />
  );
}

export { StudentLoansPage };
