/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { DriveCustomerOutcomesOverlay } from './DriveCustomerOutcomesOverlay';
import { FormAFullUserJourneySVGComponent } from './svgComponents/FormAFullUserJourneySVGComponent';
import { FormAFullUserJourneyTooltipSVGComponent } from './svgComponents/FormAFullUserJourneyTooltipSVGComponent';

const FORM_A_FULL_USER_JOURNEY_LINK: GuidancePanelImplementationData[] = [
  {
    title: 'Setup Watson Assistant with Segment',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-segment-add',
    type: 'Docs',
  },
];

function FormAFullUserJourneyPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Form a Full User Journey"
        description="Let the data provided paint the full picture of your customers’ user journey."
      />
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={FORM_A_FULL_USER_JOURNEY_LINK} />
      </GuidancePanelImplementationSection>
      <DriveCustomerOutcomesOverlay>
        <FormAFullUserJourneySVGComponent />
        <FormAFullUserJourneyTooltipSVGComponent />
      </DriveCustomerOutcomesOverlay>
    </div>
  );
}

const FormAFullUserJourneyPanelConfig: SectionPanelConfig = {
  title: 'Form a Full User Journey',
  SectionPanelComponent: FormAFullUserJourneyPanel,
  panel: LendyrPanel.FORM_A_FULL_USER_JOURNEY,
  page: LendyrPage.DRIVE_CUSTOMER_OUTCOMES_TOUR,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.FORM_A_FULL_USER_JOURNEY,
};

export { FormAFullUserJourneyPanel, FormAFullUserJourneyPanelConfig };
