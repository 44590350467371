/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelTryItOutList.css';

import React, { Children, ReactElement } from 'react';

import { assertChildType } from '../../../framework/utils/demoUtils';
import { useOnMount } from '../../../web-chat-dependencies/hooks/useOnMount';
import { HasChildren } from '../../../web-chat-dependencies/types/HasChildren';

interface GuidancePanelTryItOutStepWrapperProps extends HasChildren {
  /**
   * The instruction item number in the list.
   */
  step: number;
}

/**
 * The try it out container handles rendering the list of steps for the user to follow in a feature panel.
 */
function GuidancePanelTryItOutList({ children, hideTitle }: HasChildren & { hideTitle?: boolean }) {
  useOnMount(() => {
    Children.forEach(children, (child: ReactElement) => {
      assertChildType(child, GuidancePanelTryItOutListItem);
    });
  });

  return (
    <div className="GuidancePanelTryItOutList">
      {!hideTitle && <div className="GuidancePanelTryItOutList__Title">Try it out</div>}
      {Children.map(children, (child: ReactElement, index) => (
        <GuidancePanelTryItOutListItemContainer key={child.key} step={index + 1}>
          {child}
        </GuidancePanelTryItOutListItemContainer>
      ))}
    </div>
  );
}

/**
 * This component wraps around the actual try it out step content which includes the digit of the step.
 */
function GuidancePanelTryItOutListItemContainer({ step, children }: GuidancePanelTryItOutStepWrapperProps) {
  return (
    <div className="GuidancePanelTryItOutListItemContainer">
      <div>
        <div className="GuidancePanelTryItOutListItemContainer__Number">{step}</div>
      </div>
      <div className="GuidancePanelTryItOutListItemContainer__ContentWrapper">
        <div className="GuidancePanelTryItOutListItemContainer__Content">{children}</div>
      </div>
    </div>
  );
}

/**
 * This component is for wrapping around content that needs to be rendered into a try it out step.
 */
function GuidancePanelTryItOutListItem({ children }: HasChildren) {
  return <div className="GuidancePanelTryItOutListItem">{children}</div>;
}

export { GuidancePanelTryItOutList, GuidancePanelTryItOutListItem };
