/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const LIVE_AGENT_PANEL_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Transfer to live agents',
    link: 'https://medium.com/ibm-watson/customers-at-odds-with-your-voice-assistant-channel-transfer-is-here-dd578b43533e',
    type: 'Article',
  },
  {
    title: 'Learn to integrate with phone',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-deploy-phone',
    type: 'Docs',
  },
];

function LiveAgentPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Live Agent"
        description={
          <>
            Escalating your users to a human agent can be useful when all other options have been exhausted. Watson
            Assistant allows the agent to view the full conversation history in order to have the necessary context to
            help the user.
            <br />
            <br />
            Watson Assistant supports live agent integrations with NICE CXone, Genesys, and Twilio, among others.
          </>
        }
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Say the following:
          <GuidancePanelTextSnippet text="Yes" />
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={LIVE_AGENT_PANEL_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const LiveAgentPanelConfig: SectionPanelConfig = {
  title: 'Live Agent',
  SectionPanelComponent: LiveAgentPanel,
  page: LendyrPage.PHONE_TOUR,
  panel: LendyrPanel.PHONE_LIVE_AGENT,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.PHONE_LIVE_AGENT,
};

export { LiveAgentPanel, LiveAgentPanelConfig };
