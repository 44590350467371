/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './ContentTable.css';

import dompurify from 'dompurify';
import React from 'react';

interface ContentTableData {
  /**
   * TableData is an html string used to render the table in this component.
   */
  tableDataHTML: string;
}

/**
 * A table component for displaying table data from BYOS content.
 */
function ContentTable({ tableDataHTML }: ContentTableData) {
  // The dompurify will sanitize & strip out potentially harmful code in this case of dangerouslySetInnerHTML
  // eslint-disable-next-line react/no-danger
  return <div className="ContentTable" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(tableDataHTML) }} />;
}

export { ContentTable };
