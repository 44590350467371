/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2019, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * This file contains the definition for the public application configuration operations that are provided by the
 * host page.
 */

import { ServiceDesk, ServiceDeskFactoryParameters } from '../services/haa/ServiceDesk';
import { REGION_MAP } from '../utils/regions';
import { HomeScreenConfig } from './HomeScreenConfig';
import { OnErrorData } from './OnErrorData';
import { WatsonAssistantChatInstance } from './WatsonAssistantChatInstance';

/**
 * The different variations of the launcher that can exist in the wild. Large, small, and reveal are all covid launchers.
 */
enum LauncherType {
  /**
   * The launcher that expands to a "complex" variation on desktop.
   */
  DESKTOP = 'desktop',

  /**
   * The launcher that expands to an "extended" variation on mobile.
   */
  MOBILE = 'mobile',
}

interface PublicConfig {
  /**
   * The integration ID of your web chat integration. This is exposed as a UUID. e.g.
   * '1d7e34d5-3952-4b86-90eb-7c7232b9b540'.
   */
  integrationID: string;

  /**
   * Which data center your integration was created in. e.g. 'us-south', 'us-east', 'jp-tok' 'au-syd', 'eu-gb',
   * 'eu-de', etc.
   */
  region: keyof typeof REGION_MAP;

  /**
   * The service instance ID of the Assistant hosting your web chat integration. This is exposed as a UUID. e.g.
   * '1d7e34d5-3952-4b86-90eb-7c7232b9b540'. This will eventually be made to be required.
   */
  serviceInstanceID?: string;

  /**
   * If you have an enterprise account, this is ID of your subscription and it is required. If you need this, it will be
   * provided in the snippet for you to copy and paste. If you don't need this, you won't see it.
   */
  subscriptionID?: string;

  /**
   * Render the chat launcher element used to open and close the chat window. If you elect to not show our built in
   * chat launcher, you will be responsible for firing the launcher:toggle, launcher:open or launcher:close events
   * from your own chat launcher. Or, you can use options.openChatByDefault to just have the chat interface be open
   * at initialization.
   */
  showLauncher?: boolean;

  /**
   * By default, the chat window will be rendered in a "closed" state.
   */
  openChatByDefault?: boolean;

  /**
   * Hide the ability to minimize the web chat.
   */
  hideCloseButton?: boolean;

  /**
   * Indicates if the close and restart (X) button should be rendered.
   */
  showCloseAndRestartButton?: boolean;

  /**
   * This value is only used when a custom element is being used to render the widget. By default a number of
   * enhancements to the widget are activated on mobile devices which can interfere with a custom element. This
   * value can be used to disable those enhancements while using a custom element.
   */
  disableCustomElementMobileEnhancements?: boolean;

  /**
   * The version of the chat widget to use. This value may specify "latest" or it may specify a partial version such
   * as "1.5" (which would cover anything that is "1.5.x". If no matching version is found, an error will be logged
   * and the latest version will be used.
   *
   * @see IBMConfig.useExactVersion is an internal-use-only flag used to disable validation of the version number so
   * it can be used as-is.
   */
  clientVersion?: string;

  /**
   * This is a factory for producing custom implementations of service desks. If this value is set, then this will
   * be used to create an instance of a {@link ServiceDesk} when the user attempts to connect to an agent.
   */
  serviceDeskFactory?: (parameters: ServiceDeskFactoryParameters) => Promise<ServiceDesk>;

  /**
   * An optional namespace that can be added to the web chat that must be 30 characters or under. This value is
   * intended to enable multiple instances of the web chat to be used on the same page. The namespace for this web
   * chat. This value is used to generate a value to append to anything unique (id, session keys, etc) to allow
   * multiple web chats on the same page.
   *
   * Note: this value is used in the aria region label for the web chat. This means this value will be read out loud
   * by users using a screen reader.
   */
  namespace?: string;

  /**
   * The configuration that defines how web chat responds to link page requests (where there's a link reference in the
   * URL).
   */
  pageLinkConfig?: PageLinkConfig;

  /**
   * Contains undocumented properties that are for internal use only for development purposes.
   */
  __ibm__?: IBMConfig;
}

/**
 * This is the config that actually appears embedded in the host web page. It's the same as the public config
 * interface above that is stored in the redux store except it has a few extra properties we remove before putting
 * the config in to redux.
 */
interface PagePublicConfig extends PublicConfig {
  /**
   * The callback function that is called by the loadWatsonAssistantChat script once it is loaded. This is optional;
   * to use web chat without this requires using the "window.loadWatsonAssistantChat" function instead.
   */
  onLoad?: (instance: WatsonAssistantChatInstance) => void;

  /**
   * This is a one-off listener for errors. This value may be provided in the initial page config as a hook for
   * customers to listen for errors. We use this instead of a normal event bus handler because this function can be
   * defined and called before the event bus has been created which allows it to be used in loadWatsonAssistantChat.
   */
  onError?: (data: OnErrorData) => void;

  /**
   * An optional element the page can use as a custom in to which to render the widget.
   */
  element?: Element;
}

interface IBMConfig {
  /**
   * The version of the chat widget to use. This value may specify "latest" or it may specify a partial version such
   * as "1.5" (which would cover anything that is "1.5.x". If no matching version is found, an error will be logged
   * and the latest version will be used.
   *
   * @see PublicConfig.clientVersion for more information.
   * @deprecated
   */
  versionToUse?: string;

  /**
   * Indicates if the {@link PublicConfig#clientVersion} value should be treated exactly as specified and without any
   * validation.
   */
  useExactVersion?: boolean;

  /**
   * Home screen configuration.
   */
  homeScreenConfig?: HomeScreenConfig;

  /**
   * This flag is used to disable web chat's rounded corners. Web chat exists in places within tooling as a preview
   * which depend on web chat having sharp corners, so this flag exists to let tooling devs temporarily resolve visual
   * issues.
   */
  disableRoundCorners?: boolean;

  /**
   * Used to override the IS_MOBILE flag in order to render the extended launcher in desktop.
   */
  renderExtendedLauncher?: boolean;
}

/**
 * The configuration that defines how web chat responds to link page requests (where there's a link reference in the
 * URL).
 */
interface PageLinkConfig {
  /**
   * The configuration for all the link IDs. The keys are the link IDs and the values are the configurations
   * specific to each link ID.
   */
  linkIDs: Record<string, PageLinkIDConfig>;
}

/**
 * The link configuration for a specific link ID.
 */
interface PageLinkIDConfig {
  /**
   * The text of the utterance that will be sent to the assistant when the page is loaded and the given link ID is
   * found.
   */
  text: string;
}

export { PublicConfig, PagePublicConfig, IBMConfig, LauncherType, PageLinkIDConfig };
