/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { ChevronLeft32, ChevronRight32 } from '@carbon/icons-react';
import cx from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import { DemoSiteParamsAndHash, LendyrPanel, LendyrSection, RestartType } from '../../../framework/demoTypes';
import { demoActions } from '../../../framework/store/demoActions';
import { DemoButton } from '../../DemoButton';
import { SectionPanelConfig } from '../featureGuidancePanelUtils';

interface GuidancePanelTourNavigationProps extends DemoSiteParamsAndHash {
  /**
   * The position of the current feature panel in the feature panel map.
   */
  index: number;

  /**
   * Indicates if the intro panel is being viewed. The panels counter won't be visible when the intro panel is viewed.
   */
  isIntroPanel: boolean;

  /**
   * Indicates if the summary panel is being viewed. The panels counter won't be visible when the summary panel is
   * viewed.
   */
  isSummaryPanel: boolean;

  /**
   * The number of panels to display in the counter.
   */
  totalPanels: number;

  /**
   * The previous feature panel config to load upon clicking the "Previous" button.
   */
  previousSectionPanelConfig: SectionPanelConfig;

  /**
   * The next feature panel config to load upon clicking the "Begin tour" or "Next" button.
   */
  nextSectionPanelConfig: SectionPanelConfig;

  /**
   * The page to navigate the user to upon clicking either the "Previous" or "Next" button if a value page value is not
   * provided by their respective feature panel configs.
   */
  fallbackPageValue: string;

  /**
   * This will prevent navigating to the previous panel upon being clicking the "previous" button.
   */
  preventNavigationToPrevious: boolean;

  /**
   * This will prevent navigating to the next panel upon being clicking the "next" button.
   */
  preventNavigationToNext: boolean;

  /**
   * The callback function to fire when the "previous" button is clicked.
   */
  onPreviousButtonClick: () => void;

  /**
   * The callback function to fire when the "next" button is clicked.
   */
  onNextButtonClick: () => void;
}

/**
 * This component provides navigation functionality for a demo tour in the demo site.
 */
function GuidancePanelTourNavigation({
  section,
  index,
  isIntroPanel,
  isSummaryPanel,
  totalPanels,
  previousSectionPanelConfig,
  nextSectionPanelConfig,
  fallbackPageValue,
  preventNavigationToNext,
  preventNavigationToPrevious,
  onPreviousButtonClick,
  onNextButtonClick,
}: GuidancePanelTourNavigationProps) {
  const dispatch = useDispatch();

  const previousButtonPanel = previousSectionPanelConfig?.panel;
  const previousButtonPage = previousSectionPanelConfig?.page || fallbackPageValue;

  const nextButtonPanel = nextSectionPanelConfig?.panel || LendyrPanel.HOME;
  const nextButtonPage = nextSectionPanelConfig?.page || fallbackPageValue;
  // The panel name value for the "next" button. By default, it's the tour panel, but if we're at the end of the tour,
  // it should take the user back to the home panel.
  const nextButtonSection = nextButtonPanel ? section : LendyrSection.HOME;

  return (
    <div className="GuidancePanelTourNavigation GuidancePanel__BottomContent">
      {isIntroPanel && (
        <DemoButton renderIcon={ChevronRight32} section={section} panel={nextButtonPanel} page={nextButtonPage}>
          Begin tour
        </DemoButton>
      )}
      {!isIntroPanel && (
        <>
          <div className="GuidancePanelTourNavigation__PreviousButtonAndCounterWrapper">
            <div className={cx('GuidancePanelTourNavigation__PanelCounter', { NotVisible: isSummaryPanel })}>
              {index}/{totalPanels}
            </div>
            {previousSectionPanelConfig && (
              <DemoButton
                kind="ghost"
                hasIconOnly
                iconDescription="Previous"
                renderIcon={ChevronLeft32}
                section={section}
                panel={previousButtonPanel}
                page={previousButtonPage}
                preventNavigation={preventNavigationToPrevious}
                onClick={() => onPreviousButtonClick()}
              />
            )}
          </div>
          <div className="GuidancePanelTourNavigation__NextButtonWrapper">
            <DemoButton
              renderIcon={ChevronRight32}
              section={nextButtonSection}
              panel={nextButtonPanel}
              page={nextButtonPage}
              preventNavigation={preventNavigationToNext}
              onClick={() => {
                onNextButtonClick();
                // If there is no next feature, this means the user is at the very end of the tour and web chat should
                // reload.
                if (isSummaryPanel) {
                  dispatch(demoActions.doRestartType(RestartType.RELOAD_WEB_CHAT));
                }
              }}
            >
              {isSummaryPanel ? 'Finish tour' : 'Next'}
            </DemoButton>
          </div>
        </>
      )}
    </div>
  );
}

export { GuidancePanelTourNavigation };
