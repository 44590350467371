/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './HomePage.css';

import React from 'react';

import lendyrBackgroundPatternMortgageCalculator from '../assets/lendyr-background-pattern-mortgage-calculator.svg';
import { DemoPage } from '../components/DemoPage';
import { LendyrButton } from '../components/LendyrButton';
import { LendyrPage } from '../framework/demoTypes';
import { DEFAULT_CARD_LINKS } from '../framework/utils/demoConstants';
import { HomeMainContent } from './HomeMainContent';

function HomePage() {
  return (
    <DemoPage
      title="Workplace Financial Planning "
      description="Empower the workforce to reach for their financial goals and navigate life events through personalized one-on-one guidance, digital tools and workplace solutions."
      cardLinks={DEFAULT_CARD_LINKS}
      MainContent={<HomeMainContent />}
    >
      <div
        className="DemoPage__HomeBottomContent"
        style={{ backgroundImage: `url(${lendyrBackgroundPatternMortgageCalculator})` }}
      >
        <div className="DemoPage__CenterContent">
          <div className="DemoPage__BottomMainContent">
            <div className="DemoPageCommon__Title">
              Worried about finances?
              <br />
              Try our mortgage calculator.
            </div>
            <div className="DemoPageCommon__Description">
              Our robust capital, low rates, and loan options allow you to confidently make offers to maximize your
              returns.
            </div>
            <LendyrButton
              className="DemoPage__CalculateMortgageButton"
              page={LendyrPage.HOME_LOANS}
              hash="mortgage-calculator-section"
            >
              Calculate mortgage
            </LendyrButton>
          </div>
        </div>
      </div>
    </DemoPage>
  );
}

export { HomePage };
