/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { AskClarifyingQuestionsPanelImplementationSection } from '../EssentialsAndIndexSection/AskClarifyingQuestionsPanel';

function AccessibleAskClarifyingQuestionsPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Ask Clarifying Questions"
        description="Request additional input and clarification to help your users find a path to their solution. When the assistant isn't confident enough that a particular action is the best choice, it asks the user a clarifying question to determine the best action to take."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          To showcase how intelligent the assistant is when it comes to finding the best answer, let's assume you're
          looking for financial aid for college.
          <br />
          <br />
          Start by entering the following: <GuidancePanelTextSnippet text="Loan application" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          The assistant isn't sure what path to take and so offers you some options. In this scenario, none of the
          options apply, but don't worry, we haven't hit a dead end.
          <br />
          <br />
          Enter the following: <GuidancePanelTextSnippet text="I'm thinking about applying for a student loan" />
          The assistant will now respond with confidence. Before clicking Yes, let's take a look at what happens if you
          want to ask a question before starting your application.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <AskClarifyingQuestionsPanelImplementationSection />
    </div>
  );
}

const AccessibleAskClarifyingQuestionsPanelConfig: SectionPanelConfig = {
  title: 'Ask Clarifying Questions',
  SectionPanelComponent: AccessibleAskClarifyingQuestionsPanel,
  panel: LendyrPanel.ACCESSIBLE_ASK_CLARIFYING_QUESTIONS,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.ACCESSIBLE_ASK_CLARIFYING_QUESTIONS,
};

export { AccessibleAskClarifyingQuestionsPanel, AccessibleAskClarifyingQuestionsPanelConfig };
