/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const TICKETING_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Build a custom extension',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-build-custom-extension',
    type: 'Docs',
  },
  {
    title: 'Use a custom extension',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-call-extension',
    type: 'Docs',
  },
  {
    title: 'ServiceNow ticketing',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/extensions/starter-kits/servicenow',
    type: 'Tutorial',
  },
  {
    title: 'Zendesk ticketing',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/extensions/starter-kits/zendesk-support',
    type: 'Tutorial',
  },
  {
    title: 'HubSpot ticketing',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/extensions/starter-kits/hubspot',
    type: 'Tutorial',
  },
];

function TicketingPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Filing a Ticket"
        description={
          <>
            Use Extensions to enable advanced functionality – like retrieving real-time information from a database,
            referencing a CRM, submitting a ticket, and many other tasks that utilize tools offering a REST API –
            without getting lost in technical notation.
            <br />
            <br />
            In this case, we use an extension to Zendesk to enable someone to modify an existing credit card
            application, which gets logged as a ticket within the Zendesk console.
          </>
        }
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following:{' '}
          <GuidancePanelTextSnippet text="I'd like to modify my credit card application but I already mailed it." />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Select "Yes" to proceed with filing a ticket.</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Select "Yes" to keep the original description of the issue.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Select "Yes" to use your accounts contact info.</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Watch as the Assistant files your ticket and gives you your ticket ID number.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          To check your ticket, enter: <GuidancePanelTextSnippet text="What is the status of my ticket?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Provide your ticket ID number.</GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile codeNecessary data={TICKETING_CODE_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const TicketingPanelConfig: SectionPanelConfig = {
  title: 'Filing a Ticket',
  SectionPanelComponent: TicketingPanel,
  panel: 'ticketing',
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.TICKETING,
};

export { TicketingPanel, TicketingPanelConfig };
