/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { MouseEventHandler, useCallback } from 'react';

import { demoHistory } from '../demoHistory';
import { DemoSiteParamsAndHash, LendyrPage } from '../demoTypes';
import { URL_PARAM_PAGE } from '../utils/demoConstants';
import { createURLSearchString, scrollToElement, scrollToTop } from '../utils/demoUtils';

/**
 * This on click hook handles generating the necessary search string url in order to navigate the user around the site.
 */
function useNavigateOnClick(callback: MouseEventHandler, paramsAndHash: DemoSiteParamsAndHash): MouseEventHandler {
  return useCallback(
    event => {
      const { page, hash } = paramsAndHash;
      const { search: previousSearch, hash: previousHash } = window.location;
      const urlSearchString = createURLSearchString(paramsAndHash);
      const isNewLocation = `${previousSearch}${previousHash}` !== urlSearchString;

      if (isNewLocation) {
        // The browser preserves the user's scroll position on the page. On a page change we want to start the user at the
        // top of the page.
        if (isVisitingNewPage(page)) {
          scrollToTop();
        }

        demoHistory.push({ search: urlSearchString });

        callback?.(event);

        // Since we programmatically scroll the user to the top of the page, if there's a hash in the base
        // url, we should scroll to the hash anchor element.
        scrollToElement(`#${hash}`, 400);
      } else {
        // If no params or hash are provided, just fire the callback.
        callback?.(event);
      }
    },
    [callback, paramsAndHash],
  );
}

function isVisitingNewPage(page: string) {
  // If provided page value is not a string, we can assume a page change is not occurring.
  if (typeof page !== 'string') {
    return false;
  }

  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const currentPage = searchParams.get(URL_PARAM_PAGE) || LendyrPage.HOME;

  return currentPage !== page;
}

export { useNavigateOnClick };
