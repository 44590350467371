/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelIntroInstructions } from '../../commonComponents/GuidancePanelIntroInstructions';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { DriveCustomerOutcomesOverlay } from './DriveCustomerOutcomesOverlay';
import { DriveCustomerOutcomeDefaultSVGComponent } from './svgComponents/DriveCustomerOutcomeDefaultSVGComponent';
import { DriveCustomerOutcomesIntroTooltipSVGComponent } from './svgComponents/DriveCustomerOutcomesIntroTooltipSVGComponent';

function DriveCustomerOutcomesIntroPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Drive Customer Outcomes"
        description={
          <>
            Orient your support teams, culture, and innovation around true customer outcomes.
            <br />
            <br />
            Unify your analytics tools across the entire customer support journey. Empower your teams to experiment,
            analyze, and optimize your end-to-end customer experience.
          </>
        }
      />
      <GuidancePanelIntroInstructions />
      <DriveCustomerOutcomesOverlay>
        <DriveCustomerOutcomeDefaultSVGComponent />
        <DriveCustomerOutcomesIntroTooltipSVGComponent />
      </DriveCustomerOutcomesOverlay>
    </div>
  );
}

const DriveCustomerOutcomesIntroPanelConfig: SectionPanelConfig = {
  title: 'Drive Custom Outcomes Intro',
  SectionPanelComponent: DriveCustomerOutcomesIntroPanel,
  panel: LendyrPanel.HOME,
  page: LendyrPage.DRIVE_CUSTOMER_OUTCOMES_TOUR,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.DRIVE_CUSTOMER_OUTCOMES_INTRO,
};

export { DriveCustomerOutcomesIntroPanel, DriveCustomerOutcomesIntroPanelConfig };
