/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './HomeSectionOverlay.css';

import React from 'react';

import { DemoPageOverlayPortal } from '../../../framework/DemoPageOverlayPortal';
import { HomeSectionGuidedTours } from './HomeSectionGuidedTours';

/**
 * This component is the overlay rendered when the user is in the home panel and not viewing a section.
 */
function HomeSectionOverlay() {
  return (
    <DemoPageOverlayPortal preventPageScroll>
      <div className="HomeSectionOverlay carbon-dark-theme">
        <div className="HomeSectionOverlay__Content">
          <HomeSectionGuidedTours />
        </div>
      </div>
    </DemoPageOverlayPortal>
  );
}

export { HomeSectionOverlay };
