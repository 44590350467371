/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './MediaPlayerOverlay.css';

import React from 'react-player';

import { DemoPageOverlayPortal } from '../framework/DemoPageOverlayPortal';
import {
  IBMMediaCenterIFrame,
  IBMVideoPlayerProps,
} from './demoGuidanceComponent/commonComponents/IBMMediaCenterIFrame';
import { MediaPlayer } from './MediaPlayer';

function MediaPlayerOverlay({
  source,
  ibmVideoPlayerProps,
}: {
  source?: string;
  ibmVideoPlayerProps?: IBMVideoPlayerProps;
}) {
  return (
    <DemoPageOverlayPortal>
      <div className="MediaPlayerOverlay">
        {!ibmVideoPlayerProps && <MediaPlayer source={source} />}
        {ibmVideoPlayerProps && <IBMMediaCenterIFrame {...ibmVideoPlayerProps} />}
      </div>
    </DemoPageOverlayPortal>
  );
}

export { MediaPlayerOverlay };
