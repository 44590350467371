/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React, { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { GenericItem } from '../submodules/wa-fd-types-public/wa-types';
import { BusEventCustomResponse, BusEventType } from '../web-chat-dependencies/types/eventBusTypes';
import { WatsonAssistantChatInstance } from '../web-chat-dependencies/types/WatsonAssistantChatInstance';
import { BYOSCard } from './bringYourOwnSearch/BYOSCard';
import { BYOSCarousel } from './bringYourOwnSearch/BYOSCarousel';
import { ContentTable } from './bringYourOwnSearch/ContentTable';
import { Carousel } from './contentCarousel/Carousel';

interface CustomResponseComponentPortalProps {
  /**
   * The custom response host element to attach custom content to.
   */
  hostElement: HTMLElement;

  children: ReactNode;
}

interface CustomResponsePortalsContainer {
  instance: WatsonAssistantChatInstance;
}

/**
 * This portal container will handle rendering custom response types for the demo site.
 */
function CustomResponsePortalsContainer({ instance }: CustomResponsePortalsContainer) {
  // This state will be used to record all the custom response events that are fired from the widget.
  // These events contain the HTML elements that we will attach our portals to as well as the messages that we wish to
  // render in the message.
  const [customResponseEvents, setCustomResponseEvents] = useState<BusEventCustomResponse[]>([]);

  // When the component is mounted, register the custom response handler that will store the references to the custom
  // response events.
  useEffect(() => {
    // This handler will fire each time a custom response occurs, and we will update our state by appending the event
    // to the end of our elements list.
    function customResponseHandler(event: BusEventCustomResponse) {
      // Use functional updates since the state is computed, and we can get the previous value of the events array.
      // Passing in a reference to customResponseEvents and concatenating to it will not work since this function will
      // capture the initial value of customResponseEvents, which is empty, and not updates made to it.
      setCustomResponseEvents(eventsArray => eventsArray.concat(event));
    }

    instance.on({ type: BusEventType.CUSTOM_RESPONSE, handler: customResponseHandler });

    // Remove the custom response handler.
    return () => {
      instance.off({ type: BusEventType.CUSTOM_RESPONSE, handler: customResponseHandler });
    };
  }, [instance]);

  // All we need to do to enable the React portals is to render each portal somewhere in your application (it
  // doesn't really matter where).
  return (
    <>
      {customResponseEvents.map(function mapEvent(event, index) {
        const { message } = event.data;
        const CustomResponseComponent = getCustomResponseComponent(message);

        return (
          // eslint-disable-next-line
          <CustomResponseComponentPortal key={index} hostElement={event.data.element}>
            {CustomResponseComponent}
          </CustomResponseComponentPortal>
        );
      })}
    </>
  );
}

/**
 * Returns the custom response component to render based on the user_defined_type provided in the user defined prop.
 */
function getCustomResponseComponent(message: GenericItem) {
  switch (message.user_defined?.user_defined_type) {
    case 'content-carousel':
      return <Carousel />;
    case 'BYOS_table_card':
      return <ContentTable tableDataHTML={message?.user_defined?.table_results[0]?.table_html} />;
    case 'BYOS_carousel':
      return <BYOSCarousel carousalData={message?.user_defined} />;
    case 'BYOS_search_card':
      return <BYOSCard searchResult={message?.user_defined?.search_result} />;
    default:
      return null;
  }
}

/**
 * This is the component that will attach a React portal to the given host element. The host element is the element
 * provided by the chat widget where your custom response will be displayed in the DOM. This portal will attach
 * any React children passed to it under this component, so you can render the response using your own React
 * application. Those children will be rendered under the given element where it lives in the DOM.
 */
function CustomResponseComponentPortal({ hostElement, children }: CustomResponseComponentPortalProps) {
  return ReactDOM.createPortal(children, hostElement);
}

export default CustomResponsePortalsContainer;
