/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelHeader.css';

import { PageFirst20 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import React from 'react';
import { useDispatch } from 'react-redux';

import { demoActions } from '../../../framework/store/demoActions';
import { HasChildren } from '../../../web-chat-dependencies/types/HasChildren';
import { GuidancePanelRestartButton } from './GuidancePanelRestartButton';

interface GuidancePanelHeaderProps extends HasChildren {
  /**
   * Determines if the restart button should be visible in the tour.
   */
  showRestartButton?: boolean;
}

/**
 * This is a header component for guidance panels. This header provides a restart button and button to close the
 * guidance panel.
 */
function GuidancePanelHeader({ children, showRestartButton }: GuidancePanelHeaderProps) {
  const dispatch = useDispatch();
  return (
    <div className="GuidancePanel__TopContent GuidancePanelHeader">
      {children && <div className="GuidancePanelHeader__LeftContent">{children}</div>}
      <div className="GuidancePanelHeader__CenterContent">{showRestartButton && <GuidancePanelRestartButton />}</div>
      <Button
        kind="ghost"
        iconDescription="Close panel"
        tooltipPosition="right"
        hasIconOnly
        onClick={() => {
          dispatch(demoActions.updateSessionStorageState({ isGuidancePanelExpanded: false }));
        }}
        renderIcon={PageFirst20}
      />
    </div>
  );
}

export { GuidancePanelHeader };
