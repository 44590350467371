/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './TransactionsPage.css';

import { Money32, Password32, Purchase32 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import ProgressBar from 'carbon-components-react/lib/components/ProgressBar';
import cx from 'classnames';
import React, { useState } from 'react';

import { DemoPage } from '../components/DemoPage';
import { LendyrPageType } from '../framework/demoTypes';
import { useSegment } from '../framework/hooks/useSegment';
import { useWebChatInstance } from '../framework/hooks/useWebChatInstance';
import { CardLinkComponentProps } from '../framework/types/CardLinkComponentProps';
import { TOUR_STEP_ID_DISPUTE_CHARGE_4 } from '../framework/utils/demoConstants';
import { getDateStringFromDate } from '../framework/utils/miscUtils';

const DATE_TODAY = new Date();
const DATE_TODAY_STRING = getDateStringFromDate(DATE_TODAY);

const CARD_LINKS: CardLinkComponentProps[] = [
  { Icon: Money32, title: 'Transfer' },
  { Icon: Money32, title: 'My Rewards' },
  { Icon: Password32, title: 'Investments' },
  { Icon: Purchase32, title: 'Billing & Statements' },
];

const PENDING_CHARGES: ChargeProps[] = [
  { name: 'Cafe Tropical', date: DATE_TODAY_STRING, cost: '-$32.67' },
  { name: 'Rose Apothecary', date: DATE_TODAY_STRING, cost: '-$134.98' },
  { name: 'Sirius Cybernetics Corp', date: DATE_TODAY_STRING, cost: '-$36.97' },
  { name: 'MomCorp', date: DATE_TODAY_STRING, cost: '-$83.07' },
];
const RECOMMENTED_OPTIONS: ChargeProps[] = [
  { name: 'Goldman Sachs Physical Gold ETF', date: DATE_TODAY_STRING, cost: '+0.19%' },
  { name: 'Goldman Sachs Future Consumer Equity ETF', date: DATE_TODAY_STRING, cost: '+9.88%' },
];
const CURRENT_PORTFOLIO: ChargeProps[] = [
  {
    name: 'Goldman Sachs ActiveBeta US Small Cap Equity ETF',
    date: DATE_TODAY_STRING,
    cost: '+1.58% / $832.67',
  },
  { name: 'Goldman Sachs MarketBeta Emerging Markets Equity ETF', date: DATE_TODAY_STRING, cost: '+3.37% / $5032.67' },
  { name: 'Goldman Sachs Equal Weight U.S. Large Cap Equity ETF', date: DATE_TODAY_STRING, cost: '+2.42% / $447.07' },
];

const RECENT_CHARGES: ChargeProps[] = [
  { name: 'Globex', date: getDateStringFromDate(DATE_TODAY, -1), cost: '-$14.67' },
  { name: 'Umbrella Corp.', date: getDateStringFromDate(DATE_TODAY, -1), cost: '-$32.88' },
  { name: 'Stark Industries', date: getDateStringFromDate(DATE_TODAY, -2), cost: '-$66.67' },
  { name: 'Oceanic Airlines', date: getDateStringFromDate(DATE_TODAY, -3), cost: '-$832.67' },
  { name: 'Nakatomi Trading Corp.', date: getDateStringFromDate(DATE_TODAY, -3), cost: '-$44.44' },
  { name: 'Cafe Tropical', date: getDateStringFromDate(DATE_TODAY, -3), cost: '-$32.67' },
  { name: 'MomCorp', date: getDateStringFromDate(DATE_TODAY, -4), cost: '-$18.88' },
];

interface ChargeProps {
  /**
   * The name of the business where the charge occurred.
   */
  name: string;

  /**
   * The date when the charge occurred.
   */
  date: string;

  /**
   * The cost of the charge.
   */
  cost: string;
}

/**
 * Render a transactions page that is used for the walkMe feature demo.
 */
function TransactionsPage() {
  return (
    <DemoPage
      title="Investments"
      description="Profile & History."
      type={LendyrPageType.TERTIARY}
      cardLinks={CARD_LINKS}
      MainContent={
        <div className="TransactionsPage__Content">
          <div className="TransactionsPage__CardBalanceSection">
            <div className="TransactionsPage__CardName">My Ayco Account - 7619</div>
            <div>
              <span className="TransactionsPage__CardBalanceValue">$6,312.41</span>
              <span className="TransactionsPage__CardBalanceText">Available Cash Balance</span>
              <ProgressBar
                className="TransactionsPage__CardBalanceBar"
                label="current balance"
                max={20000}
                value={6312}
                hideLabel
              />
            </div>
            <div className="TransactionsPage__CardLimit">Total Account Balance: $20,000.00</div>
          </div>
          <div className="TransactionsPage__TransactionsSection">
            <div className="TransactionsPage__TransactionsSectionTitle">Recommended investment options</div>
            <div className="TransactionsPage__TransactionsSectionCharges">
              {RECOMMENTED_OPTIONS.map((charge, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ChargeComponent {...charge} key={index} />
              ))}
            </div>
          </div>
          <div className="TransactionsPage__TransactionsSection TransactionsPage__TransactionsSectionRecent">
            <div className="TransactionsPage__TransactionsSectionTitle">Current Portfolio</div>
            <div className="TransactionsPage__TransactionsSectionCharges">
              {CURRENT_PORTFOLIO.map((charge, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ChargeComponent {...charge} key={index} />
              ))}
            </div>
          </div>
        </div>
      }
    />
  );
}

/**
 * This component takes in charge details and renders a charge entry within the transactions page. It was necessary to
 * separate this logic because each charge needs its own state for whether the charge has been disputed.
 */
function ChargeComponent(charge: ChargeProps) {
  const [disputed, setDisputed] = useState(false);
  const instance = useWebChatInstance();
  const segment = useSegment();

  return (
    <div className="TransactionsPage__ChargeWrapper">
      <div className="TransactionsPage__Charge--left">
        <div className="TransactionsPage__ChargeName">{charge.name}</div>
        <div className="TransactionsPage__ChargeDate">{charge.date}</div>
      </div>
      <div className="TransactionsPage__Charge--right">
        <div className="TransactionsPage__ChargeCost">{charge.cost}</div>
        <Button
          className={cx('TransactionsPage__ChargeDispute', {
            TransactionsPage__ChargeDisputed: disputed,
          })}
          kind="danger--ghost"
          size="sm"
          disabled={disputed}
          onClick={() => {
            setDisputed(true);
            segment?.track('dispute button clicked');

            // If the user is in a tour, clicking the dispute button should take them to the fourth step since the third
            // step instructs them click any one, which they have completed.
            if (instance.getState().isTourActive) {
              instance.tours.goToStep(TOUR_STEP_ID_DISPUTE_CHARGE_4);
            }
          }}
        >
          {disputed ? 'Request filed' : 'Request'}
        </Button>
      </div>
    </div>
  );
}

export { TransactionsPage };
