/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './HomeMainContent.css';

import React from 'react';

import lendyrSideImageCreditCard from '../assets/lendyr-side-image-creditcard.jpg';
import { LendyrButton } from '../components/LendyrButton';
import { LendyrPage } from '../framework/demoTypes';

// This component renders the home page content. This file is temporary to use as content to place in other pages
// until those pages actually have content.

function HomeMainContent() {
  return (
    <div className="DemoPage__Body">
      <div className="DemoPage__SectionTextWrapper">
        <div className="DemoPage__SectionWrapper">
          <div className="DemoPageCommon__Title">Employee Programs</div>
          <div className="DemoPageCommon__Description">
            Help employees take control of their personal finances and drive awareness and engagement with workplace
            benefits.
          </div>
          <LendyrButton tertiary page={LendyrPage.STUDENT_LOANS}>
            View details
          </LendyrButton>
        </div>
        <div className="DemoPage__SectionWrapper">
          <div className="DemoPageCommon__Title">Individual Services</div>
          <div className="DemoPageCommon__Description">
            Dedicated advisory services to help leaders achieve long-term goals and optimize compensation and benefits.
          </div>
          <LendyrButton tertiary page={LendyrPage.PERSONAL_LOANS}>
            View details
          </LendyrButton>
        </div>
        <div className="DemoPage__SectionWrapper">
          <div className="DemoPageCommon__Title">Manage Wealth</div>
          <div className="DemoPageCommon__Description">
            Highly personalized services that help executives maximize financial opportunities and mitigate risk,
            freeing up their time to focus on your business.
          </div>
          <LendyrButton tertiary page={LendyrPage.CREDIT_CARDS}>
            View details
          </LendyrButton>
        </div>
      </div>
      <img className="DemoPage__CreditCardImage" src={lendyrSideImageCreditCard} alt="credit card" />
    </div>
  );
}

export { HomeMainContent };
