/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const VIDEO_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Add a video response',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-respond#respond-add-video',
    type: 'Docs',
  },
];

function VideoPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Video"
        description="Make your conversations more engaging and dynamic with rich media like videos. Videos can be embedded from a list of supported hosting services like YouTube and Vimeo."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following: <GuidancePanelTextSnippet text="What is Lendyr?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>View the embedded video.</GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={VIDEO_CODE_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const VideoPanelConfig: SectionPanelConfig = {
  title: 'Video',
  SectionPanelComponent: VideoPanel,
  panel: 'video',
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.VIDEO,
};

export { VideoPanel, VideoPanelConfig, VIDEO_CODE_LINKS };
