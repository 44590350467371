/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { BusEventType, TypeAndHandler } from '../../../web-chat-dependencies/types/eventBusTypes';
import { demoActions } from '../../store/demoActions';
import { CUSTOM_LAUNCHER_WEB_CHAT_CONFIG_OPTIONS } from '../../utils/demoWebChatConfigOptions';
import { DemoPanelTransitionConfig } from '../demoTransitionTypes';

const CUSTOM_LAUNCHER_TRANSITION_CONFIG: DemoPanelTransitionConfig = {
  doTransitionWithWebChat: (store, instance) => {
    const windowEvents: TypeAndHandler[] = [
      {
        type: BusEventType.WINDOW_CLOSE,
        handler: () => store.dispatch(demoActions.setStateProperty('showCustomLauncher', true)),
      },
      {
        type: BusEventType.WINDOW_OPEN,
        handler: () => {
          store.dispatch(demoActions.setStateProperty('showCustomLauncher', false));
        },
      },
    ];

    instance.on(windowEvents);

    return () => {
      instance.off(windowEvents);
    };
  },

  doAfterWebChatRender: (store, instance) => {
    if (!instance.getState().isWebChatOpen) {
      store.dispatch(demoActions.setStateProperty('showCustomLauncher', true));
    }
  },

  getWebChatConfigOptions: () => CUSTOM_LAUNCHER_WEB_CHAT_CONFIG_OPTIONS,

  restartOptions: {
    reloadWebChat: true,
  },
};

export { CUSTOM_LAUNCHER_TRANSITION_CONFIG };
