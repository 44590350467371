/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2020, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */
import { DeepPartial } from 'ts-essentials';

import { EventInputData, MessageResponse } from './wa-types';

export const EVENT_NAME_ALTERNATE_SUGGESTIONS_OPENED = 'alternate_suggestions_opened';
export const EVENT_NAME_ALTERNATE_SUGGESTIONS_CLOSED = 'alternate_suggestions_closed';
export const EVENT_NAME_MEANINGFUL_CONVERSATION = 'meaningful_conversation';
export const EVENT_NAME_UPDATE_HISTORY = 'update_history';
export const EVENT_NAME_CHANNEL_TRANSFER = 'channel_transfer';

/**
 * An event that is fired when the suggestions panel is opened. This event is used by the auto-learning system.
 */
export interface AlternateSuggestionsOpenedEvent
  extends EventInputData<typeof EVENT_NAME_ALTERNATE_SUGGESTIONS_OPENED> {
  /**
   * Indicates if the panel was opened automatically by the system (such as from the user hitting a strike) or if it was
   * opened manually by the user.
   */
  automatic: boolean;
}

/**
 * An event that is fired when the suggestions panel is closed. This event is used by the auto-learning system.
 */
export type AlternateSuggestionsClosedEvent = EventInputData<typeof EVENT_NAME_ALTERNATE_SUGGESTIONS_CLOSED>;

/**
 * An event that is fired when the system has determined that a meaningful conversation has occurred.
 */
export interface MeaningfulConversationEvent extends EventInputData<typeof EVENT_NAME_MEANINGFUL_CONVERSATION> {
  /**
   * The item that triggered this event.
   */
  triggered_by: MeaningfulConversationType;
}

/**
 * The different types of triggers for a meaningful conversation.
 */
export enum MeaningfulConversationType {
  /**
   * Indicates the user clicked on the "request agent" button from a connect-to-agent card.
   */
  CONNECT_TO_AGENT = 'connect_to_agent',

  /**
   * Indicates the user clicked on some link in the web chat (which could include any random link from a custom
   * response or an html/markdown response).
   */
  LINK = 'link',

  /**
   * Indicates the user clicked on a search result.
   */
  SEARCH = 'search_result',
}

/**
 * An event that is fired when a messageResponse is updated. This is triggered either by the end user updating messages in the pre:receive or customResponse
 * events, or by us (internal) when we want to save state of a response type (ex. connect to agent).
 */
export interface UpdateHistoryEvent extends EventInputData<typeof EVENT_NAME_UPDATE_HISTORY> {
  /**
   * The messageID of the original message that we're saving the update in history for.
   */
  messageID: string;

  /**
   * The partial message response to be saved to the backend and used on session history reload to update the state of a
   * previous message.
   */
  partial_message?: DeepPartial<MessageResponse>;
}

/**
 * An event that informs the service of a channel transfer.
 * When a channel transfer occurs both the source and target channels will have their own IDs for the user.
 * This event is sent by the target channel after the transfer has occurred.
 * The target channel will use it to assert that the given user ID in the target channel represents the same user who is already associated with the session
 * that was started on the source channel. The assertion is made via the auth_code that was generated during the conversation that happened on the source channel.
 */
export interface ChannelTransferEvent extends EventInputData<typeof EVENT_NAME_CHANNEL_TRANSFER> {
  /**
   * Authentication code used to make sure the transition between one channel to another is secured. It is a time based code that expires after 30 seconds.
   */
  auth_code: string;
  /**
   * User ID of the target channel. This will be added to the session as an authorized user id.
   */
  target_user_id: string;
}
