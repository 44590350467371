/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { LendyrSection } from '../../../../framework/demoTypes';
import { GuidancePanelContainer } from '../../commonComponents/GuidancePanelContainer';
import { ESSENTIALS_SECTION_MAP, WEB_CHAT_ESSENTIALS_FLOW_PANEL_CONFIGS } from '../../featureGuidancePanelUtils';

/**
 * The feature flow panel will take the user through the
 */
function EssentialsSectionComponent() {
  return (
    <GuidancePanelContainer
      sectionPanelConfigsMap={ESSENTIALS_SECTION_MAP}
      sectionPanelConfigs={WEB_CHAT_ESSENTIALS_FLOW_PANEL_CONFIGS}
      section={LendyrSection.ESSENTIALS}
    />
  );
}

export { EssentialsSectionComponent };
