/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Tag } from 'carbon-components-react';
import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { DemoFeaturePanelsTrackingNames } from '../framework/demoTypes';
import { WALK_ME_FLOW_ID } from '../framework/utils/demoConstants';
import { trackDemoFeatureFinished } from '../framework/utils/demoTracking';
import { THREAD_ID_MAIN } from '../submodules/wa-fd-types-public/wa-extensions-types';
import { BusEventCustomResponse, BusEventType } from '../web-chat-dependencies/types/eventBusTypes';
import { WatsonAssistantChatInstance } from '../web-chat-dependencies/types/WatsonAssistantChatInstance';

interface WalkMeFlowContainerProps {
  instance: WatsonAssistantChatInstance;
}

/**
 * This component is for the walkMe demo where the user is guided to the transactions page, so they can dispute a charge.
 */
function WalkMeFlowContainer({ instance }: WalkMeFlowContainerProps): null {
  // Start the walkMe flow and minimize the web chat.
  const startWalkMeFlow = useCallback((instance: WatsonAssistantChatInstance) => {
    // https://developer.walkme.com/reference/smart-walkthrus
    (window as any).WalkMeAPI?.startFlowById(WALK_ME_FLOW_ID);

    // Once the "show me" button has been clicked and the walkMe flow has started we consider the DAP feature finished,
    // so an event needs to be sent.
    // Check if the panel being viewed is the second "show" panel since it demos the same thing.
    trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.DIGITAL_ADOPTION_PLATFORM_INTEGRATION);

    instance.toggleOpen();
  }, []);

  useEffect(() => {
    // A customResponse handler for the walkMe demo that renders a custom button for the user to click that will start
    // the walkMe flow.
    const walkMeFlowCustomResponseHandler = (event: BusEventCustomResponse, instance: WatsonAssistantChatInstance) => {
      if (event.data.message.user_defined?.user_defined_type === 'walkMe-flow') {
        ReactDOM.render(
          <>
            <Tag
              onClick={() => {
                instance.send({
                  id: null as string,
                  thread_id: THREAD_ID_MAIN,
                  input: { text: 'Show me' },
                });
                startWalkMeFlow(instance);
              }}
            >
              Show me
            </Tag>
            <Tag
              onClick={() =>
                instance.send({
                  id: null as string,
                  thread_id: THREAD_ID_MAIN,
                  input: { text: 'Tell me' },
                })
              }
            >
              Tell me
            </Tag>
          </>,
          event.data.element,
        );
      }
    };

    instance.on({
      type: BusEventType.CUSTOM_RESPONSE,
      handler: walkMeFlowCustomResponseHandler,
    });

    return () => {
      instance.off({
        type: BusEventType.CUSTOM_RESPONSE,
        handler: walkMeFlowCustomResponseHandler,
      });
    };
  }, [instance, startWalkMeFlow]);

  // Nothing is rendered by this component so null is returned.
  return null;
}

export { WalkMeFlowContainer };
