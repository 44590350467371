/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Store } from 'redux';

import { MortgageCalculatorSteps } from '../pages/HomeLoansPage';
import { ACTIONS_SKILL } from '../submodules/wa-fd-types-public/wa-types';
import {
  BusEventPreReceive,
  BusEventPreSend,
  BusEventReceive,
  BusEventTourStep,
  BusEventType,
  BusEventWindowPreClose,
  MainWindowCloseReason,
} from '../web-chat-dependencies/types/eventBusTypes';
import { WatsonAssistantChatInstance } from '../web-chat-dependencies/types/WatsonAssistantChatInstance';
import {
  isSearchResultItem,
  isSuggestionItem,
  isTextItem,
  isUserDefinedItem,
  isVideoItem,
} from '../web-chat-dependencies/utils/messageUtils';
import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel, RestartType } from './demoTypes';
import { demoActions } from './store/demoActions';
import { DemoAppState } from './store/DemoAppState';
import { MORTGAGE_CALCULATOR_ELEMENT_ID, TOUR_STEP_ID_DISPUTE_CHARGE_2 } from './utils/demoConstants';
import { trackDemoFeatureFinished } from './utils/demoTracking';
import {
  getCurrentPanelName,
  isCurrentPage,
  navigateTo,
  scrollToMortgageCalculator,
  scrollToTop,
} from './utils/demoUtils';

/**
 * A pre:receive handler that will take the user to the mortgage calculator in the home loans page once they've
 * started the mortgage calculator action and reached the step asking them to provide their loan amount.
 */
function demoFormFillingActionHandler(event: BusEventPreReceive, store: Store<DemoAppState>) {
  // Do nothing with this listener if the user is in the home loans page.
  // In case the returned response is invalid, we should use an optional to prevent web chat from hanging.
  const { output, context } = event.data;
  const user_defined = output.generic?.[0]?.user_defined;
  const { skill_variables } = context.skills[ACTIONS_SKILL];
  const previousValues = store.getState().pageState.homeLoansPage.mortgageCalculatorFields;

  // If this message contains a user_defined that represents one of the steps to be handled by the mortgage
  // calculator then use the values provided in the skill variables to update it.
  switch (user_defined?.user_defined_type) {
    case MortgageCalculatorSteps.FIRST_STEP:
      store.dispatch(
        demoActions.setHomeLoansPageStateProperty('mortgageCalculatorFields', {
          ...previousValues,
          creditScore: skill_variables.credit_score,
        }),
      );
      store.dispatch(
        demoActions.setHomeLoansPageStateProperty('currentMortgageCalculatorStep', MortgageCalculatorSteps.FIRST_STEP),
      );
      scrollToMortgageCalculator();
      break;
    case MortgageCalculatorSteps.LOAN_TERM:
      store.dispatch(
        demoActions.setHomeLoansPageStateProperty('mortgageCalculatorFields', {
          ...previousValues,
          loanAmount: (skill_variables.mortgage_loan_amount as any).value,
        }),
      );
      store.dispatch(
        demoActions.setHomeLoansPageStateProperty('currentMortgageCalculatorStep', MortgageCalculatorSteps.LOAN_TERM),
      );
      // Redirect the user to the home loans page if they aren't already there.
      if (!isCurrentPage(LendyrPage.HOME_LOANS)) {
        navigateTo({ page: LendyrPage.HOME_LOANS, hash: MORTGAGE_CALCULATOR_ELEMENT_ID });
        scrollToTop();
      } else {
        scrollToMortgageCalculator();
      }
      break;
    case MortgageCalculatorSteps.LAST_STEP:
      store.dispatch(
        demoActions.setHomeLoansPageStateProperty('mortgageCalculatorFields', {
          ...previousValues,
          loanTerm: Number(skill_variables.mortgage_loan_term.replace(' years', '')),
        }),
      );

      // Once the last step for the mortgage calculator flow has been completed send an event for finishing the page
      // interaction feature.
      trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.FORM_FILLING);
      store.dispatch(demoActions.setHomeLoansPageStateProperty('currentMortgageCalculatorStep', null));
      scrollToMortgageCalculator();
      break;
    default:
      store.dispatch(demoActions.setHomeLoansPageStateProperty('currentMortgageCalculatorStep', null));
      break;
  }
}

/**
 * A pre:receive handler that tracks demo events in case certain response types are returned by the assistant.
 * Note: Some of these are incredibly fragile as they rely on values like alt_text that could easily be changed in
 * actions. All it takes is for someone to tweak this value and this tracking will stop. And the worst part about it is
 * nobody will probably ever notice. We really should be relying on user_defined values if we need the demo to take
 * specific action here.
 */
function demoTrackingPreReceiveHandler(event: BusEventPreReceive, instance: WatsonAssistantChatInstance) {
  const generic = event.data.output?.generic;
  generic?.forEach(messageItem => {
    // If the received item is for the lendyr video then send an event for finishing the video feature.
    if (isVideoItem(messageItem) && messageItem.alt_text === 'Lendyr advertisement') {
      trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.VIDEO);
    }

    // If the received item is for the RPA feature then send an event for finishing the RPA feature.
    if (isVideoItem(messageItem) && messageItem.alt_text === 'Watson Assistant and RPA Integration') {
      trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.RPA);
    }

    // If the received item is a custom response for the content carousel then send an event for finishing the custom
    // response extensions feature.
    if (isUserDefinedItem(messageItem) && messageItem.user_defined?.user_defined_type === 'content-carousel') {
      trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.CUSTOM_RESPONSE_EXTENSIONS);
    }

    // If the received item is a disambiguation response then send an event for finishing the clarifying questions feature.
    if (isSuggestionItem(messageItem) && messageItem.title === 'Did you mean:') {
      if (getCurrentPanelName() === LendyrPanel.ACCESSIBLE_ASK_CLARIFYING_QUESTIONS) {
        trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.ACCESSIBLE_ASK_CLARIFYING_QUESTIONS);
      } else {
        trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.ASK_CLARIFYING_QUESTIONS);
      }
    }

    // If the received item is a search item, then send an event for finishing the search feature.
    if (isSearchResultItem(messageItem)) {
      if (getCurrentPanelName() === LendyrPanel.ACCESSIBLE_SEARCH) {
        trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.ACCESSIBLE_SEARCH);
      } else {
        trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.SEARCH);
      }
    }

    // If the received item is a text item for finishing the ticketing example then send an event.
    if (
      isTextItem(messageItem) &&
      messageItem.text === 'Ticket is in progress. Someone has picked up your ticket and will get back to you shortly.'
    ) {
      trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.TICKETING);
    }

    // If the received item is a custom response for the content carousel then send an event for finishing the custom
    // response extensions feature.
    if (isTextItem(messageItem) && messageItem.user_defined?.user_defined_type === 'debit-card-appointment') {
      trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.DO);
    }

    // If the received item is a custom credit card application tour, set a tour:end listener and a timeout that will
    // silently send a message to the assistant to complete the credit card application for the user.
    if (
      isUserDefinedItem(messageItem) &&
      (messageItem.user_defined.user_defined as any)?.user_defined_type === 'credit_card_application_tour'
    ) {
      instance.once({
        type: BusEventType.TOUR_END,
        handler: async () => {
          setTimeout(() => {
            instance.send('CREDIT_CARD_APPLICATION_LOAN_AMOUNT', { silent: true });
            instance.openWindow();
          });
        },
      });
    }
  });
}

/**
 * This is a "receive" handler that will check for the RESTART_SESSION action in order to trigger a web chat restart.
 * This gives the user the ability to restart web chat without having to leave it.
 */
function restartConversationHandler(event: BusEventReceive, store: Store<DemoAppState>) {
  const generic = event.data.output?.generic;
  generic?.forEach(messageItem => {
    if (isUserDefinedItem(messageItem) && messageItem.user_defined.user_defined_type === 'restart_conversation') {
      // We need to kick off conversation restart in a setTimeout to allow the "receive" event to complete. Otherwise,
      // web chat will complain.
      setTimeout(() => store.dispatch(demoActions.doRestartType(RestartType.RESTART_CONVERSATION)));
    }
  });
}

/**
 * A pre:send handler that tracks demo events in case certain response types are returned by the assistant. This
 * handler tracks the Suggestions and Search demo features being finished.
 */
function demoTrackingPreSendHandler(event: BusEventPreSend) {
  const { input, history } = event.data;

  if (history?.label === 'Report lost card' && input.suggestion_id) {
    trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.SUGGESTIONS);
  } else if (history?.label === 'Learn about digital wallets' && input.suggestion_id) {
    trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.ACCESSIBLE_SUGGESTIONS);
  }
}

/**
 * An agent:pre:startChat listener to track when the user has connected to an agent. This means the user has
 * finished the live agent demo.
 */
function demoTrackingConnectedToAgentHandler(instance: WatsonAssistantChatInstance) {
  instance.once({
    type: BusEventType.AGENT_RECEIVE,
    handler: () => {
      const currentPanelName = getCurrentPanelName();

      if (currentPanelName === LendyrPanel.LIVE_AGENT) {
        trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.LIVE_AGENT);
      } else if (currentPanelName === LendyrPanel.UNIFY_PERSONALIZED_JOURNEYS) {
        trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.UNIFY_PERSONALIZED_JOURNEYS);
      }
    },
  });
}

/**
 * A window:pre:close listener to track when the user starts a journey tour.
 */
function demoTrackingJourneysTourHandler(event: BusEventWindowPreClose) {
  if (event.reason === MainWindowCloseReason.OPEN_TOUR) {
    const panelName = getCurrentPanelName();

    // The second show panel also demos the journey tour feature.
    if (panelName === LendyrPanel.SHOW_2) {
      trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.SHOW_2);
    } else {
      trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.JOURNEYS);
    }
  }
}

/**
 * A tour:step listener for the web chat tour to dispute a charge. If the user moves to the second step while not in
 * the transactions page, they will be automatically taken to the transactions page.
 */
function demoDisputeChargeTourStepHandler(event: BusEventTourStep, store: Store<DemoAppState>) {
  const { demoSiteParamsAndHash } = store.getState();

  if (event.step.step_id === TOUR_STEP_ID_DISPUTE_CHARGE_2 && demoSiteParamsAndHash.page !== LendyrPage.TRANSACTIONS) {
    navigateTo({ page: LendyrPage.TRANSACTIONS });
  }
}

export {
  demoFormFillingActionHandler,
  demoTrackingPreReceiveHandler,
  demoTrackingConnectedToAgentHandler,
  demoTrackingPreSendHandler,
  demoTrackingJourneysTourHandler,
  demoDisputeChargeTourStepHandler,
  restartConversationHandler,
};
