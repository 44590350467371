/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Link } from 'carbon-components-react';
import React, { Fragment } from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel, LendyrSection } from '../../../../framework/demoTypes';
import { DemoButton } from '../../../DemoButton';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { SectionPanelConfig, WEB_CHAT_FEATURE_INDEX_OBJECT } from '../../featureGuidancePanelUtils';

/**
 * Renders content that lists out all the available demo features.
 */
function FeatureListIntroPanel() {
  const featureGroupNames = Object.keys(WEB_CHAT_FEATURE_INDEX_OBJECT);

  return (
    <div>
      <GuidancePanelHeading
        title="Feature List"
        description={
          <>
            Listed below are some of the key features within Watson Assistant. They've been separated into categories
            based on what you're hoping to accomplish.
            <br />
            <br />
            Can't find what you're looking for? This isn't all Watson Assistant has to offer.{' '}
            <Link
              href="https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-web-chat-develop"
              target="_blank"
            >
              Browse more features
            </Link>
          </>
        }
      />
      {featureGroupNames.map((featureGroupName, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="FeatureIndexPanel__Group">
          <div className="FeatureIndexPanel__GroupTitle">{featureGroupName}</div>
          <div className="GuidancePanelDivider" />
          {WEB_CHAT_FEATURE_INDEX_OBJECT[featureGroupName].map(featureConfig => {
            return (
              <Fragment key={featureConfig.panel}>
                <DemoButton
                  page={featureConfig.page}
                  section={LendyrSection.INDEX}
                  panel={featureConfig.panel}
                  className="FeatureIndexPanel__FeatureButton"
                  kind="ghost"
                >
                  {featureConfig.title}
                </DemoButton>
              </Fragment>
            );
          })}
        </div>
      ))}
    </div>
  );
}

const FeatureListIntroPanelConfig: SectionPanelConfig = {
  title: 'Drive Custom Outcomes Intro',
  SectionPanelComponent: FeatureListIntroPanel,
  panel: LendyrPanel.HOME,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.FEATURE_INDEX_INTRO,
};

export { FeatureListIntroPanel, FeatureListIntroPanelConfig };
