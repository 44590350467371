/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './PostChatSurvey.css';

import {
  FaceDissatisfied32,
  FaceDissatisfiedFilled32,
  FaceNeutral32,
  FaceNeutralFilled32,
  FaceSatisfied32,
  FaceSatisfiedFilled32,
} from '@carbon/icons-react';
import { Button, TextArea } from 'carbon-components-react';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DemoFeaturePanelsTrackingNames } from '../../framework/demoTypes';
import { trackDemoFeatureFinished } from '../../framework/utils/demoTracking';
import { lendyrActions } from '../../lendyr/store/lendyrActions';
import { LendyrAppState } from '../../lendyr/store/LendyrAppState';
import { MessageRequest } from '../../submodules/wa-fd-types-public/wa-types';
import { useOnMount } from '../../web-chat-dependencies/hooks/useOnMount';
import { doFocusRef } from '../../web-chat-dependencies/utils/domUtils';
import { PostChatSurveyState } from './customPanelConstants';
import { CustomPanelContainerProps } from './customPanelTypes';

enum SatisfactionRating {
  NONE,
  DISSATISFIED,
  NEUTRAL,
  SATISFIED,
}

/**
 * A post chat survey component to surface in a custom panel for user's to provide feedback on their conversation
 * experience.
 */
function PostChatSurvey({ instance }: CustomPanelContainerProps) {
  const dispatch = useDispatch();
  const postChatSurveyState = useSelector(
    (state: LendyrAppState) => state.persistedToBrowserSessionStorage.siteData.postChatSurveyState,
  );
  const [satisfactionRating, setSatisfactionRating] = useState(SatisfactionRating.NONE);
  const closeButtonElementRef = useRef<HTMLButtonElement>();
  const panelInstance = instance.customPanels.getPanel();

  const isDissatisfiedSelected = satisfactionRating === SatisfactionRating.DISSATISFIED;
  const isNeutralSelected = satisfactionRating === SatisfactionRating.NEUTRAL;
  const isSatisfiedSelected = satisfactionRating === SatisfactionRating.SATISFIED;

  const FaceSatisfied = isSatisfiedSelected ? FaceSatisfiedFilled32 : FaceSatisfied32;
  const FaceNeutral = isNeutralSelected ? FaceNeutralFilled32 : FaceNeutral32;
  const FaceDissatisfied = isDissatisfiedSelected ? FaceDissatisfiedFilled32 : FaceDissatisfied32;

  useOnMount(() => {
    setTimeout(() => {
      doFocusRef(closeButtonElementRef);
    });
  });

  const handleOnCancel = useCallback(() => {
    // If the survey is set to open on pre:close, set it to "no". If the post chat survey is completed, we don't
    // need to set the post chat survey to "no".
    if (postChatSurveyState === PostChatSurveyState.SHOW) {
      dispatch(
        lendyrActions.updateSessionStorageStateSiteData({
          postChatSurveyState: PostChatSurveyState.HIDE,
        }),
      );
    }

    // Reset the satisfaction rating.
    setSatisfactionRating(SatisfactionRating.NONE);

    panelInstance.close();
  }, [dispatch, postChatSurveyState, panelInstance]);

  const handleOnSubmit = useCallback(() => {
    // Reset the satisfaction rating.
    setSatisfactionRating(SatisfactionRating.NONE);

    dispatch(
      lendyrActions.updateSessionStorageStateSiteData({
        postChatSurveyState: PostChatSurveyState.COMPLETED,
      }),
    );

    trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.CHAT_SURVEY);

    // Send a silent message so the assistant can thank the user for their feedback if they open web chat again.
    instance.send({ input: { text: 'Feedback' } } as MessageRequest, { silent: true });

    panelInstance.close();
  }, [dispatch, instance, panelInstance]);

  return (
    <div className="PostChatSurvey">
      <div className="PostChatSurvey__BodyWrapper">
        <div className="PostChatSurvey__Body">
          <div className="PostChatSurvey__Heading">
            <p>Did our assistant help you with your need?</p>
          </div>
          <div className="PostChatSurvey__IconsContainer">
            <Button
              className="PostChatSurvey__Icon"
              onClick={() => setSatisfactionRating(SatisfactionRating.SATISFIED)}
              kind="ghost"
            >
              <FaceSatisfied />
            </Button>
            <Button
              className="PostChatSurvey__Icon"
              onClick={() => setSatisfactionRating(SatisfactionRating.NEUTRAL)}
              kind="ghost"
            >
              <FaceNeutral />
            </Button>
            <Button
              className="PostChatSurvey__Icon"
              onClick={() => setSatisfactionRating(SatisfactionRating.DISSATISFIED)}
              kind="ghost"
            >
              <FaceDissatisfied />
            </Button>
          </div>
          <p className="PostChatSurvey__TextAreaLabel">Tell us more</p>
          <TextArea
            labelText=""
            placeholder="While this isn't required, we'd appreciate hearing what you thought about your time."
          />
        </div>
      </div>
      <div className="PostChatSurvey__ButtonContainer">
        <Button
          className="PostChatSurvey__CloseButton"
          ref={closeButtonElementRef}
          kind="secondary"
          onClick={handleOnCancel}
        >
          Close
        </Button>
        <Button className="PostChatSurvey__SubmitButton" onClick={handleOnSubmit} disabled={!satisfactionRating}>
          Submit
        </Button>
      </div>
    </div>
  );
}

export { PostChatSurvey };
