/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { IterateQuicklyOverlay } from './IterateQuicklyOverlay';
import { RecognizeGapsSVGComponent } from './svgComponents/RecognizeGapsSVG';
import { RecognizeGapsToolTipSVGComponent } from './svgComponents/RecognizeGapsToolTipSVGComponent';

const RECOGNIZE_GAPS_LINK: GuidancePanelImplementationData[] = [
  {
    title: 'Review customer conversations',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-analytics-conversations',
    type: 'Docs',
  },
  {
    title: 'Action recommendations based on unrecognized requests',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-analytics-recognition',
    type: 'Docs',
  },
];

function RecognizeGapsPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Recognize Gaps"
        description="View questions or requests from your customers that the assistant doesn't recognize so you can quickly pinpoint gaps in coverage. Watson Assistant automatically identifies and groups together similar unrecognized questions and requests, so you know exactly where to add or improve conversational flows."
      />
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={RECOGNIZE_GAPS_LINK} />
      </GuidancePanelImplementationSection>
      <IterateQuicklyOverlay>
        <RecognizeGapsSVGComponent />
        <RecognizeGapsToolTipSVGComponent />
      </IterateQuicklyOverlay>
    </div>
  );
}

const RecognizeGapsPanelConfig: SectionPanelConfig = {
  title: 'Recognize Gaps',
  SectionPanelComponent: RecognizeGapsPanel,
  page: LendyrPage.ITERATE_QUICKLY_TOUR,
  panel: LendyrPanel.RECOGNIZE_GAPS,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.RECOGNIZE_GAPS,
};

export { RecognizeGapsPanel, RecognizeGapsPanelConfig };
