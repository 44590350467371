/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './HashAnchorContainer.css';

import cx from 'classnames';
import React from 'react';

import { HasChildren } from '../web-chat-dependencies/types/HasChildren';
import { HasClassName } from '../web-chat-dependencies/types/HasClassName';
import { HasID } from '../web-chat-dependencies/types/HasID';

/**
 * This container is for wrapping content that takes advantage of URL hashes to auto-scroll the user to the content
 * on the page and not having the carbon header overlap the content.
 */
function HashAnchorContainer({ className, id, children }: HasChildren & HasID & HasClassName) {
  return (
    <div className={cx('HashAnchorContainer', className)}>
      <div className="HashAnchorContainer__AnchorElement" id={id} />
      {children}
    </div>
  );
}

export { HashAnchorContainer };
