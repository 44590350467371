/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { demoActions } from '../../store/demoActions';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const TELL_DO_SHOW_TRANSITION_CONFIG: DemoTransitionConfig = {
  onTransition: store => {
    const { isAuthenticated } = store.getState();
    if (isAuthenticated) {
      store.dispatch(demoActions.setStateProperty('isAuthenticated', false));
      return () => {
        store.dispatch(demoActions.setStateProperty('isAuthenticated', true));
      };
    }

    return null;
  },
};

export { TELL_DO_SHOW_TRANSITION_CONFIG };
