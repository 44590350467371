/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './MortgageCalculator.css';

import { Dropdown, NumberInput, TextInput } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';

import { MORTGAGE_CALCULATOR_ELEMENT_ID } from '../framework/utils/demoConstants';
import { isEnterKey } from '../web-chat-dependencies/utils/domUtils';
import { HashAnchorContainer } from './HashAnchorContainer';

const LOAN_TERM_ITEMS: LoanTermItem[] = [
  { id: '30-years', text: '30 years', value: 30 },
  { id: '20-years', text: '20 years', value: 20 },
  { id: '10-years', text: '15 years', value: 15 },
];
const INTEREST_RATE = 0.05;
const INTEREST_RATE_PER_MONTH = INTEREST_RATE / 12;

interface MortgageCalculatorFields {
  /**
   * The credit score provided by the user through web chat. This value doesn't actually affect how the mortgage
   * monthly payments are calculated.
   */
  creditScore: string | number;

  /**
   * The loan amount to render.
   */
  loanAmount: number;

  /**
   * The number of years to pay off the loan.
   */
  loanTerm: number;
}

interface LoanTermItem {
  /**
   * The id provided to the dropdown item element.
   */
  id: string;

  /**
   * The loan term text to display in the dropdown item.
   */
  text: string;

  /**
   * The loan term item value.
   */
  value: number;
}

interface MortgageCalculatorProps {
  /**
   * The mortgage calculator input values. Changing a value in this object will trigger an immediate update of the
   * values displayed in the component.
   */
  values: MortgageCalculatorFields;

  /**
   * The callback function that's fired once a loan amount is submitted by hitting the Enter key. The function is passed
   * the loan amount value.
   */
  onLoanAmountEnter: (loanAmount: number) => void;

  /**
   * The callback function that's fired when the loan amount field has focus.
   */
  onLoanAmountFocus: () => void;

  /**
   * The callback function that's fired once a loan term selection is made from the dropdown. The function is passed
   * the selected loan term value.
   */
  onLoanTermChange: (loanTerm: number) => void;

  /**
   * The helper text for the loan amount input field.
   */
  loanAmountHelperText: string;
}

/**
 * This component renders a mortgage calculator for user's to calculate their monthly payments.
 */
function MortgageCalculatorComponent({
  values,
  onLoanTermChange,
  onLoanAmountEnter,
  loanAmountHelperText,
  onLoanAmountFocus,
}: MortgageCalculatorProps) {
  const [stateCreditScore, setStateCreditScore] = useState(values.creditScore);
  const [stateLoanAmount, setStateLoanAmount] = useState(values.loanAmount);
  const [stateLoanTerm, setStateLoanTerm] = useState<number>();

  const mortgagePayment = stateLoanTerm ? calcMortgagePayment(stateLoanAmount, stateLoanTerm) : 0;
  const loanTermItem = stateLoanTerm ? getSelectedItem(stateLoanTerm) : null;
  const roundedValue = Math.round(mortgagePayment);
  const mortgagePaymentText = !Number.isNaN(roundedValue) ? `$${roundedValue}` : '$0';

  // This effect updates the loan amount/term state values and calculates the mortgage payments every time the prop
  // "values" change.
  useEffect(() => {
    const { loanAmount, loanTerm, creditScore } = values;

    setStateLoanAmount(loanAmount);
    setStateLoanTerm(loanTerm);
    setStateCreditScore(creditScore);
  }, [values]);

  return (
    <HashAnchorContainer className="MortgageCalculatorSection" id={MORTGAGE_CALCULATOR_ELEMENT_ID}>
      <div className="DemoPage__CenterContent">
        <div className="DemoPageCommon__Title">Mortgage Calculator</div>
        <div className="DemoPageCommon__Description">
          Our robust capital, low rates, and loan options allow you to confidently make offers to maximize your returns.
          <br />
          (A 5% interest rate will be applied by default)
        </div>
        <div className="MortgageCalculatorSection__CalculatorContent">
          <div className="MortgageCalculatorSection__InputContainer">
            <TextInput
              light
              id="credit-score-input"
              labelText="Credit score"
              value={stateCreditScore}
              onChange={event => setStateCreditScore(event.target.value)}
              helperText="This is just for show and won't affect the calculation."
            />
            <NumberInput
              light
              id="loan-amount-input"
              min={0}
              label="Loan amount"
              value={stateLoanAmount}
              invalidText="Please provide a loan amount."
              helperText={loanAmountHelperText}
              onFocus={onLoanAmountFocus}
              onKeyPress={event => {
                if (isEnterKey(event)) {
                  onLoanAmountEnter(stateLoanAmount);
                }
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                // imaginaryTarget is not a standard event property, it's provided by the Carbon component NumberInput,
                // but it's also missing from their type definitions.
                setStateLoanAmount((event as any).imaginaryTarget?.value);
              }}
            />
            <Dropdown
              light
              id="loan-term-input"
              items={LOAN_TERM_ITEMS}
              label="Select a loan term"
              titleText="Loan term"
              selectedItem={loanTermItem}
              itemToString={item => item.text}
              onChange={({ selectedItem }) => {
                setStateLoanTerm(selectedItem.value);
                onLoanTermChange(selectedItem.value);
              }}
            />
          </div>
          <div className="MortgageCalculatorSection__CalculatedAmount">
            <div className="MortgageCalculatorSection__PaymentTitle">Monthly payment</div>
            <div className="MortgageCalculatorSection__PaymentText">{mortgagePaymentText}</div>
          </div>
        </div>
      </div>
    </HashAnchorContainer>
  );
}

/**
 * Calculates and returns the monthly payment value for a mortgage payment using the given loan amount and term.
 *
 * @param loanAmount The loan amount to be paid off.
 * @param loanTerm The amount of years the loan is expected to be paid off.
 */
function calcMortgagePayment(loanAmount: number, loanTerm: number) {
  const totalPayments = 12 * loanTerm;
  return (
    (loanAmount * INTEREST_RATE_PER_MONTH * (1 + INTEREST_RATE_PER_MONTH) ** totalPayments) /
    ((1 + INTEREST_RATE_PER_MONTH) ** totalPayments - 1)
  );
}

/**
 * Returns the selected loan term item for the dropdown using the given loan term value.
 */
function getSelectedItem(loanTerm: number) {
  const index = LOAN_TERM_ITEMS.findIndex(item => item.value === loanTerm);
  return LOAN_TERM_ITEMS[index];
}

const MortgageCalculatorComponentExport = React.memo(MortgageCalculatorComponent);

export { MortgageCalculatorComponentExport as MortgageCalculator, MortgageCalculatorFields };
