/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './LendyrButton.css';

import { ButtonKindProps } from 'carbon-components-react';
import cx from 'classnames';
import React, { MouseEventHandler } from 'react';

import { DemoSiteParamsAndHash } from '../framework/demoTypes';
import { HasAutoFocus } from '../framework/types/HasAutoFocus';
import { HasChildren } from '../web-chat-dependencies/types/HasChildren';
import { HasClassName } from '../web-chat-dependencies/types/HasClassName';
import { DemoButton } from './DemoButton';

interface LendyrButtonProps extends HasClassName, HasChildren, DemoSiteParamsAndHash, ButtonKindProps, HasAutoFocus {
  /**
   * Determines if the button kind is tertiary.
   */
  tertiary?: boolean;

  /**
   * The callback function to fire when the button is clicked.
   */
  onClick?: MouseEventHandler;
}

function LendyrButton({ className, tertiary, ...props }: LendyrButtonProps) {
  const kind = tertiary ? 'tertiary' : 'primary';
  return (
    <DemoButton
      className={cx(
        'LendyrButton',
        { 'LendyrButton--primary': !tertiary, 'LendyrButton--tertiary': tertiary },
        className,
      )}
      kind={kind}
      {...props}
    />
  );
}

export { LendyrButton };
