/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const AUTHENTICATION_PANEL_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Build a custom extension',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-build-custom-extension',
    type: 'Docs',
  },
  {
    title: 'Use a custom extension',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-call-extension',
    type: 'Docs',
  },
];

function TrustedAIPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Trusted AI"
        description="Answer questions naturally and accomplish tasks for callers without forcing them to listen to a long list of options and navigate a phone tree."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Say the following:
          <GuidancePanelTextSnippet text="Can I transfer money from my savings to my checking?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          When asked for the amount say: <GuidancePanelTextSnippet text="$550" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          When asked to confirm the transfer, reply by saying:
          <GuidancePanelTextSnippet text="Actually, transfer $1000 from my credit card to my checking." />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          When asked to confirm the new transfer account and amount, say: <GuidancePanelTextSnippet text="Yes" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          After confirming your transfer, ask for help with something else by saying:
          <GuidancePanelTextSnippet text="Oh, that reminds me, I just got a new debit card. I need to update my address." />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Interrupt and say the following:
          <GuidancePanelTextSnippet text="Wait, what's the last thing you mailed to me? Not my new card, right?" />
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile codeNecessary data={AUTHENTICATION_PANEL_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const TrustedAIPanelConfig: SectionPanelConfig = {
  title: 'Trusted AI',
  SectionPanelComponent: TrustedAIPanel,
  page: LendyrPage.PHONE_TOUR,
  panel: LendyrPanel.PHONE_TRUSTED_AI,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.PHONE_TRUSTED_AI,
};

export { TrustedAIPanel, TrustedAIPanelConfig };
