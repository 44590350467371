/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { JSXElementConstructor, ReactElement } from 'react';

import {
  ACCESSIBLE_SCALABLE_AI_SECTION_MAP,
  BUILD_EXPERIENCE_SECTION_MAP,
  DRIVE_CUSTOMER_OUTCOME_SECTION_MAP,
  ESSENTIALS_SECTION_MAP,
  FEATURE_LIST_SECTION_MAP,
  GENERATIVE_AI_SECTION_MAP,
  ITERATE_QUICKLY_SECTION_MAP,
  PHONE_SECTION_MAP,
  TELL_DO_SHOW_SECTION_MAP,
  UNIFY_PERSONALIZED_SECTION_MAP,
} from '../../components/demoGuidanceComponent/featureGuidancePanelUtils';
import { demoHistory } from '../demoHistory';
import { DemoSiteParamsAndHash, LendyrPage, LendyrPanel, LendyrSection } from '../demoTypes';
import {
  MORTGAGE_CALCULATOR_ELEMENT_ID,
  PANEL_SPECIFIC_PAGE_MAP,
  URL_PARAM_MOBILE_VIEW,
  URL_PARAM_PAGE,
  URL_PARAM_PANEL,
  URL_PARAM_SECTION,
  URL_PARAM_WA_LID,
} from './demoConstants';

function getQueryStringParams() {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    region: urlParams.get('region'),
    integrationID: urlParams.get('integrationID'),
    serviceInstanceID: urlParams.get('serviceInstanceID'),
    subscriptionID: urlParams.get('subscriptionID'),
    clientVersion: urlParams.get('clientVersion'),
    debug: Boolean(urlParams.get('debug')),
  };
}

function getDemoSiteParamsAndHash(location: { search: string; hash: string } = window.location): DemoSiteParamsAndHash {
  const { search, hash } = location;
  const urlParams = new URLSearchParams(search);

  return {
    page: urlParams.get(URL_PARAM_PAGE) || '',
    panel: urlParams.get(URL_PARAM_PANEL) || '',
    section: urlParams.get(URL_PARAM_SECTION) || '',
    pageLinkID: urlParams.get(URL_PARAM_WA_LID),
    mobileView: urlParams.get(URL_PARAM_MOBILE_VIEW) === 'true',
    hash: hash.replace('#', ''),
  };
}

/**
 * Auto scrolls to the given element.
 */
function scrollToElement(selector: string, timeout = 0) {
  // Delay scrollIntoView since in case it doesn't happen immediately.
  setTimeout(() => {
    document.querySelector(selector)?.scrollIntoView({ behavior: 'smooth' });
  }, timeout);
}

/**
 * For manually scrolling the user to the top of the page since the browser preserves their scroll position.
 */
function scrollToTop() {
  window.scrollTo(0, 0);
}

function scrollToMortgageCalculator() {
  // A delay of at least 400ms will allow smooth scrolling to work.
  scrollToElement(`#${MORTGAGE_CALCULATOR_ELEMENT_ID}`, 400);
}

/**
 * Returns a search string specifically for navigating through the demo site. Param values are preserved, as well as the
 * page and panel params so that the content these params render are not defaulted everytime. Other url value other than
 * these will not be preserved.
 *
 * @param paramsAndHash An object holding the values the add to the url.
 */
function createURLSearchString(paramsAndHash: DemoSiteParamsAndHash) {
  const searchParams = new URLSearchParams(window.location.search);
  const { page, section, panel, pageLinkID, mobileView, hash } = paramsAndHash;
  // If the page, section, and panel values provided is not null, use them as param values. Otherwise, preserve
  // their existing values so that navigating from the guidance panel or demo page doesn't return them to the home
  // page or home guidance panel. We should check if these values are strings since empty strings means we don't
  // want to preserve the param and should load the default demo page or guidance panel component.
  const pageValue = typeof page === 'string' ? page : searchParams.get(URL_PARAM_PAGE);
  const sectionValue = typeof section === 'string' ? section : searchParams.get(URL_PARAM_SECTION);
  const panelValue = typeof panel === 'string' ? panel : searchParams.get(URL_PARAM_PANEL);
  const mobileViewValue = typeof mobileView === 'boolean' ? mobileView : searchParams.get(URL_PARAM_MOBILE_VIEW);

  // Remove the optional params from the url.
  searchParams.delete(URL_PARAM_SECTION);
  searchParams.delete(URL_PARAM_PAGE);
  searchParams.delete(URL_PARAM_PANEL);
  searchParams.delete(URL_PARAM_WA_LID);
  searchParams.delete(URL_PARAM_MOBILE_VIEW);

  // If a page value is available, set its search param.
  if (pageValue) {
    searchParams.set(URL_PARAM_PAGE, pageValue);
  }

  // If a section value is available, set its search param.
  if (sectionValue) {
    searchParams.set(URL_PARAM_SECTION, sectionValue);
  }

  // If a panel value is available, set its search param.
  if (panelValue) {
    searchParams.set(URL_PARAM_PANEL, panelValue);
  }

  // Add param for mobile view if available.
  if (mobileViewValue) {
    searchParams.set(URL_PARAM_MOBILE_VIEW, 'true');
  }

  // Add param for deep linking if available.
  if (pageLinkID) {
    searchParams.set(URL_PARAM_WA_LID, pageLinkID);
  }

  const paramsString = searchParams.toString();
  let searchString = '';

  if (paramsString) {
    searchString = `?${paramsString}`;
  }

  if (hash) {
    searchString += `#${hash}`;
  }

  return searchString;
}

/**
 * This function checks if the provided child component matches the expected child component. Otherwise, it will
 * throw an error.
 *
 * @param child The child component to compare against the expected child component.
 * @param expectedChild The expected child component to match the provided child component.
 */
function assertChildType(child: ReactElement, expectedChild: JSXElementConstructor<unknown>) {
  const { type: childType } = child;

  if (childType !== expectedChild) {
    // Check if childType is a string. This can occur if the provided child component is a JSX Element and not a
    // function component.
    const childName = typeof childType === 'string' ? childType : childType.name;
    throw new Error(`Expected ${expectedChild.name}, instead got <${childName}>.`);
  }
}

/**
 * Returns the panel name currently being viewed. This will return null if no valid value is found.
 */
function getCurrentPanelName() {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(URL_PARAM_PANEL) || searchParams.get(URL_PARAM_SECTION);
}

/**
 * Determines if the provided page is the current page being viewed.
 */
function isCurrentPage(page: LendyrPage) {
  const searchParams = new URLSearchParams(window.location.search);
  const currentPage = searchParams.get(URL_PARAM_PAGE);
  return currentPage === page;
}

/**
 * Returns the demo panel name of the current Lendyr demo panel being viewed, whether it's a feature panel or the home
 * panel of a guidance panel. This is solely for tracking purposes.
 */
function getCurrentLendyrPanelTrackingName(section: string, panel: string = LendyrPanel.HOME) {
  switch (section) {
    case LendyrSection.INDEX:
      return FEATURE_LIST_SECTION_MAP.get(panel)?.config.demoPanelTrackingName;
    case LendyrSection.ESSENTIALS:
      return ESSENTIALS_SECTION_MAP.get(panel)?.config.demoPanelTrackingName;
    case LendyrSection.TELL_DO_SHOW:
      return TELL_DO_SHOW_SECTION_MAP.get(panel)?.config.demoPanelTrackingName;
    case LendyrSection.ACCESSIBLE_SCALABLE_AI:
      return ACCESSIBLE_SCALABLE_AI_SECTION_MAP.get(panel)?.config.demoPanelTrackingName;
    case LendyrSection.UNIFY_PERSONALIZED:
      return UNIFY_PERSONALIZED_SECTION_MAP.get(panel)?.config.demoPanelTrackingName;
    case LendyrSection.PHONE:
      return PHONE_SECTION_MAP.get(panel)?.config.demoPanelTrackingName;
    case LendyrSection.ITERATE_QUICKLY:
      return ITERATE_QUICKLY_SECTION_MAP.get(panel)?.config.demoPanelTrackingName;
    case LendyrSection.DRIVE_CUSTOMER_OUTCOMES:
      return DRIVE_CUSTOMER_OUTCOME_SECTION_MAP.get(panel)?.config.demoPanelTrackingName;
    case LendyrSection.GENERATIVE_AI:
      return GENERATIVE_AI_SECTION_MAP.get(panel)?.config.demoPanelTrackingName;
    case LendyrSection.BUILD_EXPERIENCE:
      return BUILD_EXPERIENCE_SECTION_MAP.get(panel)?.config.demoPanelTrackingName;
    default:
      return null;
  }
}

/**
 * Programmatically navigates the user to the right content using the provided params and hash.
 */
function navigateTo(paramsAndHash: DemoSiteParamsAndHash) {
  demoHistory.push({ search: createURLSearchString(paramsAndHash) });
}

/**
 * Programmatically navigates the user to the page the current panel is meant to start from if the user isn't there.
 */
function navigateToPanelSpecificPage({ page, panel }: DemoSiteParamsAndHash) {
  const panelSpecificPage = PANEL_SPECIFIC_PAGE_MAP[panel];
  if (panelSpecificPage && page !== panelSpecificPage) {
    // A setTimeout is needed for navigating to panel specific page so that we don't immediately trigger another
    // subscription call before a current one has finished.
    setTimeout(() => navigateTo({ page: panelSpecificPage }));
  }
}

export {
  getQueryStringParams,
  scrollToElement,
  scrollToTop,
  createURLSearchString,
  assertChildType,
  getCurrentPanelName,
  isCurrentPage,
  getCurrentLendyrPanelTrackingName,
  getDemoSiteParamsAndHash,
  navigateTo,
  navigateToPanelSpecificPage,
  scrollToMortgageCalculator,
};
