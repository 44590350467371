/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { DEEP_LINKING_EMAIL_TEXT, PANEL_SPECIFIC_PAGE_MAP } from '../../../../framework/utils/demoConstants';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const DEEP_LINKING_NO_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Learn about deep linking',
    link: 'https://web-chat.global.assistant.watson.cloud.ibm.com/docs.html?to=api-configuration#pageLinks',
    type: 'Docs',
  },
];

const DEEP_LINKING_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Add deep linking',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/deep-linking',
    type: 'Tutorial',
  },
];

function DeepLinkingPanel() {
  return <DeepLinkingPanelContent title="Deep Linking | Link from Email" />;
}

function DeepLinkingPanelContent({ title }: { title: string }) {
  return (
    <div>
      <GuidancePanelHeading
        title={title}
        description="Direct customers from external applications or messaging like emails to your assistant with inputs that have been preconfigured or prefilled. When the due date for a customer's bill is approaching or their password is near its expiration, help them take action easily by providing them with a link to an assistant that is ready to help with their specific issue."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          In the email on the right, click "{DEEP_LINKING_EMAIL_TEXT}".
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Click one of the options.</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Once the assistant responds, select "Yes".</GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={DEEP_LINKING_NO_CODE_LINKS} />
        <GuidancePanelImplementationTile codeNecessary data={DEEP_LINKING_CODE_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const DeepLinkingPanelConfig: SectionPanelConfig = {
  title: 'Deep Linking | Link from Email',
  SectionPanelComponent: DeepLinkingPanel,
  page: PANEL_SPECIFIC_PAGE_MAP[LendyrPanel.DEEP_LINKING],
  panel: LendyrPanel.DEEP_LINKING,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.DEEP_LINKING,
};

export { DeepLinkingPanel, DeepLinkingPanelContent, DeepLinkingPanelConfig };
