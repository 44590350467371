/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidanceMobileViewWarningInlineNotification } from '../../commonComponents/GuidanceMobileViewWarningInlineNotification';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const FORM_FILLING_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'User defined response type',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-response-types-reference#user_defined',
    type: 'Docs',
  },
  {
    title: 'Handle custom responses',
    link: 'https://web-chat.global.assistant.watson.cloud.ibm.com/docs.html?to=api-events#customresponse',
    type: 'Docs',
  },
  {
    title: 'Implement page interaction',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/page-interaction',
    type: 'Tutorial',
  },
];

function FormFillingPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Form Filling"
        description="Use Watson Assistant's extensive network of user events to drive form filling functionality and provide powerful assistance and guidance. Rather than recreate and maintain existing functionality, integrate tightly with the application to show your customers how they can resolve problems themselves."
      />
      <GuidanceMobileViewWarningInlineNotification />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following:
          <GuidancePanelTextSnippet text="I am looking for a new home, and I want to know what my monthly payments will be" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          When asked to provide your loan amount, enter:
          <GuidancePanelTextSnippet text="How do I determine my loan amount?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          After learning how to determine loan amount, enter: <GuidancePanelTextSnippet text="400000" /> or some other
          number as the loan amount.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>When asked, choose a term for the loan.</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Using the information provided, Watson Assistant helps the user learn how to determine their estimated monthly
          payment.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <FormFillingImplementationSection />
    </div>
  );
}

function FormFillingImplementationSection() {
  return (
    <GuidancePanelImplementationSection>
      <GuidancePanelImplementationTile codeNecessary data={FORM_FILLING_CODE_LINKS} />
    </GuidancePanelImplementationSection>
  );
}

const FormFillingPanelConfig: SectionPanelConfig = {
  title: 'Form Filling',
  SectionPanelComponent: FormFillingPanel,
  panel: LendyrPanel.FORM_FILLING,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.FORM_FILLING,
};

export { FormFillingPanel, FormFillingPanelConfig, FormFillingImplementationSection };
