/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelHeading.css';

import React, { ReactNode } from 'react';

interface GuidancePanelHeadingProps {
  /**
   * The title of the panel.
   */
  title: ReactNode;

  /**
   * The description of the panel.
   */
  description: ReactNode;
}

/**
 * The panel heading that introduces the content in the guidance panel.
 */
function GuidancePanelHeading({ title, description }: GuidancePanelHeadingProps) {
  return (
    <div className="GuidancePanelHeading">
      <div className="GuidancePanelHeading__Title">{title}</div>
      <div className="GuidancePanelHeading__Description">{description}</div>
    </div>
  );
}

export { GuidancePanelHeading };
