/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { DriveCustomerOutcomesOverlay } from './DriveCustomerOutcomesOverlay';
import { UnifyDataSVGComponent } from './svgComponents/UnifyDataSVGComponent';
import { UnifyDataTooltipSVGComponent } from './svgComponents/UnifyDataTooltipSVGComponent';

const UNIFY_DATA_LINK: GuidancePanelImplementationData[] = [
  {
    title: 'Setup Watson Assistant with Segment',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-segment-add',
    type: 'Docs',
  },
];

function UnifyDataPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Unify Data"
        description={
          <>
            Use our integration with Segment, a Customer Data Platform, to unify your app + assistant data.
            <br />
            <br />
            Segment offers an extensive catalog of sources and destinations, so you can also connect with your CRM,
            advertising tools, analytics tools, any custom tools, and more.
            <br />
            <br />
            This feature is available on Watson Assistant Enterprise. Enterprise includes 1 source: Watson Assistant,
            and 3 destination types: Storage, Raw Data, and Analytics. To access more, customers can upgrade via
            Segment.
          </>
        }
      />
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={UNIFY_DATA_LINK} />
      </GuidancePanelImplementationSection>
      <DriveCustomerOutcomesOverlay>
        <UnifyDataSVGComponent />
        <UnifyDataTooltipSVGComponent />
      </DriveCustomerOutcomesOverlay>
    </div>
  );
}

const UnifyDataPanelConfig: SectionPanelConfig = {
  title: 'Unify Data',
  SectionPanelComponent: UnifyDataPanel,
  panel: LendyrPanel.UNIFY_DATA,
  page: LendyrPage.DRIVE_CUSTOMER_OUTCOMES_TOUR,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.UNIFY_DATA,
};

export { UnifyDataPanel, UnifyDataPanelConfig };
