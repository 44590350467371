/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import merge from 'lodash/merge';
import { DeepPartial } from 'ts-essentials';

import { LendyrAppState } from '../../lendyr/store/LendyrAppState';
import { updateLendyrTourVisitedStorageState } from '../../lendyr/utils/lendyrReducerUtils';
import { DemoSiteParamsAndHash, RestartType } from '../demoTypes';
import { DemoActions } from './demoActions';
import { DemoAppState } from './DemoAppState';
import { CreditCardsPageState, HomeLoansPageState, PersistedToBrowserSessionStorage } from './demoStoreTypes';

type DemoReducer = Record<string, (state: DemoAppState, action: any) => DemoAppState>;

const demoReducers: DemoReducer = {
  [DemoActions.CHANGE_STATE]: (state, { partialState }: { partialState: DeepPartial<DemoAppState> }) =>
    merge({}, state, partialState),

  [DemoActions.SET_DEMO_SITE_PARAMS]: (
    state: DemoAppState,
    { demoSiteParamsAndHash }: { demoSiteParamsAndHash: DemoSiteParamsAndHash },
  ) => {
    const newState = {
      ...state,
      demoSiteParamsAndHash,
    };
    return updateLendyrTourVisitedStorageState(newState as LendyrAppState, demoSiteParamsAndHash.section);
  },

  [DemoActions.SET_STATE_PROPERTY]: <TPropertyName extends keyof DemoAppState>(
    state: DemoAppState,
    { propertyName, propertyValue }: { propertyName: TPropertyName; propertyValue: DemoAppState[TPropertyName] },
  ) => {
    return {
      ...state,
      [propertyName]: propertyValue,
    };
  },

  [DemoActions.SET_CREDIT_CARDS_PAGE_STATE_PROPERTY]: <TPropertyName extends keyof CreditCardsPageState>(
    state: DemoAppState,
    {
      propertyName,
      propertyValue,
    }: { propertyName: TPropertyName; propertyValue: CreditCardsPageState[TPropertyName] },
  ) => {
    return {
      ...state,
      pageState: {
        ...state.pageState,
        creditCardsPage: {
          ...state.pageState.creditCardsPage,
          [propertyName]: propertyValue,
        },
      },
    };
  },

  [DemoActions.SET_HOME_LOANS_PAGE_STATE_PROPERTY]: <TPropertyName extends keyof HomeLoansPageState>(
    state: DemoAppState,
    { propertyName, propertyValue }: { propertyName: TPropertyName; propertyValue: HomeLoansPageState[TPropertyName] },
  ) => {
    return {
      ...state,
      pageState: {
        ...state.pageState,
        homeLoansPage: {
          ...state.pageState.homeLoansPage,
          [propertyName]: propertyValue,
        },
      },
    };
  },

  [DemoActions.DO_RESTART_TYPE]: (state, { restartType }: { restartType: RestartType }) => {
    return {
      ...state,
      restartCounter: state.restartCounter + 1,
      lastRestartType: restartType,
    };
  },

  [DemoActions.UPDATE_SESSION_STORAGE_STATE]: (
    state,
    { partial }: { partial: Partial<PersistedToBrowserSessionStorage> },
  ) => {
    return {
      ...state,
      persistedToBrowserSessionStorage: merge({}, state.persistedToBrowserSessionStorage, partial),
    };
  },

  [DemoActions.UPDATE_SESSION_STORAGE_STATE_SITE_DATA]: <TSiteData,>(
    state: DemoAppState,
    { partial }: { partial: Partial<PersistedToBrowserSessionStorage<TSiteData>['siteData']> },
  ) => {
    return {
      ...state,
      persistedToBrowserSessionStorage: {
        ...state.persistedToBrowserSessionStorage,
        siteData: merge({}, state.persistedToBrowserSessionStorage.siteData, partial),
      },
    };
  },
};

export { demoReducers };
