/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const IFRAME_NO_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Add an Iframe response',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-respond#respond-add-iframe',
    type: 'Docs',
  },
];

const IFRAME_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Share location',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/share-location',
    type: 'Tutorial',
  },
];

function IframePanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Iframe | Google Maps"
        description="Iframes allow for the use of 3rd-party widgets in the assistant with little to no coding. Provide directions with Google Maps, book appointments with Calendly, collect survey information with Survey Monkey, and more."
      />
      <IframePanelInstructions />
      <IframePanelImplementation />
    </div>
  );
}

function IframePanelInstructions() {
  return (
    <GuidancePanelTryItOutList>
      <GuidancePanelTryItOutListItem>
        Enter the following: <GuidancePanelTextSnippet text="Find nearest Lendyr branch" />
      </GuidancePanelTryItOutListItem>
      <GuidancePanelTryItOutListItem>
        When prompted from browser, share your location with the assistant.
      </GuidancePanelTryItOutListItem>
      <GuidancePanelTryItOutListItem>
        Click to open an Iframe with a map to the nearest Lendyr branch.
      </GuidancePanelTryItOutListItem>
    </GuidancePanelTryItOutList>
  );
}

function IframePanelImplementation() {
  return (
    <GuidancePanelImplementationSection>
      <GuidancePanelImplementationTile data={IFRAME_NO_CODE_LINKS} />
      <GuidancePanelImplementationTile codeNecessary data={IFRAME_CODE_LINKS} />
    </GuidancePanelImplementationSection>
  );
}

const IframePanelConfig: SectionPanelConfig = {
  title: 'Iframe | Google Maps',
  SectionPanelComponent: IframePanel,
  panel: 'iframe',
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.IFRAME,
};

export { IframePanel, IframePanelInstructions, IframePanelImplementation, IframePanelConfig };
