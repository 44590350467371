/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './CarouselContainer.css';

import { CaretLeft16, CaretRight16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import cx from 'classnames';
import React, { useState } from 'react';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper as SwiperComponent } from 'swiper/react';

const SWIPER_MODULES = [Navigation, Pagination, A11y];

/**
 * A HOC - carousel container component for rendering swipe-able content that can be configured with custom content that
 * the user can swipe between.
 */
function CarouselContainer({ children }: any) {
  // This use state will capture the reference to the nav element so that we know the Swiper component can be mounted
  // and make queries to elements.
  const [navigationElement, setNavigationElement] = useState<HTMLDivElement>();

  return (
    <>
      {navigationElement && (
        <SwiperComponent
          modules={SWIPER_MODULES}
          pagination={{
            el: navigationElement.querySelector<HTMLElement>('.CarouselContainer__BulletContainer'),
            clickable: true,
            bulletClass: 'CarouselContainer__Bullet',
            bulletActiveClass: 'CarouselContainer__Bullet--selected',
            renderBullet,
          }}
          navigation={{
            prevEl: navigationElement.querySelector<HTMLElement>('button:nth-of-type(1)'),
            nextEl: navigationElement.querySelector<HTMLElement>('button:nth-of-type(2)'),
          }}
          slidesPerView="auto"
          spaceBetween={15}
          centeredSlides
          rewind
        >
          {children}
        </SwiperComponent>
      )}
      <div
        ref={setNavigationElement}
        className={cx('CarouselContainer__Navigation', { 'CarouselContainer__Navigation--hidden': !navigationElement })}
      >
        <Button className="CarouselContainer__NavigationButton" kind="ghost">
          <CaretLeft16 />
        </Button>
        <div className="CarouselContainer__BulletContainer" />
        <Button className="CarouselContainer__NavigationButton" kind="ghost">
          <CaretRight16 />
        </Button>
      </div>
    </>
  );
}

function renderBullet(_: number, className: string): string {
  return `<span class="${className}"></span>`;
}

const CarouselContainerExport = React.memo(CarouselContainer);

export { CarouselContainerExport as CarouselContainer };
