/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { DemoSiteParamsAndHash } from '../demoTypes';
import {
  DEMO_PAGE_TRANSITIONS,
  DEMO_PANEL_TRANSITIONS,
  DEMO_SECTION_TRANSITIONS,
} from '../transitions/demoTransitions';

/**
 * Determines if an active transition disables the mobile view.
 */
function isMobileViewDisabledForCurrentTransition(demoSiteParamsAndHash?: DemoSiteParamsAndHash) {
  const { page, panel, section } = demoSiteParamsAndHash;

  return (
    DEMO_SECTION_TRANSITIONS[section]?.disableMobileView ||
    DEMO_PAGE_TRANSITIONS[page]?.disableMobileView ||
    DEMO_PANEL_TRANSITIONS[panel]?.disableMobileView
  );
}

/**
 * Returns a US date string using the provided date object. If you want to receive a date string from the past or future
 * pass a _days_ values less than or greater than the default value of 0.
 *
 * @param date The date object to start from.
 * @param addDays The days to go forward, or backwards, from the given date.
 */
function getDateStringFromDate(date: Date, addDays = 0) {
  const newDate = new Date(date);

  newDate.setDate(newDate.getDate() + addDays);

  return newDate.toLocaleDateString('en-us', { month: 'short', day: 'numeric', year: 'numeric' });
}

export { isMobileViewDisabledForCurrentTransition, getDateStringFromDate };
