/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { MediaPlayerOverlay } from '../../../MediaPlayerOverlay';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const BUILDING_INTEGRATIONS_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Connect to backend systems with ease',
    link: 'https://medium.com/ibm-watson/connect-to-backend-systems-and-third-party-apis-with-ease-thanks-to-watson-assistant-extensions-6d6150eaeba',
    type: 'Article',
  },
  {
    title: 'Web chat overview',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-web-chat-overview',
    type: 'Docs',
  },
];

const IBM_VIDEO_DATA = {
  title: 'Watson Assistant: Integrations',
  videoID: '1_1a9pt46t',
  wID: '1_p04xnbt5',
};

function BuildExperienceBuildingIntegrationsPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Building Integrations"
        description={
          <>
            Watson Assistant makes it easy to connect your virtual assistant to other tools, platforms, and channels.
            <br />
            <br />
            First, Watson Assistant offers a variety of{' '}
            <strong>pre-built low-code and no-code integrations with common channels</strong>, like web chat, phone
            (NICE CXone, Genesys), WhatsApp, Messenger, Slack, Microsoft Teams, and more.
            <br />
            <br />
            Second, Watson Assistant makes it <strong>
              easy to connect to backend systems and third-party APIs
            </strong>{' '}
            <em>without</em> costly or time-consuming middleware.
            <br />
            <br />
            Learn more in this walkthrough of integrations in Watson Assistant!
          </>
        }
      />
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={BUILDING_INTEGRATIONS_LINKS} />
      </GuidancePanelImplementationSection>
      <MediaPlayerOverlay ibmVideoPlayerProps={IBM_VIDEO_DATA} />
    </div>
  );
}

const BuildExperienceBuildingIntegrationsPanelConfig: SectionPanelConfig = {
  title: 'Building Integrations',
  SectionPanelComponent: BuildExperienceBuildingIntegrationsPanel,
  panel: LendyrPanel.BUILD_EXPERIENCE_BUILDING_INTEGRATIONS,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.BUILD_EXPERIENCE_BUILDING_INTEGRATIONS,
  page: LendyrPage.BUILD_EXPERIENCE_TOUR,
};

export { BuildExperienceBuildingIntegrationsPanel, BuildExperienceBuildingIntegrationsPanelConfig };
