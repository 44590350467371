/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './DemoApp.css';

import { Content } from 'carbon-components-react';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CustomLauncher } from './components/CustomLauncher';
import { CustomPanelPortalsContainer } from './components/customPanels/CustomPanelPortalsContainer';
import CustomResponsePortalsContainer from './components/CustomResponsePortalsContainer';
import { DemoAppHeaderAndGuidance } from './components/DemoAppHeaderAndGuidance';
import { GoogleMapsLocationContainer } from './components/GoogleMapsLocationContainer';
import { HomeScreenCustomContentPortal } from './components/HomeScreenCustomContentPortal';
import { WalkMeFlowContainer } from './components/WalkMeFlowContainer';
import { PageWidthContext } from './framework/contexts/PageWidthContext';
import { SegmentContext } from './framework/contexts/SegmentContext';
import { DemoPageOverlayPortalContextWrapper } from './framework/DemoPageOverlayPortalContextWrapper';
import { createPageRouteMap } from './framework/demoRoutes';
import { LendyrPage } from './framework/demoTypes';
import { useDemoLocation, useIsGuidanceComponentExpanded } from './framework/hooks/selectorHooks';
import { useWebChatInstance } from './framework/hooks/useWebChatInstance';
import { DemoAppState } from './framework/store/DemoAppState';
import { MOBILE_VIEW_WIDTH } from './framework/utils/demoConstants';
import { trackDemoPageView, trackDemoSectionPanelViewed } from './framework/utils/demoTracking';
import { getCurrentLendyrPanelTrackingName } from './framework/utils/demoUtils';
import {
  generateAndSetAnonymousUserId,
  getAnonymousUserID,
} from './web-chat-dependencies/services/anonymousUserIDStorage';
import { IS_MOBILE } from './web-chat-dependencies/utils/browserUtils';

const PAGE_ROUTE_MAP = createPageRouteMap();

/**
 * The demo app component handles rendering the active demo page the user has visited.
 */
function DemoApp() {
  const { page, panel, section, mobileView } = useDemoLocation();
  const webChatInstance = useWebChatInstance();
  const isGuidanceComponentExpanded = useIsGuidanceComponentExpanded();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const isMobileViewEnabled = useSelector((state: DemoAppState) => state.isMobileViewEnabled);
  const isEmulatedMobileView = !IS_MOBILE && mobileView && isMobileViewEnabled;
  const DemoPageComponent = getActivePageComponent(page);
  const segment = (window as any).analytics;

  // This effect will determine if the demo app is being viewed on a mobile device to determine the appearance of the
  // page in order to limit functionality that is only accessible on desktop.
  useEffect(() => {
    if (isEmulatedMobileView) {
      setPageWidth(MOBILE_VIEW_WIDTH);
      return () => {
        setPageWidth(window.innerWidth);
      };
    }
    const handleResize = () => setPageWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isEmulatedMobileView]);

  useEffect(() => {
    // Track the page view in our end users amplitude project.
    trackDemoPageView(page || 'home');
    // Identify the user and track the page view in segment.
    trackIdentifyAndPageView(page, segment);
  }, [page, segment]);

  // Send an amplitude event for every demo panel viewed.
  useEffect(() => {
    // The feature name takes priority over the panel name.
    const demoPanelTrackingName = getCurrentLendyrPanelTrackingName(section, panel);
    if (demoPanelTrackingName) {
      trackDemoSectionPanelViewed(demoPanelTrackingName);
    }
  }, [panel, section]);

  return (
    <DemoPageOverlayPortalContextWrapper isGuidanceComponentExpanded={isGuidanceComponentExpanded}>
      <PageWidthContext.Provider value={pageWidth}>
        <DemoAppHeaderAndGuidance />
        <Content
          className={cx('DemoApp', { 'DemoApp--withGuidancePanel': isGuidanceComponentExpanded })}
          id="main-content"
        >
          <SegmentContext.Provider value={segment}>
            <div
              className={cx('DemoApp__PageView', {
                'DemoApp__PageView--emulatedMobile': isEmulatedMobileView && !IS_MOBILE,
              })}
            >
              <div className="DemoApp__ViewWrapper">
                <div className="DemoApp__PageContainer">
                  <DemoPageComponent />
                </div>
                {webChatInstance && (
                  <>
                    <GoogleMapsLocationContainer instance={webChatInstance} />
                    <WalkMeFlowContainer instance={webChatInstance} />
                    <CustomPanelPortalsContainer instance={webChatInstance} />
                    <CustomResponsePortalsContainer instance={webChatInstance} />
                    <HomeScreenCustomContentPortal instance={webChatInstance} />
                    <CustomLauncher instance={webChatInstance} />
                  </>
                )}
                {!IS_MOBILE && <div className="DemoApp__WebChatCustomElement" />}
              </div>
            </div>
          </SegmentContext.Provider>
        </Content>
      </PageWidthContext.Provider>
    </DemoPageOverlayPortalContextWrapper>
  );
}

/**
 * Identify the user and track the page view in segment.
 *
 * @param demoPage the new page the user has switched to.
 * @param segment the segment object whose methods are used to fire tracking events.
 */
function trackIdentifyAndPageView(demoPage: string, segment: any) {
  if (segment) {
    // Grab the anonymousUserID to track with segment.
    const userID = getAnonymousUserID() || generateAndSetAnonymousUserId();
    // Identify the user for segment tracking.
    segment.identify(userID);

    // Track the page view in segment to show the segment + WA integration.
    segment.page({ name: demoPage || 'home' });
  }
}

/**
 * Given the page value, return the correct component. If no match, return home page.
 */
function getActivePageComponent(page: string) {
  return PAGE_ROUTE_MAP[page] || PAGE_ROUTE_MAP[LendyrPage.HOME];
}

export { DemoApp };
