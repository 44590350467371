/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelList.css';

import React from 'react';

import { HasChildren } from '../../../web-chat-dependencies/types/HasChildren';

interface GuidancePanelListProps extends HasChildren {
  /**
   * Determines if an ordered list should be rendered.
   */
  ordered?: boolean;
}

function GuidancePanelList({ children, ordered }: GuidancePanelListProps) {
  if (ordered) {
    return <ol className="GuidancePanelList">{children}</ol>;
  }

  return <ul className="GuidancePanelList">{children}</ul>;
}

function GuidancePanelListItem({ children }: HasChildren) {
  return <li className="GuidancePanelList__ListItem">{children}</li>;
}

export { GuidancePanelList, GuidancePanelListItem };
