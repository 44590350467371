/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './WatsonLogo.css';

import React from 'react';

/**
 * The IBM Watson logo as an SVG.
 */
function WatsonLogo() {
  return (
    <svg
      className="WatsonLogo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-label="IBM Watson"
      role="img"
    >
      <path d="M21.74,9.49h0A11.41,11.41,0,0,0,16,8h0a.76.76,0,1,0,0,1.51,10.15,10.15,0,0,1,1.91.21c-2.26,1.08-4.76,3.58-6.73,7a22.48,22.48,0,0,0-2,4.44A9.58,9.58,0,0,1,7,17.22a3.43,3.43,0,0,1,.28-2.66v0h0c.79-1.37,2.44-2.15,4.63-2.2a.76.76,0,0,0,.74-.78.75.75,0,0,0-.78-.74C9.19,10.88,7.1,11.92,6,13.74H6v0s0,0,0,0a4.84,4.84,0,0,0-.44,3.79,12,12,0,0,0,3.2,5.22A11.36,11.36,0,0,0,8.52,26a10,10,0,0,1-2-3.48A.75.75,0,0,0,5.57,22a.76.76,0,0,0-.49,1,11.45,11.45,0,0,0,5.18,6.38h0A11.42,11.42,0,0,0,16,30.92a11.74,11.74,0,0,0,3-.39,11.48,11.48,0,0,0,2.77-21ZM18.58,29.06a9.9,9.9,0,0,1-7.56-1h0c-.86-.49-1.21-2-.94-4a18.85,18.85,0,0,0,2.48,1.72,13.92,13.92,0,0,0,6.93,2,11,11,0,0,0,2.42-.28A9.78,9.78,0,0,1,18.58,29.06Zm6.06-4.66c-2,2-6.66,2.74-11.32.05a17.36,17.36,0,0,1-2.89-2.12,21.08,21.08,0,0,1,2.08-4.91c2.94-5.08,6.83-7.57,8.47-6.62h0A10,10,0,0,1,24.64,24.4Z" />
      <path d="M4.16,11.72,1.14,10a.76.76,0,1,0-.76,1.31L3.4,13a.86.86,0,0,0,.38.1.77.77,0,0,0,.66-.38A.76.76,0,0,0,4.16,11.72Z" />
      <path d="M8.29,7.59A.74.74,0,0,0,8.94,8a.75.75,0,0,0,.38-.1.76.76,0,0,0,.28-1l-1.74-3a.76.76,0,0,0-1-.27.75.75,0,0,0-.28,1Z" />
      <path d="M16,6.08a.76.76,0,0,0,.76-.76V1.83a.76.76,0,0,0-1.52,0V5.32A.76.76,0,0,0,16,6.08Z" />
      <path d="M22.68,7.87a.75.75,0,0,0,1-.28l1.75-3a.75.75,0,0,0-.28-1,.76.76,0,0,0-1,.27l-1.74,3A.76.76,0,0,0,22.68,7.87Z" />
      <path d="M31.9,10.25a.76.76,0,0,0-1-.27l-3,1.74a.76.76,0,0,0-.28,1,.77.77,0,0,0,.66.38.86.86,0,0,0,.38-.1l3-1.75A.76.76,0,0,0,31.9,10.25Z" />
      <rect />
    </svg>
  );
}

export { WatsonLogo };
