/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelRestartButton.css';

import { Tag } from 'carbon-components-react';
import React from 'react';
import { useDispatch } from 'react-redux';

import { RestartType } from '../../../framework/demoTypes';
import { demoActions } from '../../../framework/store/demoActions';
import { UpdateNow32 } from '../../UpdateNow32';

/**
 * The restart button for a guidance panel to control the state of the demo site and web chat. Depending on the
 * current page and panel being viewed will determine how web chat or the page is restarted.
 */
function GuidancePanelRestartButton() {
  const dispatch = useDispatch();
  return (
    <Tag
      className="GuidancePanelRestartButton"
      onClick={() => {
        dispatch(demoActions.doRestartType(RestartType.RESTART_CONVERSATION));
      }}
    >
      Restart <UpdateNow32 />
    </Tag>
  );
}

export { GuidancePanelRestartButton };
