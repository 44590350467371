/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Button, ButtonKindProps, ButtonProps } from 'carbon-components-react';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { DemoSiteParamsAndHash } from '../framework/demoTypes';
import { useExtractDemoLocationProps } from '../framework/hooks/useExtractDemoLocationProps';
import { useNavigateOnClick } from '../framework/hooks/useNavigateOnClick';
import { HasAutoFocus } from '../framework/types/HasAutoFocus';

interface DemoButtonProps extends ButtonProps, ButtonKindProps, DemoSiteParamsAndHash, HasAutoFocus {
  /**
   * By default, this the onClick callback function is fired and the user is navigated to the new location. This prop
   * will prevent navigating to the provided {@link DemoSiteParamsAndHash} when the button is clicked.
   */
  preventNavigation?: boolean;
}

/**
 * This component returns a carbon button that acts as a navigation link and prevents a page refresh. This is useful
 * when we need a carbon button to navigate a user to a new page or panel.
 */
function DemoButton(props: DemoButtonProps) {
  const [element, setElement] = useState<HTMLButtonElement>();
  const { paramsAndHash, ...demoButtonProps } = useExtractDemoLocationProps(props);
  const { preventNavigation, className, onClick, autofocus, ...buttonProps } = demoButtonProps;
  const navigateOnClick = useNavigateOnClick(onClick, paramsAndHash);
  const handleOnClick = preventNavigation ? onClick : navigateOnClick;

  // Handles placing focus on the button automatically if it's configured to do so. The carbon button doesn't have an
  // autofocus prop.
  useEffect(() => {
    if (autofocus && element) {
      // Handle focus through a setTimeout so that it takes effect when the UI is painted. Otherwise, this won't work.
      setTimeout(() => element.focus());
    }
  }, [autofocus, element]);

  return <Button {...buttonProps} ref={setElement} className={cx('DemoButton', className)} onClick={handleOnClick} />;
}

export { DemoButton, DemoButtonProps };
