/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelIntroInstructions } from '../../commonComponents/GuidancePanelIntroInstructions';
import { GuidancePanelList, GuidancePanelListItem } from '../../commonComponents/GuidancePanelList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function BuildExperienceIntroPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="The Build Experience"
        description={
          <>
            Over the past several years, we’ve seen the same problems come up in building a virtual assistant:
            <GuidancePanelList>
              <GuidancePanelListItem>
                It’s <strong>too complicated for a non-developer</strong> to build and manage.
              </GuidancePanelListItem>
              <GuidancePanelListItem>
                It <strong>takes too long to build meaningful conversations</strong> that cover all of the requirements
                in even some of the simplest use cases.
              </GuidancePanelListItem>
              <GuidancePanelListItem>
                It <strong>takes too long to improve</strong> your chat bot when you identify an issue
              </GuidancePanelListItem>
            </GuidancePanelList>
            Watson Assistant’s build experience has a dual purpose: <strong>to solve the above problems</strong>, as
            well as <strong>make it possible for every assistant to be helpful and meaningful to your customers</strong>
            . That’s why we’ve totally re-envisioned how you build virtual assistants with Watson Assistant. Check it
            out in this tour.
          </>
        }
      />
      <GuidancePanelIntroInstructions />
    </div>
  );
}

const BuildExperienceIntroPanelConfig: SectionPanelConfig = {
  title: 'The Build Experience intro',
  SectionPanelComponent: BuildExperienceIntroPanel,
  panel: LendyrPanel.HOME,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.BUILD_EXPERIENCE_INTRO,
};

export { BuildExperienceIntroPanel, BuildExperienceIntroPanelConfig };
