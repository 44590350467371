/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { FunctionComponent } from 'react';

import { AccessibleAskClarifyingQuestionsPanelConfig } from './lendyrSections/AccessibleScalableAISection/AccessibleAskClarifyingQuestionPanel';
import { AccessibleScalableAIIntroPanelConfig } from './lendyrSections/AccessibleScalableAISection/AccessibleScalableAIIntroPanel';
import { AccessibleScalableAISummaryPanelConfig } from './lendyrSections/AccessibleScalableAISection/AccessibleScalableAISummaryPanel';
import { AccessibleSearchPanelConfig } from './lendyrSections/AccessibleScalableAISection/AccessibleSearchPanel';
import { AccessibleSuggestionsPanelConfig } from './lendyrSections/AccessibleScalableAISection/AccessibleSuggestionsPanel';
import { ChangeConversationTopicPanelConfig } from './lendyrSections/AccessibleScalableAISection/ChangeConversationTopicPanel';
import { BuildExperienceBuildingConversationPanelConfig } from './lendyrSections/BuildExperience/BuildExperienceBuildingConversation';
import { BuildExperienceBuildingIntegrationsPanelConfig } from './lendyrSections/BuildExperience/BuildExperienceBuildingIntegrations';
import { BuildExperienceBuildingPhoneExperiencesPanelConfig } from './lendyrSections/BuildExperience/BuildExperienceBuildingPhoneExperiences';
import { BuildExperienceIntroPanelConfig } from './lendyrSections/BuildExperience/BuildExperienceIntroPanel';
import { BuildExperienceSummaryPanelConfig } from './lendyrSections/BuildExperience/BuildExperienceSummaryPanel';
import { DriveCustomerOutcomesIntroPanelConfig } from './lendyrSections/DriveCustomerOutcomes/DriveCustomerOutcomesIntroPanel';
import { DriveCustomerOutcomeSummaryPanelConfig } from './lendyrSections/DriveCustomerOutcomes/DriveCustomerOutcomeSummaryPanel';
import { FormAFullUserJourneyPanelConfig } from './lendyrSections/DriveCustomerOutcomes/FormAFullUserJourneyPanel';
import { UnifyDataPanelConfig } from './lendyrSections/DriveCustomerOutcomes/UnifyDataPanel';
import { AskClarifyingQuestionsPanelConfig } from './lendyrSections/EssentialsAndIndexSection/AskClarifyingQuestionsPanel';
import { ChatSurveyPanelConfig } from './lendyrSections/EssentialsAndIndexSection/ChatSurveyPanel';
import { ContentCarouselPanelConfig } from './lendyrSections/EssentialsAndIndexSection/ContentCarouselPanel';
import { ConversationalSearchPanelConfig } from './lendyrSections/EssentialsAndIndexSection/ConversationalSearchPanel';
import { CustomLauncherPanelConfig } from './lendyrSections/EssentialsAndIndexSection/CustomLauncherPanel';
import { DeepLinkingPanelConfig } from './lendyrSections/EssentialsAndIndexSection/DeepLinkingPanel';
import { DigitalAdoptionPlatformPanelConfig } from './lendyrSections/EssentialsAndIndexSection/DigitalAdoptionPlatformPanel';
import { EssentialsIntroPanelConfig } from './lendyrSections/EssentialsAndIndexSection/EssentialsIntroPanel';
import { EssentialsSummaryPanelConfig } from './lendyrSections/EssentialsAndIndexSection/EssentialsSummaryPanel';
import { FeatureListIntroPanelConfig } from './lendyrSections/EssentialsAndIndexSection/FeatureListIntroPanel';
import { FormFillingPanelConfig } from './lendyrSections/EssentialsAndIndexSection/FormFillingPanel';
import { IframePanelConfig } from './lendyrSections/EssentialsAndIndexSection/IframePanel';
import { JourneysPanelConfig } from './lendyrSections/EssentialsAndIndexSection/JourneysPanel';
import { LauncherAndHomeScreenPanelConfig } from './lendyrSections/EssentialsAndIndexSection/LauncherAndHomeScreenPanel';
import { LiveAgentEscalationPanelConfig } from './lendyrSections/EssentialsAndIndexSection/LiveAgentEscalationPanel';
import { OpenFromButtonsPanelConfig } from './lendyrSections/EssentialsAndIndexSection/OpenFromButtonsPanel';
import { PositionPanelConfig } from './lendyrSections/EssentialsAndIndexSection/PositionPanel';
import { PreChatFormPanelConfig } from './lendyrSections/EssentialsAndIndexSection/PreChatFormPanel';
import { RPAPanelConfig } from './lendyrSections/EssentialsAndIndexSection/RPAPanel';
import { SearchInSuggestionsPanelConfig } from './lendyrSections/EssentialsAndIndexSection/SearchInSuggestionsPanel';
import { SearchPanelConfig } from './lendyrSections/EssentialsAndIndexSection/SearchPanel';
import { SizePanelConfig } from './lendyrSections/EssentialsAndIndexSection/SizePanel';
import { SuggestionsPanelConfig } from './lendyrSections/EssentialsAndIndexSection/SuggestionsPanel';
import { ThemingPanelConfig } from './lendyrSections/EssentialsAndIndexSection/ThemingPanel';
import { TicketingPanelConfig } from './lendyrSections/EssentialsAndIndexSection/TicketingPanel';
import { VideoPanelConfig } from './lendyrSections/EssentialsAndIndexSection/VideoPanel';
import { GenerativeAIIntroPanelConfig } from './lendyrSections/GenerativeAI/GenerativeAIIntroPanel';
import { GenerativeAISummaryPanelConfig } from './lendyrSections/GenerativeAI/GenerativeAISummaryPanel';
import { GenerativeConversationalSearchPanelConfig } from './lendyrSections/GenerativeAI/GenerativeConversationalSearchPanel';
import { CreateActionsPanelConfig } from './lendyrSections/IterateQuickly/CreateActionsPanel';
import { DiagnosePanelConfig } from './lendyrSections/IterateQuickly/DiagnosePanel';
import { FixIssuesQuicklyPanelConfig } from './lendyrSections/IterateQuickly/FixIssuesQuicklyPanel';
import { IterateQuicklyIntroPanelConfig } from './lendyrSections/IterateQuickly/IterateQuicklyIntroPanel';
import { IterateQuicklySummaryPanelConfig } from './lendyrSections/IterateQuickly/IterateQuicklySummaryPanel';
import { PublishWithEasePanelConfig } from './lendyrSections/IterateQuickly/PublishWithEasePanel';
import { RecognizeGapsPanelConfig } from './lendyrSections/IterateQuickly/RecognizeGapsPanel';
import { RecognizeTrendsPanelConfig } from './lendyrSections/IterateQuickly/RecognizeTrendsPanel';
import { AuthenticationPanelConfig } from './lendyrSections/PhoneSection/AuthenticationPanel';
import { LiveAgentPanelConfig } from './lendyrSections/PhoneSection/LiveAgentPanel';
import { PhoneIntroPanelConfig } from './lendyrSections/PhoneSection/PhoneIntroPanel';
import { PhoneSummaryPanelConfig } from './lendyrSections/PhoneSection/PhoneSummaryPanel';
import { TrustedAIPanelConfig } from './lendyrSections/PhoneSection/TrustedAIPanel';
import { DoPanelConfig } from './lendyrSections/TellDoShowSection/DoPanel';
import { ShowFirstPanelConfig } from './lendyrSections/TellDoShowSection/ShowFirstPanel';
import { ShowSecondPanelConfig } from './lendyrSections/TellDoShowSection/ShowSecondPanel';
import { TellDoShowIntroPanelConfig } from './lendyrSections/TellDoShowSection/TellDoShowIntroPanel';
import { TellDoShowSummaryPanelConfig } from './lendyrSections/TellDoShowSection/TellDoShowSummaryPanel';
import { TellPanelConfig } from './lendyrSections/TellDoShowSection/TellPanel';
import { CrossChannelHelpPanelConfig } from './lendyrSections/UnifyPersonalizedSection/CrossChannelHelpPanel';
import { PersonalizedJourneysPanelConfig } from './lendyrSections/UnifyPersonalizedSection/PersonalizedJourneysPanel';
import { UnifyPersonalizeSummaryPanelConfig } from './lendyrSections/UnifyPersonalizedSection/UnifyPersonalizedSummaryPanel';
import { UnifyPersonalizeIntroPanelConfig } from './lendyrSections/UnifyPersonalizedSection/UnifyPersonalizeIntroPanel';

// Guidance panel configs.
const WEB_CHAT_FEATURE_INDEX_OBJECT: Record<string, SectionPanelConfig[]> = {
  Customization: [
    LauncherAndHomeScreenPanelConfig,
    ThemingPanelConfig,
    CustomLauncherPanelConfig,
    SizePanelConfig,
    PositionPanelConfig,
    PreChatFormPanelConfig,
  ],
  'Intelligent AI': [
    AskClarifyingQuestionsPanelConfig,
    SearchPanelConfig,
    SuggestionsPanelConfig,
    SearchInSuggestionsPanelConfig,
  ],
  Media: [VideoPanelConfig, IframePanelConfig],
  'External Systems Integration': [
    ContentCarouselPanelConfig,
    TicketingPanelConfig,
    RPAPanelConfig,
    LiveAgentEscalationPanelConfig,
  ],
  'Drive Self-Service': [
    JourneysPanelConfig,
    FormFillingPanelConfig,
    DeepLinkingPanelConfig,
    DigitalAdoptionPlatformPanelConfig,
    OpenFromButtonsPanelConfig,
  ],
  Feedback: [ChatSurveyPanelConfig],
};

const WEB_CHAT_ESSENTIALS_FLOW_PANEL_CONFIGS: SectionPanelConfig[] = [
  EssentialsIntroPanelConfig,
  LauncherAndHomeScreenPanelConfig,
  VideoPanelConfig,
  IframePanelConfig,
  ContentCarouselPanelConfig,
  FormFillingPanelConfig,
  JourneysPanelConfig,
  DeepLinkingPanelConfig,
  AskClarifyingQuestionsPanelConfig,
  SearchPanelConfig,
  SuggestionsPanelConfig,
  LiveAgentEscalationPanelConfig,
  ChatSurveyPanelConfig,
  EssentialsSummaryPanelConfig,
];

const TELL_DO_SHOW_TOUR_PANEL_CONFIGS: SectionPanelConfig[] = [
  TellDoShowIntroPanelConfig,
  TellPanelConfig,
  DoPanelConfig,
  ShowFirstPanelConfig,
  ShowSecondPanelConfig,
  TellDoShowSummaryPanelConfig,
];

const ACCESSIBLE_SCALABLE_AI_TOUR_PANEL_CONFIGS: SectionPanelConfig[] = [
  AccessibleScalableAIIntroPanelConfig,
  AccessibleAskClarifyingQuestionsPanelConfig,
  ChangeConversationTopicPanelConfig,
  AccessibleSearchPanelConfig,
  AccessibleSuggestionsPanelConfig,
  AccessibleScalableAISummaryPanelConfig,
];

const UNIFY_PERSONALIZED_TOUR_PANEL_CONFIGS: SectionPanelConfig[] = [
  UnifyPersonalizeIntroPanelConfig,
  CrossChannelHelpPanelConfig,
  PersonalizedJourneysPanelConfig,
  UnifyPersonalizeSummaryPanelConfig,
];

const PHONE_TOUR_PANEL_CONFIGS: SectionPanelConfig[] = [
  PhoneIntroPanelConfig,
  AuthenticationPanelConfig,
  TrustedAIPanelConfig,
  LiveAgentPanelConfig,
  PhoneSummaryPanelConfig,
];

const ITERATE_QUICKLY_CONFIGS: SectionPanelConfig[] = [
  IterateQuicklyIntroPanelConfig,
  RecognizeGapsPanelConfig,
  CreateActionsPanelConfig,
  RecognizeTrendsPanelConfig,
  DiagnosePanelConfig,
  FixIssuesQuicklyPanelConfig,
  PublishWithEasePanelConfig,
  IterateQuicklySummaryPanelConfig,
];

const DRIVE_CUSTOMER_OUTCOME_CONFIGS: SectionPanelConfig[] = [
  DriveCustomerOutcomesIntroPanelConfig,
  UnifyDataPanelConfig,
  FormAFullUserJourneyPanelConfig,
  DriveCustomerOutcomeSummaryPanelConfig,
];

const GENERATIVE_AI_CONFIGS: SectionPanelConfig[] = [
  GenerativeAIIntroPanelConfig,
  GenerativeConversationalSearchPanelConfig,
  GenerativeAISummaryPanelConfig,
];

const BUILD_EXPERIENCE_CONFIGS: SectionPanelConfig[] = [
  BuildExperienceIntroPanelConfig,
  BuildExperienceBuildingConversationPanelConfig,
  BuildExperienceBuildingIntegrationsPanelConfig,
  BuildExperienceBuildingPhoneExperiencesPanelConfig,
  BuildExperienceSummaryPanelConfig,
];

/**
 * The essentials tour and index panel share the same feature panels, so they are configured in the same map.
 */
const ESSENTIALS_SECTION_MAP = createEssentialsSectionMap();
const FEATURE_LIST_SECTION_MAP = createFeatureListSectionMap();
const TELL_DO_SHOW_SECTION_MAP = createTellDoShowSectionMap();
const ACCESSIBLE_SCALABLE_AI_SECTION_MAP = createAccessibleScalableAISectionMap();
const UNIFY_PERSONALIZED_SECTION_MAP = createUnifyPersonalizedSectionMap();
const PHONE_SECTION_MAP = createPhoneSectionMap();
const ITERATE_QUICKLY_SECTION_MAP = createIterateQuicklySectionMap();
const DRIVE_CUSTOMER_OUTCOME_SECTION_MAP = createDriveCustomerOutcomeSectionMap();
const GENERATIVE_AI_SECTION_MAP = createGenerativeAISectionMap();
const BUILD_EXPERIENCE_SECTION_MAP = createBuildExperienceSectionMap();

/**
 * The available props for a section panel component.
 */
interface SectionPanelComponentProps {
  /**
   * The callback function to fire when a sub-view in a panel is selected. This prop is specifically for panels in
   * section tours. See {@link GuidancePanelContainer}.
   */
  onSubViewChange?: (index: number) => void;

  /**
   * The index of the currently selected sub-view. This prop is specifically for panels in section tours.
   * See {@link GuidancePanelContainer}.
   */
  selectedSubViewIndex?: number;
}

/**
 * This config describes the expected behavior of the application upon visiting the given panel, such as the component
 * to render, page to visit, etc.
 */
interface SectionPanelConfig {
  /**
   * The button title of the section panel that's rendered in the index panel.
   */
  title: string;

  /**
   * The component to render when visiting a section panel.
   */
  SectionPanelComponent: FunctionComponent<SectionPanelComponentProps>;

  /**
   * The name of the panel being viewed for tracking purposes, separate from the basic panel name.
   */
  demoPanelTrackingName?: string;

  /**
   * The param value that will determine the render panel demo page.
   */
  page?: string;

  /**
   * The param value that will determine what feature panel to render.
   */
  panel?: string;

  /**
   * Determines if the restart button should be rendered for the current panel.
   */
  withRestartButton?: boolean;

  /**
   * The total number of sub-views in a panel. This will be helpful for determining when to prevent the navigation
   * buttons from changing panels when a sub-view is available.
   */
  totalSubViews?: number;
}

/**
 * Describes an object containing a section panel config object for rendering a section panel and the param values
 * of the previous and next feature panels the user can navigate to.
 */
interface SectionPanelMapConfig {
  /**
   * The feature panel config object to use to render a feature panel.
   */
  config: SectionPanelConfig;

  /**
   * The value of the feature panel the user can return to from the current one.
   */
  previousSectionPanel?: string;

  /**
   * The value of the feature panel the user can visit next after the current one.
   */
  nextSectionPanel?: string;

  /**
   * The index position of the panel config.
   */
  index?: number;
}

function createEssentialsSectionMap(): Map<string, SectionPanelMapConfig> {
  return createSectionPanelMap(WEB_CHAT_ESSENTIALS_FLOW_PANEL_CONFIGS);
}

/**
 * Returns a map of feature panel configs for the "web chat essentials" tour and index panel.
 */
function createFeatureListSectionMap(): Map<string, SectionPanelMapConfig> {
  const featurePanelMap = new Map(ESSENTIALS_SECTION_MAP);

  // Add the remaining feature panels outside the web chat essentials list here.
  const remainingFeaturePanelConfigs = [
    FeatureListIntroPanelConfig,
    SearchInSuggestionsPanelConfig,
    PreChatFormPanelConfig,
    SizePanelConfig,
    CustomLauncherPanelConfig,
    OpenFromButtonsPanelConfig,
    DigitalAdoptionPlatformPanelConfig,
    TicketingPanelConfig,
    RPAPanelConfig,
    PositionPanelConfig,
    ThemingPanelConfig,
    ConversationalSearchPanelConfig,
  ];

  remainingFeaturePanelConfigs.forEach(featurePanelConfig => {
    featurePanelMap.set(featurePanelConfig.panel, { config: featurePanelConfig });
  });

  return featurePanelMap;
}

function createTellDoShowSectionMap(): Map<string, SectionPanelMapConfig> {
  return createSectionPanelMap(TELL_DO_SHOW_TOUR_PANEL_CONFIGS);
}

function createAccessibleScalableAISectionMap(): Map<string, SectionPanelMapConfig> {
  return createSectionPanelMap(ACCESSIBLE_SCALABLE_AI_TOUR_PANEL_CONFIGS);
}

function createUnifyPersonalizedSectionMap(): Map<string, SectionPanelMapConfig> {
  return createSectionPanelMap(UNIFY_PERSONALIZED_TOUR_PANEL_CONFIGS);
}

function createPhoneSectionMap(): Map<string, SectionPanelMapConfig> {
  return createSectionPanelMap(PHONE_TOUR_PANEL_CONFIGS);
}

function createIterateQuicklySectionMap(): Map<string, SectionPanelMapConfig> {
  return createSectionPanelMap(ITERATE_QUICKLY_CONFIGS);
}

function createDriveCustomerOutcomeSectionMap(): Map<string, SectionPanelMapConfig> {
  return createSectionPanelMap(DRIVE_CUSTOMER_OUTCOME_CONFIGS);
}

function createGenerativeAISectionMap(): Map<string, SectionPanelMapConfig> {
  return createSectionPanelMap(GENERATIVE_AI_CONFIGS);
}

function createBuildExperienceSectionMap(): Map<string, SectionPanelMapConfig> {
  return createSectionPanelMap(BUILD_EXPERIENCE_CONFIGS);
}

/**
 * Returns a map of feature names to their feature panel configs in order to navigate back and forth between feature
 * panels in a tour.
 */
function createSectionPanelMap(tour: SectionPanelConfig[]) {
  const featurePanelMap = new Map();

  tour.forEach((featurePanelConfig, index) => {
    const mapConfig: SectionPanelMapConfig = { config: featurePanelConfig };

    mapConfig.index = index;

    mapConfig.previousSectionPanel = tour[index - 1]?.panel;

    mapConfig.nextSectionPanel = tour[index + 1]?.panel;

    featurePanelMap.set(featurePanelConfig.panel, mapConfig);
  });

  return featurePanelMap;
}

export {
  SectionPanelConfig,
  SectionPanelMapConfig,
  SectionPanelComponentProps,
  WEB_CHAT_FEATURE_INDEX_OBJECT,
  WEB_CHAT_ESSENTIALS_FLOW_PANEL_CONFIGS,
  ESSENTIALS_SECTION_MAP,
  FEATURE_LIST_SECTION_MAP,
  TELL_DO_SHOW_TOUR_PANEL_CONFIGS,
  TELL_DO_SHOW_SECTION_MAP,
  ACCESSIBLE_SCALABLE_AI_TOUR_PANEL_CONFIGS,
  ACCESSIBLE_SCALABLE_AI_SECTION_MAP,
  UNIFY_PERSONALIZED_TOUR_PANEL_CONFIGS,
  UNIFY_PERSONALIZED_SECTION_MAP,
  PHONE_TOUR_PANEL_CONFIGS,
  PHONE_SECTION_MAP,
  ITERATE_QUICKLY_CONFIGS,
  ITERATE_QUICKLY_SECTION_MAP,
  DRIVE_CUSTOMER_OUTCOME_CONFIGS,
  DRIVE_CUSTOMER_OUTCOME_SECTION_MAP,
  GENERATIVE_AI_CONFIGS,
  GENERATIVE_AI_SECTION_MAP,
  BUILD_EXPERIENCE_CONFIGS,
  BUILD_EXPERIENCE_SECTION_MAP,
};
