/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2020, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * Miscellaneous utilities for dealing with the browser.
 */

import { detect } from 'detect-browser';
import memoizeOne from 'memoize-one';

const browser = detect();
const BROWSER_INFO = browser;

// The user agent string in version 13 of the operating system no longer distinguishes between macOS and iPads so
// need add an extra check that may not be reliable in the future.
const IS_IPAD = /iPad/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
const IS_IOS = browser?.os === 'iOS';
const IS_ANDROID = browser?.os === 'Android OS';
const IS_MOBILE = IS_IOS || IS_ANDROID || IS_IPAD;
// The width and height checks here are how we differentiate between mobile android devices and tablets. Eventually new
// phones may get wide enough that the width check needs to be increased.
const IS_PHONE = IS_MOBILE && (window.screen.width < 500 || window.screen.height < 500);
const IS_TABLET = IS_MOBILE && !IS_PHONE;

const IS_SESSION_STORAGE = memoizeOne(isSessionStorageAvailable);

/**
 * Gets the hostname and pathname of the page web chat is injected in.
 */
function getURLHostnameAndPath() {
  const { origin, pathname } = window.location;
  return origin + pathname;
}

/**
 * Determines if the host page is using a secure protocol.
 */
function isSecureHost() {
  return window.location.protocol === 'https:';
}

/**
 * In some conditions (iFrames) window.sessionStorage is DEFINED, but not accessible.
 * Rather than doing window.sessionStorage || alternate checks, this actually checks if sessionStorage
 * can be used.
 *
 * @returns If window.sessionStorage is read and writeable.
 */
function isSessionStorageAvailable() {
  let isAvailable = false;
  try {
    window.sessionStorage.setItem('web-chat-test-item', 'true');
    window.sessionStorage.getItem('web-chat-test-item');
    window.sessionStorage.removeItem('web-chat-test-item');
    isAvailable = true;
  } catch {
    // Ignore.
  }
  return isAvailable;
}

export {
  IS_IOS,
  IS_ANDROID,
  IS_MOBILE,
  IS_PHONE,
  IS_TABLET,
  BROWSER_INFO,
  getURLHostnameAndPath,
  isSecureHost,
  IS_SESSION_STORAGE,
};
