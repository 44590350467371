/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { ACCESSIBLE_SCALABLE_AI_SECTION_MAP } from '../../../components/demoGuidanceComponent/featureGuidancePanelUtils';
import { LendyrPanel } from '../../demoTypes';
import { navigateTo } from '../../utils/demoUtils';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const ACCESSIBLE_CHANGE_CONVERSATION_TOPIC_TRANSITION_CONFIG: DemoTransitionConfig = {
  restartOptions: {
    onClickRestart: () => {
      // The "change conversation topic" panel depends on the previous panel, so the user should be navigated there
      // on restart.
      const { previousSectionPanel } = ACCESSIBLE_SCALABLE_AI_SECTION_MAP.get(
        LendyrPanel.ACCESSIBLE_CHANGE_CONVERSATION_TOPIC,
      );
      navigateTo({ panel: previousSectionPanel });
    },
  },
};

export { ACCESSIBLE_CHANGE_CONVERSATION_TOPIC_TRANSITION_CONFIG };
