/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidanceMobileViewWarningInlineNotification } from '../../commonComponents/GuidanceMobileViewWarningInlineNotification';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const DIGITAL_ADOPTION_PLATFORM_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Handle custom responses',
    link: 'https://web-chat.global.assistant.watson.cloud.ibm.com/docs.html?to=api-events#customresponse',
    type: 'Docs',
  },
  {
    title: 'Implement custom responses',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/custom-response',
    type: 'Tutorial',
  },
];

function DigitalAdoptionPlatformPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Digital Adoption Platform Integration"
        description="The best assistant is a teacher that shows your users how to self-serve. Use Watson Assistant to provide an overlay on the underlying app and give users guided walkthroughs of functionality.  In this instance, the assistant has been integrated with WalkMe, a digital adoption platform. When the assistant recognizes the user asking for help disputing a charge, it triggers an appropriate tour to show the user how to go about disputing their charge."
      />
      <GuidanceMobileViewWarningInlineNotification />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following: <GuidancePanelTextSnippet text="I don't understand a charge I saw on the app" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Click "Show me".</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Use the WalkMe tour to dispute the most recent charge.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <DigitalAdoptionPlatformImplementationSection />
    </div>
  );
}

function DigitalAdoptionPlatformImplementationSection() {
  return (
    <GuidancePanelImplementationSection>
      <GuidancePanelImplementationTile codeNecessary data={DIGITAL_ADOPTION_PLATFORM_CODE_LINKS} />
    </GuidancePanelImplementationSection>
  );
}

const DigitalAdoptionPlatformPanelConfig: SectionPanelConfig = {
  title: 'Digital Adoption Platform Integration',
  SectionPanelComponent: DigitalAdoptionPlatformPanel,
  panel: LendyrPanel.DIGITAL_ADOPTION_PLATFORM_INTEGRATION,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.DIGITAL_ADOPTION_PLATFORM_INTEGRATION,
};

export {
  DigitalAdoptionPlatformPanel,
  DigitalAdoptionPlatformImplementationSection,
  DigitalAdoptionPlatformPanelConfig,
};
