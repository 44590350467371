/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { DeepPartial } from 'ts-essentials';

import { DemoSiteParamsAndHash, RestartType } from '../demoTypes';
import { DemoAppState } from './DemoAppState';
import { CreditCardsPageState, HomeLoansPageState, PersistedToBrowserSessionStorage } from './demoStoreTypes';

enum DemoActions {
  CHANGE_STATE = 'CHANGE_STATE',
  SET_DEMO_SITE_PARAMS = 'SET_DEMO_SITE_PARAMS',
  SET_STATE_PROPERTY = 'SET_STATE_PROPERTY',
  SET_CREDIT_CARDS_PAGE_STATE_PROPERTY = 'SET_CREDIT_CARDS_PAGE_STATE_PROPERTY',
  SET_HOME_LOANS_PAGE_STATE_PROPERTY = 'SET_HOME_LOANS_PAGE_STATE_PROPERTY',
  DO_RESTART_TYPE = 'DO_RESTART_TYPE',
  UPDATE_SESSION_STORAGE_STATE = 'UPDATE_SESSION_STORAGE_STATE',
  UPDATE_SESSION_STORAGE_STATE_SITE_DATA = 'UPDATE_SESSION_STORAGE_STATE_SITE_DATA',
}

const demoActions = {
  changeState(partialState: DeepPartial<DemoAppState>) {
    return { type: DemoActions.CHANGE_STATE, partialState };
  },

  setDemoSiteParamsAndHash(demoSiteParamsAndHash: DemoSiteParamsAndHash) {
    return { type: DemoActions.SET_DEMO_SITE_PARAMS, demoSiteParamsAndHash };
  },

  setStateProperty: <TPropertyName extends keyof DemoAppState>(
    propertyName: TPropertyName,
    propertyValue: DemoAppState[TPropertyName],
  ) => {
    return { type: DemoActions.SET_STATE_PROPERTY, propertyName, propertyValue };
  },

  setCreditCardPageStateProperty: <TPropertyName extends keyof CreditCardsPageState>(
    propertyName: TPropertyName,
    propertyValue: CreditCardsPageState[TPropertyName],
  ) => {
    return { type: DemoActions.SET_CREDIT_CARDS_PAGE_STATE_PROPERTY, propertyName, propertyValue };
  },

  setHomeLoansPageStateProperty: <TPropertyName extends keyof HomeLoansPageState>(
    propertyName: TPropertyName,
    propertyValue: HomeLoansPageState[TPropertyName],
  ) => {
    return { type: DemoActions.SET_HOME_LOANS_PAGE_STATE_PROPERTY, propertyName, propertyValue };
  },

  doRestartType(restartType: RestartType) {
    return { type: DemoActions.DO_RESTART_TYPE, restartType };
  },

  updateSessionStorageState<TDemoSiteStorage>(
    partial: DeepPartial<PersistedToBrowserSessionStorage<TDemoSiteStorage>>,
  ) {
    return { type: DemoActions.UPDATE_SESSION_STORAGE_STATE, partial };
  },

  updateSessionStorageStateSiteData<TDemoSiteStorage>(
    partial: DeepPartial<PersistedToBrowserSessionStorage<TDemoSiteStorage>['siteData']>,
  ) {
    return { type: DemoActions.UPDATE_SESSION_STORAGE_STATE_SITE_DATA, partial };
  },
};

export { DemoActions, demoActions };
