/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { LendyrPage, LendyrPanel, LendyrSection } from '../demoTypes';
import { DemoTransitionConfig } from './demoTransitionTypes';
import { CREDIT_CARDS_TRANSITION_CONFIG } from './pageTransitions/creditCardsTransitionConfig';
import { FAQ_TRANSITION_CONFIG } from './pageTransitions/faqTransitionConfig';
import { HOME_LOANS_TRANSITION_CONFIG } from './pageTransitions/homeLoansTransitionConfig';
import { ACCESSIBLE_CHANGE_CONVERSATION_TOPIC_TRANSITION_CONFIG } from './panelTransitions/accessibleChangeConversationTopicTransitionConfig';
import { CHAT_SURVEY_TRANSITION_CONFIG } from './panelTransitions/chatSurveyTransitionConfig';
import { CUSTOM_LAUNCHER_TRANSITION_CONFIG } from './panelTransitions/customLauncherTransitionConfig';
import { DIGITAL_ADOPTION_PLATFORM_TRANSITION_CONFIG } from './panelTransitions/digitalAdoptionPlatformTransitionConfig';
import { LAUNCHER_AND_HOME_SCREEN_TRANSITION_CONFIG } from './panelTransitions/launcherAndHomeScreenTransitionConfig';
import { OPEN_FROM_BUTTONS_TRANSITION_CONFIG } from './panelTransitions/openFromButtonsTransitionConfig';
import { POSITION_TRANSITION_CONFIG } from './panelTransitions/positionTransitionConfig';
import { PRE_CHAT_FORM_TRANSITION_CONFIG } from './panelTransitions/preChatFormTransitionConfig';
import { SHOW_FIRST_TRANSITION_CONFIG } from './panelTransitions/showFirstTransitionConfig';
import { SHOW_SECOND_TRANSITION_CONFIG } from './panelTransitions/showSecondTransitionConfig';
import { SIZE_TRANSITION_CONFIG } from './panelTransitions/sizeTransitionConfig';
import { THEMING_TRANSITION_CONFIG } from './panelTransitions/themingTransitionConfig';
import { ACCESSIBLE_SCALABLE_AI_TRANSITION_CONFIG } from './sectionTransitions/accessibleScalableAITransitionConfig';
import { ESSENTIALS_TRANSITION_CONFIG } from './sectionTransitions/essentialsTransitionConfig';
import { FEATURE_LIST_TRANSITION_CONFIG } from './sectionTransitions/featureListTransitionConfig';
import { HOME_SECTION_TRANSITION_CONFIG } from './sectionTransitions/homeSectionTransitionConfig';
import { PHONE_TRANSITION_CONFIG } from './sectionTransitions/phoneTransitionConfig';
import { TELL_DO_SHOW_TRANSITION_CONFIG } from './sectionTransitions/tellDoShowTransitonConfig';

const DEMO_PAGE_TRANSITIONS: Record<string, DemoTransitionConfig> = {
  [LendyrPage.CREDIT_CARDS]: CREDIT_CARDS_TRANSITION_CONFIG,
  [LendyrPage.HOME_LOANS]: HOME_LOANS_TRANSITION_CONFIG,
  [LendyrPage.FAQ]: FAQ_TRANSITION_CONFIG,
  [LendyrPage.EMAIL]: { withoutWebChat: true },
  [LendyrPage.PHONE_TOUR]: { withoutWebChat: true },
  [LendyrPage.ITERATE_QUICKLY_TOUR]: { withoutWebChat: true },
  [LendyrPage.DRIVE_CUSTOMER_OUTCOMES_TOUR]: { withoutWebChat: true },
  [LendyrPage.GENERATIVE_AI_TOUR]: { withoutWebChat: true },
  [LendyrPage.BUILD_EXPERIENCE_TOUR]: { withoutWebChat: true },
};

const DEMO_PANEL_TRANSITIONS: Record<string, DemoTransitionConfig> = {
  [LendyrPanel.LAUNCHER_AND_HOME_SCREEN]: LAUNCHER_AND_HOME_SCREEN_TRANSITION_CONFIG,
  [LendyrPanel.POSITION]: POSITION_TRANSITION_CONFIG,
  [LendyrPanel.SIZE]: SIZE_TRANSITION_CONFIG,
  [LendyrPanel.CUSTOM_LAUNCHER]: CUSTOM_LAUNCHER_TRANSITION_CONFIG,
  [LendyrPanel.PRE_CHAT_FORM]: PRE_CHAT_FORM_TRANSITION_CONFIG,
  [LendyrPanel.ACCESSIBLE_CHANGE_CONVERSATION_TOPIC]: ACCESSIBLE_CHANGE_CONVERSATION_TOPIC_TRANSITION_CONFIG,
  [LendyrPanel.OPEN_FROM_BUTTONS]: OPEN_FROM_BUTTONS_TRANSITION_CONFIG,
  [LendyrPanel.SHOW_1]: SHOW_FIRST_TRANSITION_CONFIG,
  [LendyrPanel.SHOW_2]: SHOW_SECOND_TRANSITION_CONFIG,
  [LendyrPanel.CHAT_SURVEY]: CHAT_SURVEY_TRANSITION_CONFIG,
  [LendyrPanel.THEMING]: THEMING_TRANSITION_CONFIG,
  [LendyrPanel.DIGITAL_ADOPTION_PLATFORM_INTEGRATION]: DIGITAL_ADOPTION_PLATFORM_TRANSITION_CONFIG,
  [LendyrPanel.FORM_FILLING]: { disableMobileView: true },
};

const DEMO_SECTION_TRANSITIONS: Record<string, DemoTransitionConfig> = {
  [LendyrSection.HOME]: HOME_SECTION_TRANSITION_CONFIG,
  [LendyrSection.TELL_DO_SHOW]: TELL_DO_SHOW_TRANSITION_CONFIG,
  [LendyrSection.ACCESSIBLE_SCALABLE_AI]: ACCESSIBLE_SCALABLE_AI_TRANSITION_CONFIG,
  [LendyrSection.PHONE]: PHONE_TRANSITION_CONFIG,
  [LendyrSection.ESSENTIALS]: ESSENTIALS_TRANSITION_CONFIG,
  [LendyrSection.INDEX]: FEATURE_LIST_TRANSITION_CONFIG,
  [LendyrSection.ITERATE_QUICKLY]: { disableMobileView: true },
  [LendyrSection.DRIVE_CUSTOMER_OUTCOMES]: { disableMobileView: true },
  [LendyrSection.GENERATIVE_AI]: { disableMobileView: true },
  [LendyrSection.BUILD_EXPERIENCE]: { disableMobileView: true },
};

export { DEMO_PAGE_TRANSITIONS, DEMO_PANEL_TRANSITIONS, DEMO_SECTION_TRANSITIONS };
