/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2021, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * This hooks runs once when a component is first mounted. It's main purpose is to hide the eslint warning without
 * risking someone using the callback with values that could change after mounting.
 */

import React, { EffectCallback, useEffect } from 'react';

const useOnMount = (callback: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, []);
};

export { useOnMount };
