/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './IBMVideoPlayer.css';

import React from 'react';

interface IBMVideoPlayerProps {
  /**
   * The iframe title.
   */
  title: string;

  /**
   * The id of the video to embed. This is the entryID in the embed url.
   */
  videoID: string;

  /**
   * This is included in the video embed url. Without this the video won't load.
   */
  wID: string;
}

/**
 * This component is for rendering IBM MediaCenter videos. Read the documentation on MediaCenter embed url:
 * https://w3.ibm.com/w3publisher/mediacenter/technical-documentation/embed-audio-video-iframe
 */
function IBMMediaCenterIFrame({ title, videoID, wID }: IBMVideoPlayerProps) {
  return (
    <div className="IBMVideoPlayer__Wrapper">
      <iframe
        title={title}
        src={`https://cdnapisec.kaltura.com/p/1773841/sp/177384100/embedIframeJs/uiconf_id/39954662/partner_id/1773841?iframeembed=true&playerId=kaltura_player&entry_id=${videoID}&flashvars[akamaiHD.loadingPolicy]=preInitialize&amp;flashvars[akamaiHD.asyncInit]=true&amp;flashvars[twoPhaseManifest]=true&amp;flashvars[streamerType]=hdnetworkmanifest&amp;flashvars[localizationCode]=en&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=${wID}`}
        width="100%"
        height="100%"
        allow="autoplay *; fullscreen *; encrypted-media *"
        sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
        allowFullScreen
      />
    </div>
  );
}

export { IBMMediaCenterIFrame, IBMVideoPlayerProps };
