/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { PedestrianFamily32, PlanePrivate32, Purchase32, Tools32 } from '@carbon/icons-react';
import React from 'react';

import lendyrSideImageFamily from '../assets/lendyr-side-image-family.jpg';
import { DemoPage } from '../components/DemoPage';
import { LendyrButton } from '../components/LendyrButton';
import { LendyrPageType } from '../framework/demoTypes';
import { CardLinkComponentProps } from '../framework/types/CardLinkComponentProps';

const CARD_LINKS: CardLinkComponentProps[] = [
  { Icon: Tools32, title: 'Executive Financial Planning' },
  { Icon: Purchase32, title: 'Family Office' },
  { Icon: PedestrianFamily32, title: 'Credit Consolidation' },
  { Icon: PlanePrivate32, title: 'Travel & Wedding' },
];

function PersonalLoansPage() {
  return (
    <DemoPage
      title="Individual Services"
      description="Take control of your finances."
      type={LendyrPageType.SECONDARY}
      cardLinks={CARD_LINKS}
      MainContent={
        <div className="DemoPage__Body">
          <div className="DemoPage__SectionTextWrapper">
            <div className="DemoPage__SectionWrapper">
              <div className="DemoPageCommon__Title">Executive Financial Planning</div>
              <div className="DemoPageCommon__Description">
                Executive financial planning helps coordinate your financial life across varying levels of wealth and
                financial complexity.
              </div>
              <LendyrButton tertiary>View details</LendyrButton>
            </div>
            <div className="DemoPage__SectionWrapper">
              <div className="DemoPageCommon__Title">Family Office</div>
              <div className="DemoPageCommon__Description">
                Strategic integration to streamline your financial life and gain peace of mind
              </div>
              <LendyrButton tertiary>View details</LendyrButton>
            </div>
            <div className="DemoPage__SectionWrapper">
              <div className="DemoPageCommon__Title">Credit Consolidation</div>
              <div className="DemoPageCommon__Description">
                Consolidate your debt today and make fewer payments with less interest.
              </div>
              <LendyrButton tertiary>View details</LendyrButton>
            </div>
          </div>
          <img className="DemoPage__CreditCardImage" src={lendyrSideImageFamily} alt="family" />
        </div>
      }
    />
  );
}

export { PersonalLoansPage };
