/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './HomeSectionComponent.css';

import { Launch24, ListNumbered16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import React, { useState } from 'react';

import { WatsonLogo } from '../../../assets/WatsonLogo';
import { LendyrSection } from '../../../framework/demoTypes';
import { useDemoLocation } from '../../../framework/hooks/selectorHooks';
import { BREAKPOINT_1055 } from '../../../framework/utils/demoConstants';
import { useOnMount } from '../../../web-chat-dependencies/hooks/useOnMount';
import { IS_MOBILE } from '../../../web-chat-dependencies/utils/browserUtils';
import { DemoClickableTile } from '../../DemoClickableTile';
import { GuidancePanelContentContainer } from '../commonComponents/GuidancePanelContentContainer';
import { GuidancePanelHeading } from '../commonComponents/GuidancePanelHeading';
import { HomeSectionGuidedTours } from './HomeSectionGuidedTours';
import { HomeSectionOverlay } from './HomeSectionOverlay';

interface HomeSectionComponentProps {
  /**
   * Indicates that the overlay menu can be open if the guidance panel is expanded.
   */
  isGuidancePanelExpanded: boolean;

  /**
   * The callback function to fire when the overlay menu is closed.
   */
  onOverlayMenuClose: () => void;
}

/**
 * This is the home panel for the feature guidance panel where the user's demo site experience will start from. They
 * will have the ability to select going through a feature tour, or go directly to a feature panel from the feature
 * index tile.
 */
function HomeSectionComponent({ isGuidancePanelExpanded, onOverlayMenuClose }: HomeSectionComponentProps) {
  const { section } = useDemoLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isGuidanceOverlayVisible = windowWidth <= BREAKPOINT_1055;
  const isOverlayMenuOpened = !IS_MOBILE && !section && !isGuidanceOverlayVisible && isGuidancePanelExpanded;

  // This effect is for tracking the width of the screen. This component tracks the page width itself instead of
  // relying on usePageWidth hook which can have its value set to the mobile view page width. This component should not
  // be affected by mobile view.
  useOnMount(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <GuidancePanelContentContainer className="HomeGuidancePanel">
      <div className="GuidancePanel__MainContent">
        <GuidancePanelHeading
          title={
            <>
              <WatsonLogo />
              Welcome!
            </>
          }
          description="See IBM Watson Assistant in action on Lendyr Bank's fictional website by trying the guided tours to the right."
        />
        <DemoClickableTile section={LendyrSection.INDEX}>
          <div className="HomeGuidancePanel__TileContent">
            <div className="HomeGuidancePanel__TileTitle">
              <ListNumbered16 />
              Feature List
            </div>
            <div className="HomeGuidancePanel__TileDescription">
              Take a look at some of the key features of Watson Assistant.
            </div>
          </div>
        </DemoClickableTile>
        {!isOverlayMenuOpened && (
          <>
            <div className="GuidancePanelDivider" />
            <HomeSectionGuidedTours />
          </>
        )}
      </div>
      <div className="GuidancePanel__BottomContent">
        <Button onClick={onOverlayMenuClose} kind="ghost">
          View Lendyr Site
        </Button>
        <Button
          className="HomeGuidancePanel__TrialButton"
          as="a"
          href="https://ibm.biz/BdP9qN"
          target="_blank"
          renderIcon={Launch24}
        >
          Start free trial today
        </Button>
      </div>
      {isOverlayMenuOpened && <HomeSectionOverlay />}
    </GuidancePanelContentContainer>
  );
}

export { HomeSectionComponent };
