/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { ENV_VARIABLES } from '../../../../framework/utils/environmentVariables';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const AUTHENTICATION_PANEL_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Build a custom extension',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-build-custom-extension',
    type: 'Docs',
  },
  {
    title: 'Use a custom extension',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-call-extension',
    type: 'Docs',
  },
];

const PHONE_NUMBER = ENV_VARIABLES.DEMO_PHONE_TOUR_NUMBER;

function AuthenticationPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Authentication"
        description={
          <>
            Watson Assistant can integrate with your CRMs, backend databases, and analytics tools to authenticate users
            and personalize the help offered.
            <br />
            <br />
            In this flow, the assistant authenticates the user, greets them by number, and provides information from
            their account details.
          </>
        }
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>Call the number: {PHONE_NUMBER}</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Say the following:
          <GuidancePanelTextSnippet text="My account number is 4532 9876" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          When prompted, say the PIN number: <GuidancePanelTextSnippet text="9348" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Say the following: <GuidancePanelTextSnippet text="What's my checking account balance?" />
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile codeNecessary data={AUTHENTICATION_PANEL_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const AuthenticationPanelConfig: SectionPanelConfig = {
  title: 'Authentication',
  SectionPanelComponent: AuthenticationPanel,
  page: LendyrPage.PHONE_TOUR,
  panel: LendyrPanel.PHONE_AUTHENTICATION,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.PHONE_AUTHENTICATION,
};

export { AuthenticationPanel, AuthenticationPanelConfig };
