/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const ASK_CLARIFYING_QUESTIONS_NO_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Enable clarifying questions',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-understand-questions#understand-questions-ask-clarifying-question',
    type: 'Docs',
  },
];

function AskClarifyingQuestionsPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Ask Clarifying Questions"
        description="Watson Assistant has many ways to ensure that your users can always find a path to their solution. When the assistant isn't confident, it asks the user a clarifying question to determine the best action to take."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          To showcase how intelligent the assistant is when it comes to finding the best answer, let's assume you're
          looking for financial aid for college.
          <br />
          <br />
          Start by entering the following:
          <GuidancePanelTextSnippet text="Loan application" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Select "Applying for a home loan".</GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <AskClarifyingQuestionsPanelImplementationSection />
    </div>
  );
}

function AskClarifyingQuestionsPanelImplementationSection() {
  return (
    <GuidancePanelImplementationSection>
      <GuidancePanelImplementationTile data={ASK_CLARIFYING_QUESTIONS_NO_CODE_LINKS} />
    </GuidancePanelImplementationSection>
  );
}

const AskClarifyingQuestionsPanelConfig: SectionPanelConfig = {
  title: 'Ask Clarifying Questions',
  SectionPanelComponent: AskClarifyingQuestionsPanel,
  panel: LendyrPanel.ASK_CLARIFYING_QUESTIONS,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.ASK_CLARIFYING_QUESTIONS,
};

export {
  AskClarifyingQuestionsPanel,
  AskClarifyingQuestionsPanelImplementationSection,
  AskClarifyingQuestionsPanelConfig,
};
