/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { PANEL_SPECIFIC_PAGE_MAP } from '../../../../framework/utils/demoConstants';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { DeepLinkingPanelContent } from '../EssentialsAndIndexSection/DeepLinkingPanel';

function CrossChannelHelpPanel() {
  return <DeepLinkingPanelContent title="Cross-Channel Help" />;
}

const CrossChannelHelpPanelConfig: SectionPanelConfig = {
  title: 'Cross Channel Help',
  SectionPanelComponent: CrossChannelHelpPanel,
  page: PANEL_SPECIFIC_PAGE_MAP[LendyrPanel.DEEP_LINKING],
  panel: LendyrPanel.UNIFY_CROSS_CHANNEL_HELP,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.UNIFY_CROSS_CHANNEL_HELP,
};

export { CrossChannelHelpPanel, CrossChannelHelpPanelConfig };
