/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2019, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * Miscellaneous utils for dealing with promises.
 */

/**
 * This is an async function that will simply wait for the given amount of time.
 *
 * @param milliseconds The amount of time in milliseconds to wait.
 */
async function sleep(milliseconds: number) {
  await new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });
}

/**
 * This function returns a Promise that will be resolved if the provided Promise has resolved within the duration specified.
 * Otherwise the promise will be rejected.
 *
 * @param promise The Promise which will be resolved or timed out.
 * @param duration The duration of the timeout in milliseconds.
 */
function resolveOrTimeout<T>(promise: Promise<T>, duration: number): Promise<T> {
  // Create a promise that rejects in <ms> milliseconds
  const timeout = new Promise<T>((resolve, reject) => {
    setTimeout(() => {
      reject(new Error(`The operation timed out after ${duration}ms`));
    }, duration);
  });

  // Returns a race between the timeout and the original in promise
  return Promise.race([promise, timeout]);
}

export { sleep, resolveOrTimeout };
