/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2020, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import ObjectMap from '../types/ObjectMap';

/**
 * Used in loadWAC to determine the right route for the public configs, the /message endpoint, and the history endpoint
 * to use. It has been moved to loadWac so that once watsonplatform.net is deprecated this is the only file that needs
 * to be changed and any version after the initial moving of this mapping will still work.
 */
const REGION_MAP: ObjectMap<string> = {
  dev: 'integrations.us-south.assistant.dev.watson.appdomain.cloud',
  staging: 'integrations.us-south.assistant.test.watson.appdomain.cloud',
  'us-south': 'integrations.us-south.assistant.watson.appdomain.cloud',
  'us-east': 'integrations.us-east.assistant.watson.appdomain.cloud',
  'jp-tok': 'integrations.jp-tok.assistant.watson.appdomain.cloud',
  'au-syd': 'integrations.au-syd.assistant.watson.appdomain.cloud',
  'eu-gb': 'integrations.eu-gb.assistant.watson.appdomain.cloud',
  'eu-de': 'integrations.eu-de.assistant.watson.appdomain.cloud',
  'kr-seo': 'integrations.kr-seo.assistant.watson.appdomain.cloud',
};

export { REGION_MAP };
