/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import cloneDeep from 'lodash/cloneDeep';
import { Store } from 'redux';

import { postChatSurveySetup } from './components/customPanels/demoCustomPanelUtils';
import {
  demoDisputeChargeTourStepHandler,
  demoFormFillingActionHandler,
  demoTrackingConnectedToAgentHandler,
  demoTrackingJourneysTourHandler,
  demoTrackingPreReceiveHandler,
  demoTrackingPreSendHandler,
  restartConversationHandler,
} from './framework/demoHandlers';
import { demoHistory } from './framework/demoHistory';
import { CustomPanelType, DemoFeaturePanelsTrackingNames, RestartType } from './framework/demoTypes';
import { injectWalkMeScript } from './framework/injectWalkMeScript';
import { demoActions } from './framework/store/demoActions';
import { DemoAppState } from './framework/store/DemoAppState';
import {
  DEMO_PAGE_TRANSITIONS,
  DEMO_PANEL_TRANSITIONS,
  DEMO_SECTION_TRANSITIONS,
} from './framework/transitions/demoTransitions';
import {
  DemoPanelTransitionConfig,
  DemoTransitionCleanupConfig,
  DemoTransitionConfig,
} from './framework/transitions/demoTransitionTypes';
import { WALK_ME_FLOW_ID } from './framework/utils/demoConstants';
import {
  DEFAULT_SESSION_STORAGE_STATE,
  getLocalStorageState,
  getSessionStorageState,
  updateLocalStorage,
  updateSessionStorage,
} from './framework/utils/demoStorageUtils';
import { trackDemoFeatureFinished } from './framework/utils/demoTracking';
import { getDemoSiteParamsAndHash, navigateToPanelSpecificPage } from './framework/utils/demoUtils';
import { DEFAULT_WEB_CHAT_CONFIG_OPTIONS } from './framework/utils/demoWebChatConfigOptions';
import { isMobileViewDisabledForCurrentTransition } from './framework/utils/miscUtils';
import { destroyWebChat } from './framework/webChatSetup';
import { LendyrAppState } from './lendyr/store/LendyrAppState';
import { DEFAULT_LENDYR_SESSION_STORAGE_STATE } from './lendyr/utils/lendyrConstants';
import { MeaningfulConversationType } from './submodules/wa-fd-types-public/event-types';
import {
  BusEventPreReceive,
  BusEventReceive,
  BusEventTourStep,
  BusEventType,
} from './web-chat-dependencies/types/eventBusTypes';
import { WatsonAssistantChatInstance } from './web-chat-dependencies/types/WatsonAssistantChatInstance';
import { IS_MOBILE } from './web-chat-dependencies/utils/browserUtils';
import { MEANINGFUL_CONVERSATION_ATTRIBUTE } from './web-chat-dependencies/utils/constants';
import { isOrInsideOfClass, isOrInsideOfPredicate } from './web-chat-dependencies/utils/domUtils';

/**
 * This demo controller handles managing demo site transitions. A transition can occur on a page, section, and panel
 * change. A transition can be configured to run side effects to change the application state and provides a mechanism
 * to undo the side effects upon a new transition. Side effects that don't require web chat will be fired during a
 * transition. Managing side effects through transitions instead of components gives us full control of how and when
 * side effects should occur. This also provides us a way to write custom restart behavior for a transition upon the
 * user clicking the "restart" button. It even handles managing the state of the site upon toggling mobile view.
 */
class DemoController {
  webChatInstance: WatsonAssistantChatInstance;

  store: Store<DemoAppState>;

  /**
   * The cleanup config for the currently active page transition.
   */
  pageTransitionCleanupConfig: DemoTransitionCleanupConfig;

  /**
   * The cleanup config for the currently active section transition.
   */
  sectionTransitionCleanupConfig: DemoTransitionCleanupConfig;

  /**
   * The cleanup config for the currently active panel transition.
   */
  panelTransitionCleanupConfig: DemoTransitionCleanupConfig;

  /**
   * Determines if a web chat reload has been triggered manually and not from a transition. This flag is used in
   * {@link #handleTransitionsAndWebChat} to kick of the process of removing web chat from the page and determining
   * if web chat should be embedded.
   */
  isManualWebChatReloadTriggered = false;

  /**
   * Sets the web chat instance for the controller to handle transitions that make use of web chat.
   */
  setWebChatInstance: (instance: WatsonAssistantChatInstance) => void;

  constructor(store: Store<DemoAppState>, setWebChatInstance: (instance: WatsonAssistantChatInstance) => void) {
    this.store = store;
    this.setWebChatInstance = setWebChatInstance;

    injectWalkMeScript();

    const { persistedToBrowserSessionStorage, demoSiteParamsAndHash } = store.getState();
    const { page, section, panel, pageLinkID } = demoSiteParamsAndHash;
    const isDeepLinkOrLinkToPage = Boolean(page || pageLinkID) && !section && !panel;
    // If the user has been deep linked, or linked to a page in the demo site with no section/panel to view, minimize
    // the guidance panel since the user is visiting for demo purposes that don't require the guidance panel to be
    // visible.
    if (isDeepLinkOrLinkToPage && persistedToBrowserSessionStorage.isGuidancePanelExpanded) {
      store.dispatch(demoActions.updateSessionStorageState({ isGuidancePanelExpanded: false }));
    }

    // Handle the initial state of the application.
    this.handleTransitionsAndWebChat();

    store.subscribe(() => this.handleTransitionsAndWebChat());
    store.subscribe(this.handlerPersistedBrowserStorage());
    store.subscribe(this.handleWebChatRestartAndReload());

    // Set an onclick listener to the page document so that we can track if a user has bookmarked a search result from
    // the suggestions panel.
    document.addEventListener('click', handleDocumentClick);
    // This history listener reacts to url search param changes that determine the location of the user in the site.
    // It's what triggers the transitions to occur.
    // Note: This listener will fire off when the user uses the browser's back/forward button (POP), we navigate the user
    // using history.push() (PUSH), or perform a (REPLACE) action.
    demoHistory.listen(({ location }) => {
      const demoSiteParamsAndHash = getDemoSiteParamsAndHash(location);
      store.dispatch(demoActions.setDemoSiteParamsAndHash(demoSiteParamsAndHash));
    });
  }

  /**
   * If a new web chat instance is provided, this function will fire {@link doTransitionWithWebChat}
   * functions for the currently active transitions. Providing a null value, will result in withWebChat cleanup
   * functions to fire in order to remove web chat side effects.
   */
  handleWebChatTransitions(instance: WatsonAssistantChatInstance) {
    const { webChatInstance, store } = this;
    if (!webChatInstance && instance) {
      store.dispatch(demoActions.changeState({ isWebChatLoaded: true }));
      // A new web chat instance has been created, and we should handle web chat side effects.
      this.webChatInstance = instance;
      this.setWebChatInstance(instance);
      this.fireWebChatTransitionFunctions(instance);
    } else if (webChatInstance && !instance) {
      // The web chat instance has been destroyed, and we should clean up web chat side effects.
      this.webChatInstance = null;
      this.setWebChatInstance(null);
      this.undoWebChatSideEffects();
      destroyWebChat(webChatInstance);
      this.store.dispatch(demoActions.changeState({ isWebChatLoaded: false }));
    }
  }

  /**
   * This function handles page, section, and panel transitions, as well as the embedded state of web chat based on
   * the state of the site.
   */
  handleTransitionsAndWebChat() {
    const {
      pageTransitionCleanupConfig,
      sectionTransitionCleanupConfig,
      panelTransitionCleanupConfig,
      store,
      webChatInstance,
      isManualWebChatReloadTriggered,
    } = this;
    const { demoSiteParamsAndHash, isWebChatLoaded } = store.getState();
    const { page: currentPage, panel: currentPanel, section: currentSection } = demoSiteParamsAndHash;
    // Check that web chat isn't loaded so that we can proceed with kicking off a reload.
    const shouldReloadWebChat = !isWebChatLoaded && isManualWebChatReloadTriggered;

    // Determine if a transition has occurred. When the site is first loaded with null param values, this should
    // still kickoff the transition handler as an initial transition.
    const isPageTransition = currentPage !== pageTransitionCleanupConfig?.name;
    const isSectionTransition = currentSection !== sectionTransitionCleanupConfig?.name;
    const isPanelTransition = currentPanel !== panelTransitionCleanupConfig?.name;

    const isTransition = isPageTransition || isSectionTransition || isPanelTransition;

    if (isTransition) {
      const newPageTransitionConfig = DEMO_PAGE_TRANSITIONS[currentPage];
      const newPanelTransitionConfig = DEMO_PANEL_TRANSITIONS[currentPanel];
      const newSectionTransitionConfig = DEMO_SECTION_TRANSITIONS[currentSection];

      // Track the existing transitions as the old transitions in case we get new ones.
      const oldPageTransitionCleanupConfig = pageTransitionCleanupConfig;
      const oldSectionTransitionCleanupConfig = sectionTransitionCleanupConfig;
      const oldPanelTransitionCleanupConfig = panelTransitionCleanupConfig;

      // Create the necessary cleanup config before firing any transition functions. We need to create these ahead of
      // time before any potential action dispatched from transitions.
      if (isPageTransition) {
        this.pageTransitionCleanupConfig = {
          name: currentPage,
          restartOptions: newPageTransitionConfig?.restartOptions,
        };
      }

      if (isSectionTransition) {
        this.sectionTransitionCleanupConfig = {
          name: currentSection,
          restartOptions: newSectionTransitionConfig?.restartOptions,
        };
      }

      if (isPanelTransition) {
        this.panelTransitionCleanupConfig = {
          name: currentPanel,
          restartOptions: newPanelTransitionConfig?.restartOptions,
        };
      }

      // Fire the initial transition functions in a setTimeout. These transition functions can dispatch actions to
      // that update the redux store which should be handled after other tasks (e.g. dispatched actions) have been
      // completed in order to handle the next transitions and determine the state of web chat.
      setTimeout(() => {
        const { isMobileViewEnabled, shouldEmbedWebChat, demoSiteParamsAndHash } = store.getState();
        // Determine if we can use of the web chat instance to trigger web chat transition functions. If the web chat
        // instance is available, but web chat's embed state is false, this means web chat will be destroyed, and we
        // shouldn't trigger web chat transition functions.
        const isWebChatAvailable = Boolean(webChatInstance) && shouldEmbedWebChat;

        // Handle toggling the mobile view on non-mobile devices. This is in case a user is visiting the demo site
        // on a mobile device with mobileView param true.
        if (!IS_MOBILE) {
          const shouldDisableMobileViewToggle = isMobileViewDisabledForCurrentTransition(demoSiteParamsAndHash);

          // Disable the mobile view if the current transition is disables it.
          if (isMobileViewEnabled && shouldDisableMobileViewToggle) {
            store.dispatch(demoActions.changeState({ isMobileViewEnabled: false }));
          } else if (!isMobileViewEnabled && !shouldDisableMobileViewToggle) {
            // Re-enable the mobile view if the current transition doesn't disable it.
            store.dispatch(demoActions.changeState({ isMobileViewEnabled: true }));
          }
        }

        if (isPageTransition) {
          cleanupTransition(oldPageTransitionCleanupConfig);
          this.fireInitialTransitionFunctions(
            this.pageTransitionCleanupConfig,
            newPageTransitionConfig,
            isWebChatAvailable,
          );
        }

        if (isSectionTransition) {
          cleanupTransition(oldSectionTransitionCleanupConfig);
          this.fireInitialTransitionFunctions(
            this.sectionTransitionCleanupConfig,
            newSectionTransitionConfig,
            isWebChatAvailable,
          );
        }

        if (isPanelTransition) {
          cleanupTransition(oldPanelTransitionCleanupConfig);
          this.fireInitialTransitionFunctions(
            this.panelTransitionCleanupConfig,
            newPanelTransitionConfig,
            isWebChatAvailable,
          );
        }
      });
    }

    if (isTransition || shouldReloadWebChat) {
      this.handleWebChatState();
    }
  }

  /**
   * This function handles determining web chat's embedded state. When a transition or a manual trigger of reloading
   * web chat occurs, this will handle initiating the process of removing and embedding web chat based on the
   * current state of the site.
   */
  handleWebChatState() {
    const { store, isManualWebChatReloadTriggered } = this;
    const { demoSiteParamsAndHash, isWebChatLoaded } = store.getState();
    const { page: currentPage, panel: currentPanel, section: currentSection } = demoSiteParamsAndHash;

    const currentPageTransitionConfig = DEMO_PAGE_TRANSITIONS[currentPage];
    const currentPanelTransitionConfig = DEMO_PANEL_TRANSITIONS[currentPanel] as DemoPanelTransitionConfig;
    const currentSectionTransitionConfig = DEMO_SECTION_TRANSITIONS[currentSection];

    // This flag should be reset when this function is called since this function is only fired on manual restart
    // once web chat is no loner loaded.
    if (isManualWebChatReloadTriggered) {
      this.isManualWebChatReloadTriggered = false;
    }

    setTimeout(() => {
      const isTransitionWithoutWebChat =
        currentPageTransitionConfig?.withoutWebChat ||
        currentSectionTransitionConfig?.withoutWebChat ||
        currentPanelTransitionConfig?.withoutWebChat;
      const pagesWithWebChat = currentPanelTransitionConfig?.pagesWithWebChat;
      const pageIsAllowedWebChat = !pagesWithWebChat || pagesWithWebChat.includes(currentPage);
      const isEmbedWebChatAllowed = !isTransitionWithoutWebChat && pageIsAllowedWebChat;

      // Embed web chat if it already isn't and is allowed to be embedded.
      if (!isWebChatLoaded && isEmbedWebChatAllowed) {
        store.dispatch(demoActions.setStateProperty('shouldEmbedWebChat', true));
      } else if (isWebChatLoaded && !isEmbedWebChatAllowed) {
        // Set web chat to be removed if web chat is no longer allowed to be embedded.
        store.dispatch(demoActions.setStateProperty('shouldEmbedWebChat', false));
      }
    });
  }

  /**
   * This function will fire transition functions using the provided transition config, and set its returning value to the
   * provided cleanup config properties. This function is called before web chat renders and so will not handle
   * {@link DemoTransitionConfig.doAfterWebChatRender}.
   */
  fireInitialTransitionFunctions(
    transitionCleanupConfig: DemoTransitionCleanupConfig,
    transitionsConfig: DemoTransitionConfig,
    isWebChatAvailable: boolean,
  ) {
    if (transitionCleanupConfig && transitionsConfig) {
      const { webChatInstance: instance, store } = this;
      transitionCleanupConfig.undoTransition = transitionsConfig.onTransition?.(store);
      transitionCleanupConfig.undoBeforeWebChatRender =
        (isWebChatAvailable && transitionsConfig.doTransitionWithWebChat?.(store, instance)) || null;
    }
  }

  /**
   * This function will attempt to fire web chat side effects for an active transition if it's configured with one.
   * A transition can occur before web chat has loaded, so this function handles web chat side effects when the web
   * chat instance is available.
   */
  fireWebChatTransitionFunctions(instance: WatsonAssistantChatInstance) {
    const { pageTransitionCleanupConfig, sectionTransitionCleanupConfig, panelTransitionCleanupConfig, store } = this;
    const pageTransitionName = pageTransitionCleanupConfig?.name;
    const sectionTransitionName = sectionTransitionCleanupConfig?.name;
    const panelTransitionName = panelTransitionCleanupConfig?.name;

    // Get the active transition configs in order to fire the required web chat transition functions.
    const pageTransition = pageTransitionName && DEMO_PAGE_TRANSITIONS[pageTransitionName];
    const sectionTransition = sectionTransitionName && DEMO_SECTION_TRANSITIONS[sectionTransitionName];
    const panelTransition = panelTransitionName && DEMO_PANEL_TRANSITIONS[panelTransitionName];

    if (pageTransitionCleanupConfig && !pageTransitionCleanupConfig.undoBeforeWebChatRender) {
      pageTransitionCleanupConfig.undoBeforeWebChatRender = pageTransition?.doTransitionWithWebChat?.(store, instance);
    }

    if (sectionTransitionCleanupConfig && !sectionTransitionCleanupConfig.undoBeforeWebChatRender) {
      sectionTransitionCleanupConfig.undoBeforeWebChatRender = sectionTransition?.doTransitionWithWebChat?.(
        store,
        instance,
      );
    }

    if (panelTransitionCleanupConfig && !panelTransitionCleanupConfig.undoBeforeWebChatRender) {
      panelTransitionCleanupConfig.undoBeforeWebChatRender = panelTransition?.doTransitionWithWebChat?.(
        store,
        instance,
      );
    }

    this.doSetupBeforeWebChatRenders();
    // The web chat instance is provided to components through React context. Once web chat is available, those
    // components will be rendered and set web chat listeners. To ensure web chat is rendered after these
    // components have rendered, set a timeout to render web chat and let the browser handle this task when it
    // becomes available in the message queue.
    setTimeout(() => {
      instance.render().then(() => {
        if (pageTransitionCleanupConfig && !pageTransitionCleanupConfig.undoAfterWebChatRender) {
          pageTransitionCleanupConfig.undoAfterWebChatRender = pageTransition?.doAfterWebChatRender?.(store, instance);
        }

        if (sectionTransitionCleanupConfig && !sectionTransitionCleanupConfig.undoAfterWebChatRender) {
          sectionTransitionCleanupConfig.undoAfterWebChatRender = sectionTransition?.doAfterWebChatRender?.(
            store,
            instance,
          );
        }

        if (panelTransitionCleanupConfig && !panelTransitionCleanupConfig.undoAfterWebChatRender) {
          panelTransitionCleanupConfig.undoAfterWebChatRender = panelTransition?.doAfterWebChatRender?.(
            store,
            instance,
          );
        }

        this.doSetupAfterWebChatRenders();
      });
    });
  }

  /**
   * This function handles web chat instance calls that determine the default behavior of web chat before it renders.
   */
  doSetupBeforeWebChatRenders() {
    const { store, webChatInstance: instance } = this;
    const { customPanelType, demoSiteParamsAndHash } = store.getState();

    // Since the post-chat survey isn't tied to a transition we can add it to
    switch (customPanelType) {
      case CustomPanelType.POST_CHAT_SURVEY:
        postChatSurveySetup(store as Store<LendyrAppState>, instance);
        break;
      default:
        break;
    }

    const cssVariables: Record<string, string> = { 'BASE-z-index': 'var(--DemoSite-WebChat-z-index)' };

    if (!IS_MOBILE && demoSiteParamsAndHash.mobileView) {
      this.doSetupForWebChatMobileView(instance, cssVariables);
    }

    instance.updateCSSVariables(cssVariables);

    // Set default event handlers.
    instance.on([
      {
        type: BusEventType.PRE_RECEIVE,
        handler: (event: BusEventPreReceive) => demoFormFillingActionHandler(event, store),
      },
      {
        type: BusEventType.PRE_RECEIVE,
        handler: demoTrackingPreReceiveHandler,
      },
      {
        type: BusEventType.RECEIVE,
        handler: (event: BusEventReceive) => restartConversationHandler(event, store),
      },
      {
        type: BusEventType.PRE_SEND,
        handler: demoTrackingPreSendHandler,
      },
      {
        type: BusEventType.AGENT_PRE_START_CHAT,
        handler: (event, instance) => demoTrackingConnectedToAgentHandler(instance),
      },
      {
        type: BusEventType.WINDOW_PRE_CLOSE,
        handler: demoTrackingJourneysTourHandler,
      },
      {
        type: BusEventType.TOUR_STEP,
        handler: (event: BusEventTourStep) => demoDisputeChargeTourStepHandler(event, store),
      },
    ]);
  }

  /**
   * This function handles any setup that requires web chat to have rendered.
   */
  doSetupAfterWebChatRenders() {
    const { webChatInstance } = this;
    const { isWebChatOpen } = webChatInstance.getState();

    // Hide the web chat window if it's not open by default in the mobile view.
    if (this.isWebChatInMobileViewCustomElement() && !isWebChatOpen) {
      webChatInstance.elements.getMainWindow().addClassName('Hidden');
    }

    if (IS_MOBILE) {
      trackMobileLauncherExtendedClick();
    }
  }

  /**
   * Handles configuring web chat to render in the emulated mobile view using a custom element.
   */
  doSetupForWebChatMobileView(instance: WatsonAssistantChatInstance, cssVariables: Record<string, string>) {
    // Set the launcher position the same as we do in mobile devices.
    cssVariables['LAUNCHER-position-bottom'] = '16px';
    cssVariables['LAUNCHER-position-right'] = '16px';

    // If web chat is configured to be in the mobile view custom element, handle the visibility of the custom
    // element based on the open state of web chat.
    if (this.isWebChatInMobileViewCustomElement()) {
      const { isWebChatOpen } = instance.getState();
      const mobileViewWebChatCustomElement = getWebChatMobileViewCustomElement();
      const chatMainWindowInstance = instance.elements.getMainWindow();

      if (isWebChatOpen) {
        mobileViewWebChatCustomElement.classList.add('DemoApp__WebChatCustomElement--visible');
      }

      instance.on({
        type: BusEventType.WINDOW_PRE_OPEN,
        handler: () => {
          chatMainWindowInstance.removeClassName('Hidden');
          mobileViewWebChatCustomElement.classList.add('DemoApp__WebChatCustomElement--visible');
        },
      });

      instance.on({
        type: BusEventType.WINDOW_CLOSE,
        handler: () => {
          chatMainWindowInstance.addClassName('Hidden');
          mobileViewWebChatCustomElement.classList.remove('DemoApp__WebChatCustomElement--visible');
        },
      });
    }
  }

  /**
   * Determines if web chat is currently being rendered in a custom element.
   */
  isWebChatInMobileViewCustomElement() {
    const panelConfigOptions = this.getPanelWebChatConfigOptions();
    const webChatMobileViewCustomElement = getWebChatMobileViewCustomElement();
    return panelConfigOptions?.element === webChatMobileViewCustomElement;
  }

  /**
   * This function will fire any cleanup transition side effects that used web chat. This can occur when web chat
   * is destroyed, but no transition occurred.
   */
  undoWebChatSideEffects() {
    const { pageTransitionCleanupConfig, sectionTransitionCleanupConfig, panelTransitionCleanupConfig } = this;

    // Undo web chat side effects set before web chat has rendered.
    if (pageTransitionCleanupConfig?.undoBeforeWebChatRender) {
      pageTransitionCleanupConfig.undoBeforeWebChatRender();
      this.pageTransitionCleanupConfig.undoBeforeWebChatRender = null;
    }

    if (sectionTransitionCleanupConfig?.undoBeforeWebChatRender) {
      sectionTransitionCleanupConfig.undoBeforeWebChatRender();
      this.sectionTransitionCleanupConfig.undoBeforeWebChatRender = null;
    }

    if (panelTransitionCleanupConfig?.undoBeforeWebChatRender) {
      panelTransitionCleanupConfig.undoBeforeWebChatRender();
      this.panelTransitionCleanupConfig.undoBeforeWebChatRender = null;
    }

    // Undo web chat side effects set before web chat has rendered.
    if (pageTransitionCleanupConfig?.undoAfterWebChatRender) {
      pageTransitionCleanupConfig.undoAfterWebChatRender();
      this.pageTransitionCleanupConfig.undoAfterWebChatRender = null;
    }

    if (sectionTransitionCleanupConfig?.undoAfterWebChatRender) {
      sectionTransitionCleanupConfig.undoAfterWebChatRender();
      this.sectionTransitionCleanupConfig.undoAfterWebChatRender = null;
    }

    if (panelTransitionCleanupConfig?.undoAfterWebChatRender) {
      panelTransitionCleanupConfig.undoAfterWebChatRender();
      this.panelTransitionCleanupConfig.undoAfterWebChatRender = null;
    }
  }

  /**
   * This function returns a function that determines whether to restart/reload web chat based on the current state
   * of the page. A restart/reload can be triggered manually by firing a {@link DO_RESTART_TYPE} action, the page
   * switching between desktop and mobile view, or clicking the restart button.
   *
   * Toggling the mobile view should reload web chat in order to render the mobile launcher on desktop.
   */
  handleWebChatRestartAndReload() {
    const { store } = this;
    const { restartCounter, isMobileViewEnabled, demoSiteParamsAndHash } = store.getState();

    let previousMobileView = demoSiteParamsAndHash.mobileView;
    let previousIsMobileViewEnabled = isMobileViewEnabled;
    let previousRestartCounter = restartCounter;

    return () => {
      const {
        restartCounter,
        lastRestartType,
        demoSiteParamsAndHash,
        isMobileViewEnabled: currentIsMobileViewEnabled,
      } = store.getState();
      const { mobileView: currentMobileView } = demoSiteParamsAndHash;
      const isManualRestart = restartCounter > previousRestartCounter;
      // A mobile view toggle can be triggered by either clicking on the desktop/icons in demo site header, or if a
      // transition disables/enables mobile view.
      const isMobileViewToggled =
        previousMobileView !== currentMobileView ||
        (previousIsMobileViewEnabled !== currentIsMobileViewEnabled && currentMobileView);
      const shouldRestartWebChat = isManualRestart || isMobileViewToggled;

      if (shouldRestartWebChat) {
        const isManualWebChatReload = lastRestartType === RestartType.RELOAD_WEB_CHAT || isMobileViewToggled;
        const shouldReloadWebChat = isManualWebChatReload || this.shouldReloadWebChatOnRestart();

        previousRestartCounter = restartCounter;
        previousMobileView = currentMobileView;
        previousIsMobileViewEnabled = currentIsMobileViewEnabled;

        // End the current WalkMe flow.
        // https://developer.walkme.com/reference/smart-walkthrus
        (window as any).WalkMeAPI?.stopFlow(WALK_ME_FLOW_ID);
        // Handle configured transition restart options.
        this.handleTransitionRestartOptions(shouldReloadWebChat);
        // Reset the demo site session storage state.
        store.dispatch(
          demoActions.updateSessionStorageState(DEFAULT_SESSION_STORAGE_STATE(DEFAULT_LENDYR_SESSION_STORAGE_STATE)),
        );

        if (shouldReloadWebChat) {
          this.isManualWebChatReloadTriggered = true;

          store.dispatch(
            demoActions.changeState({
              showCustomLauncher: false,
              // Reset the custom panel type to none on web chat reload.
              customPanelType: CustomPanelType.NONE,
              shouldEmbedWebChat: false,
            }),
          );
        }
      }

      // A manual restart should navigate the user to a panel specific page since toggling mobile view isn't a true
      // restart.
      if (isManualRestart) {
        navigateToPanelSpecificPage(demoSiteParamsAndHash);
      }
    };
  }

  /**
   * Determines if a current transition has set web chat to reload on restart button click.
   */
  shouldReloadWebChatOnRestart() {
    const { pageTransitionCleanupConfig, sectionTransitionCleanupConfig, panelTransitionCleanupConfig } = this;

    return Boolean(
      pageTransitionCleanupConfig?.restartOptions?.reloadWebChat ||
        sectionTransitionCleanupConfig?.restartOptions?.reloadWebChat ||
        panelTransitionCleanupConfig?.restartOptions?.reloadWebChat,
    );
  }

  /**
   * Handles restart options for an active transition.
   */
  async handleTransitionRestartOptions(isWebChatReload: boolean) {
    const {
      webChatInstance,
      pageTransitionCleanupConfig,
      sectionTransitionCleanupConfig,
      panelTransitionCleanupConfig,
      store,
    } = this;

    const pageRestartOptions = pageTransitionCleanupConfig?.restartOptions;
    const sectionRestartOptions = sectionTransitionCleanupConfig?.restartOptions;
    const panelRestartOptions = panelTransitionCleanupConfig?.restartOptions;

    pageRestartOptions?.onClickRestart?.(store, webChatInstance);
    sectionRestartOptions?.onClickRestart?.(store, webChatInstance);
    panelRestartOptions?.onClickRestart?.(store, webChatInstance);

    if (!isWebChatReload) {
      await webChatInstance?.restartConversation();
    }

    if (pageRestartOptions?.closeWebChat || sectionRestartOptions?.closeWebChat || panelRestartOptions?.closeWebChat) {
      webChatInstance?.closeWindow();
    }
  }

  /**
   * Returns web chat config object. This can either come from the current active transition if provides one.
   * Otherwise, the default config options will be returned.
   */
  getPanelWebChatConfigOptions() {
    const { panelTransitionCleanupConfig, store } = this;
    const { demoSiteParamsAndHash, isMobileViewEnabled } = store.getState();
    const { mobileView } = demoSiteParamsAndHash;

    const currentPanelName = panelTransitionCleanupConfig?.name;
    const panelWebChatConfigOptions = DEMO_PANEL_TRANSITIONS[currentPanelName] as DemoPanelTransitionConfig;
    const publicConfig = panelWebChatConfigOptions?.getWebChatConfigOptions?.(store) || DEFAULT_WEB_CHAT_CONFIG_OPTIONS;
    const clonedPublicConfig = cloneDeep(publicConfig);

    // Use the original reference to the custom element instead of the cloned element just in case.
    clonedPublicConfig.element = publicConfig.element;

    // If the site is in a mobile view on desktop, update the web chat config to embed web chat in the mobile and
    // render the mobile launcher.
    if (!IS_MOBILE && mobileView && isMobileViewEnabled) {
      // If there's already a custom element configured, use that instead.
      if (!clonedPublicConfig.element) {
        clonedPublicConfig.element = getWebChatMobileViewCustomElement();
      }

      clonedPublicConfig.disableCustomElementMobileEnhancements = true;
      // Since web chat's rounded corners are removed in a custom element in an actual mobile device, this should be
      // reflected on the mobile view in desktop as well.
      clonedPublicConfig.__ibm__.disableRoundCorners = true;
      clonedPublicConfig.__ibm__.renderExtendedLauncher = true;
    }

    return clonedPublicConfig;
  }

  /**
   * Handles copying persisted browser storage states in the store to browser session and local storage.
   */
  handlerPersistedBrowserStorage() {
    let prevPersistedToBrowserSessionStorage = this.store.getState().persistedToBrowserSessionStorage;
    let prevPersistedToBrowserLocalStorage = this.store.getState().persistedToBrowserLocalStorage;

    return () => {
      const { persistedToBrowserSessionStorage, persistedToBrowserLocalStorage } = this.store.getState();
      const sessionStorageState = getSessionStorageState();
      const localStorageState = getLocalStorageState();

      if (sessionStorageState !== prevPersistedToBrowserSessionStorage) {
        prevPersistedToBrowserSessionStorage = sessionStorageState;
        updateSessionStorage(persistedToBrowserSessionStorage);
      }

      if (localStorageState !== prevPersistedToBrowserLocalStorage) {
        prevPersistedToBrowserLocalStorage = localStorageState;
        updateLocalStorage(persistedToBrowserLocalStorage);
      }
    };
  }
}

/**
 * Fires transition cleanup functions to undo the transition's side effects.
 */
function cleanupTransition(transitionCleanupConfig: DemoTransitionCleanupConfig) {
  if (transitionCleanupConfig) {
    transitionCleanupConfig.undoTransition?.();
    transitionCleanupConfig.undoBeforeWebChatRender?.();
    transitionCleanupConfig.undoAfterWebChatRender?.();
  }
}

/**
 * This function handles listening to elements that were clicked on in the document. This listener can help us with
 * tracking when a web chat element has been clicked for demo purposes.
 */
function handleDocumentClick(event: Event) {
  const element = event.target as HTMLElement;
  const stopAtElement = document.querySelector('#WACContainer');

  if (isOrInsideOfPredicate(element, isBookMarkButtonPredicate, stopAtElement)) {
    trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.SEARCH_IN_SUGGESTIONS);
  } else if (isOrInsideOfClass(element, 'WACHomeScreenModern__starter')) {
    trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.HOME_SCREEN);
  } else if (isOrInsideOfClass(element, 'WACLauncherComplex__Container--introAnimation')) {
    trackExpandedAndExtendedLauncherClick();
  }
}

/**
 * Checks if the provided element is a bookmark button, meaning the user has bookmarked a search result, and we
 * should track it as feature finished for "search in suggestions".
 */
function isBookMarkButtonPredicate(element: HTMLElement) {
  const candidateAttributeValue = element.getAttribute(MEANINGFUL_CONVERSATION_ATTRIBUTE);
  return candidateAttributeValue === MeaningfulConversationType.SEARCH && element.innerText === 'Bookmark';
}

/**
 * This handles tracking when the mobile launcher is clicked in the extended state. Because the mobile launcher
 * changes in place, we have to rely on mutation observers to determine in what state the user clicked on the mobile
 * launcher.
 */
function trackMobileLauncherExtendedClick() {
  const launcherContainer = document.querySelector('.WACLauncher__ButtonContainer');
  const handleMobileLauncherClick = (event: Event) => {
    if (isOrInsideOfClass(event.target as Element, 'WACLauncherExtended__Button--extended')) {
      trackExpandedAndExtendedLauncherClick();
    }

    launcherContainer.removeEventListener('click', handleMobileLauncherClick);
  };

  launcherContainer.addEventListener('click', handleMobileLauncherClick);
}

function trackExpandedAndExtendedLauncherClick() {
  trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.LAUNCHER);
}

/**
 * Returns the custom element that will host the web chat window for the mobile view.
 */
function getWebChatMobileViewCustomElement() {
  return document.querySelector('.DemoApp__WebChatCustomElement');
}

export { DemoController };
