/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

/**
 * This context provides the demo page overlay host element in order to create overlay components, such as a modal
 * overlay.
 */
const DemoPageOverlayPortalContext = React.createContext<HTMLDivElement>(null);

export { DemoPageOverlayPortalContext };
