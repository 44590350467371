/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';

import { HasChildren } from '../web-chat-dependencies/types/HasChildren';
import { useDemoPageOverlayHostElement } from './hooks/useDemoPageOverlayHostElement';
import { demoActions } from './store/demoActions';

interface DemoPageOverlayPortalProps extends HasChildren {
  /**
   * Determines if rendering the overlay should prevent page scroll.
   */
  preventPageScroll?: boolean;
}

/**
 * This component provides a portal that will allow us to overlay content over the demo page.
 */
function DemoPageOverlayPortal({ children, preventPageScroll }: DemoPageOverlayPortalProps) {
  const dispatch = useDispatch();
  const pageOverlayHostElement = useDemoPageOverlayHostElement();

  useEffect(() => {
    if (pageOverlayHostElement) {
      // In the event that a DemoPageOverlayPortal component is being mounted and a second DemoPageOverlayPortal
      // component is being unmounted, the cleanup function of the second component may be fired after the first
      // component has mounted, causing it to be hidden. This is why we must handle this in a setTimeout.
      setTimeout(() => dispatch(demoActions.changeState({ isPageOverlayHidden: false })));

      return () => {
        dispatch(demoActions.changeState({ isPageOverlayHidden: true }));
      };
    }

    return null;
  }, [dispatch, pageOverlayHostElement]);

  useEffect(() => {
    if (pageOverlayHostElement && preventPageScroll) {
      dispatch(demoActions.changeState({ preventPageScroll: true }));

      return () => {
        dispatch(demoActions.changeState({ preventPageScroll: false }));
      };
    }

    return null;
  }, [dispatch, pageOverlayHostElement, preventPageScroll]);

  // If the host element is available, use the portal to render the overlay content. Otherwise, render a hidden element
  // until the host element becomes available.
  return pageOverlayHostElement ? ReactDOM.createPortal(children, pageOverlayHostElement) : <></>;
}

export { DemoPageOverlayPortal };
