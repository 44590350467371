/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { DemoTransitionConfig } from '../demoTransitionTypes';

const OPEN_FROM_BUTTONS_TRANSITION_CONFIG: DemoTransitionConfig = {
  restartOptions: {
    closeWebChat: true,
  },
};

export { OPEN_FROM_BUTTONS_TRANSITION_CONFIG };
