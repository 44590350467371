/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */
import { LendyrSection, RestartType } from '../../demoTypes';
import { demoActions } from '../../store/demoActions';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const FEATURE_LIST_TRANSITION_CONFIG: DemoTransitionConfig = {
  onTransition: store => {
    let previousPanel = store.getState().demoSiteParamsAndHash.panel;

    const unsubscribe = store.subscribe(() => {
      const { section: currentSection, panel: currentPanel } = store.getState().demoSiteParamsAndHash;

      // If the user is navigating from the feature list home panel to a feature panel.
      if (currentSection === LendyrSection.INDEX && previousPanel !== currentPanel) {
        previousPanel = currentPanel;

        // If the user is visiting a feature panel, reload web chat.
        if (currentPanel) {
          store.dispatch(demoActions.doRestartType(RestartType.RELOAD_WEB_CHAT));
        }
      }
    });

    return () => {
      unsubscribe();
    };
  },
};

export { FEATURE_LIST_TRANSITION_CONFIG };
