/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */
import { HomeScreenConfig } from '../../web-chat-dependencies/types/HomeScreenConfig';

const DEFAULT_LAUNCHER_GREETING_MESSAGE = "Hi! I'm a virtual assistant. How can I help you today?";
const DEFAULT_HOME_SCREEN_CONFIG: HomeScreenConfig = {
  starters: {
    buttons: [{ label: 'Get to know Lendyr' }, { label: 'Pay bills' }, { label: 'Find nearest branch' }],
  },
};

export { DEFAULT_LAUNCHER_GREETING_MESSAGE, DEFAULT_HOME_SCREEN_CONFIG };
