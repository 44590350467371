/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { StatusCodes } from 'http-status-codes';
import { v4 as originalUUID } from 'uuid';

import { AgentProfile } from '../submodules/wa-fd-types-public/agent-events-types';
import { THREAD_ID_MAIN } from '../submodules/wa-fd-types-public/wa-extensions-types';
import { MessageRequest, MessageResponse } from '../submodules/wa-fd-types-public/wa-types';
import { EndChatInfo, ServiceDeskCallback, StartChatOptions } from '../web-chat-dependencies/services/haa/ServiceDesk';
import { ServiceDeskImpl } from '../web-chat-dependencies/services/haa/ServiceDeskImpl';
import { ChatRequest } from '../web-chat-dependencies/types/watsonAssistantAPITypes';
import { API_DEBUGGING_HEADER_NAME } from '../web-chat-dependencies/utils/constants';
import { sleep } from '../web-chat-dependencies/utils/lang/promiseUtils';
import { isConnectToAgent } from '../web-chat-dependencies/utils/messageUtils';
import { REGION_MAP } from '../web-chat-dependencies/utils/regions';
import avatarAnna from './avatarAnna.jpg';
import avatarJonathan from './avatarJonathan.jpg';

const MOCK_AGENT_PROFILE_JONATHAN: AgentProfile = {
  id: 'Jonathan-id',
  nickname: 'Jonathan',
  profile_picture_url: avatarJonathan,
};

const MOCK_AGENT_PROFILE_ANNA: AgentProfile = {
  id: 'Anna-id',
  nickname: 'Anna',
  profile_picture_url: avatarAnna,
};

// The parameters to send on fetch requests.
const FETCH_PARAMETERS = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

// The error response text when a session has expired
const SESSION_NOT_FOUND_ERROR_TEXT = 'session not found';

/**
 * This class acts as a fake service desk integration for use on the demo site. It will use an assistant to act as a
 * fake agent.
 */

class DemoServiceDesk extends ServiceDeskImpl {
  /**
   * The endpoint for making calls to the assistant.
   */
  private messageURL: string;

  /**
   * The current session ID of the assistant.
   */
  private sessionID: string;

  /**
   * The current agent.
   */
  private currentAgent: AgentProfile;

  constructor(
    callback: ServiceDeskCallback,
    integrationID: string,
    region: keyof typeof REGION_MAP,
    subscriptionID: string,
  ) {
    super(callback, null);
    this.messageURL = `https://${REGION_MAP[region]}/${subscriptionID || 'public'}/message/${integrationID}`;
  }

  /**
   * Checks if any agents are online and ready to communicate with the user.
   */
  async areAnyAgentsOnline(connectMessage: MessageResponse): Promise<boolean | null> {
    return true;
  }

  /**
   * Instructs the service desk to start a new chat. This should be called immediately after the service desk
   * instance has been created. It will make the appropriate calls to the service desk and begin communicating back
   * to the calling code using the callback produce to the instance. This may only be called once per instance.
   */
  async startChat(connectMessage: MessageResponse, startChatOptions: StartChatOptions): Promise<void> {
    await sleep(2000);
    const connectToAgent = connectMessage.output.generic.find(isConnectToAgent);
    const messageText = connectToAgent?.message_to_human_agent || '';
    const result = await this.sendToAssistant(messageText);
    if (result) {
      setTimeout(async () => {
        this.currentAgent = MOCK_AGENT_PROFILE_JONATHAN;
        this.callback.agentJoined(this.currentAgent);
        await sleep(2000);
        this.callback.agentTyping(true);
        await sleep(2000);
        this.callback.sendMessageToUser(result, this.currentAgent.id);
        this.callback.agentTyping(false);
      });
    } else {
      throw new Error('Unable to connect to demo agent');
    }
  }

  /**
   * Tells the service desk to terminate the chat.
   */
  async endChat(info?: EndChatInfo): Promise<void> {
    this.sessionID = null;
    this.currentAgent = null;
  }

  /**
   * Sends a message to the agent in the service desk.
   */
  async sendMessageToAgent(message: MessageRequest, messageID: string): Promise<void> {
    const result = await this.sendToAssistant(message.input.text);
    if (result) {
      setTimeout(async () => {
        await sleep(2000);
        await this.showMessageToUser(result);

        const actionType = result.output.generic[0]?.user_defined?.action_type;
        if (actionType === 'transfer') {
          const transferResult = await this.sendToAssistant('INTERNAL_AFTER_TRANSFER_MESSAGE');
          await sleep(2000);
          this.callback.beginTransferToAnotherAgent();

          if (this.currentAgent === MOCK_AGENT_PROFILE_JONATHAN) {
            this.currentAgent = MOCK_AGENT_PROFILE_ANNA;
          } else {
            this.currentAgent = MOCK_AGENT_PROFILE_JONATHAN;
          }

          await sleep(2000);
          this.callback.agentJoined(this.currentAgent);

          await sleep(2000);
          await this.showMessageToUser(transferResult);
        }

        if (actionType === 'endChat') {
          await this.callback.agentEndedChat();
        }
      });
    } else {
      throw new Error('Unable to connect to demo agent');
    }
  }

  /**
   * Displays a message from the agent to the user, complete with a delayed typing indicator.
   */
  private async showMessageToUser(message: MessageResponse) {
    this.callback.agentTyping(true);
    await sleep(2000);
    this.callback.sendMessageToUser(message, this.currentAgent.id);
    this.callback.agentTyping(false);
  }

  /**
   * Informs the service desk that the user has read all the messages that have been sent by the service desk.
   */
  async userReadMessages(): Promise<void> {}

  /**
   * Tells the service desk if a user has started or stopped typing.
   */
  async userTyping(isTyping: boolean): Promise<void> {}

  /**
   * Sends the given message to the assistant.
   */
  private async sendToAssistant(message: string): Promise<MessageResponse> {
    try {
      return this.sendToAssistantWithError(message, true);
    } catch (error) {
      console.error('Error sending message to demo agent', error);
      return null;
    }
  }

  /**
   * Sends the given message to the assistant.
   */
  private async sendToAssistantWithError(message: string, allowRetry: boolean): Promise<MessageResponse> {
    const fetchParameters = {
      ...FETCH_PARAMETERS,
      headers: {
        ...FETCH_PARAMETERS.headers,
        [API_DEBUGGING_HEADER_NAME]: originalUUID(),
      },
    };

    const messageRequest: MessageRequest = {
      input: {
        text: message,
      },
      thread_id: THREAD_ID_MAIN,
      id: originalUUID(),
    };

    const chatRequest: ChatRequest = {
      value: messageRequest,
      session_id: this.sessionID,
      user_id: 'web-chat-demo-human-agent-user-id',
    };

    const result = await fetch(this.messageURL, {
      ...fetchParameters,
      body: JSON.stringify(chatRequest),
    });

    const resultText = await result.text();

    if (result.ok) {
      const result: MessageResponse = JSON.parse(resultText);
      this.sessionID = result.session_id;
      return result;
    }

    if (result.status === StatusCodes.NOT_FOUND && resultText?.toLowerCase().includes(SESSION_NOT_FOUND_ERROR_TEXT)) {
      // The session expired so reset and try again.
      this.sessionID = null;
      if (allowRetry) {
        return this.sendToAssistantWithError(message, false);
      }
    }

    throw new Error(`An error occurred ${result.status}`);
  }
}

export { DemoServiceDesk };
