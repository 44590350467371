/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './HomeScreenCustomContent.css';

import { Help16, Home16, ShoppingBag16 } from '@carbon/icons-react';
import React from 'react';
import ReactDOM from 'react-dom';

import { LendyrPage } from '../framework/demoTypes';
import { WatsonAssistantChatInstance } from '../web-chat-dependencies/types/WatsonAssistantChatInstance';
import { PageLink } from './PageLink';

interface HomeScreenCustomContentProps {
  instance: WatsonAssistantChatInstance;
}

/**
 * Renders a portal to mount custom content into the home screen panel.
 */
function HomeScreenCustomContentPortal({ instance }: HomeScreenCustomContentProps) {
  return ReactDOM.createPortal(
    <>
      <div className="ibm-web-chat--card-home-screen__title-outer">
        <u>Quick Links</u>
      </div>
      <div className="ibm-web-chat--card-home-screen">
        <ul>
          <li className="home-screen--article-link">
            <PageLink page={LendyrPage.TRANSACTIONS}>
              <ShoppingBag16 />
              Purchases
            </PageLink>
          </li>
          <li className="home-screen--article-link">
            <PageLink page={LendyrPage.HOME_LOANS}>
              <Home16 /> Home Loans
            </PageLink>
          </li>
          <li className="home-screen--article-link">
            <PageLink page={LendyrPage.FAQ}>
              <Help16 /> FAQ
            </PageLink>
          </li>
        </ul>
      </div>
    </>,
    instance.writeableElements.homeScreenAfterStartersElement,
  );
}

export { HomeScreenCustomContentPortal };
