/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';

import { DemoController } from '../DemoController';
import { HasChildren } from '../web-chat-dependencies/types/HasChildren';
import { PagePublicConfig } from '../web-chat-dependencies/types/PublicConfig';
import { WatsonAssistantChatInstance } from '../web-chat-dependencies/types/WatsonAssistantChatInstance';
import { WebChatInstanceContext } from './contexts/WebChatInstanceContext';
import { useRefFn } from './hooks/useRefFn';
import { DemoAppState } from './store/DemoAppState';
import { loadWebChat, loadWebChatScript } from './webChatSetup';

let loadWebChatScriptPromise: Promise<unknown>;

/**
 * This component manages the state of web chat and uses the demo controller to fire transitions. This component
 * loads web chat and determines if web chat should be loaded based on the application state. It also utilizes the
 * demo controller instance to fire web chat transitions when the web chat instance becomes available. When a
 * restart is triggered, it will use the controller instance to handle the restart options for the active transitions.
 */
function DemoControllerComponent({ children }: HasChildren) {
  const store = useStore();
  const [webChatInstance, setWebChatInstance] = useState<WatsonAssistantChatInstance>(null);
  const shouldEmbedWebChat = useSelector((state: DemoAppState) => state.shouldEmbedWebChat);
  const demoControllerRef = useRefFn(() => new DemoController(store, setWebChatInstance));

  // This effect handles embedding web chat, and handling web chat transitions.
  useEffect(() => {
    if (shouldEmbedWebChat) {
      const demoController = demoControllerRef.current;
      const webChatConfigOptions = demoController.getPanelWebChatConfigOptions();

      embedWebChat(webChatConfigOptions, instance => {
        demoController.handleWebChatTransitions(instance);
      });

      return () => {
        // Handle removing web chat in a setTimeout so that other tasks, such as transitions, are finished, and we can
        // then handle cleaning up web chat transition functions afterwards.
        setTimeout(() => {
          demoController.handleWebChatTransitions(null);
        });
      };
    }

    return undefined;
  }, [demoControllerRef, shouldEmbedWebChat]);

  return <WebChatInstanceContext.Provider value={webChatInstance}>{children}</WebChatInstanceContext.Provider>;
}

/**
 * This function ensures the web chat script was loaded, and then loading web chat. Once the web chat instance is
 * available, it's provided through a callback.
 */
async function embedWebChat(options: PagePublicConfig, callback: (instance: WatsonAssistantChatInstance) => void) {
  if (!loadWebChatScriptPromise) {
    loadWebChatScriptPromise = loadWebChatScript();
  }

  await loadWebChatScriptPromise;

  const instance = await loadWebChat(options);

  callback(instance);
}

export { DemoControllerComponent };
