/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { PANEL_SPECIFIC_PAGE_MAP } from '../../../../framework/utils/demoConstants';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const POSITION_FEATURE_CODE_LINK: GuidancePanelImplementationData[] = [
  {
    title: 'Implement a custom element',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/custom-element',
    type: 'Tutorial',
  },
];

function PositionPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Change Web Chat Position"
        description="Web chat does not need to open from the traditional bottom-right placement on a webpage. Its position can be completely customized. In this case, the web chat has been embedded on the help page to replace the need for a search bar. It could also be inserted as a side panel in a dashboard, or wherever else it's needed or desired."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>View the web chat.</GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile codeNecessary data={POSITION_FEATURE_CODE_LINK} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const PositionPanelConfig: SectionPanelConfig = {
  title: 'Change Web Chat Position',
  SectionPanelComponent: PositionPanel,
  page: PANEL_SPECIFIC_PAGE_MAP[LendyrPanel.POSITION],
  panel: LendyrPanel.POSITION,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.POSITION,
};

export { PositionPanel, PositionPanelConfig };
