/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { MediaPlayerOverlay } from '../../../MediaPlayerOverlay';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const BUILDING_CONVERSATION_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Overview: Editing actions',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-build-actions-overview',
    type: 'Docs',
  },
  {
    title: 'Getting started with Watson Assistant',
    link: 'https://medium.com/ibm-watson/getting-started-with-the-new-watson-assistant-ff8cfcbf048f',
    type: 'Article',
  },
];

const IBM_VIDEO_DATA = {
  title: 'Build your first AI-powered chatbot using IBM Watson Assistant Actions',
  videoID: '1_bm7cb5bt',
  wID: '1_3lgo10i6',
};

function BuildExperienceBuildingConversationPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Building Conversation"
        description={
          <>
            An action is a problem or a task that your customer wants to resolve and that your virtual assistant can
            address. Anything from paying a bill to getting an invoice to asking about vacation days could be an action
            in your assistant.
            <br />
            <br />
            Actions require <strong>no code</strong>, and they wrap a lot of complex, technical concepts - like intents,
            entities, and slots - into an <strong>intuitive, non-technical experience</strong>.
            <br />
            <br />
            Watch this video where Morgan, an IBM engineer, will walk you through how to build actions in Watson
            Assistant!
          </>
        }
      />
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={BUILDING_CONVERSATION_LINKS} />
      </GuidancePanelImplementationSection>
      <MediaPlayerOverlay ibmVideoPlayerProps={IBM_VIDEO_DATA} />
    </div>
  );
}

const BuildExperienceBuildingConversationPanelConfig: SectionPanelConfig = {
  title: 'Building Conversation',
  SectionPanelComponent: BuildExperienceBuildingConversationPanel,
  panel: LendyrPanel.BUILD_EXPERIENCE_BUILDING_CONVERSATION,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.BUILD_EXPERIENCE_BUILDING_CONVERSATION,
  page: LendyrPage.BUILD_EXPERIENCE_TOUR,
};

export { BuildExperienceBuildingConversationPanel, BuildExperienceBuildingConversationPanelConfig };
