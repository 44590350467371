/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './PreChatForm.css';

import { Button, TextInput } from 'carbon-components-react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DemoFeaturePanelsTrackingNames } from '../../framework/demoTypes';
import { trackDemoFeatureFinished } from '../../framework/utils/demoTracking';
import { lendyrActions } from '../../lendyr/store/lendyrActions';
import { CustomPanelContainerProps } from './customPanelTypes';

interface PreChatFormValues {
  firstName?: string;
  lastName?: string;
  email?: string;
}

function PreChatForm({ instance }: CustomPanelContainerProps) {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const panelInstance = instance.customPanels.getPanel();
  const isSubmittingDisabled = !firstName || !lastName || !email;

  return (
    <div className="PreChatForm">
      <div className="PreChatForm__Body">
        <div className="PreChatForm__BodyContent">
          <div className="PreChatForm__GreetingMessage">
            <span>Let&apos;s get started</span>
          </div>
          <TextInput
            id="input-first-name"
            labelText="First name"
            value={firstName}
            placeholder="Required"
            onChange={event => setFirstName(event.target.value)}
          />
          <TextInput
            id="input-last-name"
            labelText="Last name"
            placeholder="Required"
            value={lastName}
            onChange={event => setLastName(event.target.value)}
          />
          <TextInput
            id="input-email"
            labelText="Email"
            placeholder="Required"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </div>
      </div>
      <div className="PreChatForm__ButtonContainer">
        <Button className="PreChatForm__CancelButton" kind="secondary" onClick={() => panelInstance.close()}>
          Cancel
        </Button>
        <Button
          className="PreChatForm__StartChatButton"
          onClick={() => {
            dispatch(
              lendyrActions.updateSessionStorageStateSiteData({
                preChatFormValues: { firstName },
              }),
            );
            trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.PRE_CHAT_FORM);
            panelInstance.close();
          }}
          disabled={isSubmittingDisabled}
        >
          Start chat
        </Button>
      </div>
    </div>
  );
}

export { PreChatForm, PreChatFormValues };
