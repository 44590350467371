/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { createStore } from 'redux';

import { getLocalStorageState, getSessionStorageState, loadPersistedStorageStates } from '../utils/demoStorageUtils';
import { getDemoSiteParamsAndHash } from '../utils/demoUtils';
import { DemoActions } from './demoActions';
import { DemoAppState } from './DemoAppState';
import { demoReducers } from './demoReducers';

function doCreateDemoStore() {
  loadPersistedStorageStates();

  const sessionStorageState = getSessionStorageState();
  const localStorageState = getLocalStorageState();
  const demoSiteParamsAndHash = getDemoSiteParamsAndHash(window.location);
  const appState: DemoAppState = {
    demoSiteParamsAndHash,
    persistedToBrowserSessionStorage: sessionStorageState,
    persistedToBrowserLocalStorage: localStorageState,
    isAuthenticated: true,
    showCustomLauncher: false,
    shouldEmbedWebChat: false,
    isWebChatLoaded: false,
    customPanelType: null,
    pageState: {
      creditCardsPage: {
        selectedCardOffer: null,
        annualIncome: 0,
      },
      homeLoansPage: {
        mortgageCalculatorFields: {
          creditScore: 0,
          loanAmount: 0,
          loanTerm: null,
        },
      },
    },
    restartCounter: 0,
    lastRestartType: null,
    preventPageScroll: false,
    isPageOverlayHidden: true,
    isMobileViewEnabled: true,
  };

  return createStore(fireDemoAction, appState);
}

function fireDemoAction(state: DemoAppState, action: { type: DemoActions }) {
  return (action && demoReducers[action.type]?.(state, action)) || state;
}

export { doCreateDemoStore };
