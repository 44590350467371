/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './DemoPageModal.css';

import React from 'react';

import { DemoPageOverlayPortal } from '../framework/DemoPageOverlayPortal';
import { HasChildren } from '../web-chat-dependencies/types/HasChildren';

/**
 * This component provides a way to surface a modal with custom content.
 */
function DemoPageModal({ children }: HasChildren) {
  return (
    <DemoPageOverlayPortal>
      <div className="DemoPageModal__Background">
        <div className="DemoPageModal">{children}</div>
      </div>
    </DemoPageOverlayPortal>
  );
}

export { DemoPageModal };
