/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { OverflowMenuItem, OverflowMenuItemButtonProps } from 'carbon-components-react';
import React, { Ref } from 'react';

import { DemoSiteParamsAndHash } from '../framework/demoTypes';
import { useExtractDemoLocationProps } from '../framework/hooks/useExtractDemoLocationProps';
import { useNavigateOnClick } from '../framework/hooks/useNavigateOnClick';

type DemoOverflowMenuItemProps = OverflowMenuItemButtonProps & DemoSiteParamsAndHash;

/**
 * This component returns a carbon overflow menu item that acts as a navigation link and prevents a page refresh. This
 * is useful when we need a carbon button to navigate a user to a new page or panel.
 */
function DemoOverflowMenuItem(props: DemoOverflowMenuItemProps, ref: Ref<OverflowMenuItem>) {
  const { paramsAndHash, className, onClick, children, ...regularProps } = useExtractDemoLocationProps(props);
  const navigateOnClick = useNavigateOnClick(onClick, paramsAndHash);
  return (
    <OverflowMenuItem ref={ref} {...regularProps} onClick={navigateOnClick}>
      {children}
    </OverflowMenuItem>
  );
}

// Note: Use forward ref to prevent react from surfacing a warning about using "ref".
const DemoOverflowMenuItemExport = React.forwardRef(DemoOverflowMenuItem);

export { DemoOverflowMenuItemExport as DemoOverflowMenuItem };
