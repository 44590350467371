/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { WatsonAssistantChatInstance } from '../../web-chat-dependencies/types/WatsonAssistantChatInstance';

const WebChatInstanceContext = React.createContext<WatsonAssistantChatInstance>(null);

export { WebChatInstanceContext };
