/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { LendyrSection, RestartType } from '../../demoTypes';
import { demoActions } from '../../store/demoActions';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const HOME_SECTION_TRANSITION_CONFIG: DemoTransitionConfig = {
  onTransition: store => {
    // When the user transitions away from the home panel, web chat should reload.
    return () => {
      const { demoSiteParamsAndHash, isWebChatLoaded } = store.getState();
      const currentSection = demoSiteParamsAndHash.section;

      // We only want web chat to reload if the section the user is visiting is a tour.
      if (isWebChatLoaded && currentSection !== LendyrSection.INDEX) {
        store.dispatch(demoActions.doRestartType(RestartType.RELOAD_WEB_CHAT));
      }
    };
  },
};

export { HOME_SECTION_TRANSITION_CONFIG };
