/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { DemoApp } from './DemoApp';
import { DemoControllerComponent } from './framework/DemoControllerComponent';
import { doCreateDemoStore } from './framework/store/doCreateDemoStore';
import { DEMO_SITE_SCROLLBAR_WIDTH } from './framework/utils/demoConstants';

// Need to wait until the DOM is there to get element references.
window.addEventListener('DOMContentLoaded', () => {
  const store = doCreateDemoStore();

  document.body.style.setProperty('--DemoSite-Scrollbar-width', `${DEMO_SITE_SCROLLBAR_WIDTH}px`);

  ReactDOM.render(
    <Provider store={store}>
      <DemoControllerComponent>
        <DemoApp />
      </DemoControllerComponent>
    </Provider>,
    document.querySelector('#react-root'),
  );
});
