/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const SEARCH_NO_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Set up Search integration',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-search-add',
    type: 'Docs',
  },
  {
    title: 'Bring your own search',
    link: 'https://medium.com/ibm-watson/bring-your-own-search-to-ibm-watson-assistant-587e77410c98',
    type: 'Article',
  },
];

function SearchPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Search"
        description="Help your users find the information they need by enabling Watson Assistant to search through your existing content. Watson Assistant can connect to any search system so you can leverage your existing website search, knowledge base search, or content management system (CMS) search. Integrate with IBM Watson Discovery, Google Search, Coveo, and more in order to surface relevant help content based on the users' goals."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          View the default search experience by entering in the following:
          <GuidancePanelTextSnippet text="Can I use my points on airfare?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          View search results that appear as in-line text by entering the following:
          <GuidancePanelTextSnippet text="What are Lendyr Promotions for checking accounts?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          View search results that appear in a carousel by entering the following:
          <GuidancePanelTextSnippet text="What are the different checking bonuses you offer?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          View search results that appear in a table by entering the following:
          <GuidancePanelTextSnippet text="Show me checking account bonuses" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          View search results that are filtered based on user information by entering the following: "Account specific
          promotions", then select "Business" or "Personal".
          <GuidancePanelTextSnippet text="Account specific promotions" />
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <SearchPanelImplementationSection />
    </div>
  );
}

function SearchPanelImplementationSection() {
  return (
    <GuidancePanelImplementationSection>
      <GuidancePanelImplementationTile data={SEARCH_NO_CODE_LINKS} />
    </GuidancePanelImplementationSection>
  );
}

const SearchPanelConfig: SectionPanelConfig = {
  title: 'Search',
  SectionPanelComponent: SearchPanel,
  panel: LendyrPanel.SEARCH,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.SEARCH,
};

export { SearchPanel, SearchPanelImplementationSection, SearchPanelConfig };
