/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Store } from 'redux';

import { demoActions } from '../../store/demoActions';
import { DemoAppState } from '../../store/DemoAppState';
import { MORTGAGE_CALCULATOR_ELEMENT_ID } from '../../utils/demoConstants';
import { scrollToMortgageCalculator } from '../../utils/demoUtils';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const HOME_LOANS_TRANSITION_CONFIG: DemoTransitionConfig = {
  onTransition: store => {
    // Programmatically scroll to the mortgage calculator since the auto-scrolling to the element doesn't work.
    if (store.getState().demoSiteParamsAndHash.hash === MORTGAGE_CALCULATOR_ELEMENT_ID) {
      scrollToMortgageCalculator();
    }

    return () => resetHomeLoansPageState(store);
  },

  restartOptions: {
    onClickRestart: resetHomeLoansPageState,
  },
};

function resetHomeLoansPageState(store: Store<DemoAppState>) {
  store.dispatch(
    demoActions.setHomeLoansPageStateProperty('mortgageCalculatorFields', {
      creditScore: 0,
      loanAmount: 0,
      loanTerm: null,
    }),
  );
  store.dispatch(demoActions.setHomeLoansPageStateProperty('currentMortgageCalculatorStep', null));
}

export { HOME_LOANS_TRANSITION_CONFIG };
