/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelBackButton.css';

import { ArrowLeft16 } from '@carbon/icons-react';
import React from 'react';

import { DemoSiteParamsAndHash } from '../../../framework/demoTypes';
import { DemoButton } from '../../DemoButton';

interface GuidancePanelBackButtonProps extends DemoSiteParamsAndHash {
  /**
   * The back button text.
   */
  label: string;

  /**
   * The callback function to fire when the back button is clicked.
   */
  onClick?: () => void;
}

/**
 * The back button for navigating back to a guidance panel.
 */
function GuidancePanelBackButton({ label, ...props }: GuidancePanelBackButtonProps) {
  return (
    <DemoButton
      className="GuidancePanelBackButton"
      kind="ghost"
      renderIcon={ArrowLeft16}
      hasIconOnly
      iconDescription={label}
      {...props}
    />
  );
}

export { GuidancePanelBackButton };
