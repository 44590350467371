/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidanceMobileViewWarningInlineNotification } from '../../commonComponents/GuidanceMobileViewWarningInlineNotification';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const SIZE_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: "Customize web chat's layout",
    link: 'https://web-chat.global.assistant.watson.cloud.ibm.com/docs.html?to=api-instance-methods#updatecssvariables',
    type: 'Docs',
  },
  {
    title: 'Implement a custom element',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/custom-element',
    type: 'Tutorial',
  },
];

function SizePanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Change Web Chat Size"
        description={<>Customize the width, and height of the web chat.</>}
      />
      <GuidanceMobileViewWarningInlineNotification />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>Open the assistant.</GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile codeNecessary data={SIZE_CODE_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const SizePanelConfig: SectionPanelConfig = {
  title: 'Change Web Chat Size',
  SectionPanelComponent: SizePanel,
  panel: LendyrPanel.SIZE,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.SIZE,
  withRestartButton: true,
};

export { SizePanel, SizePanelConfig };
