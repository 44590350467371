/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './Carousel.css';

import { Tile } from 'carbon-components-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SwiperSlide } from 'swiper/react';

import lendyrTopazCard from '../../assets/gs-fin-execmgmt.png';
import lendyrPreferredCard from '../../assets/gs-fin-mgmt-3.png';
import lendyrEverydayCard from '../../assets/gs-fin-wellness.png';
import lendyrEverydayTest from '../../assets/test.png';
import { LendyrPage } from '../../framework/demoTypes';
import { useWebChatInstance } from '../../framework/hooks/useWebChatInstance';
import { demoActions } from '../../framework/store/demoActions';
import { DemoAppState } from '../../framework/store/DemoAppState';
import { CREDIT_CARD_OFFERS, LendyrCard } from '../../pages/creditCardsPage/CreditCardOffers';
import { IS_MOBILE } from '../../web-chat-dependencies/utils/browserUtils';
import { CarouselContainer } from '../CarouselContainer';
import { LendyrButton } from '../LendyrButton';

const CREDIT_CARD_DATA = [
  {
    type: LendyrCard.EVERYDAY,
    title: 'Financial Wellness',
    description: 'Get started with your financial wellness benefit offered by your employer.',
    buttonText: 'Ready to get started?',
    dataURI: lendyrEverydayCard,
    hash: 'everyday-card',
    alt: 'everyday card',
    source_url: 'https://wellness.ayco.com/financialwellness',
  },
  {
    type: LendyrCard.PREFERRED,
    title: 'Financial Management',
    description: 'A financial benefit that meets the needs of increasing financial complexity',
    buttonText: 'Ready to get started?',
    dataURI: lendyrPreferredCard,
    hash: 'preferred-card',
    alt: 'preferred card',
    source_url: 'https://www.ayco.com/employees/management.html',
  },
  {
    type: LendyrCard.TOPAZ,
    title: 'Executive Financial Management',
    description: 'A financial corporate benefit for executives and senior leaders.',
    buttonText: 'Contact an advisor',
    dataURI: lendyrTopazCard,
    hash: 'topaz-card',
    alt: 'topaz card',
    source_url: 'https://www.ayco.com/employees/executive.html',
  },
  {
    type: LendyrCard.TOPAZ,
    title: 'SurvivorSupport®',
    description: 'Specialized guidance to help you navigate financial decisions after the loss of a loved one.',
    buttonText: 'Contact us',
    dataURI: lendyrEverydayTest,
    hash: 'topaz-card',
    alt: 'topaz card',
    source_url: 'https://www.ayco.com/employees/survivor-support.html',
  },
];

/**
 * A carousel component for displaying swipe-able content that can be configured with custom content that the user
 * can swipe between.
 */
function Carousel() {
  const instance = useWebChatInstance();
  const mobileView = useSelector((state: DemoAppState) => state.demoSiteParamsAndHash.mobileView);
  const dispatch = useDispatch();

  return (
    <CarouselContainer>
      {CREDIT_CARD_DATA.map(({ type, dataURI, title, description, buttonText, hash, alt, source_url }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={index}>
          <Tile className="Carousel__Card">
            <img className="Carousel__CardImage" src={dataURI} alt={alt} />
            <div className="Carousel__CardText">
              <div className="Carousel__CardTitle">{title}</div>
              <div className="Carousel__CardDescription">{description}</div>
            </div>
            <LendyrButton
              className="Carousel__CardButton"
              page={LendyrPage.STUDENT_LOANS}
              hash={hash}
              kind="secondary"
              onClick={() => {
                if (IS_MOBILE || mobileView) {
                  instance.closeWindow();
                }
              }}
            >
              Learn more
            </LendyrButton>
            <LendyrButton
              className="Carousel__CardButton"
              page={LendyrPage.CREDIT_CARDS}
              hash={hash}
              onClick={
                () => window.open(source_url, '_blank')
                // const cardOffer = getCardOffer(type);
                // dispatch(demoActions.setCreditCardPageStateProperty('selectedCardOffer', cardOffer));
              }
            >
              {buttonText}
            </LendyrButton>
          </Tile>
        </SwiperSlide>
      ))}
    </CarouselContainer>
  );
}

function getCardOffer(type: LendyrCard) {
  return CREDIT_CARD_OFFERS.find(cardOffer => cardOffer.type === type);
}

const CarouselExport = React.memo(Carousel);

export { CarouselExport as Carousel };
