/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './DemoPage.css';

import cx from 'classnames';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import lendyrBackgroundPatternHeaderPrimary from '../assets/lendyr-background-pattern-header-primary.svg';
import lendyrBackgroundPatternHeaderSecondary from '../assets/lendyr-background-pattern-header-secondary.svg';
import lendyrBackgroundPatternHeaderTertiary from '../assets/lendyr-background-pattern-header-tertiary.svg';
import { LendyrPage, LendyrPageType } from '../framework/demoTypes';
import { useDemoLocation } from '../framework/hooks/selectorHooks';
import { usePageWidth } from '../framework/hooks/usePageWidth';
import { DemoAppState } from '../framework/store/DemoAppState';
import { CardLinkComponentProps } from '../framework/types/CardLinkComponentProps';
import { BREAKPOINT_756, BREAKPOINT_960, MOBILE_VIEW_WIDTH } from '../framework/utils/demoConstants';
import { HasChildren } from '../web-chat-dependencies/types/HasChildren';
import { HasClassName } from '../web-chat-dependencies/types/HasClassName';
import { IS_MOBILE } from '../web-chat-dependencies/utils/browserUtils';
import { CardLinkComponent } from './CardLinkComponent';
import { DemoPageHeader } from './DemoPageHeader';
import { PageLink } from './PageLink';

interface DemoPageProps extends HasChildren, HasClassName {
  /**
   * The title of the page.
   */
  title: string;

  /**
   * A description of the page.
   */
  description: string;

  /**
   * Objects that describe a card link to display to navigate the user somewhere.
   */
  cardLinks: CardLinkComponentProps[];

  /**
   * Custom content to append to the section right under the top content.
   */
  MainContent: JSX.Element;

  /**
   * Custom content to append to the top section of the page right under the page title.
   */
  TopContent?: JSX.Element;

  /**
   * The type of demo page to render.
   */
  type?: LendyrPageType;
}

/**
 * The demo page web chat will interact for demo purposes. If a page requires additional content than the available
 * real estate provides, the demo page can be used to wrap around content that will be rendered above the footer and
 * under the main content.
 */
function DemoPage({
  className,
  title,
  description,
  TopContent,
  MainContent,
  cardLinks,
  children,
  type,
}: DemoPageProps) {
  const { mobileView } = useDemoLocation();
  const pageWidth = usePageWidth();
  const preventPageScroll = useSelector((state: DemoAppState) => state.preventPageScroll);
  const isMobileViewEnabled = useSelector((state: DemoAppState) => state.isMobileViewEnabled);
  const demoPageElementRef = useRef<HTMLDivElement>();
  const hasCardLinks = Boolean(cardLinks?.length);
  const isEmulatedMobileView = !IS_MOBILE && mobileView && isMobileViewEnabled;
  // Set the width of the demo page based on the state of the demo site.
  const demoPageWidth = isEmulatedMobileView ? MOBILE_VIEW_WIDTH : pageWidth;
  const isDesktop = demoPageWidth > BREAKPOINT_960;

  return (
    <div
      ref={demoPageElementRef}
      className={cx('DemoPage', className, {
        'DemoPage--preventScroll': preventPageScroll,
        [`DemoPage--min-${BREAKPOINT_756}px`]: demoPageWidth >= 756,
        [`DemoPage--min-${BREAKPOINT_960}px`]: isDesktop,
        'DemoPage--secondaryPage': type === LendyrPageType.SECONDARY,
        'DemoPage--tertiaryPage': type === LendyrPageType.TERTIARY,
      })}
    >
      <div
        className={cx('DemoPage__TopContent', {
          'DemoPage__TopContent--cardsPadding': hasCardLinks,
        })}
        // style={{ backgroundImage: `url(${headerBackgroundPattern(type)})` }}
      >
        <DemoPageHeader isDesktop={isDesktop} type={type} />
        <div className="DemoPage__CenterContent">
          <div className="DemoPage__Description">{description}</div>
          <div className="DemoPage__Title">{title}</div>
        </div>
        {TopContent && <div className="DemoPage__ExternalTopContent DemoPage__CenterContent">{TopContent}</div>}
        {hasCardLinks && (
          <div className="DemoPage__CardsWrapper">
            <div className="DemoPage__CardsMenu">
              {cardLinks.map((props, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <CardLinkComponent key={index} {...props} />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={cx('DemoPage__MainContent', { 'DemoPage__MainContent--cardsPadding': hasCardLinks })}>
        {MainContent}
      </div>
      {/* Render any custom content to the page. */}
      {children}
      <footer className="DemoPage__Footer">
        <div className="DemoPage__FooterListsContainer">
          <div className="DemoPage__FooterList">
            <div className="DemoPage__FooterListTitle">Products</div>
            <div>
              <PageLink className="DemoPage__FooterLink" page={LendyrPage.STUDENT_LOANS}>
                Employee Programs
              </PageLink>
            </div>
            <div>
              <PageLink className="DemoPage__FooterLink" page={LendyrPage.PERSONAL_LOANS}>
                Individual Services
              </PageLink>
            </div>
            <div>
              <PageLink className="DemoPage__FooterLink" page={LendyrPage.HOME_LOANS}>
                Manage Wealth
              </PageLink>
            </div>
            <div>
              <PageLink className="DemoPage__FooterLink" page={LendyrPage.CREDIT_CARDS}>
                Credit Cards
              </PageLink>
            </div>
          </div>
          <div className="DemoPage__FooterList">
            <div className="DemoPage__FooterListTitle">Support</div>
            <div>
              <PageLink className="DemoPage__FooterLink" page={LendyrPage.FAQ}>
                FAQs
              </PageLink>
            </div>
            <div className="DemoPage__FooterLink">Chat with us</div>
            <div className="DemoPage__FooterLink">Security</div>
          </div>
          <div className="DemoPage__FooterList">
            <div className="DemoPage__FooterListTitle">Partners</div>
            <div className="DemoPage__FooterLink">Affiliates</div>
            <div className="DemoPage__FooterLink">Employers</div>
            <div className="DemoPage__FooterLink">Financial Advisors</div>
          </div>
          <div className="DemoPage__FooterList">
            <div className="DemoPage__FooterListTitle">Company</div>
            <div className="DemoPage__FooterLink">Blog</div>
            <div className="DemoPage__FooterLink">Careers</div>
          </div>
          <div className="DemoPage__FooterList">
            <div className="DemoPage__FooterListTitle">Legal</div>
            <div className="DemoPage__FooterLink">Terms of Use</div>
            <div className="DemoPage__FooterLink">Privacy Policy</div>
          </div>
        </div>
      </footer>
    </div>
  );
}

/**
 * Depending on the type of the demo page being rendered return a different pattern for the header background.
 *
 * @param type The type of demo page to render, {@link LendyrPageType}.
 * @returns an svg.
 */
// function headerBackgroundPattern(type: string) {
//   switch (type) {
//     case LendyrPageType.SECONDARY:
//       return lendyrBackgroundPatternHeaderSecondary;
//     case LendyrPageType.TERTIARY:
//       return lendyrBackgroundPatternHeaderTertiary;
//     default:
//       return lendyrBackgroundPatternHeaderPrimary;
//   }
// }

export { DemoPage };
