/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import cx from 'classnames';
import React from 'react';

import { HasClassName } from '../web-chat-dependencies/types/HasClassName';

type LendyrLogoProps = HasClassName;

/**
 * The Lendyr site logo as a svg.
 */
function LendyrLogo({ className }: LendyrLogoProps) {
  return (
    <svg
      className={cx('LendyrLogo', className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22H4C2.89543 22 2 21.1046 2 20V12Z"
        strokeWidth="4"
      />
    </svg>
  );
}

export { LendyrLogo };
