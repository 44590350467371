/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2020, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * This is the abstract base class that defines the common functionality across all instances of the various service
 * desk implementations.
 */

import { PublicServiceDeskConfig } from '../../../submodules/wa-fd-types-public/integration-configuration-types';
import { MessageRequest, MessageResponse } from '../../../submodules/wa-fd-types-public/wa-types';
import { ServiceDeskStateFromWAC } from '../HumanAgentService';
import { ServiceDesk, ServiceDeskCallback, StartChatOptions } from './ServiceDesk';

abstract class ServiceDeskImpl implements ServiceDesk {
  /**
   * The callback that is used to send events back to the chat widget.
   */
  callback: ServiceDeskCallback;

  /**
   * The configuration required by the specific implementation of the service desk for connecting to the actual
   * service desk.
   */
  config: PublicServiceDeskConfig;

  /**
   * The current values of pieces of state from the main chat. These values may change at any time.
   */
  state: ServiceDeskStateFromWAC;

  constructor(callback: ServiceDeskCallback, config: PublicServiceDeskConfig) {
    this.callback = callback;
    this.config = config;
  }

  /**
   * Informs the service desk of a change in the state of the web chat that is relevant to the service desks. These
   * values may change at any time.
   */
  public updateState(state: ServiceDeskStateFromWAC) {
    this.state = state;
  }

  /**
   * Instructs the service desk to start a new chat. This should be called immediately after the service desk
   * instance has been created. It will make the appropriate calls to the service desk and begin communicating back
   * to the calling code using the callback produce to the instance. This may only be called once per instance.
   *
   * @param connectMessage The original server message response that caused the connection to an agent. It will
   * contain specific information to send to the service desk as part of the connection. This can includes things
   * like a message to display to a human agent.
   * @param startChatOptions Configuration for agent application installed inside the service desk.
   * @returns Returns a Promise that resolves when the service desk has successfully started a new chat. This does
   * not necessarily mean that an agent has joined the conversation or has read any messages sent by the user.
   */
  abstract startChat(connectMessage: MessageResponse, startChatOptions: StartChatOptions): Promise<void>;

  /**
   * Tells the service desk to terminate the chat.
   *
   * @returns Returns a Promise that resolves when the service desk has successfully handled the call.
   */
  abstract endChat(): Promise<void>;

  /**
   * Sends a message to the agent in the service desk.
   *
   * @param message The message from the user.
   * @param messageID The unique ID of the message assigned by the widget.
   * @returns Returns a Promise that resolves when the service desk has successfully handled the call.
   */
  abstract sendMessageToAgent(message: MessageRequest, messageID: string): Promise<void>;

  /**
   * Informs the service desk that the user has read all the messages that have been sent by the service desk.
   *
   * This functionality is not currently implemented in the web chat widget.
   *
   * @returns Returns a Promise that resolves when the service desk has successfully handled the call.
   */
  abstract userReadMessages(): Promise<void>;

  /**
   * Tells the service desk if a user has started or stopped typing.
   *
   * This functionality is not currently implemented in the web chat widget.
   *
   * @param isTyping If true, indicates that the user is typing. False indicates the user has stopped typing.
   * @returns Returns a Promise that resolves when the service desk has successfully handled the call.
   */
  abstract userTyping(isTyping: boolean): Promise<void>;

  /**
   * Checks if any agents are online and ready to communicate with the user.
   *
   * @param connectMessage The message that contains the transfer_info object that may be used by the service desk
   * so it can perform a more specific check.
   * @returns True if some agents are available or false if no agents are available. This may also return null which
   * means the availability status of agents is unknown or the service desk doesn't support this information.
   */
  abstract areAnyAgentsOnline(connectMessage: MessageResponse): Promise<boolean>;
}

export { ServiceDeskImpl };
