/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelInfoText } from '../../commonComponents/GuidancePanelInfoText';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const LAUNCHER_AND_HOME_SCREEN_NO_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Set up launcher',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-web-chat-config#web-chat-configure-launcher',
    type: 'Docs',
  },
  {
    title: 'Set up home screen',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-web-chat-config#web-chat-configure-home-screen',
    type: 'Docs',
  },
  {
    title: 'Change style and appearance',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-web-chat-style',
    type: 'Docs',
  },
];

const LAUNCHER_AND_HOME_SCREEN_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Implement custom home screen content',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/home-screen-custom-element',
    type: 'Tutorial',
  },
  {
    title: 'Update launcher and home screen text',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/change-launcher-and-home-screen-text',
    type: 'Tutorial',
  },
];

function LauncherAndHomeScreenPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Launcher & Chat Home"
        description="The Launcher expands to show the Home Screen of the Assistant, welcoming users when they need help and guiding them towards starting a conversation."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Wait 15 seconds for the launcher to expand into the Chat Window (in the lower right corner) and show a
          greeting message.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          To begin a conversation with the assistant, click on the greeting message or the assistant icon.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelInfoText text="Don't see the animation? Click the restart button." />
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={LAUNCHER_AND_HOME_SCREEN_NO_CODE_LINKS} />
        <GuidancePanelImplementationTile codeNecessary data={LAUNCHER_AND_HOME_SCREEN_CODE_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const LauncherAndHomeScreenPanelConfig: SectionPanelConfig = {
  title: 'Launcher & Chat Home',
  SectionPanelComponent: LauncherAndHomeScreenPanel,
  panel: LendyrPanel.LAUNCHER_AND_HOME_SCREEN,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.LAUNCHER_AND_HOME_SCREEN,
  withRestartButton: true,
};

export { LauncherAndHomeScreenPanel, LauncherAndHomeScreenPanelConfig };
