/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2020, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import {
  ConnectToAgentItem,
  GenericItem,
  IFrameItem,
  MessageResponseTypes,
  OptionItem,
  SearchResultItem,
  SuggestionItem,
  TextItem,
  UserDefinedItem,
  VideoItem,
} from '../../submodules/wa-fd-types-public/wa-types';

/**
 * This function determines if the given generic item is a text item. This function acts as a type guard which will
 * narrow the type to {@link TextItem} if it returns true.
 */
function isTextItem(item: GenericItem): item is TextItem {
  return item && item.response_type === 'text' && (item as TextItem).text !== undefined;
}

/**
 * This function determines if the given generic item is an iFrame item. This function acts as a type guard which will
 * narrow the type to {@link IFrameItem} if it returns true.
 */
function isIFrameItem(item: GenericItem): item is IFrameItem {
  return item && item.response_type === 'iframe' && (item as IFrameItem).source !== undefined;
}

/**
 * This function determines if the given generic item is a video item. This function acts as a type guard which will
 * narrow the type to {@link VideoItem} if it returns true.
 */
function isVideoItem(item: GenericItem): item is VideoItem {
  return item && item.response_type === 'video' && (item as VideoItem).source !== undefined;
}

/**
 * This function determines if the given generic item is a user_defined item. This function acts as a type guard which
 * will narrow the type to {@link UserDefinedItem} if it returns true.
 */
function isUserDefinedItem(item: GenericItem): item is UserDefinedItem {
  return item && item.response_type === 'user_defined' && (item as UserDefinedItem).user_defined !== undefined;
}

/**
 * This function determines if the given generic item is a suggestion item. This function acts as a type guard which
 * will narrow the type to {@link SuggestionItem} if it returns true.
 */
function isSuggestionItem(item: GenericItem): item is SuggestionItem {
  return item && item.response_type === 'suggestion' && (item as SuggestionItem).suggestions !== undefined;
}

/**
 * This is a type guard that determines if the given item is an {@link OptionItem} item.
 */
function isOptionItem(item: GenericItem): item is OptionItem {
  return item && item.response_type === MessageResponseTypes.OPTION && (item as OptionItem).options !== undefined;
}

/**
 * Indicates if the dialog response is a "connect_to_agent" message.
 */
function isConnectToAgent(response: GenericItem): response is ConnectToAgentItem {
  return response && response.response_type === MessageResponseTypes.CONNECT_TO_AGENT;
}

/**
 * Determines if the given search result item is empty (has no results in it).
 */
function isSearchResultItem(item: GenericItem): item is SearchResultItem {
  return item?.response_type === MessageResponseTypes.SEARCH;
}

export {
  isTextItem,
  isIFrameItem,
  isVideoItem,
  isUserDefinedItem,
  isSuggestionItem,
  isOptionItem,
  isConnectToAgent,
  isSearchResultItem,
};
