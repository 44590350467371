/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { LendyrSection } from '../../../../framework/demoTypes';
import { GuidancePanelContainer } from '../../commonComponents/GuidancePanelContainer';
import { BUILD_EXPERIENCE_CONFIGS, BUILD_EXPERIENCE_SECTION_MAP } from '../../featureGuidancePanelUtils';

function BuildExperienceSectionComponent() {
  return (
    <GuidancePanelContainer
      sectionPanelConfigsMap={BUILD_EXPERIENCE_SECTION_MAP}
      sectionPanelConfigs={BUILD_EXPERIENCE_CONFIGS}
      section={LendyrSection.BUILD_EXPERIENCE}
    />
  );
}

export { BuildExperienceSectionComponent };
