/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelInfoText } from '../../commonComponents/GuidancePanelInfoText';
import { GuidancePanelIntroInstructions } from '../../commonComponents/GuidancePanelIntroInstructions';
import { GuidancePanelList, GuidancePanelListItem } from '../../commonComponents/GuidancePanelList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function EssentialsIntroPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Web Chat Essentials"
        description="This feature tour will introduce you to the essential features of Watson Assistant web chat."
      />
      <GuidancePanelIntroInstructions />
      <GuidancePanelInfoText text="Something not working? Want to retry a feature? Click the restart button above." />
    </div>
  );
}

function EssentialsPrimaryThemes() {
  return (
    <GuidancePanelList>
      <GuidancePanelListItem>
        Personalized and consumable help that's powered by an extensible web chat
      </GuidancePanelListItem>
      <GuidancePanelListItem>Increased adoption of your self-serve applications</GuidancePanelListItem>
      <GuidancePanelListItem>
        Intelligent fallback methods to ensure users can always find a path to their solution
      </GuidancePanelListItem>
    </GuidancePanelList>
  );
}

const EssentialsIntroPanelConfig: SectionPanelConfig = {
  title: 'Essentials Intro',
  SectionPanelComponent: EssentialsIntroPanel,
  panel: LendyrPanel.HOME,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.WEB_CHAT_ESSENTIALS_INTRO,
};

export { EssentialsIntroPanel, EssentialsPrimaryThemes, EssentialsIntroPanelConfig };
