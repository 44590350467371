/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React, { useEffect, useState } from 'react';

import { LendyrPage, LendyrPanel, LendyrSection } from '../../../framework/demoTypes';
import { useDemoLocation } from '../../../framework/hooks/selectorHooks';
import { SectionPanelConfig, SectionPanelMapConfig } from '../featureGuidancePanelUtils';
import { GuidancePanelContentContainer } from './GuidancePanelContentContainer';
import { GuidancePanelHeader } from './GuidancePanelHeader';
import { GuidancePanelTourNavigation } from './GuidancePanelTourNavigation';

interface GuidancePanelContainerProps {
  /**
   * A map of section panel map configs that describe panels in a tour to render with references to the configs of
   * next and previous panels in the tour.
   */
  sectionPanelConfigsMap: Map<string, SectionPanelMapConfig>;

  /**
   * The list of sections panel configs for a tour.
   */
  sectionPanelConfigs: SectionPanelConfig[];

  /**
   * The section name of the tour.
   */
  section: LendyrSection;

  /**
   * Determines if the restart button should be visible in the tour.
   */
  showRestartButton?: boolean;
}

/**
 * This guidance panel container component handles managing and rendering a section tour with navigation capabilities.
 */
function GuidancePanelContainer({
  sectionPanelConfigsMap,
  sectionPanelConfigs,
  section,
  showRestartButton,
}: GuidancePanelContainerProps) {
  const { panel, page } = useDemoLocation();
  const [selectedSubViewIndex, setSelectedSubViewIndex] = useState(0);
  const { index, config, previousSectionPanel, nextSectionPanel } = sectionPanelConfigsMap.get(
    panel || LendyrPanel.HOME,
  );
  const { SectionPanelComponent, totalSubViews } = config;

  const previousSectionPanelConfig = sectionPanelConfigsMap.get(previousSectionPanel)?.config;
  const nextSectionPanelConfig = sectionPanelConfigsMap.get(nextSectionPanel)?.config;

  // The total number of panels, not including the intro and summary panels.
  const totalPanels = sectionPanelConfigs.length - 2;
  const isSummaryPanel = index === sectionPanelConfigs.length - 1;

  const fallbackPageValue = getFallbackPageValue(page);

  // Handles panel sub-views navigation.
  const hasSubViews = Boolean(totalSubViews);
  const preventNavigationToPrevious = hasSubViews && selectedSubViewIndex !== 0;
  const preventNavigationToNext = hasSubViews && selectedSubViewIndex !== totalSubViews - 1;

  // This effect specifically handles resetting the selected sub-view to 0 when leaving a panel so the next panel with
  // sub-views visited starts at 0 too.
  useEffect(() => {
    if (panel) {
      return () => {
        setSelectedSubViewIndex(0);
      };
    }

    return null;
  }, [panel]);

  return (
    <GuidancePanelContentContainer>
      <GuidancePanelHeader showRestartButton={showRestartButton} />
      <div className="GuidancePanel__MainContent">
        <SectionPanelComponent onSubViewChange={setSelectedSubViewIndex} selectedSubViewIndex={selectedSubViewIndex} />
      </div>
      <GuidancePanelTourNavigation
        section={section}
        index={index}
        isIntroPanel={index === 0}
        isSummaryPanel={isSummaryPanel}
        totalPanels={totalPanels}
        previousSectionPanelConfig={previousSectionPanelConfig}
        nextSectionPanelConfig={nextSectionPanelConfig}
        fallbackPageValue={fallbackPageValue}
        preventNavigationToPrevious={preventNavigationToPrevious}
        preventNavigationToNext={preventNavigationToNext}
        onPreviousButtonClick={() => {
          if (preventNavigationToPrevious) {
            setSelectedSubViewIndex(subItemIndex => subItemIndex - 1);
          }
        }}
        onNextButtonClick={() => {
          if (preventNavigationToNext) {
            setSelectedSubViewIndex(subItemIndex => subItemIndex + 1);
          }
        }}
      />
    </GuidancePanelContentContainer>
  );
}

/**
 * Returns the fallback page value for the back button in a tour when the user visits a certain page and is leaving
 * a tour.
 */
function getFallbackPageValue(page: string) {
  switch (page) {
    case LendyrPage.EMAIL:
    case LendyrPage.PHONE_TOUR:
    case LendyrPage.ITERATE_QUICKLY_TOUR:
    case LendyrPage.DRIVE_CUSTOMER_OUTCOMES_TOUR:
    case LendyrPage.GENERATIVE_AI_TOUR:
    case LendyrPage.BUILD_EXPERIENCE_TOUR:
      return LendyrPage.HOME;
    default:
      return null;
  }
}

export { GuidancePanelContainer };
