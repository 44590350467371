/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './FeatureListSectionComponent.css';

import React from 'react';

import { LendyrPage, LendyrPanel, LendyrSection } from '../../../../framework/demoTypes';
import { useDemoLocation } from '../../../../framework/hooks/selectorHooks';
import { GuidancePanelBackButton } from '../../commonComponents/GuidancePanelBackButton';
import { GuidancePanelContentContainer } from '../../commonComponents/GuidancePanelContentContainer';
import { GuidancePanelHeader } from '../../commonComponents/GuidancePanelHeader';
import { FEATURE_LIST_SECTION_MAP } from '../../featureGuidancePanelUtils';

/**
 * The feature index panel lists out all the available feature demos to directly display by clicking from a list of
 * buttons.
 */
function FeatureListSectionComponent() {
  const { panel, page } = useDemoLocation();
  const featurePanelMapConfig = FEATURE_LIST_SECTION_MAP.get(panel || LendyrPanel.HOME);
  const SectionPanelComponent = featurePanelMapConfig?.config.SectionPanelComponent;
  const withRestartButton = featurePanelMapConfig?.config.withRestartButton;
  const isFeaturePanelSelected = Boolean(panel);
  const backButtonPanelName = isFeaturePanelSelected ? LendyrSection.INDEX : LendyrSection.HOME;
  // The back button in the email page should take the user to the home page
  const backButtonPage = getBackButtonPageValue(page, panel);

  return (
    <GuidancePanelContentContainer>
      <GuidancePanelHeader showRestartButton={withRestartButton}>
        {isFeaturePanelSelected && (
          <GuidancePanelBackButton label="Feature list" section={backButtonPanelName} page={backButtonPage} panel="" />
        )}
      </GuidancePanelHeader>
      <div className="GuidancePanel__MainContent">
        <SectionPanelComponent />
      </div>
    </GuidancePanelContentContainer>
  );
}

/**
 * Determines if the back button should navigate the user to the home page depending on the page/panel the user is
 * visiting.
 */
function getBackButtonPageValue(page: string, panel: string) {
  if (page === LendyrPage.EMAIL || panel === LendyrPanel.POSITION) {
    return LendyrPage.HOME;
  }

  return null;
}

export { FeatureListSectionComponent };
