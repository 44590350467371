/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * Describes the different restart actions for web chat upon clicking the restart button.
 */
enum RestartType {
  /**
   * Will simply restart web chat through {@link instance.restartConversation}.
   */
  RESTART_CONVERSATION,

  /**
   * Will destroy then reload web chat.
   */
  RELOAD_WEB_CHAT,
}

/**
 * The different custom panel types that can be rendered.
 */
enum CustomPanelType {
  POST_CHAT_SURVEY = 'post-chat-survey',
  PRE_CHAT_FORM = 'pre-chat-form',
  NONE = 'none',
}

/**
 * These are the different demo feature panel names that are used for tracking when the user views the guidance panel.
 * These names have become slightly out of date as lendyr has evolved however we're choosing to leave them out of date,
 * so we don't break the existing tracking.
 */
enum DemoFeaturePanelsTrackingNames {
  PRE_CHAT_FORM = 'Engagement: Pre-chat form',
  LAUNCHER = 'Engagement: Launcher',
  CUSTOM_LAUNCHER = 'Engagement: Custom launcher',
  HOME_SCREEN = 'Engagement: Home Screen',
  LAUNCHER_AND_HOME_SCREEN = 'Engagement: Launcher & Home Screen',
  SIZE = 'Engagement: Change the size of web chat',
  DEEP_LINKING = 'Engagement: Deep linking',
  VIDEO = 'Rich media: Video',
  IFRAME = 'Rich media: Iframe',
  POSITION = 'Change web chat position',
  CUSTOM_RESPONSE_EXTENSIONS = 'Integrations: Custom Response Types Powered by Extensions',
  FORM_FILLING = 'Integrations: Form filling',
  DIGITAL_ADOPTION_PLATFORM_INTEGRATION = 'Digital Adoption Platform Integration',
  ASK_CLARIFYING_QUESTIONS = 'Diagnose & Recover: Ask Clarifying Questions',
  SEARCH = 'Diagnose & Recover: Search',
  SUGGESTIONS = 'Diagnose & Recover: Suggestions',
  SEARCH_IN_SUGGESTIONS = 'Diagnose & Recover: Search in Suggestions',
  LIVE_AGENT = 'Diagnose & Recover: Live Agent',
  CHAT_SURVEY = 'Feedback: Chat survey',
  TICKETING = 'Filing a ticket',
  OPEN_FROM_BUTTONS = 'Open from buttons',
  JOURNEYS = 'Journeys',
  RPA = 'RPA',
  THEMING = 'Theming',
  CONVERSATION_SEARCH = 'Conversational Search',
  TELL = 'Tell',
  DO = 'Do',
  SHOW_1 = 'Show (1/2)',
  SHOW_2 = 'Show (2/2)',
  ACCESSIBLE_CONVERSATIONAL_SEARCH = 'Accessible & Scalable AI: Conversational Search',
  ACCESSIBLE_ASK_CLARIFYING_QUESTIONS = 'Accessible & Scalable AI: Ask Clarifying Questions',
  ACCESSIBLE_CHANGE_CONVERSATION_TOPIC = 'Accessible & Scalable AI: Change conversation topic',
  ACCESSIBLE_SEARCH = 'Accessible & Scalable AI: Search',
  ACCESSIBLE_SUGGESTIONS = 'Accessible & Scalable AI: Suggestions',
  UNIFY_CROSS_CHANNEL_HELP = 'Unify & Personalized: Cross channel help',
  UNIFY_PERSONALIZED_JOURNEYS = 'Unify & Personalized: Personalized journeys',
  PHONE_AUTHENTICATION = 'Phone: Authentication',
  PHONE_TRUSTED_AI = 'Phone: Trusted AI',
  PHONE_LIVE_AGENT = 'Phone: Live Agent',
  RECOGNIZE_GAPS = 'Iterate Quickly: Recognize gaps',
  CREATE_ACTIONS = 'Iterate Quickly: Create actions',
  RECOGNIZE_TRENDS = 'Iterate Quickly: Recognize trends',
  DIAGNOSE = 'Iterate Quickly: Diagnose',
  FIX_ISSUES_QUICKLY = 'Iterate Quickly: Fix issues quickly',
  PUBLISH_WITH_EASE = 'Iterate Quickly: Publish with ease',
  UNIFY_DATA = 'Drive Customer Outcome: Unify data',
  FORM_A_FULL_USER_JOURNEY = 'Drive Customer Outcome: Form a full user journey',
  GENERATIVE_CONVERSATIONAL_SEARCH = 'Generative AI: Conversational Search',
  GENERATIVE_ACCELERATED_AUTHORING = 'Generative AI: Accelerated Authoring',
  BUILD_EXPERIENCE_BUILDING_CONVERSATION = 'The Build Experience: Building Conversation',
  BUILD_EXPERIENCE_BUILDING_INTEGRATIONS = 'The Build Experience: Building Integrations',
  BUILD_EXPERIENCE_BUILDING_PHONE_EXPERIENCE = 'The Build Experience: Building Phone Experiences',
}

/**
 * These are the different intro and summary panels for a demo tour, these names are used for tracking when the user
 * views these panels.
 */
enum DemoSectionIntroAndSummaryTrackingNames {
  WEB_CHAT_ESSENTIALS_INTRO = 'Web chat essentials intro',
  WEB_CHAT_ESSENTIALS_SUMMARY = 'Web chat essentials summary',
  FEATURE_INDEX_INTRO = 'Feature index intro',
  TELL_DO_SHOW_INTRO = 'Tell, Do, & Show intro',
  TELL_DO_SHOW_SUMMARY = 'Tell, Do, & Show summary',
  ACCESSIBLE_SCALABLE_AI_INTRO = 'Accessible & Scalable AI intro',
  ACCESSIBLE_SCALABLE_AI_SUMMARY = 'Accessible & Scalable AI summary',
  UNIFY_PERSONALIZED_INTRO = 'Unify & Personalized intro',
  UNIFY_PERSONALIZED_SUMMARY = 'Unify & Personalized summary',
  PHONE_INTRO = 'Phone intro',
  PHONE_SUMMARY = 'Phone summary',
  ITERATE_QUICKLY_INTRO = 'Iterate Quickly intro',
  ITERATE_QUICKLY_SUMMARY = 'Iterate Quickly summary',
  DRIVE_CUSTOMER_OUTCOMES_INTRO = 'Drive Customer Outcomes intro',
  DRIVE_CUSTOMER_OUTCOMES_SUMMARY = 'Drive Customer Outcomes summary',
  GENERATIVE_AI_INTRO = 'Generative AI intro',
  GENERATIVE_AI_SUMMARY = 'Generative AI summary',
  BUILD_EXPERIENCE_INTRO = 'The Build Experience intro',
  BUILD_EXPERIENCE_SUMMARY = 'The Build Experience summary',
}

/**
 * The different lendyr pages.
 */
enum LendyrPage {
  HOME = '',
  STUDENT_LOANS = 'student-loans',
  PERSONAL_LOANS = 'personal-loans',
  HOME_LOANS = 'home-loans',
  CREDIT_CARDS = 'credit-cards',
  TRANSACTIONS = 'transactions',
  FAQ = 'faq',
  EMAIL = 'email',
  PHONE_TOUR = 'phone-tour',
  ITERATE_QUICKLY_TOUR = 'iterate-quickly-tour',
  DRIVE_CUSTOMER_OUTCOMES_TOUR = 'drive-customer-outcomes-tour',
  GENERATIVE_AI_TOUR = 'generative-ai-tour',
  BUILD_EXPERIENCE_TOUR = 'build-experience-tour',
}

/**
 * The different types of colors that determine the style of a lendyr page.
 */
enum LendyrPageType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

/**
 * The different types of panels for a guidance panel section. These values determine the panel to render in a tour.
 */
enum LendyrPanel {
  HOME = '',
  LAUNCHER_AND_HOME_SCREEN = 'launcher-and-homescreen',
  ASK_CLARIFYING_QUESTIONS = 'ask-clarifying-questions',
  SUGGESTIONS = 'suggestions',
  SEARCH = 'search',
  ESSENTIALS_SUMMARY = 'summary',
  SIZE = 'size',
  DEEP_LINKING = 'deep-linking',
  CHAT_SURVEY = 'chat-survey',
  OPEN_FROM_BUTTONS = 'open-from-buttons',
  PRE_CHAT_FORM = 'pre-chat-form',
  LIVE_AGENT = 'live-agent',
  POSITION = 'position',
  CUSTOM_LAUNCHER = 'custom-launcher',
  THEMING = 'theming',
  DIGITAL_ADOPTION_PLATFORM_INTEGRATION = 'digital-adoption-platform-integration',
  JOURNEYS = 'journeys',
  RPA = 'RPA',
  FORM_FILLING = 'form-filling',
  CONVERSATION_SEARCH = 'conversational-search',
  TELL = 'tell',
  DO = 'do',
  SHOW_1 = 'show-1',
  SHOW_2 = 'show-2',
  TELL_DO_SHOW_SUMMARY = 'tell-do-show-summary',
  ACCESSIBLE_CONVERSATIONAL_SEARCH = 'accessible-conversational-search',
  ACCESSIBLE_SCALABLE_AI_SUMMARY = 'accessible-scalable-ai-summary',
  ACCESSIBLE_ASK_CLARIFYING_QUESTIONS = 'accessible-ask-clarifying-questions',
  ACCESSIBLE_CHANGE_CONVERSATION_TOPIC = 'accessible-change-conversation-topic',
  ACCESSIBLE_SEARCH = 'accessible-search',
  ACCESSIBLE_SUGGESTIONS = 'accessible-suggestions',
  UNIFY_PERSONALIZED_SUMMARY = 'unify-personalized-summary',
  UNIFY_CROSS_CHANNEL_HELP = 'unify-cross-channel-help',
  UNIFY_PERSONALIZED_JOURNEYS = 'unify-personalized-journeys',
  PHONE_SUMMARY = 'phone-summary',
  PHONE_AUTHENTICATION = 'phone-authentication',
  PHONE_TRUSTED_AI = 'phone-trusted-ai',
  PHONE_LIVE_AGENT = 'phone-live-agent',
  RECOGNIZE_GAPS = 'recognize-gaps',
  CREATE_ACTIONS = 'create-actions',
  RECOGNIZE_TRENDS = 'recognize-trends',
  DIAGNOSE = 'diagnose',
  FIX_ISSUES_QUICKLY = 'fix-issues-quickly',
  PUBLISH_WITH_EASE = 'publish-with-ease',
  ITERATE_QUICKLY_SUMMARY = 'iterate-quickly-summary',
  DRIVE_CUSTOMER_OUTCOME_SUMMARY = 'drive-customer-outcome-summary',
  UNIFY_DATA = 'unify-data',
  FORM_A_FULL_USER_JOURNEY = 'form-a-full-user-journey',
  GENERATIVE_CONVERSATIONAL_SEARCH = 'generative-conversational-search',
  GENERATIVE_ACCELERATED_AUTHORING = 'generative-accelerated-authoring',
  GENERATIVE_AI_SUMMARY = 'generative-ai-summary',
  BUILD_EXPERIENCE_BUILDING_CONVERSATION = 'build-experience-building-conversation',
  BUILD_EXPERIENCE_BUILDING_INTEGRATIONS = 'build-experience-building-integrations',
  BUILD_EXPERIENCE_BUILDING_PHONE_EXPERIENCES = 'build-experience-building-phone-experiences',
  BUILD_EXPERIENCE_SUMMARY = 'build-experience-summary',
}

/**
 * The different types of guidance panel sections.
 */
enum LendyrSection {
  HOME = '',
  INDEX = 'index', // Feature List section.
  ESSENTIALS = 'essentials',
  TELL_DO_SHOW = 'tell-do-show',
  ACCESSIBLE_SCALABLE_AI = 'accessible-scalable-ai',
  UNIFY_PERSONALIZED = 'unify-personalized',
  PHONE = 'phone',
  ITERATE_QUICKLY = 'iterate-quickly',
  DRIVE_CUSTOMER_OUTCOMES = 'drive-customer-outcomes',
  GENERATIVE_AI = 'generative-ai',
  BUILD_EXPERIENCE = 'build-experience',
}

type DemoPanelsTrackingNames = DemoFeaturePanelsTrackingNames | DemoSectionIntroAndSummaryTrackingNames;

type DemoPageComponent = React.FunctionComponent;

interface DemoSiteParamsAndHash {
  /**
   * The param value that will determine the render panel demo page.
   */
  page?: string;

  /**
   * The param value that will determine the type of panel tour to render.
   */
  section?: string;

  /**
   * The param value that will determine what feature panel to render.
   */
  panel?: string;

  /**
   * The watson assistant link id that triggers an action once web chat has loaded.
   */
  pageLinkID?: string;

  /**
   * Determines if the demo site needs to displayed in a mobile view.
   */
  mobileView?: boolean;

  /**
   * The hash value to add to the url.
   */
  hash?: string;
}

export {
  DemoFeaturePanelsTrackingNames,
  DemoSectionIntroAndSummaryTrackingNames,
  DemoPanelsTrackingNames,
  LendyrPage,
  LendyrPageType,
  LendyrSection,
  LendyrPanel,
  DemoPageComponent,
  CustomPanelType,
  DemoSiteParamsAndHash,
  RestartType,
};
