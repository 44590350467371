/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function AccessibleScalableAISummaryPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Tour Summary"
        description={
          <>
            With Watson Assistant, you get AI that's accessible, scalable, and reliable.
            <br />
            <br />
            Deliver more accurate and consistent customer experiences out of the box. Watson Assistant offers advanced
            clarification and defined response modes to achieve higher accuracy with little training data needed. Our
            hybrid approach of supervised and unsupervised continuous learning ensures you can grow your assistant at
            scale.
          </>
        }
      />
    </div>
  );
}

const AccessibleScalableAISummaryPanelConfig: SectionPanelConfig = {
  title: 'Tour Summary',
  SectionPanelComponent: AccessibleScalableAISummaryPanel,
  panel: LendyrPanel.ACCESSIBLE_SCALABLE_AI_SUMMARY,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.ACCESSIBLE_SCALABLE_AI_SUMMARY,
};

export { AccessibleScalableAISummaryPanel, AccessibleScalableAISummaryPanelConfig };
