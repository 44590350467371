/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2020, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/* eslint-disable complexity */

import { ENV_VARIABLES } from '../../framework/utils/environmentVariables';
import { PublicConfig } from '../types/PublicConfig';
import { REGION_MAP } from './regions';

/**
 * Given a config object, either returns the CDN provided in the environment.
 */
function getCDNURL() {
  return ENV_VARIABLES.DEMO_WEB_CHAT_HOST;
}

/**
 * Returns the prefix for the endpoint used to make calls to Amplitude.
 */
function getAnalyticsPath(publicConfig: PublicConfig) {
  const prefix = `https://${REGION_MAP[publicConfig.region]}`;
  return `${prefix}/analytics`;
}

export { getAnalyticsPath, getCDNURL };
