/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { trackDemoSectionPanelAction } from '../../../../framework/utils/demoTracking';
import { useOnMount } from '../../../../web-chat-dependencies/hooks/useOnMount';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelSelectList, GuidanceSelectListItem } from '../../commonComponents/GuidancePanelSelectList';
import { SectionPanelComponentProps, SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { IterateQuicklyOverlay } from './IterateQuicklyOverlay';
import { EditActionContentSVGComponent } from './svgComponents/EditActionContentSVGComponent';
import { EditActionContentTooltip1SVGComponent } from './svgComponents/EditActionContentTooltip1SVGComponent';
import { EditActionContentTooltip2SVGComponent } from './svgComponents/EditActionContentTooltip2SVGComponent';
import { ReorderActionsSVGComponent } from './svgComponents/ReorderActionsSVGComponent';
import { ReorderActionsTooltipSVGComponent } from './svgComponents/ReorderActionsTooltipSVGComponent';

const FIX_ISSUES_QUICKLY_LINK: GuidancePanelImplementationData[] = [
  {
    title: 'Build actions',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-build-actions-overview',
    type: 'Docs',
  },
];

const FIX_ISSUES_QUICKLY = 'Fix Issues Quickly';
const FIX_ISSUES_QUICKLY_ITEM_1_TEXT = 'Edit action content';
const FIX_ISSUES_QUICKLY_ITEM_2_TEXT = 'Reorder actions';

function FixIssuesQuicklyPanel({ selectedSubViewIndex, onSubViewChange }: SectionPanelComponentProps) {
  // Upon visiting this panel, track the first preview in the list as being viewed.
  useOnMount(() => {
    trackDemoSectionPanelAction(`${FIX_ISSUES_QUICKLY} - ${FIX_ISSUES_QUICKLY_ITEM_1_TEXT}`);
  });

  return (
    <div>
      <GuidancePanelHeading
        title={FIX_ISSUES_QUICKLY}
        description="Watson Assistant makes it easy to edit and rearrange conversation steps with a drag-and-drop interface, easy-to-use rich text editor, automatic variable naming, and conditions."
      />
      <GuidancePanelSelectList
        selectedIndex={selectedSubViewIndex}
        onChange={index => {
          if (selectedSubViewIndex !== index) {
            if (index === 0) {
              trackDemoSectionPanelAction(`${FIX_ISSUES_QUICKLY} - ${FIX_ISSUES_QUICKLY_ITEM_1_TEXT}`);
            } else {
              trackDemoSectionPanelAction(`${FIX_ISSUES_QUICKLY} - ${FIX_ISSUES_QUICKLY_ITEM_2_TEXT}`);
            }

            onSubViewChange(index);
          }
        }}
      >
        <GuidanceSelectListItem text={FIX_ISSUES_QUICKLY_ITEM_1_TEXT} />
        <GuidanceSelectListItem text={FIX_ISSUES_QUICKLY_ITEM_2_TEXT} />
      </GuidancePanelSelectList>
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={FIX_ISSUES_QUICKLY_LINK} />
      </GuidancePanelImplementationSection>
      <IterateQuicklyOverlay>
        {selectedSubViewIndex === 0 && (
          <>
            <EditActionContentSVGComponent />
            <EditActionContentTooltip1SVGComponent />
            <EditActionContentTooltip2SVGComponent />
          </>
        )}
        {selectedSubViewIndex === 1 && (
          <>
            <ReorderActionsSVGComponent />
            <ReorderActionsTooltipSVGComponent />
          </>
        )}
      </IterateQuicklyOverlay>
    </div>
  );
}

const FixIssuesQuicklyPanelConfig: SectionPanelConfig = {
  title: 'Fix Issues Quickly',
  SectionPanelComponent: FixIssuesQuicklyPanel,
  page: LendyrPage.ITERATE_QUICKLY_TOUR,
  panel: LendyrPanel.FIX_ISSUES_QUICKLY,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.FIX_ISSUES_QUICKLY,
  totalSubViews: 2,
};

export { FixIssuesQuicklyPanel, FixIssuesQuicklyPanelConfig };
