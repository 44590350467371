/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import merge from 'lodash/merge';

import { LendyrAppState } from '../store/LendyrAppState';
import { LendyrLocalStorageTourState } from '../types/lendyrStoreTypes';

/**
 * This function handles updating the lendyr tour states in persisted local storage.
 */
function updateLendyrTourStorageState(
  state: LendyrAppState,
  section: string,
  partialTourState: Partial<LendyrLocalStorageTourState>,
): LendyrAppState {
  const sectionTourState = state.persistedToBrowserLocalStorage.siteData.tours[section];

  return {
    ...state,
    persistedToBrowserLocalStorage: {
      ...state.persistedToBrowserLocalStorage,
      siteData: {
        ...state.persistedToBrowserLocalStorage.siteData,
        tours: {
          ...state.persistedToBrowserLocalStorage.siteData.tours,
          [section]: merge({}, sectionTourState, partialTourState),
        },
      },
    },
  };
}

/**
 * Updates a lendyr tour state in persisted local storage state to visited if the user has not visited the tour they're
 * navigating to already.
 */
function updateLendyrTourVisitedStorageState(state: LendyrAppState, section: string) {
  const { siteData } = state.persistedToBrowserLocalStorage;
  const tourState = siteData.tours[section];

  if (section && !tourState?.visited) {
    return updateLendyrTourStorageState(state, section, { visited: true });
  }

  return state;
}

export { updateLendyrTourStorageState, updateLendyrTourVisitedStorageState };
