/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { EssentialsPrimaryThemes } from './EssentialsIntroPanel';

/**
 * The very last panel in the essentials flow for the web chat essentials panel in the demo site.
 */
function EssentialsSummaryPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Summary"
        description={
          <>
            Utilize the essential features of Watson Assistant web chat, a prebuilt chat UI that reduces burden and
            total cost of ownership for you, to deliver exceptional, scalable experiences through 3 primary themes:
            <EssentialsPrimaryThemes />
            All with web chat, a prebuilt chat UI that reduces burden and total cost of ownership for you.
          </>
        }
      />
    </div>
  );
}

const EssentialsSummaryPanelConfig: SectionPanelConfig = {
  title: 'Essentials Summary',
  panel: LendyrPanel.ESSENTIALS_SUMMARY,
  SectionPanelComponent: EssentialsSummaryPanel,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.WEB_CHAT_ESSENTIALS_SUMMARY,
};

export { EssentialsSummaryPanel, EssentialsSummaryPanelConfig };
