/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2019, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * This file contains the type definitions for the event bus.
 */

// Constants for the bus event types.

import { AgentProfile } from '../../submodules/wa-fd-types-public/agent-events-types';
import { GenericItem, MessageRequest, MessageResponse } from '../../submodules/wa-fd-types-public/wa-types';
import { AgentsOnlineStatus } from '../services/HumanAgentService';
import { TourStepGenericItem } from './TourState';
import { Message } from './watsonAssistantAPITypes';
import { WatsonAssistantChatInstance } from './WatsonAssistantChatInstance';

enum BusEventType {
  PRE_RECEIVE = 'pre:receive',
  RECEIVE = 'receive',
  PRE_SEND = 'pre:send',
  SEND = 'send',
  WINDOW_PRE_OPEN = 'window:pre:open',
  WINDOW_OPEN = 'window:open',
  WINDOW_PRE_CLOSE = 'window:pre:close',
  WINDOW_CLOSE = 'window:close',
  CUSTOM_RESPONSE = 'customResponse',
  PROCESS_OPEN = 'process:open',
  PROCESS_CLOSE = 'process:close',
  PROCESS_CANCEL = 'process:cancel',
  HISTORY_BEGIN = 'history:begin',
  HISTORY_END = 'history:end',
  IDENTITY_EXPIRED = 'identityTokenExpired',
  PRE_RESTART_CONVERSATION = 'pre:restartConversation',
  RESTART_CONVERSATION = 'restartConversation',
  CHAT_READY = 'chat:ready',
  CUSTOM_PANEL_PRE_OPEN = 'customPanel:pre:open',
  CUSTOM_PANEL_OPEN = 'customPanel:open',
  CUSTOM_PANEL_PRE_CLOSE = 'customPanel:pre:close',
  CUSTOM_PANEL_CLOSE = 'customPanel:close',
  TOUR_START = 'tour:start',
  TOUR_END = 'tour:end',
  TOUR_STEP = 'tour:step',

  /**
   * This event is fired before web chat processes a message received from a human agent from a service desk.
   */
  AGENT_PRE_RECEIVE = 'agent:pre:receive',

  /**
   * This event is fired after web chat processes a message received from a human agent from a service desk.
   */
  AGENT_RECEIVE = 'agent:receive',

  /**
   * This event is fired before web chat sends a message to a human agent from a service desk.
   */
  AGENT_PRE_SEND = 'agent:pre:send',

  /**
   * This event is fired after web chat sends a message to a human agent from a service desk.
   */
  AGENT_SEND = 'agent:send',

  /**
   * This event is fired before a chat with a service desk has started. This occurs as soon as the user clicks the
   * "Request agent" button and before any attempt is made to communicate with the service desk.
   */
  AGENT_PRE_START_CHAT = 'agent:pre:startChat',

  /**
   * This event is fired before a chat with an agent is ended. This occurs after the user has selected "Yes" from the
   * confirmation modal, but it can also be fired if the chat is ended by the agent.
   */
  AGENT_PRE_END_CHAT = 'agent:pre:endChat',

  /**
   * This event is fired after a chat with an agent has ended. This is fired after {@link AGENT_PRE_END_CHAT} but
   * can be fired both from the user leaving the chat or the agent ending the chat.
   */
  AGENT_END_CHAT = 'agent:endChat',

  /**
   * This event is fired after web chat calls "areAnyAgentsOnline" for a service desk. It will report the value returned
   * from that call. This is particularly useful if some custom code wants to take action if no agents are online.
   */
  AGENT_ARE_ANY_AGENTS_ONLINE = 'agent:areAnyAgentsOnline',

  ALL_EVENTS = '*',
}

/**
 * The possible reasons why the chat window may be opened.
 */
enum MainWindowOpenReason {
  /**
   * Indicates the user clicked on our built-in launcher button that opened the main window.
   */
  DEFAULT_LAUNCHER = 'default_launcher',

  /**
   * Indicates the main window was opened because {@link PublicConfig.openChatByDefault} was set to true.
   */
  OPEN_BY_DEFAULT = 'open_by_default',

  /**
   * Indicates the main window was opened as a result of session history.
   */
  SESSION_HISTORY = 'session_history',

  /**
   * Indicates the main window was opened by a call to {@link WatsonAssistantChatInstance.openWindow} or
   * {@link WatsonAssistantChatInstance.toggleOpen}.
   */
  CALLED_OPEN_WINDOW = 'called_open_window',

  /**
   * Indicates the main window was opened because a link ID was found that started a specific conversation.
   */
  LINK = 'link',

  /**
   * Indicates the main window was opened from a tour.
   */
  FROM_TOUR = 'from_tour',
}

enum MainWindowCloseReason {
  /**
   * Indicates the user clicked on our built-in minimize button that closed to the launcher.
   */
  DEFAULT_MINIMIZE = 'default_minimize',

  /**
   * Indicates the main window was closed by a call to {@link WatsonAssistantChatInstance.closeWindow} or
   * {@link WatsonAssistantChatInstance.toggleOpen}.
   */
  CALLED_CLOSE_WINDOW = 'called_close_window',

  /**
   * Indicates the main window was closed to open a tour. This either happens when a tour is started from a TourCard, or
   * when the main window is closed with the minimize button, or the {@link WatsonAssistantChatInstance.closeWindow}
   * function, and there's an active tour to be shown.
   */
  OPEN_TOUR = 'open_tour',
}

/**
 * The possible reasons why a tour could be started. Purposefully not firing this event when the restart button is clicked.
 */
enum TourStartReason {
  /**
   * If the {@link startTour} instance method was used to start the tour.
   */
  START_TOUR_METHOD = 'start_tour_method',

  /**
   * If the skip_card property was true within the tour json.
   */
  SKIP_CARD = 'skip_card',

  /**
   * If the user clicked the tour card's start button.
   */
  START_TOUR_CLICKED = 'start_tour_clicked',
}

/**
 * The possible reasons why a tour could have ended. Purposefully not firing this event when the close button is clicked
 * or the {@link endTour} instance method is used.
 */
enum TourEndReason {
  /**
   * If the user clicked the done button.
   */
  DONE_CLICKED = 'done_clicked',
}

// The discriminating union of all the possible bus event types.
type BusEvent =
  | BusEventPreReceive
  | BusEventReceive
  | BusEventPreSend
  | BusEventSend
  | BusEventCustomResponse
  | BusEventWindowPreClose
  | BusEventWindowPreOpen
  | BusEventWindowOpen
  | BusEventWindowClose
  | BusEventProcessOpen
  | BusEventProcessClose
  | BusEventProcessCancel
  | BusEventIdentityExpired
  | BusEventHistoryBegin
  | BusEventHistoryEnd
  | BusEventPreReset
  | BusEventReset
  | BusEventChatReady
  | BusEventCustomPanelPreOpen
  | BusEventCustomPanelOpen
  | BusEventCustomPanelPreClose
  | BusEventCustomPanelClose
  | BusEventTourStart
  | BusEventTourEnd
  | BusEventTourStep
  | BusEventAgentPreReceive
  | BusEventAgentReceive
  | BusEventAgentPreSend
  | BusEventAgentSend
  | BusEventAgentPreStartChat
  | BusEventAgentPreEndChat
  | BusEventAgentEndChat
  | BusEventAgentAreAnyAgentsOnline
  | BusEventAllEvents;

interface BusEventPreReceive {
  type: BusEventType.PRE_RECEIVE;
  data: MessageResponse;

  /**
   * Defaulted to true. If true then changes made to the message object in pre:receive will be saved in the session
   * history for the length of the session. If the changes are saved then the message will look the same to the end user
   * before and after page change/reload as long as the session history feature is enabled. If updateHistory is false
   * the changes will not be saved in the history. While the message will still initially update successfully if this is
   * false, the changes won't be preserved and the end user will see the original message (that was received before the
   * pre:receive changes) when a page change/reload occurs.
   */
  updateHistory: boolean;
}

interface BusEventReceive {
  type: BusEventType.RECEIVE;
  data: MessageResponse;
}

interface BusEventPreSend {
  type: BusEventType.PRE_SEND;
  data: MessageRequest;
}

interface BusEventSend {
  type: BusEventType.SEND;
  data: MessageRequest;
}

interface BusEventAgentPreReceive {
  type: BusEventType.AGENT_PRE_RECEIVE;
  data: MessageResponse;
  agentProfile?: AgentProfile;
}

interface BusEventAgentReceive {
  type: BusEventType.AGENT_RECEIVE;
  data: MessageResponse;
  agentProfile?: AgentProfile;
}

interface BusEventAgentPreSend {
  type: BusEventType.AGENT_PRE_SEND;
  data: MessageRequest;
}

interface BusEventAgentSend {
  type: BusEventType.AGENT_SEND;
  data: MessageRequest;
}

interface BusEventWindowPreClose {
  type: BusEventType.WINDOW_PRE_CLOSE;

  /**
   * The reason for the main window being closed.
   */
  reason: MainWindowCloseReason;

  /**
   * This is used by the event handler to indicate that the close should be cancelled and web chat should remain open.
   */
  cancelClose: boolean;

  /**
   * This is used by the event handler to indicate that the close should be cancelled and web chat should remain open.
   *
   * @deprecated Use cancelClose instead.
   */
  vetoClose?: boolean;
}

interface BusEventWindowPreOpen {
  type: BusEventType.WINDOW_PRE_OPEN;

  /**
   * The reason for the window being opened.
   */
  reason: MainWindowOpenReason;

  /**
   * This is used by the event handler to indicate that the open should be cancelled and web chat should remain closed.
   */
  cancelOpen: boolean;
}

interface BusEventWindowOpen {
  type: BusEventType.WINDOW_OPEN;

  /**
   * The reason for the window being opened.
   */
  reason: MainWindowOpenReason;

  /**
   * This is used by the event handler to indicate that the open should be cancelled and web chat should remain
   * closed. Since the window is already open when this event is fired, this property will cause the window to
   * close. Note that the window close events are *not* fired when this occurs.
   */
  cancelOpen: boolean;
}

interface BusEventWindowClose {
  type: BusEventType.WINDOW_CLOSE;
}

interface BusEventReset {
  type: BusEventType.RESTART_CONVERSATION;
}

interface BusEventChatReady {
  type: BusEventType.CHAT_READY;
}

interface BusEventPreReset {
  type: BusEventType.PRE_RESTART_CONVERSATION;
}

interface BusEventCustomResponse {
  type: BusEventType.CUSTOM_RESPONSE;
  data: {
    message: GenericItem;
    fullMessage: Message;
    element?: HTMLElement;
  };
}

interface BusEventProcessOpen {
  type: BusEventType.PROCESS_OPEN;
}

interface BusEventProcessClose {
  type: BusEventType.PROCESS_CLOSE;
}

interface BusEventProcessCancel {
  type: BusEventType.PROCESS_CANCEL;
}

interface BusEventIdentityExpired {
  type: BusEventType.IDENTITY_EXPIRED;

  /**
   * JWT string that is expected to be updated when this event is fired with a new identityToken.
   */
  identityToken: string;
}

/**
 * The event is fired whenever the widget begins processing a list of messages that have been loaded from history.
 * This event may be fired not only when the history is first loaded, but it may be fired later during the life of
 * the widget if additional messages are loaded from history.
 *
 * This event is fired when this process begins. This is fired before all the "pre:receive" and "receive" events are
 * fired which means that the messages here are the original messages before any possible modifications by the event
 * handlers.
 */
interface BusEventHistoryBegin {
  /**
   * The discriminating type of this event.
   */
  type: BusEventType.HISTORY_BEGIN;

  /**
   * The list of all the messages that are being loaded by this history event.
   */
  messages: Message[];
}

/**
 * The event is fired whenever the widget begins processing a list of messages that have been loaded from history.
 * This event may be fired not only when the history is first loaded, but it may be fired later during the life of
 * the widget if additional messages are loaded from history.
 *
 * This event is fired when this process ends. This is fired after all the "pre:receive" and "receive" events are
 * fired which means that the messages here are the potentially modified messages after any possible modifications
 * by the event handlers.
 */
interface BusEventHistoryEnd {
  /**
   * The discriminating type of this event.
   */
  type: BusEventType.HISTORY_END;

  /**
   * The list of all the messages that were loaded by this history event.
   */
  messages: Message[];
}

/**
 * The catch-all event that covers the firing of all the other events.
 */
interface BusEventAllEvents {
  type: BusEventType.ALL_EVENTS;
  [key: string]: any;
}

interface BusEventCustomPanelPreOpen {
  type: BusEventType.CUSTOM_PANEL_PRE_OPEN;
}

interface BusEventCustomPanelOpen {
  type: BusEventType.CUSTOM_PANEL_OPEN;
}

interface BusEventCustomPanelPreClose {
  type: BusEventType.CUSTOM_PANEL_PRE_CLOSE;
}

interface BusEventCustomPanelClose {
  type: BusEventType.CUSTOM_PANEL_CLOSE;
}

/**
 * Fired when a tour is started. The tour could be started upon receipt of a tour message with skip_card true, when a
 * developer used the {@link startTour} instance method, or when the start tour card is clicked by the user.
 * Purposefully not firing this event when the restart button is clicked.
 */
interface BusEventTourStart {
  type: BusEventType.TOUR_START;

  /**
   * The reason for the tour starting.
   */
  reason: TourStartReason;
}

/**
 * Fired when the tour is ended by clicking the done button at the end of the tour. Purposefully not firing this event
 * when the close button is clicked or the {@link endTour} instance method is used.
 */
interface BusEventTourEnd {
  type: BusEventType.TOUR_END;

  /**
   * The reason for the tour ending.
   */
  reason: TourEndReason;
}

/**
 * Fired when a new step is shown to the user. This could be caused by a tour starting/restarting, the user clicking the
 * next or previous buttons within the tour, or by a developer calling the {@link goToNextStep} or {@link goToStep}
 * instance methods. Purposefully not firing this event when a tour is resumed.
 */
interface BusEventTourStep {
  type: BusEventType.TOUR_STEP;

  /**
   * The details of the new step item.
   */
  step: TourStepGenericItem;
}

/**
 * This event is fired before the user is connected to a service desk. This occurs as soon as the user clicks the
 * "Request agent" button and before any attempt is made to communicate with the service desk.
 */
interface BusEventAgentPreStartChat<TPayloadType = unknown> {
  /**
   * The type of the event.
   */
  type: BusEventType.AGENT_PRE_START_CHAT;

  /**
   * The message that was used to trigger the connection to the agent.
   */
  message: MessageResponse;

  /**
   * This flag can be set by a listener to indicate that the connection process should be cancelled.
   */
  cancelStartChat?: boolean;

  /**
   * Some arbitrary payload of data that will be passed to the service desk when a chat is started.
   */
  preStartChatPayload?: TPayloadType;
}

/**
 * This event is fired before a chat with an agent is ended. This occurs after the user has selected "Yes" from the
 * confirmation modal, but it can also be fired if the chat is ended by the agent.
 */
interface BusEventAgentPreEndChat<TPayloadType = unknown> {
  /**
   * The type of the event.
   */
  type: BusEventType.AGENT_PRE_END_CHAT;

  /**
   * Indicates if the chat was ended by the agent.
   */
  endedByAgent: boolean;

  /**
   * An arbitrary payload object that a listener may set. This payload will be passed to the service desk
   * {@link ServiceDesk#endChat} function.
   */
  preEndChatPayload: TPayloadType;

  /**
   * This value may be set by a listener to indicate that the process of ending the chat should be cancelled.
   */
  cancelEndChat: boolean;
}

/**
 * This event is fired after a chat with an agent has ended. This is fired after {@link AGENT_PRE_END_CHAT} but
 * can be fired both from the user leaving the chat or the agent ending the chat.
 */
interface BusEventAgentEndChat {
  /**
   * The type of the event.
   */
  type: BusEventType.AGENT_END_CHAT;

  /**
   * Indicates if the chat was ended by the agent.
   */
  endedByAgent: boolean;
}

/**
 * This event is fired after web chat calls "areAnyAgentsOnline" for a service desk. It will report the value returned
 * from that call. This is particularly useful if some custom code wants to take action if no agents are online.
 */
interface BusEventAgentAreAnyAgentsOnline {
  /**
   * The type of the event.
   */
  type: BusEventType.AGENT_ARE_ANY_AGENTS_ONLINE;

  /**
   * The result that was returned from "areAnyAgentsOnline". If an error occurred, this will be
   * {@link AgentsOnlineStatus.OFFLINE}.
   */
  areAnyAgentsOnline: AgentsOnlineStatus;
}

/**
 * The type of handler for event bus events. This function may return a Promise in which case, the bus will await
 * the result and the loop will block until the Promise is resolved.
 */
type EventBusHandler = (event: BusEvent, instance: WatsonAssistantChatInstance) => unknown;

/**
 * The type of the object that is passed to the event bus functions (e.g. "on") when registering a handler.
 */
interface TypeAndHandler {
  /**
   * The type of event this handler is for.
   */
  type: BusEventType;

  /**
   * The handler for events of this type.
   */
  handler: EventBusHandler;
}

export {
  BusEvent,
  BusEventType,
  BusEventPreReceive,
  BusEventReceive,
  BusEventPreSend,
  BusEventSend,
  BusEventCustomResponse,
  BusEventWindowPreOpen,
  BusEventWindowOpen,
  BusEventWindowPreClose,
  BusEventWindowClose,
  BusEventProcessOpen,
  BusEventProcessClose,
  BusEventProcessCancel,
  BusEventHistoryBegin,
  BusEventHistoryEnd,
  BusEventIdentityExpired,
  BusEventReset,
  BusEventPreReset,
  EventBusHandler,
  TypeAndHandler,
  BusEventCustomPanelPreClose,
  BusEventCustomPanelClose,
  BusEventCustomPanelPreOpen,
  BusEventCustomPanelOpen,
  BusEventTourStart,
  BusEventTourEnd,
  BusEventTourStep,
  BusEventAgentPreReceive,
  BusEventAgentReceive,
  BusEventAgentPreSend,
  BusEventAgentSend,
  BusEventAgentPreStartChat,
  BusEventAgentPreEndChat,
  BusEventAgentEndChat,
  BusEventAgentAreAnyAgentsOnline,
  MainWindowOpenReason,
  MainWindowCloseReason,
};
