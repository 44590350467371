/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { useRef } from 'react';

const SENTINEL = {};

/**
 * Returns a mutable ref just like {@link useRef}, but it handles manually setting our initial value to `.current`.
 * Pass in a function that returns the initial value you want to set.
 */
function useRefFn<T>(init: () => T) {
  const ref = useRef<T | typeof SENTINEL>(SENTINEL);
  if (ref.current === SENTINEL) {
    ref.current = init();
  }
  return ref as React.MutableRefObject<T>;
}

export { useRefFn };
