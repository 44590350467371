/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import {
  DEFAULT_LENDYR_LOCAL_STORAGE_STATE,
  DEFAULT_LENDYR_SESSION_STORAGE_STATE,
} from '../../lendyr/utils/lendyrConstants';
import { PersistedToBrowserLocalStorage, PersistedToBrowserSessionStorage } from '../store/demoStoreTypes';
import { APP_NAMESPACE, VERSION } from './demoConstants';

const APP_STATE_STORAGE_KEY = `${APP_NAMESPACE}-state`;

/**
 * Returns the default session storage state object for the demo site. You can pass in a default site data object.
 */
function DEFAULT_SESSION_STORAGE_STATE<T>(siteData?: T): PersistedToBrowserSessionStorage<T> {
  return { version: VERSION, siteData, isGuidancePanelExpanded: true };
}

/**
 * Returns the default local storage state object for the demo site. You can pass in a default site data object.
 */
function DEFAULT_LOCAL_STORAGE_STATE<T>(siteData?: T): PersistedToBrowserLocalStorage<T> {
  return { siteData };
}

/**
 * Handles loading the lendyr session/local storage states. If the version of lendyr doesn't match the version in
 * storage, it gets replaced.
 */
function loadPersistedStorageStates() {
  const localStorageItem = localStorage.getItem(APP_STATE_STORAGE_KEY);

  if (!localStorageItem) {
    localStorage.setItem(
      APP_STATE_STORAGE_KEY,
      JSON.stringify(DEFAULT_LOCAL_STORAGE_STATE(DEFAULT_LENDYR_LOCAL_STORAGE_STATE)),
    );
  }

  loadSessionStorageState();
}

/**
 * Handles loading the default session storage state object if it doesn't exist. If it does, this ensures that the
 * version in session storage matches the current lendyr version.
 */
function loadSessionStorageState() {
  const sessionStorageItem = sessionStorage.getItem(APP_STATE_STORAGE_KEY);
  let createNewStorageItem = !sessionStorageItem;

  try {
    if (!createNewStorageItem) {
      // If we don't need to create a session storage item, check the version in session storage to determine if we
      // need to create a new storage item.
      const storageData = JSON.parse(sessionStorageItem) as PersistedToBrowserSessionStorage;
      if (storageData.version !== VERSION) {
        createNewStorageItem = true;
        sessionStorage.removeItem(APP_STATE_STORAGE_KEY);
      }
    }
  } catch {
    createNewStorageItem = true;
  }

  if (createNewStorageItem) {
    sessionStorage.setItem(
      APP_STATE_STORAGE_KEY,
      JSON.stringify(DEFAULT_SESSION_STORAGE_STATE(DEFAULT_LENDYR_SESSION_STORAGE_STATE)),
    );
  }
}

/**
 * Updates values in session storage state with the given property values.
 */
function updateSessionStorage<TSiteData>(storageState: PersistedToBrowserSessionStorage<TSiteData>) {
  sessionStorage.setItem(APP_STATE_STORAGE_KEY, JSON.stringify(storageState));
}

/**
 * Updates values in local storage state with the given property values.
 */
function updateLocalStorage<TSiteData>(storageState: PersistedToBrowserLocalStorage<TSiteData>) {
  localStorage.setItem(APP_STATE_STORAGE_KEY, JSON.stringify(storageState));
}

/**
 * Retrieves the session storage state object.
 */
function getSessionStorageState() {
  try {
    const storageItem = sessionStorage.getItem(APP_STATE_STORAGE_KEY);
    if (storageItem) {
      return JSON.parse(storageItem);
    }
  } catch {
    // Do nothing.
  }

  return DEFAULT_SESSION_STORAGE_STATE(DEFAULT_LENDYR_SESSION_STORAGE_STATE);
}

/**
 * Retrieves the local storage state object.
 */
function getLocalStorageState() {
  try {
    const storageItem = localStorage.getItem(APP_STATE_STORAGE_KEY);
    if (storageItem) {
      return JSON.parse(storageItem);
    }
  } catch {
    // Do nothing.
  }

  return DEFAULT_LOCAL_STORAGE_STATE(DEFAULT_LENDYR_LOCAL_STORAGE_STATE);
}

export {
  DEFAULT_SESSION_STORAGE_STATE,
  DEFAULT_LOCAL_STORAGE_STATE,
  loadPersistedStorageStates,
  getSessionStorageState,
  getLocalStorageState,
  updateSessionStorage,
  updateLocalStorage,
};
