/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './DemoPageOverlayWrapper.css';

import cx from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { HasChildren } from '../web-chat-dependencies/types/HasChildren';
import { DemoPageOverlayPortalContext } from './contexts/DemoPageOverlayPortalContext';
import { useDemoLocation } from './hooks/selectorHooks';
import { DemoAppState } from './store/DemoAppState';

interface DemoPageOverlayPortalContextWrapperProps extends HasChildren {
  isGuidanceComponentExpanded: boolean;
}

/**
 * This component handles setting the demo page overlay context in order to allow surfacing content over the demo page
 * by getting the ref object of overlay host element.
 */
function DemoPageOverlayPortalContextWrapper({
  children,
  isGuidanceComponentExpanded,
}: DemoPageOverlayPortalContextWrapperProps) {
  const { section, panel, mobileView } = useDemoLocation();
  const isPageOverlayHidden = useSelector((state: DemoAppState) => state.isPageOverlayHidden);
  const isMobileViewEnabled = useSelector((state: DemoAppState) => state.isMobileViewEnabled);
  const [hostElement, setHostElement] = useState<HTMLDivElement>(null);
  // This prevents the page overlay from respecting mobile view for the splash page.
  const isViewingSplashPage = isGuidanceComponentExpanded && !section && !panel;

  return (
    <DemoPageOverlayPortalContext.Provider value={hostElement}>
      <div
        className={cx('DemoPageOverlay', {
          'DemoPageOverlay--withGuidancePanel': isGuidanceComponentExpanded,
          'DemoPageOverlay--emulatedMobile': !isViewingSplashPage && isMobileViewEnabled && mobileView,
          Hidden: isPageOverlayHidden,
        })}
      >
        <div className="DemoPageOverlay__Content" ref={setHostElement} />
      </div>
      {children}
    </DemoPageOverlayPortalContext.Provider>
  );
}

export { DemoPageOverlayPortalContextWrapper };
