/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './CardLinkComponent.css';

import React from 'react';

import { useWebChatInstance } from '../framework/hooks/useWebChatInstance';
import { CardLinkComponentProps } from '../framework/types/CardLinkComponentProps';
import { DemoClickableTile } from './DemoClickableTile';

/**
 * A card the navigates the user to the provided pathname in the demo page.
 */
function CardLinkComponent({ Icon, title, page, onClick }: CardLinkComponentProps) {
  const instance = useWebChatInstance();
  return (
    <DemoClickableTile
      className="CardLink"
      page={page}
      onClick={() => {
        if (instance) {
          onClick?.(instance);
        }
      }}
    >
      <div className="CardLink__Wrapper">
        <Icon />
        <span>{title}</span>
      </div>
    </DemoClickableTile>
  );
}

const CardLinkComponentExport = React.memo(CardLinkComponent);

export { CardLinkComponentExport as CardLinkComponent };
