/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './DemoAppHeaderAndGuidance.css';

import { Home20, Laptop20, Mobile20, PageFirst20, PageLast20, Warning20 } from '@carbon/icons-react';
import {
  Button,
  Header,
  HeaderGlobalAction,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  SkipToContent,
  Tooltip,
} from 'carbon-components-react';
import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LendyrPage, LendyrPanel, LendyrSection } from '../framework/demoTypes';
import { useIsGuidanceComponentExpanded } from '../framework/hooks/selectorHooks';
import { demoActions } from '../framework/store/demoActions';
import { DemoAppState } from '../framework/store/DemoAppState';
import { IS_MOBILE } from '../web-chat-dependencies/utils/browserUtils';
import { DemoButton } from './DemoButton';
import { DemoGuidanceComponent } from './demoGuidanceComponent/DemoGuidanceComponent';

function DemoAppHeaderAndGuidance() {
  const dispatch = useDispatch();
  const isGuidanceComponentExpanded = useIsGuidanceComponentExpanded();
  const mobileView = useSelector((state: DemoAppState) => state.demoSiteParamsAndHash.mobileView);
  const enableMobileViewToggle = useSelector((state: DemoAppState) => state.isMobileViewEnabled);
  const isMobileView = mobileView && enableMobileViewToggle;

  const onGuidancePanelToggle = useCallback(() => {
    dispatch(demoActions.updateSessionStorageState({ isGuidancePanelExpanded: !isGuidanceComponentExpanded }));
  }, [isGuidanceComponentExpanded, dispatch]);

  return (
    <Header className="DemoAppHeader" aria-label="Demo Site">
      <SkipToContent />
      {IS_MOBILE && (
        <HeaderGlobalAction
          className="DemoApp__GuidancePanelToggle"
          aria-label={isGuidanceComponentExpanded ? 'Close panel' : 'Open panel'}
          onClick={onGuidancePanelToggle}
        >
          {isGuidanceComponentExpanded && <PageFirst20 />}
          {!isGuidanceComponentExpanded && <PageLast20 />}
        </HeaderGlobalAction>
      )}
      {!IS_MOBILE && (
        <div className="carbon-dark-theme">
          <DemoButton
            className="DemoApp__HomeButton"
            section={LendyrSection.HOME}
            page={LendyrPage.HOME}
            panel={LendyrPanel.HOME}
            kind="ghost"
            onClick={() => {
              if (!isGuidanceComponentExpanded) {
                onGuidancePanelToggle();
              }
            }}
          >
            <Home20 />
          </DemoButton>
        </div>
      )}
      {!IS_MOBILE && !isGuidanceComponentExpanded && (
        <div className="DemoApp__OpenPanelButton carbon-dark-theme">
          <Button kind="ghost" onClick={onGuidancePanelToggle}>
            <PageLast20 />
          </Button>
        </div>
      )}
      <HeaderName prefix="IBM">Watson Assistant Demo Site</HeaderName>
      {IS_MOBILE && <MobileWarningButton />}
      <HeaderNavigation aria-label="Watson Assistant Demo Site">
        <HeaderMenuItem
          className="DemoApp__HeaderLink"
          href="https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-deploy-web-chat"
          target="_blank"
        >
          Documentation
        </HeaderMenuItem>
        <HeaderMenuItem
          className="DemoApp__HeaderLink"
          href="https://www.ibm.com/products/watson-assistant/pricing"
          target="_blank"
        >
          Pricing
        </HeaderMenuItem>
        <HeaderMenuItem
          className="DemoApp__HeaderLink"
          href="https://www.ibm.com/account/reg/us-en/signup?formid=DEMO-watsonassistant"
          target="_blank"
        >
          Talk to an Expert
        </HeaderMenuItem>
        <HeaderMenuItem className="DemoApp__HeaderLink" href="https://ibm.biz/BdP9qN" target="_blank">
          Free Trial
        </HeaderMenuItem>
      </HeaderNavigation>
      {!IS_MOBILE && (
        <div
          className={cx('DemoAppHeader__ViewToggleButtons carbon-dark-theme', {
            'DemoAppHeader__ViewToggleButtons--disabled': !enableMobileViewToggle,
          })}
        >
          <DemoButton
            className={cx('DemoAppHeader__ViewToggleButton', {
              'DemoAppHeader__ViewToggleButton--selected': !isMobileView,
            })}
            kind="ghost"
            disabled={!isMobileView || !enableMobileViewToggle}
            mobileView={false}
          >
            <Laptop20 />
            {!isMobileView && <div className="DemoAppHeader__ViewToggleIndicator" />}
          </DemoButton>
          <DemoButton
            className={cx('DemoAppHeader__ViewToggleButton', {
              'DemoAppHeader__ViewToggleButton--selected': isMobileView,
            })}
            kind="ghost"
            disabled={isMobileView || !enableMobileViewToggle}
            mobileView
          >
            <Mobile20 />
            {isMobileView && <div className="DemoAppHeader__ViewToggleIndicator" />}
          </DemoButton>
        </div>
      )}
      <DemoGuidanceComponent expanded={isGuidanceComponentExpanded} onOverlayMenuClose={onGuidancePanelToggle} />
    </Header>
  );
}

function MobileWarningButton() {
  const [displayDisclaimer, setDisplayDisclaimer] = useState(true);
  const disclaimer =
    'Disclaimer: This demo site is designed to be used in a desktop environment, some functionality may not work on mobile devices.';

  return (
    <div>
      {displayDisclaimer && (
        <Tooltip
          className="DemoApp__MobileWarningTooltip"
          triggerId="tooltip-trigger"
          tooltipBodyId="tooltip-body"
          showIcon={false}
          open
          align="end"
          direction="bottom"
          menuOffset={{ top: 10, left: 25 }}
        >
          <div id="tooltip-body">{disclaimer}</div>
        </Tooltip>
      )}
      <Button
        className="DemoApp__MobileWarningButton"
        id="tooltip-trigger"
        renderIcon={Warning20}
        iconDescription={disclaimer}
        hasIconOnly
        kind="danger--ghost"
        size="small"
        onClick={() => setDisplayDisclaimer(!displayDisclaimer)}
      />
    </div>
  );
}

const DemoAppHeaderAndGuidanceExport = React.memo(DemoAppHeaderAndGuidance);

export { DemoAppHeaderAndGuidanceExport as DemoAppHeaderAndGuidance };
