/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelSelectList.css';

import { Button } from 'carbon-components-react';
import cx from 'classnames';
import React, { Children, ReactElement } from 'react';

import { HasChildren } from '../../../web-chat-dependencies/types/HasChildren';

interface GuidanceSelectListItemContainerProps extends HasChildren {
  /**
   * The position the item is on the list.
   */
  position: number;

  /**
   * Determines if the list item should be in the selected state.
   */
  selected: boolean;

  /**
   * The callback function to fire when the select item is clicked.
   */
  onClick: () => void;
}

interface GuidanceSelectListProps extends HasChildren {
  /**
   * The index of the item that should be in the active state.
   */
  selectedIndex: number;

  /**
   * The callback function to fire when a select item is clicked. This function will provide the index of the selected
   * item.
   */
  onChange: (index: number) => void;
}

/**
 * This component handles rendering and managing the select list.
 */
function GuidancePanelSelectList({ children, onChange, selectedIndex }: GuidanceSelectListProps) {
  return (
    <ul className="GuidanceSelectList">
      {Children.map(children, (child: ReactElement, index) => (
        <GuidanceSelectListItemContainer
          key={child.key}
          position={index + 1}
          selected={selectedIndex === index}
          onClick={() => {
            onChange(index);
          }}
        >
          {child}
        </GuidanceSelectListItemContainer>
      ))}
    </ul>
  );
}

/**
 * This container handles rendering the select item. This contains the position of the item in the list and children.
 */
function GuidanceSelectListItemContainer({
  position,
  children,
  selected,
  onClick,
}: GuidanceSelectListItemContainerProps) {
  return (
    <li>
      <Button
        kind="ghost"
        className={cx('GuidanceSelectList__Container', { 'GuidanceSelectList__Container--selected': selected })}
        onClick={onClick}
      >
        <div className="GuidanceSelectList__NumberWrapper">
          <div className="GuidanceSelectList__Number">{position}</div>
        </div>
        {children}
      </Button>
    </li>
  );
}

function GuidanceSelectListItem({ text }: { text: string }) {
  return <div className="GuidanceSelectList_Item">{text}</div>;
}

export { GuidancePanelSelectList, GuidanceSelectListItem };
