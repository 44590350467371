/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { LendyrPage } from '../../demoTypes';
import { demoActions } from '../../store/demoActions';
import { navigateTo } from '../../utils/demoUtils';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const SHOW_FIRST_TRANSITION_CONFIG: DemoTransitionConfig = {
  restartOptions: {
    onClickRestart: store => {
      // Authenticate the user in store state if they aren't already.
      if (!store.getState().isAuthenticated) {
        store.dispatch(demoActions.setStateProperty('isAuthenticated', true));
      }

      store.dispatch(demoActions.setCreditCardPageStateProperty('selectedCardOffer', null));
      navigateTo({ page: LendyrPage.CREDIT_CARDS });
    },
  },

  disableMobileView: true,
};

export { SHOW_FIRST_TRANSITION_CONFIG };
