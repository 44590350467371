/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const OPEN_FROM_BUTTONS_LINK: GuidancePanelImplementationData[] = [
  {
    title: 'Learn about triggering to open web chat from anywhere on website/app',
    link: 'https://web-chat.global.assistant.watson.cloud.ibm.com/docs.html?to=api-instance-methods#openwindow',
    type: 'Docs',
  },
];

function OpenFromButtonsPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Open From Buttons"
        description="Drive customers to your assistant via buttons on the page. Have the button be a simple entrypoint into the assistant or have the button trigger a specific topic."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Click the "Chat with us" button on the top of the page.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile codeNecessary data={OPEN_FROM_BUTTONS_LINK} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const OpenFromButtonsPanelConfig: SectionPanelConfig = {
  title: 'Open From Buttons',
  SectionPanelComponent: OpenFromButtonsPanel,
  panel: LendyrPanel.OPEN_FROM_BUTTONS,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.OPEN_FROM_BUTTONS,
};

export { OpenFromButtonsPanel, OpenFromButtonsPanelConfig };
