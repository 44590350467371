/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function RPAPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Robotic Process Automation"
        description="Integrate with IBM RPA (or other RPA tools like UIPath) to enable complex processes. RPA (robotic process automation) is especially useful in cases where you are trying to replicate a human using some multi-step process that doesn't offer any APIs. In this instance, we use RPA to open a new bank account."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following: <GuidancePanelTextSnippet text="Open an account" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Select an account type</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Click the link in "learn more <strong>here</strong>" to watch video explaining how Watson Assistant integrates
          with IBM RPA to open the bank account
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Schedule an appointment to pick up a card for your new account
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
    </div>
  );
}

const RPAPanelConfig: SectionPanelConfig = {
  title: 'Robotic Process Automation',
  SectionPanelComponent: RPAPanel,
  panel: LendyrPanel.RPA,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.RPA,
};

export { RPAPanel, RPAPanelConfig };
