/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { SearchPanelImplementationSection } from '../EssentialsAndIndexSection/SearchPanel';

function AccessibleSearchPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Search"
        description={
          <>
            Search connects Watson Assistant to a repository of information (such as your website, content management
            system, or knowledge base) so the assistant can surface relevant help content your users need.
            <br />
            <br />
            This time, instead of relying on an action you've created, enter the following to see how the assistant is
            able to use Search to find answers to questions you've answered elsewhere.
          </>
        }
      />

      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          View the default search experience by entering in the following:
          <GuidancePanelTextSnippet text="By the way I'm traveling soon. Can I use my points on airfare?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          View search results that appear in a table by entering the following:
          <GuidancePanelTextSnippet text="Show me checking account bonuses" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          View search results that are filtered based on user information by entering the following: "Account specific
          promotions", then select "Business" or "Personal".
          <GuidancePanelTextSnippet text="Account specific promotions" />
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <SearchPanelImplementationSection />
    </div>
  );
}

const AccessibleSearchPanelConfig: SectionPanelConfig = {
  title: 'Search',
  SectionPanelComponent: AccessibleSearchPanel,
  panel: LendyrPanel.ACCESSIBLE_SEARCH,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.ACCESSIBLE_SEARCH,
};

export { AccessibleSearchPanel, AccessibleSearchPanelConfig };
