/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { LendyrPanel } from '../../demoTypes';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const FAQ_TRANSITION_CONFIG: DemoTransitionConfig = {
  doAfterWebChatRender: (store, instance) => {
    const { panel } = store.getState().demoSiteParamsAndHash;
    const { isWebChatOpen } = instance.getState();

    // If the user is currently in the  position panel and web chat isn't open, this means web chat is in a custom
    // element and needs to be manually opened.
    if (panel === LendyrPanel.POSITION && !isWebChatOpen) {
      instance.openWindow();
    }
  },
};

export { FAQ_TRANSITION_CONFIG };
