/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */
import { PostChatSurveyState } from '../../components/customPanels/customPanelConstants';
import { LendyrLocalStorageState, LendyrSessionStorageState } from '../types/lendyrStoreTypes';

const DEFAULT_LENDYR_SESSION_STORAGE_STATE: LendyrSessionStorageState = {
  postChatSurveyState: PostChatSurveyState.HIDE,
  preChatFormValues: null,
  showChangeConversationTopicPanelWarning: true,
};

const DEFAULT_LENDYR_LOCAL_STORAGE_STATE: LendyrLocalStorageState = {
  tours: {},
};

export { DEFAULT_LENDYR_SESSION_STORAGE_STATE, DEFAULT_LENDYR_LOCAL_STORAGE_STATE };
