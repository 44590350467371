/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { MediaPlayerOverlay } from '../../../MediaPlayerOverlay';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const BUILDING_INTEGRATIONS_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Learn more about expressive voices',
    link: 'https://www.ibm.com/blog/is-your-conversational-ai-setting-the-right-tone/',
    type: 'Article',
  },
  {
    title: 'Integrating with phone',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-deploy-phone',
    type: 'Docs',
  },
];

const IBM_VIDEO_DATA = {
  title: 'Watson Assistant: Call Center Modernization',
  videoID: '1_te4ofe0a',
  wID: '1_hraitgau',
};

function BuildExperienceBuildingPhoneExperiencesPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Building Phone Experiences"
        description={
          <>
            Watson Assistant includes prebuilt low-code and no-code integrations to contact center platforms like{' '}
            <strong>NICE CXone</strong> and <strong>Genesys</strong>. It also includes out-of-the-box expressive voices,
            so your virtual assistant sounds friendly and understanding over the phone.
            <br />
            <br />
            See it all in this video demonstrating Watson Assistant’s phone capabilities.
          </>
        }
      />
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={BUILDING_INTEGRATIONS_LINKS} />
      </GuidancePanelImplementationSection>
      <MediaPlayerOverlay ibmVideoPlayerProps={IBM_VIDEO_DATA} />
    </div>
  );
}

const BuildExperienceBuildingPhoneExperiencesPanelConfig: SectionPanelConfig = {
  title: 'Building Phone Experiences',
  SectionPanelComponent: BuildExperienceBuildingPhoneExperiencesPanel,
  panel: LendyrPanel.BUILD_EXPERIENCE_BUILDING_PHONE_EXPERIENCES,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.BUILD_EXPERIENCE_BUILDING_PHONE_EXPERIENCE,
  page: LendyrPage.BUILD_EXPERIENCE_TOUR,
};

export { BuildExperienceBuildingPhoneExperiencesPanel, BuildExperienceBuildingPhoneExperiencesPanelConfig };
