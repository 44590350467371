/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { GuidancePanelTryItOutList, GuidancePanelTryItOutListItem } from './GuidancePanelTryItOutList';

/**
 * This component renders the default intro panel instructions for a feature tour in the demo site.
 */
function GuidancePanelIntroInstructions() {
  return (
    <GuidancePanelTryItOutList>
      <GuidancePanelTryItOutListItem>Use the buttons below to navigate through the tour.</GuidancePanelTryItOutListItem>
      <GuidancePanelTryItOutListItem>
        Follow the steps to familiarize yourself with specific features.
      </GuidancePanelTryItOutListItem>
      <GuidancePanelTryItOutListItem>
        For more information, check out the linked documentation and guided tutorials.
      </GuidancePanelTryItOutListItem>
    </GuidancePanelTryItOutList>
  );
}

export { GuidancePanelIntroInstructions };
