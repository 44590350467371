/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const SUGGESTIONS_NO_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Configure Suggestions',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-web-chat-config#web-chat-suggestions',
    type: 'Docs',
  },
];

function SuggestionsPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Suggestions"
        description={
          'By clicking the "?" icon in the corner, users can get the extra help they need – either via intelligently suggested alternate topics, relevant search results, external support like a human agent, or by accessing the common topics from the home screen. This is a powerful last attempt to offer a user a self-service route to their solution.'
        }
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following: <GuidancePanelTextSnippet text="What digital wallets can I use?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          The assistant gives you a relevant answer, but it's not the right one. Click on the Suggestions "?" icon in
          the corner of the assistant to view an intelligent menu of alternate help.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Click "Learn about digital wallets". Now that you're in the correct topic, you can find the answer to your
          original question.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={SUGGESTIONS_NO_CODE_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const SuggestionsPanelConfig: SectionPanelConfig = {
  title: 'Suggestions',
  SectionPanelComponent: SuggestionsPanel,
  panel: LendyrPanel.SUGGESTIONS,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.SUGGESTIONS,
};

export { SuggestionsPanel, SuggestionsPanelConfig };
