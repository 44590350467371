/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { IframePanelImplementation, IframePanelInstructions } from '../EssentialsAndIndexSection/IframePanel';

function TellPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Tell"
        description="For straightforward questions, tell your customer the right answer. The right answer can be a simple text response, a back-and-forth conversation, or an engaging piece of media like an image, video, or embedded iFrame."
      />
      <IframePanelInstructions />
      <div className="GuidancePanelDivider" />
      <IframePanelImplementation />
    </div>
  );
}

const TellPanelConfig: SectionPanelConfig = {
  title: 'Tell',
  SectionPanelComponent: TellPanel,
  panel: LendyrPanel.TELL,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.TELL,
};

export { TellPanel, TellPanelConfig };
