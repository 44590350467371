/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './ThemingPanel.css';

import { ContentSwitcher, Switch } from 'carbon-components-react';
import React, { useState } from 'react';

import customThemingHomeScreen from '../../../../assets/custom-theming-home-screen.png';
import customThemingWebChat from '../../../../assets/custom-theming-web-chat.png';
import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { trackDemoSectionPanelAction } from '../../../../framework/utils/demoTracking';
import { ThemingPanelThemeType } from '../../../../framework/utils/themingPanelTypes';
import { ThemingPanelPageOverlay } from '../../../ThemingPanelPageOverlay';
import { GuidanceMobileViewWarningInlineNotification } from '../../commonComponents/GuidanceMobileViewWarningInlineNotification';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const THEMING = 'Theming';
const THEMING_HOME_SCREEN = 'Home Screen';
const THEMING_WEB_CHAT = 'Web Chat';

const THEMING_PANEL_NO_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Configure style and appearance',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-web-chat-style',
    type: 'Docs',
  },
];

const THEMING_PANEL_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Customize for other languages',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-web-chat-develop#strings',
    type: 'Docs',
  },
  {
    title: 'Implement custom buttons',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-web-chat-develop-custom-buttons',
    type: 'Tutorial',
  },
  {
    title: 'Leverage Carbon Design System',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/blob/master/integrations/webchat/examples/using-carbon',
    type: 'Tutorial',
  },
];

function ThemingPanel() {
  const [selectedCustomThemingType, setSelectedCustomThemingType] = useState(ThemingPanelThemeType.HOME_SCREEN);

  return (
    <div>
      <GuidancePanelHeading
        title="Theming"
        description="Your web chat should be an extension of your brand. With custom theming you can fine-tune the appearance of your web chat to be an integrated part of your website, rather than a tacked-on feature."
      />
      <GuidanceMobileViewWarningInlineNotification />
      <div className="GuidancePanelDivider" />
      <ContentSwitcher
        className="ThemingPanel__Switcher"
        onChange={({ index: customThemingType }) => {
          if (customThemingType === ThemingPanelThemeType.HOME_SCREEN) {
            trackDemoSectionPanelAction(`${THEMING} - ${THEMING_HOME_SCREEN}`);
          } else {
            trackDemoSectionPanelAction(`${THEMING} - ${THEMING_WEB_CHAT}`);
          }

          setSelectedCustomThemingType(customThemingType);
        }}
        selectedIndex={selectedCustomThemingType}
      >
        <Switch name="switch-home-screen" text="Home Screen" />
        <Switch name="switch-web-chat" text="Web chat" />
      </ContentSwitcher>
      {selectedCustomThemingType === ThemingPanelThemeType.HOME_SCREEN && (
        <div className="ThemingPanel__SwitcherContent">
          <img src={customThemingHomeScreen} alt="customize home screen" />
          <p>
            Make a good first impression and get customers the help they need more quickly. Customize your avatar,
            change the background color, and add fonts to build a seamless experience.
          </p>
          <GuidancePanelTryItOutList hideTitle>
            <GuidancePanelTryItOutListItem>Custom avatar</GuidancePanelTryItOutListItem>
            <GuidancePanelTryItOutListItem>Custom fonts</GuidancePanelTryItOutListItem>
            <GuidancePanelTryItOutListItem>Background color</GuidancePanelTryItOutListItem>
          </GuidancePanelTryItOutList>
        </div>
      )}
      {selectedCustomThemingType === ThemingPanelThemeType.WEB_CHAT && (
        <div className="ThemingPanel__SwitcherContent">
          <img src={customThemingWebChat} alt="customize web chat" />
          <p>
            Continue to customize within the conversation itself. Utilize color to let your brand shine through. Change
            the color of the header, messages, and interactive points of interest.
          </p>
          <GuidancePanelTryItOutList hideTitle>
            <GuidancePanelTryItOutListItem>Header color</GuidancePanelTryItOutListItem>
            <GuidancePanelTryItOutListItem>Interactive objects</GuidancePanelTryItOutListItem>
            <GuidancePanelTryItOutListItem>Message color</GuidancePanelTryItOutListItem>
            <GuidancePanelTryItOutListItem>Custom buttons</GuidancePanelTryItOutListItem>
          </GuidancePanelTryItOutList>
        </div>
      )}
      <div className="GuidancePanelDivider" />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={THEMING_PANEL_NO_CODE_LINKS} />
        <GuidancePanelImplementationTile codeNecessary data={THEMING_PANEL_CODE_LINKS} />
      </GuidancePanelImplementationSection>
      <ThemingPanelPageOverlay customThemingType={selectedCustomThemingType} />
    </div>
  );
}

const ThemingPanelConfig: SectionPanelConfig = {
  title: 'Theming',
  SectionPanelComponent: ThemingPanel,
  panel: LendyrPanel.THEMING,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.THEMING,
};

export { ThemingPanelConfig };
