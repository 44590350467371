/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { JourneysPanelContent } from '../EssentialsAndIndexSection/JourneysPanel';

function ShowSecondPanel() {
  return (
    <JourneysPanelContent
      title="Show (2/2)"
      description="Deeply integrate your assistant into your digital customer experience to show and teach your customers how to confidently complete tasks for themselves."
    />
  );
}

const ShowSecondPanelConfig: SectionPanelConfig = {
  title: 'Show (2/2)',
  SectionPanelComponent: ShowSecondPanel,
  panel: LendyrPanel.SHOW_2,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.SHOW_2,
};

export { ShowSecondPanel, ShowSecondPanelConfig };
