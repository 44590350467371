/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPage, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function PreChatFormPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Pre-Chat Form"
        description="Add a form that asks the users for some information before they start the conversation. This is most useful in unauthenticated use cases where you want some upfront information to inform how the conversation plays out."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>Click the launcher to open the assistant.</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Enter information that the pre-chat form asks for and submit the form.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
    </div>
  );
}

const PreChatFormPanelConfig: SectionPanelConfig = {
  title: 'Pre-Chat Form',
  SectionPanelComponent: PreChatFormPanel,
  panel: LendyrPanel.PRE_CHAT_FORM,
  page: LendyrPage.HOME,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.PRE_CHAT_FORM,
};

export { PreChatFormPanel, PreChatFormPanelConfig };
