/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const CUSTOM_LAUNCHER_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Hide the default launcher',
    link: 'https://web-chat.global.assistant.watson.cloud.ibm.com/docs.html?to=api-configuration',
    type: 'Docs',
  },
  {
    title: 'Implement a custom launcher',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/custom-launcher',
    type: 'Tutorial',
  },
];

function CustomLauncherPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Custom Launcher"
        description={
          <>
            Though the built-in launcher of the web chat has been tested and shown to increase engagement rates, you can
            use a completely customized launcher if you wish.
          </>
        }
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Click on the custom launcher to open the assistant
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile codeNecessary data={CUSTOM_LAUNCHER_CODE_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const CustomLauncherPanelConfig: SectionPanelConfig = {
  title: 'Custom Launcher',
  SectionPanelComponent: CustomLauncherPanel,
  panel: LendyrPanel.CUSTOM_LAUNCHER,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.CUSTOM_LAUNCHER,
  withRestartButton: true,
};

export { CustomLauncherPanel, CustomLauncherPanelConfig };
