/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelInfoText } from '../../commonComponents/GuidancePanelInfoText';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const CHAT_SURVEY_NO_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Use sub-actions',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-step-what-next#go-to-another-action',
    type: 'Docs',
  },
];

const CHAT_SURVEY_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Implement chat forms',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/pre-post-chat-forms',
    type: 'Tutorial',
  },
];

function ChatSurveyPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Chat Survey"
        description={
          <>
            Collect feedback from your users with a survey that appears after each action ends, when the user clicks to
            minimize the widget, as a permanent option in the header menu, or any combination of the three.
            <br />
            <br />
            The feedback itself can be made in Actions, via an iFrame survey tool like Survey Monkey, or by using the
            web chat's custom content functionality. The data can then be sent to an external source or viewed as a
            variable in your assistant's conversation logs.
          </>
        }
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          To view the survey, send a message to the assistant, then click “Rate Chat" in the menu or click to minimize
          the widget.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelInfoText text="Click the restart button to go through the chat survey experience again." />
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={CHAT_SURVEY_NO_CODE_LINKS} />
        <GuidancePanelImplementationTile codeNecessary data={CHAT_SURVEY_CODE_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const ChatSurveyPanelConfig: SectionPanelConfig = {
  title: 'Chat Survey',
  SectionPanelComponent: ChatSurveyPanel,
  panel: LendyrPanel.CHAT_SURVEY,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.CHAT_SURVEY,
};

export { ChatSurveyPanel, ChatSurveyPanelConfig };
