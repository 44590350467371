/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { ENV_VARIABLES } from './utils/environmentVariables';

/**
 * Injects the walkMe script into the page.
 */
export function injectWalkMeScript() {
  // Import the walkMe snippet.
  const walkMe = document.createElement('script');
  // If we're in dev or stage we want the walkMe src url to be 'https://cdn.walkme.com/users/891fd78d2ac54f689836ca3ddd25dc9e/test/walkme_891fd78d2ac54f689836ca3ddd25dc9e_https.js'.
  // If we're in prod we want the walkMe src url to be 'https://cdn.walkme.com/users/891fd78d2ac54f689836ca3ddd25dc9e/walkme_891fd78d2ac54f689836ca3ddd25dc9e_https.js'.
  walkMe.src = `https://cdn.walkme.com/users/891fd78d2ac54f689836ca3ddd25dc9e${ENV_VARIABLES.DEMO_WALKME_ENV}walkme_891fd78d2ac54f689836ca3ddd25dc9e_https.js`;
  document.head.appendChild(walkMe);
  (window as any)._walkmeConfig = { smartLoad: true };
}
