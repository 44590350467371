/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Store } from 'redux';

import { ACTIONS_SKILL } from '../../../submodules/wa-fd-types-public/wa-types';
import { BusEventPreReceive, BusEventType } from '../../../web-chat-dependencies/types/eventBusTypes';
import { HomeScreenConfig } from '../../../web-chat-dependencies/types/HomeScreenConfig';
import { LauncherType } from '../../../web-chat-dependencies/types/PublicConfig';
import { WatsonAssistantChatInstance } from '../../../web-chat-dependencies/types/WatsonAssistantChatInstance';
import { DemoFeaturePanelsTrackingNames } from '../../demoTypes';
import { demoActions } from '../../store/demoActions';
import { DemoAppState } from '../../store/DemoAppState';
import { trackDemoFeatureFinished } from '../../utils/demoTracking';
import { DEFAULT_HOME_SCREEN_CONFIG, DEFAULT_LAUNCHER_GREETING_MESSAGE } from '../../utils/demoWebChatConstants';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const CREDIT_CARDS_TRANSITION_CONFIG: DemoTransitionConfig = {
  onTransition: (store: Store<DemoAppState>) => {
    return () => {
      const { selectedCardOffer, annualIncome } = store.getState().pageState.creditCardsPage;

      // Reset credit card page state values if they have existing values.
      if (selectedCardOffer) {
        store.dispatch(demoActions.setCreditCardPageStateProperty('selectedCardOffer', null));
      }

      if (annualIncome) {
        store.dispatch(demoActions.setCreditCardPageStateProperty('annualIncome', 0));
      }
    };
  },

  doTransitionWithWebChat: (store: Store<DemoAppState>, instance: WatsonAssistantChatInstance) => {
    const updateLauncherGreetingAndHomeScreenConfig = (greeting: string, homeScreenConfig: HomeScreenConfig) => {
      instance.updateLauncherGreetingMessage(greeting, LauncherType.DESKTOP);
      instance.updateLauncherGreetingMessage(greeting, LauncherType.MOBILE);
      instance.updateHomeScreenConfig(homeScreenConfig);
    };

    // This pre:receive handler takes the given annual income.
    const preReceiveHandler = (event: BusEventPreReceive) => {
      const { output, context } = event.data;
      const userDefinedType = output.generic[0]?.user_defined?.user_defined_type;
      const skillVariables = context.skills[ACTIONS_SKILL].skill_variables;

      if (userDefinedType === 'annual_income') {
        trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.SHOW_1);
        store.dispatch(
          demoActions.setCreditCardPageStateProperty('annualIncome', (skillVariables.annual_income as any).value),
        );
      }
    };

    instance.on({ type: BusEventType.PRE_RECEIVE, handler: preReceiveHandler });

    // Update the launcher greeting message and home screen with content related to the credit cards page.
    updateLauncherGreetingAndHomeScreenConfig('Have any questions about our credit cards?', {
      starters: {
        buttons: [
          { label: 'Learn about types of credit cards' },
          { label: 'Building healthy credit habits' },
          { label: 'Talk to an expert' },
        ],
      },
    });

    return () => {
      // Return the launcher greeting message and home screen to its default state.
      updateLauncherGreetingAndHomeScreenConfig(DEFAULT_LAUNCHER_GREETING_MESSAGE, DEFAULT_HOME_SCREEN_CONFIG);

      // Unsubscribe from the previous pre:receive handler.
      instance.off({ type: BusEventType.PRE_RECEIVE, handler: preReceiveHandler });
    };
  },
};

export { CREDIT_CARDS_TRANSITION_CONFIG };
