/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { useMemo } from 'react';

import { DemoSiteParamsAndHash } from '../demoTypes';

function useExtractDemoLocationProps<T extends DemoSiteParamsAndHash>(props: T) {
  const { page, section, panel, pageLinkID, mobileView, hash, ...regularProps } = props;
  const paramsAndHash = useMemo(
    () => ({
      page,
      section,
      panel,
      pageLinkID,
      mobileView,
      hash,
    }),
    [page, section, panel, pageLinkID, mobileView, hash],
  );

  return { paramsAndHash, ...regularProps };
}

export { useExtractDemoLocationProps };
