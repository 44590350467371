/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelSectionClickableTile.css';

import { ArrowRight24, Checkmark24 } from '@carbon/icons-react';
import classNames from 'classnames';
import React from 'react';

import { DemoClickableTile, DemoClickableTileProps } from '../../DemoClickableTile';

interface GuidancePanelTourClickableTileProps extends DemoClickableTileProps {
  /**
   * The title of the section the user will visit.
   */
  title: string;

  /**
   * The description of the section.
   */
  description: string;

  /**
   * The time it takes to complete the tour.
   */
  duration: string;

  /**
   * Determines if the checkmark icon should be shown. This will show a tile as visited.
   */
  showCheckmark: boolean;

  /**
   * The color of the tag.
   */
  color?: 'teal' | 'purple' | 'blue';
}

function GuidancePanelTourClickableTile({
  title,
  description,
  duration,
  color,
  showCheckmark,
  ...demoClickableTileProps
}: GuidancePanelTourClickableTileProps) {
  return (
    <DemoClickableTile
      className={classNames('GuidancePanelTourClickableTile', {
        [`GuidancePanelTourClickableTile--${color}`]: Boolean(color),
      })}
      {...demoClickableTileProps}
    >
      <div className="GuidancePanelTourClickableTile__TextContent">
        <div className="GuidancePanelTourClickableTile__Title">{title}</div>
        <div className="GuidancePanelTourClickableTile__Description">{description}</div>
      </div>
      <div className="GuidancePanelTourClickableTile__Footer">
        <span className="GuidancePanelTourClickableTile__DurationText">{duration}</span>
        {showCheckmark && <Checkmark24 className="GuidancePanelTourClickableTile__Icon" />}
        {!showCheckmark && <ArrowRight24 className="GuidancePanelTourClickableTile__Icon" />}
      </div>
    </DemoClickableTile>
  );
}

export { GuidancePanelTourClickableTile };
