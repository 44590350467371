/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function BuildExperienceSummaryPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Tour Summary"
        description={
          <>
            We think it’s about time a bot-building tool let anyone actually build AI-powered bots that can
            automatically handle human conversation and scale across a company without breaking.
            <br />
            <br />
            That’s why Watson Assistant designed its build experience for the people who directly interact with
            customers on a daily basis!
            <br />
            <br />
            Watson Assistant is also designing new features powered by <strong>generative AI</strong> and{' '}
            <strong>large language models</strong> to improve the build experience.
            <br />
            <br />
            To learn more about Watson Assistant, its build experience, or how it’s using large language models to
            further improve the build experience, click the Book a Demo button in the top toolbar to book time with an
            expert, or try a Free Trial of Watson Assistant, also available through the top toolbar.
          </>
        }
      />
    </div>
  );
}

const BuildExperienceSummaryPanelConfig: SectionPanelConfig = {
  title: 'The Build Experience summary',
  SectionPanelComponent: BuildExperienceSummaryPanel,
  panel: LendyrPanel.BUILD_EXPERIENCE_SUMMARY,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.BUILD_EXPERIENCE_SUMMARY,
};

export { BuildExperienceSummaryPanel, BuildExperienceSummaryPanelConfig };
