/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelTextSnippet.css';

import { CodeSnippet } from 'carbon-components-react';
import React from 'react';

interface GuidancePanelTextSnippetProps {
  text: string;
}

/**
 * A carbon code snippet component re-styled for the feature guidance panel.
 */
function GuidancePanelTextSnippet({ text }: GuidancePanelTextSnippetProps) {
  return (
    <CodeSnippet className="GuidancePanelTextSnippet" type="single" light>
      {text}
    </CodeSnippet>
  );
}

export { GuidancePanelTextSnippet };
