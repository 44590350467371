/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { InlineNotification } from 'carbon-components-react';
import React from 'react';
import { useSelector } from 'react-redux';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { LendyrAppState } from '../../../../lendyr/store/LendyrAppState';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const CHANGE_CONVERSATION_TOPIC_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Configure changing topics',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-change-topic',
    type: 'Docs',
  },
];

function ChangeConversationTopicPanel() {
  const showInlineWarning = useSelector(
    (state: LendyrAppState) => state.persistedToBrowserSessionStorage.siteData.showChangeConversationTopicPanelWarning,
  );
  return (
    <div>
      <GuidancePanelHeading
        title="Change Conversation Topic"
        description="Jump around from topic to topic, knowing that the assistant is more than up to the task. Fill in multiple pieces of information – in this case, the type of program, type of loan, and the state of residence – naturally and seamlessly."
      />
      {showInlineWarning && (
        <InlineNotification kind="warning" title="Warning" hideCloseButton>
          The steps below may not work as expected if you haven't completed the previous panel's steps.
        </InlineNotification>
      )}
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Let's jump around a bit and switch topics by entering the following:
          <GuidancePanelTextSnippet text="How much can we get in loans?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          You don't have to select an option here. The assistant is able to analyze your answers to send you in the
          right direction. For instance, try entering the following:
          <GuidancePanelTextSnippet text="I'm coming from high school" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          The assistant understands that if you're coming from high school, you're most likely to be looking for a
          financial aid for an undergraduate program. Now that it's resolved this topic, it changes back to the original
          topic. Select "Yes" to start your application.
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Try selecting "Maryland" and then "Yes" so the assistant can pre-qualify you for the loan you've requested.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile data={CHANGE_CONVERSATION_TOPIC_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const ChangeConversationTopicPanelConfig: SectionPanelConfig = {
  title: 'Change Conversation Topic',
  SectionPanelComponent: ChangeConversationTopicPanel,
  panel: LendyrPanel.ACCESSIBLE_CHANGE_CONVERSATION_TOPIC,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.ACCESSIBLE_CHANGE_CONVERSATION_TOPIC,
};

export { ChangeConversationTopicPanel, ChangeConversationTopicPanelConfig };
