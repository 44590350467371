/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { BusEventPreSend, BusEventType, TypeAndHandler } from '../../../web-chat-dependencies/types/eventBusTypes';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const DIGITAL_ADOPTION_PLATFORM_TRANSITION_CONFIG: DemoTransitionConfig = {
  doTransitionWithWebChat: (store, instance) => {
    const preSendTypeAndHandler: TypeAndHandler = {
      type: BusEventType.PRE_SEND,
      handler: (event: BusEventPreSend) => {
        if (event.data.input.text === "I don't understand a charge I saw on the app") {
          event.data.history.label = "I don't understand a charge I saw on the app";
          // Trigger WalkMe instead of the wen chat journey for this panel.
          event.data.input.text = 'WALK_ME_INTEGRATION';
        }
      },
    };

    instance.on(preSendTypeAndHandler);
    return () => {
      instance.off(preSendTypeAndHandler);
    };
  },

  disableMobileView: true,
};

export { DIGITAL_ADOPTION_PLATFORM_TRANSITION_CONFIG };
