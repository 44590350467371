/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { useSelector } from 'react-redux';

import { DemoAppState } from '../store/DemoAppState';

/**
 * This file contains selector hooks for retrieving redux store values.
 */

function useDemoLocation() {
  return useSelector((state: DemoAppState) => state.demoSiteParamsAndHash);
}

function useIsGuidanceComponentExpanded() {
  return useSelector((state: DemoAppState) => state.persistedToBrowserSessionStorage.isGuidancePanelExpanded);
}

export { useDemoLocation, useIsGuidanceComponentExpanded };
