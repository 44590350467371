/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { CreditCardsPage } from '../pages/creditCardsPage/CreditCardsPage';
import { EmailPage } from '../pages/EmailPage';
import { FAQPage } from '../pages/FAQPage';
import { HomeLoansPage } from '../pages/HomeLoansPage';
import { HomePage } from '../pages/HomePage';
import { PersonalLoansPage } from '../pages/PersonalLoansPage';
import { PhoneTourPage } from '../pages/PhoneTourPage';
import { PrimaryBlankPage } from '../pages/PrimaryBlankPage';
import { StudentLoansPage } from '../pages/StudentLoansPage';
import { TransactionsPage } from '../pages/TransactionsPage';
import ObjectMap from '../web-chat-dependencies/types/ObjectMap';
import { DemoPageComponent, LendyrPage } from './demoTypes';

function createPageRouteMap() {
  const pageRouteMapping: ObjectMap<DemoPageComponent> = {};

  pageRouteMapping[LendyrPage.HOME] = HomePage;
  pageRouteMapping[LendyrPage.STUDENT_LOANS] = StudentLoansPage;
  pageRouteMapping[LendyrPage.PERSONAL_LOANS] = PersonalLoansPage;
  pageRouteMapping[LendyrPage.HOME_LOANS] = HomeLoansPage;
  pageRouteMapping[LendyrPage.CREDIT_CARDS] = CreditCardsPage;
  pageRouteMapping[LendyrPage.TRANSACTIONS] = TransactionsPage;
  pageRouteMapping[LendyrPage.FAQ] = FAQPage;
  pageRouteMapping[LendyrPage.EMAIL] = EmailPage;
  pageRouteMapping[LendyrPage.PHONE_TOUR] = PhoneTourPage;
  pageRouteMapping[LendyrPage.ITERATE_QUICKLY_TOUR] = PrimaryBlankPage;
  pageRouteMapping[LendyrPage.DRIVE_CUSTOMER_OUTCOMES_TOUR] = PrimaryBlankPage;
  pageRouteMapping[LendyrPage.GENERATIVE_AI_TOUR] = PrimaryBlankPage;
  pageRouteMapping[LendyrPage.BUILD_EXPERIENCE_TOUR] = PrimaryBlankPage;

  return pageRouteMapping;
}

export { createPageRouteMap };
