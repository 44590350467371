/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2020, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import memoizeOne from 'memoize-one';
import { KeyboardEvent as ReactKeyboardEvent, RefObject } from 'react';
import { tabbable } from 'tabbable';

/**
 * The calculated size of scrollbars in the application. Note that this value can vary by browser and operating
 * system. This is memoized, so it's calculated lazily after the application has loaded.
 */
const SCROLLBAR_WIDTH = memoizeOne(getScrollbarWidth);

/**
 * Calculates the width of a scrollbar in the system. This will add a temporary scrollable div to the document with
 * a div inside and then measure the difference in size of the element.
 */
function getScrollbarWidth(): number {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container.
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width.
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM.
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

/**
 * Requests focus be moved to the given element, optionally deferring it to the next event loop.
 *
 * @param element The element to move focus to.
 * @param preventScroll Indicates if scrolling should be prevented as a result of the focus change.
 */
function doFocus(element: HTMLElement | SVGElement, preventScroll: boolean = false) {
  if (element && document.activeElement !== element && tabbable(element)) {
    element.focus({ preventScroll });
  }
}

/**
 * Requests focus be moved to the element referenced by the given react ref if the referenced element is defined.
 *
 * @param ref The reference to the element to move focus to.
 * @param defer Indicates if the focus should be executed now or if it should be deferred to another event loop.
 * @param preventScroll Indicates if scrolling should be prevented as a result of the focus change.
 */
function doFocusRef(ref: RefObject<HTMLElement>, defer: boolean = false, preventScroll = false) {
  if (ref) {
    if (defer) {
      setTimeout(() => {
        doFocusRef(ref);
      });
    } else if (ref.current) {
      doFocus(ref.current, preventScroll);
    }
  }
}

/**
 * Determines if the given element is, or is within, the element we're looking for using the provided predicate.
 *
 * @param startAt The element at which to start the checking. This function will navigate up the tree from here.
 * @param predicate A callback function providing the current element in the tree hierarchy to use as a condition.
 * @param stopAt An optional element that will tell the function when to stop as it moves up the tree.
 */
function isOrInsideOfPredicate(startAt: Element, predicate: (element: Element) => boolean, stopAt: Element = null) {
  while (startAt && startAt !== stopAt) {
    if (predicate(startAt)) {
      return true;
    }
    startAt = startAt.parentElement;
  }

  return false;
}

/**
 * Determines if the given element has the given class or is contained inside another element that does.
 *
 * @param startAt The element at which to start the checking. This function will navigate up the tree from here.
 * @param className The value of the attribute to look for.
 * @param stopAt An optional element that will tell the function when to stop as it moves up the tree.
 */
function isOrInsideOfClass(startAt: Element, className: string, stopAt: Element = null): boolean {
  return isOrInsideOfPredicate(
    startAt,
    element => {
      return element.classList.contains(className);
    },
    stopAt,
  );
}

/**
 * Determines if the given keyboard event represent a press of the enter key. This will exclude the key went pressed
 * as part of IME composing. This function supports both the built-in typescript KeyboardEvent type and the React
 * version (which is missing some properties).
 */
function isEnterKey(event: ReactKeyboardEvent | KeyboardEvent) {
  if (event.key === 'Enter') {
    // Users using IMEs could be making a word selection when they hit enter. This check will prevent the user's
    // message from being sent prematurely.
    return !((event as KeyboardEvent).isComposing || event.keyCode === 229);
  }
  return false;
}

export { SCROLLBAR_WIDTH, doFocus, doFocusRef, isEnterKey, isOrInsideOfPredicate, isOrInsideOfClass };
