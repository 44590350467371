/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2020, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * This module is responsible for tracking events through amplitude. It tracks each user's triggered event and
 * specific properties for that event.
 *
 * Amplitude creates their user profiles using a combination of device_id and user_id. If a user_id is added to
 * a session with a given device_id, that device is then added to the user profile. We make use of this by
 * assigning a device_id in sessionStorage to be used across an entire session. If a user_id is not defined
 * (say JWT has not been passed in yet), we don't define it. When we DO get the user_id, amplitude then knows to
 * supply it to the rest of the session.
 *
 * See https://help.amplitude.com/hc/en-us/articles/115003135607-Tracking-Unique-Users#h_5c321a51-5d16-43f0-a4fe-f907567ad63b
 */

import { ENV_VARIABLES } from '../../framework/utils/environmentVariables';
import ObjectMap from '../types/ObjectMap';
import { BROWSER_INFO, getURLHostnameAndPath, IS_PHONE, IS_TABLET } from '../utils/browserUtils';
import { VERSION } from '../utils/constants';
import { ServiceManager } from './ServiceManager';

interface TrackEvent {
  /**
   * For calls that track docs, we pass in a userID (just a cookied guid)
   */
  user_id: string;

  /**
   * The response type being used in event.
   *
   * E.g. for input message: text, options (buttons, dropdown) ...
   * E.g. for bot message: text, options (button, dropdown), image, pause, search, connect to human agent,
   * disambiguation
   */
  event_type: string;

  /**
   * Map of properties to send with the event.
   * Can contain all properties of TrackEventProps that are not set elsewhere on the TrackEvent
   */
  event_properties: ObjectMap<any>;

  /**
   * The user properties that should be updated with the event
   */
  user_properties?: ObjectMap<any>;
}

interface TrackEventProps {
  /**
   * The name of the event being tracked.
   */
  eventName: string;

  /**
   * The description of the event being tracked.
   */
  eventDescription?: string;

  /**
   * The type of interaction on the demo site (e.g. Panel viewed, Page viewed, etc.)
   */
  type?: string;

  /**
   * The name of a feature {@link DemoFeaturePanelsTrackingNames}, a panel {@link DemoPanelsTrackingNames}, or a
   * particular demo page. This property could be expanded to be used elsewhere.
   */
  name?: string;

  /**
   * Describes how the user is viewing the demo site.
   */
  viewType?: 'desktop' | 'mobile' | 'emulated mobile';
}

// The parameters to send on fetch requests.
// Note: The content-type must be application/json per amplitude's HTTP API v2 docs.
// https://developers.amplitude.com/docs/http-api-v2#request-format
const FETCH_PARAMETERS = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
};

// Defaults to Amplitude testing environment API key if none provided.
const API_KEY = ENV_VARIABLES.DEMO_AMPLITUDE_API_KEY;

const POST_URL = '/2/httpapi';

// Use the Identify API to set the User ID for a particular Device ID or update user properties of a particular user
// without sending an event. However, these updates will only affect events going forward.
const IDENTIFY_POST_URL = `/identify?api_key=${API_KEY}`;

const MAX_AMPLITUDE_EVENTS = 10;

// Returns the current device type, differentiating between; phone, tablet, and desktop.
// eslint-disable-next-line no-nested-ternary
const BROWSER_DEVICE_TYPE = IS_TABLET ? 'tablet' : IS_PHONE ? 'phone' : 'desktop';

class TrackService {
  /**
   * The service manager to use to access services.
   */
  serviceManager: ServiceManager;

  /**
   * The host
   */
  endpointPath: string;

  /**
   * Queue for events that are ready to send to Amplitude. We do not send events immediately to prevent overloading the
   * limit of 10/second per device.
   */
  private eventQueue: TrackEvent[] = [];

  /**
   * We keep track of the first userID we use, this way we don't end up switching the userID and confusing amplitude
   * partway through.
   */
  private firstUserID: string;

  /**
   * We need to add user_properties to the first batch of events, this variable is used to determine if the properties
   * have been added to the first batch or not.
   */
  private firstBatchUserProperties: boolean = false;

  /**
   * Flag indicating whether to disable the sending of identity calls.
   */
  private isIdentifyEnabled: boolean;

  /**
   * Flag indicating whether to disable the sending of tracking events.
   */
  private isEventEnabled: boolean;

  constructor(
    serviceManager: ServiceManager,
    isIdentifyEnabled: boolean,
    isEventEnabled: boolean,
    endpointPath: string,
  ) {
    this.serviceManager = serviceManager;
    this.isIdentifyEnabled = isIdentifyEnabled;
    this.isEventEnabled = isEventEnabled;
    this.endpointPath = endpointPath;
  }

  /**
   * Pushes an event onto the eventQueue and starts the sendEvents loop if event is the first event in the eventQueue
   *
   * @param event The event to send to Amplitude
   */
  pushEvent(event: TrackEvent) {
    this.eventQueue.push(event);
    // If this is the only event in the queue start the sendEvents loop
    if (this.eventQueue.length === 1) {
      this.sendEvents();
    }
  }

  /**
   * Batches events from the eventQueue and sends them to Amplitude. Repeats for additional events in the queue.
   * For more information see: https://developers.amplitude.com/#Http-Api-V2
   */
  sendEvents() {
    // Using setTimeout to allow for multiple events to be added to the eventQueue in a single browser tick before
    // they are sent to Amplitude in a batch.
    setTimeout(() => {
      if (this.eventQueue.length) {
        // Amplitude v2 api has an event batch size limit of 10 events
        const eventBatch = this.eventQueue.splice(0, MAX_AMPLITUDE_EVENTS);

        if (!this.firstBatchUserProperties) {
          // Need to add user properties to all events in the first batch otherwise there will be events in the first
          // batch that don't have the below user properties.
          eventBatch.map(event => {
            event.user_properties = {
              'Browser name': BROWSER_INFO?.name,
              'Browser version': BROWSER_INFO?.version,
              'Browser OS': BROWSER_INFO?.os,
              'Browser device type': BROWSER_DEVICE_TYPE,
              'Deployed full path': getURLHostnameAndPath(),
              'Deployed hostname': window.location.hostname,
              'User loaded version': VERSION,
              ...event.user_properties,
            };
            return event;
          });

          this.firstBatchUserProperties = true;
        }

        fetch(`${this.endpointPath}${POST_URL}`, {
          ...FETCH_PARAMETERS,
          body: JSON.stringify({ api_key: API_KEY, events: eventBatch }),
        }).catch(error => {
          console.error('Failed to track events', error);
        });

        // If events remain in the queue, loop through sendEvents again to ensure they are all sent.
        this.sendEvents();
      }
    });
  }

  /**
   * Get the unique tracking user id to use in amplitude
   * Prepend "WAEndUser-" to show who are our end users.
   *
   * @param serviceInstanceID The user's service instance id.
   * @param userID The user's id (if provided).
   */
  getTrackUserID(serviceInstanceID: string, userID: string) {
    // If a user ID isn't set, yet, just return undefined.
    if (!userID) {
      return undefined;
    }
    // If we have a userID already defined, just return it.
    if (this.firstUserID) {
      return this.firstUserID;
    }
    // If there is a userID set, but not a firstUserID, we set it.
    this.firstUserID = `WAEndUser-${serviceInstanceID}:${userID}`;
    return this.firstUserID;
  }

  /**
   * Identifies the user and then pushes the event onto the eventQueue.
   *
   * @param options The included properties {@link TrackEventProps} wished to be tracked.
   * @param userID The userID to use for the track event. Because the docs and demo site do not use the same
   * serviceManager as web chat they both need to create their own userID's for tracking and pass them to this function.
   */
  public demoTrack(options: TrackEventProps, userID: string) {
    if (options) {
      const { eventName, ...rest } = options;
      const identifyUser = {
        user_id: userID,
      };

      // We cannot guarantee the user has already been identified with Amplitude in the docs site or the demo site, so we
      // identify the user with Amplitude before each demoOrDocsTrack event. This does not cost us events.
      const identifyUserUrl = `${this.endpointPath}${IDENTIFY_POST_URL}&identification=${JSON.stringify(identifyUser)}`;
      fetch(identifyUserUrl, FETCH_PARAMETERS).catch(error => {
        console.error('Failed to identify user', error);
      });

      const event = {
        user_id: userID,
        event_type: eventName,
        event_properties: rest,
      };

      this.pushEvent(event);
    }
  }
}

export default TrackService;
export { TrackService, TrackEvent, TrackEventProps };
