/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './LoadingLocationPortal.css';

import { Loading } from 'carbon-components-react';
import React from 'react';
import ReactDom from 'react-dom';

import { MountChildrenOnDelay } from '../web-chat-dependencies/components/util/MountChildrenOnDelay';
import { WatsonAssistantChatInstance } from '../web-chat-dependencies/types/WatsonAssistantChatInstance';

interface LoadingLocationPortalProps {
  instance: WatsonAssistantChatInstance;
}

/**
 * This component is for the "find nearest location" demo where the users location is retrieved from the browser and
 * then a google maps iFrame is shown to direct them to the nearest location. This component is specifically used to
 * tell the user what is happening when they see a delay while their location is being retrieved from the browser.
 */
function LoadingLocationPortal(props: LoadingLocationPortalProps) {
  // Create a react portal that attaches some text and a loading spinner to the writeable element below the header.
  return ReactDom.createPortal(
    <div className="LoadingLocationPortal">
      <MountChildrenOnDelay delay={1000}>
        <div className="LoadingLocationPortal__Text">Retrieving your location</div>
        <Loading small withOverlay={false} />
      </MountChildrenOnDelay>
    </div>,
    props.instance.writeableElements.beforeInputElement,
  );
}

export { LoadingLocationPortal };
