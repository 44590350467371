/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelImplementationSection.css';

import { Link, Tag, Tile } from 'carbon-components-react';
import React, { Children, ReactElement } from 'react';

import { trackDemoSetupLinkClicked } from '../../../framework/utils/demoTracking';
import {
  assertChildType,
  getCurrentLendyrPanelTrackingName,
  getDemoSiteParamsAndHash,
} from '../../../framework/utils/demoUtils';
import { useOnMount } from '../../../web-chat-dependencies/hooks/useOnMount';
import { HasChildren } from '../../../web-chat-dependencies/types/HasChildren';

/**
 * The types of content a user will visit when visiting a link.
 */
type LinkType = 'Article' | 'Docs' | 'Tutorial';

interface GuidancePanelImplementationData {
  /**
   * The title of the implementation.
   */
  title: string;

  /**
   * A link to the implementation page of the tutorial, or link to docs.
   */
  link: string;

  /**
   * The type of content the user will visit.
   */
  type: LinkType;
}

interface GuidancePanelImplementationTileProps {
  /**
   * The type of implementation.
   */
  codeNecessary?: boolean;

  /**
   * A list of link data for generating links on the page users can visit.
   */
  data: GuidancePanelImplementationData[];
}

/**
 * Renders the implementation section for providing links to tutorials/docs to implement a feature described in the
 * feature guidance panel.
 */
function GuidancePanelImplementationSection({ children }: HasChildren) {
  useOnMount(() => {
    Children.forEach(children, (child: ReactElement) => {
      assertChildType(child, GuidancePanelImplementationTile);
    });
  });

  return (
    <div className="GuidancePanelImplementationSection">
      <div className="GuidancePanelImplementationSection__Title">Implementation options</div>
      {children}
    </div>
  );
}

/**
 * Renders a tile with links to tutorials/docs on how to implement a feature, as well as whether, or not, there is
 * code necessary.
 */
function GuidancePanelImplementationTile({ codeNecessary, data }: GuidancePanelImplementationTileProps) {
  const title = codeNecessary ? 'Code necessary' : 'No code necessary';
  return (
    <div className="GuidancePanelImplementationTile">
      <div className="GuidancePanelImplementationTile__Title">{title}</div>
      <Tile>
        {data.map(({ title, link, type }) => (
          <div key={title} className="GuidancePanelImplementationTile__LinkWrapper">
            <Link
              key={title}
              href={link}
              target="_blank"
              onClick={() => {
                const { section, panel } = getDemoSiteParamsAndHash();
                const demoPanelTrackingName = getCurrentLendyrPanelTrackingName(section, panel);

                if (demoPanelTrackingName) {
                  trackDemoSetupLinkClicked(demoPanelTrackingName);
                }
              }}
            >
              {title}
            </Link>
            {type && (
              <Tag className="GuidancePanelImplementationTile__Tag" type={getTagType(type)}>
                {type}
              </Tag>
            )}
          </div>
        ))}
      </Tile>
    </div>
  );
}

function getTagType(type: LinkType) {
  switch (type) {
    case 'Article':
      return 'magenta';
    case 'Docs':
      return 'purple';
    case 'Tutorial':
      return 'green';
    default:
      return null;
  }
}

export { GuidancePanelImplementationSection, GuidancePanelImplementationTile, GuidancePanelImplementationData };
