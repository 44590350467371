/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoSectionIntroAndSummaryTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelList, GuidancePanelListItem } from '../../commonComponents/GuidancePanelList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

function TellDoShowSummaryPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Tour Summary"
        description={
          <>
            With Watson Assistant, you can deliver an integrated digital experience between the assistant and the
            application.
            <br />
            <GuidancePanelList>
              <GuidancePanelListItem>
                <strong>Tell</strong> the customer the right informational answer
              </GuidancePanelListItem>
              <GuidancePanelListItem>
                <strong>Do</strong> the action for the customer
              </GuidancePanelListItem>
              <GuidancePanelListItem>
                <strong>Show</strong> the customer how to resolve the issue themselves, and empower them to complete
                tasks on your primary digital channels.
              </GuidancePanelListItem>
            </GuidancePanelList>
          </>
        }
      />
    </div>
  );
}

const TellDoShowSummaryPanelConfig: SectionPanelConfig = {
  title: 'Tour Summary',
  SectionPanelComponent: TellDoShowSummaryPanel,
  panel: LendyrPanel.TELL_DO_SHOW_SUMMARY,
  demoPanelTrackingName: DemoSectionIntroAndSummaryTrackingNames.TELL_DO_SHOW_SUMMARY,
};

export { TellDoShowSummaryPanel, TellDoShowSummaryPanelConfig };
