/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { Education32, Money32, Password32, Purchase32 } from '@carbon/icons-react';

import packageJSON from '../../../package.json';
import { SCROLLBAR_WIDTH } from '../../web-chat-dependencies/utils/domUtils';
import { LendyrPage, LendyrPanel } from '../demoTypes';
import { CardLinkComponentProps } from '../types/CardLinkComponentProps';
import { ENV_VARIABLES } from './environmentVariables';

// The version of the application according to the package.json file (without the "-" part).
const VERSION: string = packageJSON.version?.split('-')[0];

// The namespace of the application. This can be used to namespace anything unique to the application, such as browser
// storage data.
const APP_NAMESPACE = 'lendyr';

// The configuration properties for the demo human agent assistant. We use the draft integration in dev and stage and
// the live integration in production.
const DEMO_HA_ASSISTANT = {
  integrationID: ENV_VARIABLES.DEMO_HA_ASSISTANT_INTEGRATION_ID,
  region: 'us-south',
  serviceInstanceID: '9a3613d2-3ce6-4928-8eb6-4d659d87ae68',
};

// The configuration properties for the demo assistant. We use the draft integration in dev and stage and the live
// integration in production.
const DEMO_ASSISTANT = {
  integrationID: ENV_VARIABLES.DEMO_ASSISTANT_INTEGRATION_ID,
  region: 'us-south',
  serviceInstanceID: '243a9350-d533-4e96-b355-331e677ac332',
};

// Demo site page breakpoints.
const BREAKPOINT_756 = 756;
const BREAKPOINT_960 = 960;
// This is the breakpoint in pixels at which point the guidance panel will float over an overlay.
const BREAKPOINT_1055 = 1055;

const DEMO_SITE_SCROLLBAR_WIDTH = SCROLLBAR_WIDTH();

// The width of the demo site mobile view display.
const MOBILE_VIEW_WIDTH = 392 + DEMO_SITE_SCROLLBAR_WIDTH;

// The URL search param that determines the current demo page to render.
const URL_PARAM_PAGE = 'page';
// The URL search param that determines the current section to render.
const URL_PARAM_SECTION = 'section';
// The URL search param that determines the current panel to render.
const URL_PARAM_PANEL = 'panel';
// The URL search param that triggers web chat's page links feature.
const URL_PARAM_WA_LID = 'wa_lid';
// The URL search param that changes the demo site to mobile view.
const URL_PARAM_MOBILE_VIEW = 'mobileView';

// The piece of text in the email page that will trigger the deep linking web chat feature.
const DEEP_LINKING_EMAIL_TEXT = 'approve pending trade requests';

// A map of panel names to the page said panel should start from when being visited.
const PANEL_SPECIFIC_PAGE_MAP: Record<string, LendyrPage> = {
  [LendyrPanel.DEEP_LINKING]: LendyrPage.EMAIL,
  [LendyrPanel.UNIFY_CROSS_CHANNEL_HELP]: LendyrPage.EMAIL,
  [LendyrPanel.POSITION]: LendyrPage.FAQ,
};

const MORTGAGE_CALCULATOR_ELEMENT_ID = 'mortgage-calculator-section';

const WALK_ME_FLOW_ID = 1294763;

// The step ids for the dispute charge web chat tour.
const TOUR_STEP_ID_DISPUTE_CHARGE_2 = 'tour_step_id_dispute_charge_2';
const TOUR_STEP_ID_DISPUTE_CHARGE_4 = 'tour_step_id_dispute_charge_4';

const TOUR_STEP_ID_CREDIT_CARD_2 = 'tour_step_id_credit_card_2';
const TOUR_STEP_ID_CREDIT_CARD_3 = 'tour_step_id_credit_card_3';

// A list of card links that take the user to various pages of the demo site.
const DEFAULT_CARD_LINKS: CardLinkComponentProps[] = [
  { Icon: Education32, title: 'Employee Programs', page: LendyrPage.STUDENT_LOANS },
  { Icon: Money32, title: 'Individual Services', page: LendyrPage.PERSONAL_LOANS },
  { Icon: Password32, title: 'Manage Wealth', page: LendyrPage.HOME_LOANS },
  {
    Icon: Purchase32,
    title: 'Learn More',
    page: LendyrPage.CREDIT_CARDS,
    onClick: instance => {
      if (instance?.getState().isTourActive) {
        instance.tours.goToStep(TOUR_STEP_ID_CREDIT_CARD_2);
      }
    },
  },
];

export {
  VERSION,
  APP_NAMESPACE,
  DEMO_HA_ASSISTANT,
  DEMO_ASSISTANT,
  BREAKPOINT_960,
  BREAKPOINT_756,
  BREAKPOINT_1055,
  DEFAULT_CARD_LINKS,
  URL_PARAM_PAGE,
  DEEP_LINKING_EMAIL_TEXT,
  PANEL_SPECIFIC_PAGE_MAP,
  MORTGAGE_CALCULATOR_ELEMENT_ID,
  URL_PARAM_SECTION,
  URL_PARAM_WA_LID,
  URL_PARAM_PANEL,
  WALK_ME_FLOW_ID,
  URL_PARAM_MOBILE_VIEW,
  MOBILE_VIEW_WIDTH,
  DEMO_SITE_SCROLLBAR_WIDTH,
  TOUR_STEP_ID_DISPUTE_CHARGE_2,
  TOUR_STEP_ID_DISPUTE_CHARGE_4,
  TOUR_STEP_ID_CREDIT_CARD_3,
};
