/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { InlineNotification } from 'carbon-components-react';
import React from 'react';

import { useDemoLocation } from '../../../framework/hooks/selectorHooks';
import { IS_MOBILE } from '../../../web-chat-dependencies/utils/browserUtils';

/**
 * This component is an inline notification warning for panels that do not have an ideal mobile experience. This
 * component will only be rendered if the demo site is in the emulated mobile view.
 */
function GuidanceMobileViewWarningInlineNotification() {
  const { mobileView } = useDemoLocation();
  return (
    !IS_MOBILE &&
    mobileView && (
      <InlineNotification kind="warning" title="Warning" hideCloseButton>
        This demo is not functional in a mobile environment.
      </InlineNotification>
    )
  );
}

export { GuidanceMobileViewWarningInlineNotification };
