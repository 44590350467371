/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames, LendyrPanel } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';
import { LiveAgentEscalationPanelImplementation } from '../EssentialsAndIndexSection/LiveAgentEscalationPanel';

function PersonalizedJourneysPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Personalized Journeys"
        description="Route and manage the conversation based on who the customer is and what they are trying to accomplish. In this example, the assistant can first recognize that the user is concerned about security and then deliver an upsell message to explore new credit card options. Then, the user can be intelligently routed to the appropriate human support agent for this specific topic and customer profile."
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following:
          <GuidancePanelTextSnippet text="I want to dispute a charge on my credit card" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Click "Tell me".</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>Click "Yes".</GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Click to connect to an agent who specializes in this promotion.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <LiveAgentEscalationPanelImplementation />
    </div>
  );
}

const PersonalizedJourneysPanelConfig: SectionPanelConfig = {
  title: 'Personalized Journeys',
  SectionPanelComponent: PersonalizedJourneysPanel,
  panel: LendyrPanel.UNIFY_PERSONALIZED_JOURNEYS,
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.UNIFY_PERSONALIZED_JOURNEYS,
};

export { PersonalizedJourneysPanel, PersonalizedJourneysPanelConfig };
