/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { lendyrActions } from '../../../lendyr/store/lendyrActions';
import { BusEventPreReceive, BusEventType } from '../../../web-chat-dependencies/types/eventBusTypes';
import { isOptionItem, isTextItem } from '../../../web-chat-dependencies/utils/messageUtils';
import { DemoFeaturePanelsTrackingNames } from '../../demoTypes';
import { trackDemoFeatureFinished } from '../../utils/demoTracking';
import { DemoTransitionConfig } from '../demoTransitionTypes';

const ACCESSIBLE_SCALABLE_AI_TRANSITION_CONFIG: DemoTransitionConfig = {
  doTransitionWithWebChat: (store, instance) => {
    const preReceiveHandler = (event: BusEventPreReceive) => {
      const generic = event.data.output?.generic;

      generic?.forEach(item => {
        if (isOptionItem(item) && item.user_defined?.user_defined_type === 'change-conversation-topic-start') {
          store.dispatch(
            lendyrActions.updateSessionStorageStateSiteData({
              showChangeConversationTopicPanelWarning: false,
            }),
          );
        }

        if (isTextItem(item) && item.user_defined?.user_defined_type === 'change-conversation-topic-end') {
          trackDemoFeatureFinished(DemoFeaturePanelsTrackingNames.ACCESSIBLE_CHANGE_CONVERSATION_TOPIC);
        }
      });
    };

    instance.on({ type: BusEventType.PRE_RECEIVE, handler: preReceiveHandler });

    return () => {
      instance.off({ type: BusEventType.PRE_RECEIVE, handler: preReceiveHandler });
    };
  },

  restartOptions: {
    onClickRestart: store => {
      store.dispatch(
        lendyrActions.updateSessionStorageStateSiteData({
          showChangeConversationTopicPanelWarning: true,
        }),
      );
    },
  },
};

export { ACCESSIBLE_SCALABLE_AI_TRANSITION_CONFIG };
