/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './GuidancePanelInfoText.css';

import { Help20 } from '@carbon/icons-react';
import React from 'react';

interface GuidancePanelInfoTextProps {
  text: string;
}

/**
 * This component is for displaying text that relay some sort of useful information in the guidance panel.
 */
function GuidancePanelInfoText({ text }: GuidancePanelInfoTextProps) {
  return (
    <div className="GuidancePanelInfoText">
      <div>
        <Help20 />
      </div>
      <div className="GuidancePanelInfoText__TextContainer">
        <div>{text}</div>
      </div>
    </div>
  );
}

export { GuidancePanelInfoText };
