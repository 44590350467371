/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { HasClassName } from '../web-chat-dependencies/types/HasClassName';

function UpdateNow32({ className }: HasClassName) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <polygon points="27 25.586 25 23.586 25 21 23 21 23 24.414 25.586 27 27 25.586" />
      <path d="M24,31a7,7,0,1,1,7-7A7.0078,7.0078,0,0,1,24,31Zm0-12a5,5,0,1,0,5,5A5.0055,5.0055,0,0,0,24,19Z" />
      <path d="M16,28A12.0134,12.0134,0,0,1,4,16H2A14.0158,14.0158,0,0,0,16,30Z" />
      <path d="M12,8H7.0784A11.9843,11.9843,0,0,1,28,16h2A13.9778,13.9778,0,0,0,6,6.2344V2H4v8h8Z" />
      <rect className="cls-1" fill="none" width="32" height="32" />
    </svg>
  );
}

export { UpdateNow32 };
