/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './LoginModal.css';

import { Close24 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import React from 'react';

import { MockTextInput } from './demoGuidanceComponent/commonComponents/MockTextInput';
import { LendyrButton } from './LendyrButton';
import { PageLink } from './PageLink';

interface LoginModalProps {
  /**
   * The callback function to fire when the user clicks the login button.
   */
  onLogin: () => void;

  /**
   * The callback function to fire when the user clicks the close button.
   */
  onClose: () => void;
}

function LoginModal({ onLogin, onClose }: LoginModalProps) {
  return (
    <div className="LoginModal">
      <div className="LoginModal__CloseButtonWrapper">
        <Button iconDescription="Close" kind="ghost" renderIcon={Close24} onClick={onClose} hasIconOnly />
      </div>
      <div className="LoginModal__Title">Log in</div>
      <div className="LoginModal__InputContainer">
        <MockTextInput labelText="Email" value="cade@example.com" helperText="This field is for demo purposes only." />
        <MockTextInput labelText="Password" value="••••••••••••" helperText="This field is for demo purposes only." />
        <PageLink>Forgot password?</PageLink>
        <LendyrButton className="LoginModal__LogInButton" onClick={onLogin} autofocus>
          Log in
        </LendyrButton>
      </div>
    </div>
  );
}

export { LoginModal };
