/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';

import { DemoFeaturePanelsTrackingNames } from '../../../../framework/demoTypes';
import { GuidancePanelHeading } from '../../commonComponents/GuidancePanelHeading';
import {
  GuidancePanelImplementationData,
  GuidancePanelImplementationSection,
  GuidancePanelImplementationTile,
} from '../../commonComponents/GuidancePanelImplementationSection';
import { GuidancePanelTextSnippet } from '../../commonComponents/GuidancePanelTextSnippet';
import {
  GuidancePanelTryItOutList,
  GuidancePanelTryItOutListItem,
} from '../../commonComponents/GuidancePanelTryItOutList';
import { SectionPanelConfig } from '../../featureGuidancePanelUtils';

const CONTENT_CAROUSEL_CODE_LINKS: GuidancePanelImplementationData[] = [
  {
    title: 'Build a custom extension',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-build-custom-extension',
    type: 'Docs',
  },
  {
    title: 'Use a custom extension',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-call-extension',
    type: 'Docs',
  },
  {
    title: 'Handle custom responses',
    link: 'https://web-chat.global.assistant.watson.cloud.ibm.com/docs.html?to=api-events#customresponse',
    type: 'Docs',
  },
  {
    title: 'Create Extensions',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/extensions',
    type: 'Tutorial',
  },
  {
    title: 'Implement custom responses',
    link: 'https://github.com/watson-developer-cloud/assistant-toolkit/tree/master/integrations/webchat/examples/custom-response',
    type: 'Tutorial',
  },
  {
    title: 'Implement content carousel',
    link: 'https://cloud.ibm.com/docs/watson-assistant?topic=watson-assistant-web-chat-develop-content-carousel',
    type: 'Tutorial',
  },
];

function ContentCarouselPanel() {
  return (
    <div>
      <GuidancePanelHeading
        title="Content Carousel"
        description={
          <>
            Extensions are a way for conversation authors to enable advanced functionality – like retrieving real-time
            information from a database, referencing a CRM, submitting a ticket, and many other tasks that utilize tools
            offering a REST API – without getting lost in technical notation.
            <br />
            <br />
            In this case, we use extensions and custom response types to retrieve credit card product information from a
            database and render it in a carousel.
          </>
        }
      />
      <GuidancePanelTryItOutList>
        <GuidancePanelTryItOutListItem>
          Enter the following: <GuidancePanelTextSnippet text="What are some credit card options?" />
        </GuidancePanelTryItOutListItem>
        <GuidancePanelTryItOutListItem>
          Swipe through options and click one of the "Learn more" buttons.
        </GuidancePanelTryItOutListItem>
      </GuidancePanelTryItOutList>
      <GuidancePanelImplementationSection>
        <GuidancePanelImplementationTile codeNecessary data={CONTENT_CAROUSEL_CODE_LINKS} />
      </GuidancePanelImplementationSection>
    </div>
  );
}

const ContentCarouselPanelConfig: SectionPanelConfig = {
  title: 'Content Carousel',
  SectionPanelComponent: ContentCarouselPanel,
  panel: 'custom-response-types-extensions',
  demoPanelTrackingName: DemoFeaturePanelsTrackingNames.CUSTOM_RESPONSE_EXTENSIONS,
};

export { ContentCarouselPanel, ContentCarouselPanelConfig };
