/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './FAQPage.css';

import { Search } from 'carbon-components-react';
import React from 'react';

import {
  GuidancePanelList,
  GuidancePanelListItem,
} from '../components/demoGuidanceComponent/commonComponents/GuidancePanelList';
import { DemoPage } from '../components/DemoPage';
import { LendyrButton } from '../components/LendyrButton';
import { LendyrPageType, LendyrPanel } from '../framework/demoTypes';
import { useDemoLocation } from '../framework/hooks/selectorHooks';
import { useWebChatInstance } from '../framework/hooks/useWebChatInstance';
import { DEFAULT_CARD_LINKS } from '../framework/utils/demoConstants';

const FAQ_PAGE_CUSTOM_ELEMENT_ID = 'web-chat-custom-element';

function FAQPage() {
  const instance = useWebChatInstance();
  const { panel } = useDemoLocation();
  const isPositionOfWebChatFeature = panel === LendyrPanel.POSITION;

  return (
    <DemoPage
      className="FAQPage"
      title="Frequently Asked Questions"
      description="Support and Resources."
      type={LendyrPageType.TERTIARY}
      cardLinks={DEFAULT_CARD_LINKS}
      TopContent={
        isPositionOfWebChatFeature ? null : (
          <Search id="DemoPageFAQ__Search" labelText="" placeholder="Type your question or a keyword" size="lg" />
        )
      }
      MainContent={
        <div className="DemoPage__CenterContent">
          {isPositionOfWebChatFeature && <div className="DemoPageFAQ__CustomElement" id={FAQ_PAGE_CUSTOM_ELEMENT_ID} />}
          <section>
            <div className="DemoPageFAQ__Heading">Overview</div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">
                What is Lendyr? How is it different from other financial institutions?
              </div>
              <div className="DemoPageFAQ__Answer">
                Lendyr is a customer-first bank, and the money we save on overhead is passed on to our clients in the
                form of high interest rates and minimal fees. For example, we don't charge overdraft fees. We also have
                convenient customer service options that are ideal for people who don't need a great deal of help and
                support for their banking.
              </div>
            </div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">Where can I find the nearest Lendyr branch?</div>
              <div className="DemoPageFAQ__Answer">
                Your nearest branch is closer than you think! Ask our virtual assistant in the web chat.
              </div>
            </div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">What if I lose my credit card?</div>
              <div className="DemoPageFAQ__Answer">
                If your card is lost or stolen, contact us online, by phone or through our mobile app as quickly as
                possible. We offer credit cards offer zero liability to our clients, protecting you from having to pay
                for unauthorized purchases.
              </div>
            </div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">Can I use my points on airfare?</div>
              <div className="DemoPageFAQ__Answer">
                Using points and miles to book your flights is a great option. Not only do you save money, but you can
                use your points to book flights that may not be feasible when paying cash.
              </div>
            </div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">Which digital wallets can I use with my Lendyr cards?</div>
              <div className="DemoPageFAQ__Answer">
                You can use any of the following digital wallets for your Lendyr cards; Apple Pay, Fitbit Pay, Google
                Pay, Paypal, Samsung Pay
              </div>
            </div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question DemoPageFAQ__DisputeChargeQuestion">
                How do I dispute a charge?
                {instance && (
                  <LendyrButton
                    className="DemoPageFAQ__StartWalkthroughButton"
                    onClick={() => instance.send('START_DISPUTE_CHARGE', { silent: true })}
                  >
                    Start Walkthrough
                  </LendyrButton>
                )}
              </div>
              <div className="DemoPageFAQ__Answer">
                {instance && (
                  <>
                    To dispute your charges, you can take a walkthrough by clicking the Start Walkthrough button, or
                    follow the steps below:
                  </>
                )}
                <GuidancePanelList ordered>
                  <GuidancePanelListItem>Visit the Transactions page.</GuidancePanelListItem>
                  <GuidancePanelListItem>Scroll down to the table of recent transactions.</GuidancePanelListItem>
                  <GuidancePanelListItem>
                    You can find the "Dispute" button for a charge on the right side of the table. When you click the
                    "Dispute" button, we begin the process of evaluating the transaction and your reimbursement options.
                    Our dispute agents will follow up via phone or email within 48 hours.
                  </GuidancePanelListItem>
                </GuidancePanelList>
              </div>
            </div>
          </section>
          <section>
            <div className="DemoPageFAQ__Heading">Zelle&reg; Frequently Asked Questions (FAQs)</div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">What is Zelle&reg;?</div>
              <div className="DemoPageFAQ__Answer">
                Zelle&reg; is a fast, safe and easy way to send money directly between almost any bank accounts in the
                U.S., typically within minutes. With just an email address or U.S. mobile phone number, you can send
                money to people you trust, regardless of where they bank.
              </div>
            </div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">How do I use Zelle&reg; to receive money?</div>
              <div className="DemoPageFAQ__Answer">
                If you have already enrolled with Zelle&reg;, you do not need to take any further action. The money will
                be sent directly into your bank account, typically within minutes.
                <br />
                <br />
                If you have not yet enrolled with Zelle&reg;, follow these steps:
                <GuidancePanelList>
                  <GuidancePanelListItem>
                    Click on the link provided in the payment notification you received via email or text message.
                  </GuidancePanelListItem>
                  <GuidancePanelListItem>Select Lendyr.</GuidancePanelListItem>
                  <GuidancePanelListItem>
                    Follow the instructions provided on the page to enroll and receive your payment. Pay attention to
                    the email address or U.S. mobile number where you received the payment notification - you should
                    enroll with Zelle&reg; using that email address or U.S. mobile number to ensure you receive your
                    money.
                  </GuidancePanelListItem>
                </GuidancePanelList>
              </div>
            </div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">Is Zelle&reg; free?</div>
              <div className="DemoPageFAQ__Answer">Lendyr does not charge any fees to use Zelle&reg;.</div>
            </div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">Can we use Zelle&reg; internationally?</div>
              <div className="DemoPageFAQ__Answer">
                In order to use Zelle&reg;, the sender and recipient’s bank accounts must be based in the U.S.
              </div>
            </div>
          </section>
          <section>
            <div className="DemoPageFAQ__Heading">Wire Transfer Frequently Asked Questions (FAQs)</div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">What is a wire transfer?</div>
              <div className="DemoPageFAQ__Answer">
                A wire transfer is a service that allows customers to transfer money electronically to another financial
                institution (e.g., bank, investment firm, mortgage lender). Typically, a wire transfer arrives at the
                other financial institution within 24 hours, depending on the sender’s financial institution cutoff
                time.
              </div>
            </div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">What types of wire transfers are available at Lendyr?</div>
              <div className="DemoPageFAQ__Answer">
                Lendyr offers customers the option to send and receive domestic wire transfers to and from other banks
                and financial institutions.
              </div>
            </div>
            <div className="DemoPageFAQ__Item">
              <div className="DemoPageFAQ__Question">Can I cancel a wire transfer after I’ve submitted it?</div>
              <div className="DemoPageFAQ__Answer">
                Once you submit a wire transfer request through the self-service portal, there <strong>may</strong> be a
                short window of time to cancel it. To do so, you must call the Contact Center at 123-456-7890 for help.
                The Contact Center will do its best to cancel the wire transfer request; however, once the wire transfer
                has been sent, it cannot be canceled.
              </div>
            </div>
          </section>
        </div>
      }
    />
  );
}

export { FAQPage, FAQ_PAGE_CUSTOM_ELEMENT_ID };
