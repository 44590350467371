/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import './CreditCardsPage.css';

import { Money32, PlanePrivate32, ShoppingCart32, Tag32 } from '@carbon/icons-react';
import React from 'react';
import { useSelector } from 'react-redux';

import lendyrSideImageCreditCardsVacation from '../../assets/lendyr-side-image-credit-cards-vacation.jpg';
import { DemoPage } from '../../components/DemoPage';
import { LendyrPageType } from '../../framework/demoTypes';
import { DemoAppState } from '../../framework/store/DemoAppState';
import { CardLinkComponentProps } from '../../framework/types/CardLinkComponentProps';
import { CreditCardApplication } from './CreditCardApplication';
import { CreditCardOffers } from './CreditCardOffers';

const CARD_LINKS: CardLinkComponentProps[] = [
  { Icon: PlanePrivate32, title: 'Travel' },
  { Icon: Money32, title: 'Cash Back' },
  { Icon: ShoppingCart32, title: 'Rewards' },
  { Icon: Tag32, title: 'No Annual Fees' },
];

function CreditCardsPage() {
  const selectedCardOffer = useSelector((state: DemoAppState) => state.pageState.creditCardsPage.selectedCardOffer);
  return (
    <DemoPage
      className="CreditCards"
      title="Credit Cards"
      description="Cash back. Rewards. Bonuses."
      type={LendyrPageType.SECONDARY}
      cardLinks={CARD_LINKS}
      MainContent={
        <>
          {!selectedCardOffer && <CreditCardOffers />}
          {selectedCardOffer && <CreditCardApplication cardOffer={selectedCardOffer} />}
        </>
      }
    >
      {!selectedCardOffer && (
        <div className="CreditCards__BottomSection">
          <div className="CreditCards__ContentWrapper">
            <div className="CreditCards__Content">
              <div className="CreditCards__TextWrapper">
                <div className="DemoPageCommon__Title">Cash Back</div>
                <div className="DemoPageCommon__Description">
                  The advantages of enrolling in cash-back programs are amazing. You get cash back for spending your
                  money, whether using your credit or debit card or even shopping online.
                </div>
              </div>
              <div className="CreditCards__TextWrapper">
                <div className="DemoPageCommon__Title">Reward points</div>
                <div className="DemoPageCommon__Description">
                  Reward points are earned through making purchases in select categories. Points can be earned through
                  bonus promotions or by making purchases in certain categories such as dining, travel, gas and more.
                </div>
              </div>
              <div className="CreditCards__TextWrapper">
                <div className="DemoPageCommon__Title">Rewards</div>
                <div className="DemoPageCommon__Description">
                  If you use your card regularly, earning rewards is one of the primary advantages. You can redeem them
                  for things you were going to purchase already as well as the occasional treat.
                </div>
              </div>
            </div>
            <img
              className="CreditCards__VacationImage"
              src={lendyrSideImageCreditCardsVacation}
              alt="take a vacation"
            />
          </div>
        </div>
      )}
    </DemoPage>
  );
}

export { CreditCardsPage };
