/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2019, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

/**
 * This file documents the interfaces used by the API for communicating with Watson Assistant. The published
 * documentation for the API can be found here: https://cloud.ibm.com/apidocs/assistant-v2#send-user-input-to-assistant.
 */

/**
 * This is the name of the main dialog skill. A recent change did allow for skills with other names to be created so
 * this is mainly used for legacy or default purposes. This is used when looking up dialog specific data from the
 * context.
 */
export const MAIN_SKILL = 'main skill';

/**
 * This is the name of the skill that is used by actions. This is used when looking up dialog specific data from the
 * context.
 */
export const ACTIONS_SKILL = 'actions skill';

/**
 * ID of the start of the dialog as it appears in the dialog state. This is used when identifying dialog responses
 * that are part of a multi-turn response.
 */
export const DIALOG_ROOT = 'root';

/**
 * The set of possible dialog response preferences for an options response.
 */
export enum DialogResponsePreference {
  /**
   * Indicates the options should be displayed as a drop-down.
   */
  DROPDOWN = 'dropdown',

  /**
   * Indicates the options should be displayed as buttons.
   */
  BUTTON = 'button',
}

/**
 * The set of possible message types in a request.
 */
export enum MessageType {
  TEXT = 'text',

  /**
   * Captures both events created by Suggestions and events created during a conversation between Cade and a HumanAgent
   */
  EVENT = 'event',

  /**
   * Indicates explicitly that the client would like to connect to an agent and is requesting a message response
   * that will provide the information for doing that.
   */
  REQUEST_CONNECT_TO_AGENT = 'request_connect_to_agent',

  /**
   * Indicates that this message should execute a search using an attached discovery skill and bypass any actions or
   * dialog skill that may also be attached.
   */
  SEARCH = 'search',
}

/**
 * The set of possible message types in a response from Watson Assistant.
 */
export enum MessageResponseTypes {
  TEXT = 'text',
  OPTION = 'option',
  /**
   * Suggestion is typically presented to the user as disambiguation. However, it can also be presented to Agents to
   * help teach the bot.
   */
  SUGGESTION = 'suggestion',
  CONNECT_TO_AGENT = 'connect_to_agent',
  IMAGE = 'image',
  PAUSE = 'pause',
  SEARCH = 'search',
  CHANNEL_TRANSFER = 'channel_transfer',
  USER_DEFINED = 'user_defined',
  IFRAME = 'iframe',
  VIDEO = 'video',
  AUDIO = 'audio',
  DATE = 'date',
}

/**
 * A suggestion can be an indication of a single turn (FAQ) or multi-turn. Typically, the type of suggestion will be
 * used when offering a suggestion to an agent. If it is a single turn, the agent can just copy/paste it to the
 * user. If it is a multi-turn the agent might hand it off back to Watson.
 */
export enum SuggestionTypes {
  /**
   * Single turn.
   */
  ANSWER = 'answer',

  /**
   * Multi turn.
   */
  FLOW = 'flow',
}

/**
 * This interface represents the top-level content of a message that is sent to the integrations backend.
 */
export interface ChatRequest<TInputType extends BaseMessageInput = MessageInput | EventInput> {
  /**
   * The value of the assistant message.
   */
  value: MessageRequest<TInputType>;

  /**
   * The id of the current session to send the message for. This may be null in which case a new session will be
   * created.
   */
  session_id?: string;

  /**
   * The id of the user making the request. This value comes from the app config for the widget.
   */
  user_id: string;

  /**
   * Indicates if this request and its response should be excluded from history.
   */
  exclude_history?: boolean;
}

/**
 * This interface represents the main content that is sent to Watson Assistant when a response is requested.
 */
export interface MessageRequest<TInputType extends BaseMessageInput = MessageInput> {
  /**
   * The input data to the assistant to make in this request.
   */
  input: TInputType;

  /**
   * Optional context which is added from external resources.
   */
  context?: MessageContext;
}

/**
 * The id of the user. There is no explicit definition of user in the Watson Assistant API, however there is an undocumented property (source), which
 * effectively is trying to capture this information. As the integrations evolve we expect the notion of a User Profile to get more rich (See wwx-haa-types).
 */
export interface User {
  id: string;
}

export interface BaseMessageInput {
  /**
   * The type of user input. Currently, only text input is supported.
   */
  message_type?: MessageType;
}

/**
 * Base interface for events that capture either a meaningful conversation, suggestions changes, history updates or
 * interactions between a user and human agents.
 *
 * The message_type of EventInput must be of value `event`.
 */
export interface EventInput<TEventInputType = EventInputData> extends BaseMessageInput {
  event: TEventInputType;

  message_type: MessageType.EVENT;
}

/**
 * Input for an event. The name of the event is mandatory. Additional fields depend on the event.
 *
 * @template TNameType The type of the name property for the event. This can just be a string or it can be a
 * specific string in order to create type safety ensuring each event has the right name (e.g. "typeof MY_EVENT_NAME").
 */
export interface EventInputData<TNameType extends string = string> {
  /**
   * The name of the event.
   */
  name: TNameType;
}

export interface MessageInput extends BaseMessageInput {
  /**
   * The text of the user input. This string cannot contain carriage return, newline, or tab characters.
   */
  text?: string;

  /**
   * An identifier denoting that this message input represents an approved suggestion.
   */
  suggestion_id?: string;

  /**
   * The source of the user input. Defined by service desks.
   */
  source?: User;

  /**
   * Original time this message was sent. This may be used by the service to log historical events in the order they
   * were really created (as opposed to when they got processed by the service). The time is in ISO format (e.g.
   * 2020-03-15T08:59:56.952Z).
   */
  client_timestamp?: string;

  /**
   * A request can denote the intents and avoid the need to activate the service NLU. This is typically useful when the request
   * is an approval of a suggestion.
   */
  intents?: {
    /**
     * name of intent
     */
    intent: string;
    confidence: number; // example 0.708;
  }[];

  /**
   * A request can denote the entities and avoid the need to activate the service NLU. This is typically useful when the request
   * is an approval of a suggestion.
   */
  entities?: {
    entity: string; // eg, "card",
    location: number[];
    value: string; // eg, "credit",
    confidence: number; // eg, 1
  }[];

  /**
   * Options to send to dialog.
   */
  options?: MessageInputOptions;

  /**
   * An array of media types that can be sent to assistant via a message request
   */
  attachments?: MessageInputAttachment[];

  /**
   * Info that is used internally and not exposed to customers
   */
  internal?: MessageInputInternal;
}

export interface MessageInputAttachment {
  /**
   * The content type for the media stored at media_url. ie: image/jpeg, this is not validated to make this extensible.
   */
  media_type?: string;

  /**
   * A URL referencing the content of the media attached to the message.
   */
  url: string;
}

export interface MessageInputOptions {
  /**
   * Outputs multiple intents. Default value is false.
   */
  alternate_intents?: boolean;

  /**
   * Forces suggestions to be returned. Those suggestions may different from ones returned by disambiguation due to
   * service thresholds. Default value is false.
   */
  suggestion_only?: boolean;

  /**
   * Resets current conversation state before responding. The default value is false.
   */
  restart?: boolean;

  /**
   * Causes response to contain output.debug. The default value is false.
   */
  debug?: boolean;

  /**
   * Causes response to contain output.context. The default value is false.
   */
  return_context?: boolean;

  /**
   * Causes response to contain inside output.context the full state.
   */
  export?: boolean;

  /**
   * Indicates if the client wants the assistant to return alternate responses. The default value is false.
   */
  alternate_responses?: boolean;

  /**
   * Settings specifically for disambiguation.
   */
  disambiguation?: {
    /**
     * Indicates if alternates responses should be returned when disambiguation fires. This is distinctly different
     * from the "alternate_responses" flag above that indicates if alternate responses should be returned for any
     * response. The default value is false.
     */
    alternate_responses?: boolean;
  };
}

export interface MessageInputInternal {
  /**
   * Events to track in Amplitude
   */
  track?: {
    /**
     * Custom properties for an api_call event in Amplitude
     */
    api_call: Record<string, unknown>;
  };

  /**
   * Info about the integration, actions webhook callout feature temporarily requires passing ip and a request id for Store/Orchestrator to post back a reply
   */
  integrations?: Record<string, unknown>;

  /**
   * Transaction ID of the message call
   */
  transaction_id?: string;

  /**
   * Info required to route a callout response back to the originating store pod
   */
  integration_callback?: {
    /**
     * The IP address of the originating store pod of the callout
     */
    ip_address: string;

    /**
     * Request ID of the callout
     */
    request_id: string;
  };

  /**
   * True if the message call to assistant was stateless, false or undefined otherwise
   */
  is_stateless?: boolean;

  /**
   * The name of the actions skill variable that a callout response is stored in
   */
  callout_result_variable?: string;

  /**
   * Details about the callout request that was sent
   */
  callout_request?: TurnEventCalloutRequest;

  /**
   * An error object that can be set to propagate errors through components
   */
  error?: any;
}

/**
 * This interface represents the main response content that is received by the client when Watson Assistant responds
 * to a previous request.
 */
export interface MessageResponse {
  /**
   * Assistant output to be rendered or processed by the client.
   */
  output: MessageOutput;

  /**
   * If options.return_context is set to true, the current accumulative context of the service will be provided.
   */
  context?: MessageContext;
}

/**
 * The output value for an assistant response.
 */
export interface MessageOutput {
  /**
   * Array of intents.
   */
  intents?: [];

  /**
   * Array of entities.
   */
  entities?: [];

  /**
   * Responses intended to be processed by a generic channel.
   */
  generic?: GenericItem[];

  /**
   * Possible alternate responses.
   */
  alternate_responses?: GenericItem[];

  /**
   * An object containing any custom properties included in the response.
   */
  user_defined?: Record<string, unknown>;

  /**
   * @deprecated Used by the channels to render channel specific responses. One should use an item in output.generic of
   * type user_defined.
   */
  integrations?: Record<string, unknown>;
  /**
   * Contains extra information that is returned when we ask for debug information.
   */
  debug?: {
    nodes_visited?: VisitedNode[];
    /**
     * Indicates if the branch from a previous flow just exited.
     */
    branch_exited?: boolean;

    /**
     * Indicates the reason if the branch from a previous flow just exited.
     */
    branch_exited_reason?: 'completed' | 'fallback';

    /**
     * Actions specific debug property.
     */
    turn_events?: TurnEvent[];
  };
}

/**
 * Represents an entry in the 'turn_events' debug array.
 */
export interface TurnEvent {
  /**
   * An action turn event that was triggered.
   */
  event: string;

  source: {
    /**
     * The step that was visited.
     */
    step: string;

    /**
     * The action this event belongs to.
     */
    action: string;

    /**
     * The source type. This could be 'action' or 'step'.
     */
    type: string;
  };

  /**
   * Determines if the current event is asking a question.
   */
  has_question?: boolean;

  /**
   * Determines if the user was prompted. Seems to only appear when a step was answered.
   */
  prompted?: boolean;

  /**
   * The condition type that was met to trigger event.
   */
  condition_type?: string;

  /**
   * Contains the error message for a turn. This field may contain response details in case of a failed webhook request.
   */
  error?: {
    message: string;
  };

  callout?: {
    /**
     * The type of the callout
     */
    type: string;

    /**
     * Internal data initially created by Dialog when a callout is made
     */
    internal?: {
      /**
       * The integration ID of the extension that was called out to
       */
      catalog_item_id: string;
    };

    /**
     * Request details for an extensions callout
     */
    request?: TurnEventCalloutRequest;

    /**
     * Response details from an extensions callout for a turn event.
     */
    response?: TurnEventCalloutResponse;
  };
}

/**
 * Represents the response from an extension callout that may appear in a turn event.
 */
export interface TurnEventCalloutResponse {
  /**
   * The status code returned from the extensions that was called out to.
   */
  status_code: number;

  /**
   * The entire response body from the extension that was called out to. This can be anything that the extension returns.
   */
  body: unknown;
}

export interface TurnEventCalloutRequest {
  /**
   * The REST method used to make the request: GET, POST, PUT, etc.
   */
  method: string;

  /**
   * The entire URL of the callout request including all query parameters.
   */
  url: string;

  /**
   * The entire request body sent to the extension that was called out to.
   */
  body?: unknown;
}

/**
 * Represents an entry in the "nodes_visited" debug array.
 */
export interface VisitedNode {
  /**
   * A dialog node that was triggered during processing of the input message.
   */
  dialog_node?: string;

  /**
   * The title of the dialog node.
   */
  title?: string;

  /**
   * The conditions that triggered the dialog node.
   */
  conditions?: string;
}

/**
 * Contextual information gathered by the service over a single session.
 */
export interface MessageContext {
  global?: {
    /**
     * Global context
     */
    system?: MessageSystemContext;
  };

  /**
   * Used by integrations to provide additional metadata which is integration specific.
   */
  integrations?: {
    channel: {
      name: string;
      private: {
        user: {
          id: string;
          phone_number?: string;
        };
      };
    };
    [integration_name: string]: Record<string, any>;
  };
  skills?: {
    /**
     * Skill specific context
     */
    [skill_key: string]: {
      user_defined: Record<string, any>;
      system?: {
        /**
         * System context for a specific skill.
         */
        state?: string; // Base64 encoded JSON string representing the dialog state.
      };

      /**
       * Actions session (skill-global) variables
       */
      skill_variables?: Record<string, string>;

      /**
       * Actions local variables
       */
      action_variables?: Record<string, any>;
    };
  };
}

export interface MessageSystemContext {
  timezone?: string; // "Europe/Budapest",
  user_id: string;
  turn_count?: number;

  /**
   * Indicates that the system does not expect additional input from the end user. Digital channels will just reply,
   * returning the thread of control to the system. Channels such as Web chat and Voice might have a dedicated
   * implementation. For instance, for Voice one might play music and in parallel return the thread of control to
   * the system.
   */
  skip_user_input?: boolean;

  /**
   * The current system locale. Example: "en-US".
   */
  locale?: string;
}

/**
 * Decoded representation of a dialog skill state.
 */
export interface DialogState {
  /**
   * Position of the conversation in the dialog tree. A stack which has only a DIALOG ROOT represents that the conversation has
   * exited the current branch.
   */
  dialog_stack?: [
    {
      /**
       * ID of the dialog node.
       */
      dialog_node: string;
    },
  ];
  digressed: boolean;
}

/**
 * Channel options that only the chat channel can make use of.
 */
export interface ChatChannelItemOptions {
  dimensions?: {
    /**
     * This property's value is used to calculate a responsive height for web chat's media player so that its aspect
     * ratio is the same between different screen widths.
     */
    base_height?: number;
  };
}

/**
 * Channel options that only the voice channel can make use of.
 */
export interface VoiceChannelItemOptions {
  /**
   * Determines if an audio file should loop. If a user is placed on hold, the assistant can loop an audio file while
   * the user is waiting.
   */
  loop: boolean;
}

/**
 * For channel-specific properties that shouldn't be in the root of the generic item, they are placed in their
 * respective channel option.
 */
export interface ChannelItemOptions {
  chat?: ChatChannelItemOptions;
  voice_telephony?: VoiceChannelItemOptions;
}

/**
 * A single dialog response from Watson Assistant.
 */
export interface GenericItem {
  /**
   * The type of response returned by the dialog node.
   */
  response_type: MessageResponseTypes;

  /**
   * String representation for the message for low fidelity channels
   */
  fallback?: string;

  /**
   * If defined, denotes the channels which this item should be rendered on.
   * Integrations backend, does the filtering for the channels it supports.
   * If not defined, all the channels should render this item.
   */
  channels?: {
    channel: string;
  }[];

  /**
   * Channel options are for grouping channel-specific properties that enable channel-specific behavior.
   */
  channel_options?: ChannelItemOptions;
}

/**
 * User defined Item can be of any structure.
 */
export interface UserDefinedItem extends GenericItem {
  user_defined: Record<string, unknown>;
}

export interface TextItem extends GenericItem {
  /**
   * The text of the response.
   */
  text?: string;
}

export interface SearchResultItem extends GenericItem {
  /**
   * The title or introductory text to show before the response. This text is defined in the search skill configuration.
   */
  header: string;

  /**
   * The title or introductory text to show before the response.
   */
  title?: string;

  /**
   * The API proposal https://github.ibm.com/Watson/developer-cloud--api-proposals/pull/293 introduced a breaking
   * change to switch to display primary and additional search results instead of results. We need to also support
   * the old behavior so check to see if we've got any of the new results. An array of objects containing search
   * results.
   */
  results?: SearchResult[];

  /**
   * With the new api proposal to have primary results and secondary results these have to be added here.
   * An array of objects containing primary search results.
   */
  primary_results?: SearchResult[];

  /**
   * An array of objects containing additional search results.
   */
  additional_results?: SearchResult[];
}

export interface SearchResultHighlight {
  /**
   * A description of the search result. This is taken from a highlight field in the
   * Discovery service response, as specified in the search skill configuration.
   */
  body: string[];

  /**
   * The title of the search result. This is taken from a highlight field in the Discovery service response, as
   * specified in the search skill configuration.
   */
  title: string[];
}

/**
 * The metadata for a single search result.
 */
export interface SearchResultMetadata {
  /**
   * The confidence score for the given result. For more information about how the confidence is calculated, see the
   * Discovery service documentation.
   */
  confidence: number;

  /**
   * An unbounded measure of the relevance of a particular result, dependent on the query and matching document. A
   * higher score indicates a greater match to the query parameters.
   */
  score: number;
}

interface SearchResultAnswer {
  /**
   * The actual text in answer from the discovery search result.
   */
  text: string;

  /**
   * Confidence score of the answer
   */
  confidence: number;
}

/**
 * A single search result.
 */
export interface SearchResult {
  /**
   * The unique identifier of the document in the Discovery service collection.
   */
  id: string;

  /**
   * A description of the search result. This is taken from an abstract or summary field in the
   * Discovery service response, as specified in the search skill configuration.
   */
  body?: string;

  /**
   * The title of the search result. This is taken from a title field in the Discovery service response, as
   * specified in the search skill configuration.
   */
  title?: string;

  /**
   * The URL of the original data object in its native data source.
   */
  url?: string;

  highlight?: SearchResultHighlight;

  /**
   * The metadata for this search result.
   */
  result_metadata: SearchResultMetadata;

  /**
   * The answers for search results.
   */
  answers?: SearchResultAnswer[];
}

export interface ConnectToAgentItem extends GenericItem {
  /**
   * A label identifying the topic of the conversation, derived from the user_label property of the relevant node.
   */
  topic?: string;

  /**
   * A message to be sent to the human agent who will be taking over the conversation.
   */
  message_to_human_agent?: string;

  /**
   * Dialog node id that was used to determine the topic.
   */
  dialog_node?: string;

  /**
   * Contains the message to be rendered if escalate to agent is requested and there are agent(s) available.
   */
  agent_available?: {
    message: string;
  };

  /**
   * Contains the message to be rendered if escalate to agent is requested and there are no agents available.
   */
  agent_unavailable?: {
    message: string;
  };
}

export interface PauseItem extends GenericItem {
  /**
   * How long to pause, in milliseconds.
   */
  time?: number;

  /**
   * Whether to send a "user is typing" event during the pause.
   */
  typing?: boolean;
}

export interface ChannelTransferItem extends GenericItem {
  /**
   * A message to display to the user on the source channel before the transfer occurred.
   */
  message_to_user: string;

  /**
   * Details on how to construct the channel transfer connection. For instance for a transfer to chat there is a need to provide the link, session id and auth code.
   */
  transfer_info?: {
    target?: {
      [channel_name: string]: Record<string, string>;
    };
  };
}

export interface OptionItem extends GenericItem {
  /**
   * An array of objects describing the options from which the user can choose.
   */
  options: Option[];

  /**
   * Title to be shown alongside the options. Some channels (Facebook, Slack) will not show options unless a title
   * is available.
   */
  title?: string;
  description?: string;

  /**
   * The preferred type of control to display.
   */
  preference?: DialogResponsePreference;
}

export interface Option {
  /**
   * The user-facing label for the option or disambiguation suggestion. This label is taken from the user_label property
   * of the corresponding dialog node.
   */
  label: string;
  value: {
    /**
     * An input object that should be sent back to the assistant when this option is chosen by a user.
     */
    input: MessageInput;
  };
}

export interface SuggestionItem extends GenericItem {
  /**
   * An array of objects describing the possible matching dialog nodes from which the user can choose.
   */
  suggestions?: Suggestion[];

  /**
   * The title or introductory text to show before the response. For example: "Did you mean A or B?".
   */
  title?: string;
}

export interface Suggestion extends Option {
  dialog_node: string;
  suggestion_type?: SuggestionTypes;
  /**
   * The dialog output that will be returned from the Watson Assistant service if the user selects the corresponding
   * option.
   */
  output: {
    generic: GenericItem[];
  };
}

export interface IFrameItem extends GenericItem {
  /**
   * The source URL to an embeddable page
   */
  source: string;

  /**
   * The preview image of the source URL. This property is unfurled from the source URL at runtime and not provided by Tanya
   */
  image_url?: string;

  /**
   * The title of the source URL. This property is unfurled from the source URL at runtime but can optionally be overridden provided by Tanya
   */
  title?: string;

  /**
   * The description of the source URL. This property is unfurled from the source URL at runtime and not provided by Tanya
   */
  description?: string;
}

/**
 * A reusable media object that may need to display a title and description with an alt_text to label the item for
 * accessibility purposes.
 */
export interface MediaItem extends GenericItem {
  /**
   * The url pointing to a media source, whether audio, video, or image.
   */
  source: string;

  /**
   * The title for the item.
   */
  title?: string;

  /**
   * The description for the item.
   */
  description?: string;

  /**
   * The alt text for labeling the item. Screen readers will announce this text when the user's virtual cursor
   * is focused on the item.
   */
  alt_text?: string;
}

/**
 * The image response type definition for future reuse.
 */
export type ImageItem = MediaItem;

/**
 * The video response type definition for future reuse.
 */
export type VideoItem = MediaItem;

/**
 * The audio response type definition for future reuse.
 */
export type AudioItem = MediaItem;

/**
 * This is the response item that represents a request for a date which should prompt the client to use a date picker or
 * similar control to provide a date. There are currently no additional properties of the response.
 */
export type DateItem = GenericItem;
