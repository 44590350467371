/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';

import { LendyrPage, LendyrSection } from '../../../framework/demoTypes';
import { LendyrAppState } from '../../../lendyr/store/LendyrAppState';
import { GuidancePanelTourClickableTile } from '../commonComponents/GuidancePanelTourClickableTile';

/**
 * This component renders the clickable tiles that navigate the user to a demo tour in the home panel overlay.
 */
function HomeSectionGuidedTours() {
  const toursStorageState = useSelector((state: LendyrAppState) => state.persistedToBrowserLocalStorage.siteData.tours);

  return (
    <>
      <div className="HomeSectionGuidedTours__Title">Getting Started</div>
      <div className="HomeSectionGuidedTours__Content">
        <GuidancePanelTourClickableTile
          title="Tell, Do, & Show"
          description="Watson Assistant helps your customers help themselves through conversation, automation, and demonstration."
          section={LendyrSection.TELL_DO_SHOW}
          page={LendyrPage.HOME}
          showCheckmark={toursStorageState[LendyrSection.TELL_DO_SHOW]?.visited}
          color="purple"
          duration="5 min"
        />
        <GuidancePanelTourClickableTile
          title="Generative AI and Large Language Models"
          description="Watsonx large language models understand and automate better conversational experiences."
          section={LendyrSection.GENERATIVE_AI}
          showCheckmark={toursStorageState[LendyrSection.GENERATIVE_AI]?.visited}
          color="teal"
          duration="3 min"
        />
        <GuidancePanelTourClickableTile
          title="The Build Experience"
          description="Designed for non-technical teams to build, integrate, and launch in hours, not days."
          section={LendyrSection.BUILD_EXPERIENCE}
          showCheckmark={toursStorageState[LendyrSection.BUILD_EXPERIENCE]?.visited}
          color="blue"
          duration="8 min"
        />
      </div>
      <div className="HomeSectionGuidedTours__Title">Dive Deeper</div>
      <div className="HomeSectionGuidedTours__Content">
        <GuidancePanelTourClickableTile
          title="Drive Customer Outcomes"
          description="Intelligent analytics that track your customer experiences from problem found to problem solved."
          section={LendyrSection.DRIVE_CUSTOMER_OUTCOMES}
          page={LendyrPage.DRIVE_CUSTOMER_OUTCOMES_TOUR}
          showCheckmark={toursStorageState[LendyrSection.DRIVE_CUSTOMER_OUTCOMES]?.visited}
          duration="2 min"
        />
        <GuidancePanelTourClickableTile
          title="Accessible & Scalable AI"
          description="Out-of-the-box artificial intelligence that engages in natural, seamless conversation."
          section={LendyrSection.ACCESSIBLE_SCALABLE_AI}
          page={LendyrPage.HOME}
          showCheckmark={toursStorageState[LendyrSection.ACCESSIBLE_SCALABLE_AI]?.visited}
          duration="5 min"
        />
        <GuidancePanelTourClickableTile
          title="Unify & Personalized"
          description="An open integration framework makes delivering consistent and personalized experiences easy, without changing your tech stack."
          section={LendyrSection.UNIFY_PERSONALIZED}
          page={LendyrPage.HOME}
          showCheckmark={toursStorageState[LendyrSection.UNIFY_PERSONALIZED]?.visited}
          duration="3 min"
        />
        <GuidancePanelTourClickableTile
          title="Web Chat Essentials"
          description="Get an introduction to the essential features of the Watson Assistant web chat."
          section={LendyrSection.ESSENTIALS}
          page={LendyrPage.HOME}
          showCheckmark={toursStorageState[LendyrSection.ESSENTIALS]?.visited}
          duration="8 min"
        />
        <GuidancePanelTourClickableTile
          title="Iterate Quickly"
          description="Unlock your team’s potential to improve and iterate quickly on your support experiences."
          section={LendyrSection.ITERATE_QUICKLY}
          page={LendyrPage.ITERATE_QUICKLY_TOUR}
          showCheckmark={toursStorageState[LendyrSection.ITERATE_QUICKLY]?.visited}
          duration="5 min"
        />
        <GuidancePanelTourClickableTile
          title="Phone"
          description="Watson Assistant integrates with the contact center for 24/7 support with expressive voices."
          section={LendyrSection.PHONE}
          page={LendyrPage.PHONE_TOUR}
          showCheckmark={toursStorageState[LendyrSection.PHONE]?.visited}
          duration="6 min"
        />
      </div>
    </>
  );
}

export { HomeSectionGuidedTours };
