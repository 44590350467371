/**
 *
 * IBM Confidential
 *
 * (C) Copyright IBM Corp. 2022, 2023
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U. S. Copyright Office
 *
 * US Government Users Restricted Rights - Use, duplication or
 * disclosure restricted by GSA ADP Schedule Contract with IBM Corp.
 *
 */

import { PagePublicConfig } from '../web-chat-dependencies/types/PublicConfig';
import { WatsonAssistantChatInstance } from '../web-chat-dependencies/types/WatsonAssistantChatInstance';
import { getCDNURL } from '../web-chat-dependencies/utils/config';
import { getQueryStringParams } from './utils/demoUtils';

/**
 * Injects a web chat instance into the page.
 */
function loadWebChatScript() {
  const { clientVersion } = getQueryStringParams();
  const cdnURL = getCDNURL();

  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = () => resolve();
    script.onerror = () => reject();
    script.src = `${cdnURL}/versions/${clientVersion || '7'}/WatsonAssistantChatEntry.js`;
    document.head.appendChild(script);
  });
}

async function loadWebChat(options: PagePublicConfig) {
  return (window as any).loadWatsonAssistantChat(options);
}

/**
 * Destroys web chat from the page and fires the onDestroy callback after doing so.
 */
function destroyWebChat(instance: WatsonAssistantChatInstance) {
  if (instance) {
    // Manually close web chat so that the pre:close event that the pre:close event for mobile view hides the custom
    // element that renders web chat since .destroySession() doesn't fire close events.
    instance.closeWindow().then(() => {
      instance.destroySession();
      instance.destroy();
    });
  }
}

export { loadWebChatScript, loadWebChat, destroyWebChat };
